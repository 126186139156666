import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { TfiEye } from 'react-icons/tfi'
import { formatedDate,renderName,renderNameInitial } from '../../../utils/Helper'

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Employer', field: 'EmpTempName' },
    { label: 'Offer Process Ended on', field: 'date' },
    { label: 'Status', field: 'interviewStatus' },
    { label: 'Remark', field: 'reasonForCancel' },
]

const CndOfferHistoryList = ({ data, isLoading, handleHistoryOfferPdf }) => {
 

    const renderStatus = (status, index) => {
        return <>
            {status}
            <div className={`icon icon1 mx-1 mx-sm-3`} onClick={() => handleHistoryOfferPdf(index)}>
                <TfiEye className='fs-4' />
                <span className="text">View OFFER</span>
            </div>
        </>
    }

    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.EmpTempName),
            candidateName: renderName(data.EmpTempName),
            date: formatedDate(data.date),
            status: renderStatus(data.status, index)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} isLoading={isLoading} />
    )
}

export default React.memo(CndOfferHistoryList)