import React, { memo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import DialogAlert from "../customModal/customAlertModal";
import getBaseUrl from "../../hooks/http";
import { IoIosAddCircle, IoIosCloseCircle } from "react-icons/io";
import { TfiEye } from "react-icons/tfi";
import FileViewerModal from "./FileViewerModal";

import './yourDocument.css'

const YourDocument = ({
  isOpen,
  onClose,
  docName,
  formStatusError,
  showStatusError,
  verificationCount,
  formUnverifiedData,
  setFormUnverifiedData,
  formData,
  setFormData,
  mode,
  formEditData,
  setFormEditData,
  formError,
  setFormError,
  PendingCounter,
  setFormStatusError,
  setFormPendingStatus,
  formPendingStatus,
  showEditCancel,
  viewModal,
  setViewModal,
}) => {
  const [verifiedFiles, setVerifiedFiles] = useState([""]);
  const [files, setFiles] = useState([""]);
  const [dialogAlert, setDialogAlert] = useState({
    isOpen: false,
    message: "",
  });
  const [fileViewer, setFileViewer] = useState(false);
  const [fileToView, setFileToView] = useState("");
  const [isDocEdit, setIsDocEdit] = useState(false);
  const [error, setError] = useState({})
  const [documentConfirmationModal, setDocumentConfirmationModal] = useState(false)

  useEffect(() => {
    let oldVerifiedFile = [];
    let oldFiles = [];

    if (docName === 'passport') {
      oldFiles = (verificationCount > 0) ? formUnverifiedData.passport : formData.passport;
      oldVerifiedFile = (verificationCount > 0) ? formData.passport : [];
    } else if (docName === 'educationCertificates') {
      oldFiles = (verificationCount > 0) ? formUnverifiedData.educationCertificates : formData.educationCertificates;
      oldVerifiedFile = (verificationCount > 0) ? formData.educationCertificates : [];
    } else if (docName === 'medicalReport') {
      oldFiles = (verificationCount > 0) ? formUnverifiedData.medicalReport : formData.medicalReport;
      oldVerifiedFile = (verificationCount > 0) ? formData.medicalReport : [];
    }

    const unverifiedFiles = oldFiles.length > 0 ? oldFiles : [''];
    setFiles(unverifiedFiles);
    setVerifiedFiles(oldVerifiedFile);
    setIsDocEdit((oldFiles.length > 0));
    setError({});
  }, [docName, isOpen]);


  const handleError = (errMessage, input) => {
    setError((prevState) => ({ ...prevState, [input]: errMessage }));
  };

  const handleChangeDocumnet = () => {
    if (files.length === 0) setFiles(['']);
    setIsDocEdit(true);
  }


  //handle Add More Files
  const handleAddMoreFiles = () => {
    //check prev file is added or not 
    const index = files.length > 0 ? files.length - 1 : 0;
    if (files[index] === '') handleError('Please choose file before adding more file.', 'newDoc');
    else if (files.length < 5) { setFiles([...files, ""]); }
    else { handleError('Max. files uploaded.', 'newDoc') }
  };

  //handle Remove File
  const handleRemoveFile = (targetIndex) => {
    const newArray = files.filter((item, index) => index !== targetIndex);
    setFiles(newArray);
    // set change Doc btn disable attribute false
    if (newArray.length === 0) setIsDocEdit(false);
    setFormError({ ...formError, saveProceedError: false });
    //set error 
    handleError('', 'newDoc')
  };

  //handle file Selection
  const handleFileSelection = (e, index) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
    const fileSize = Math.round(file.size / 1024);

    //File type validation
    if (!validTypes.includes(fileType)) {
      // invalid file type code goes here.
      setDialogAlert({
        isOpen: true,
        message:
          "Invalid file. Only .jpg, .jpeg, .png, .pdf files are allowed.",
      });
      return;
    }

    ////File Size validation
    if (fileSize > 2048) {
      // invalid file type code goes here.
      setDialogAlert({
        isOpen: true,
        message: "File size exceeds the limit (2 MB)",
      });
      return;
    }

    const newArray = [...files];
    newArray[index] = e.target.files[0];
    setFormError({ ...formError, saveProceedError: false });
    setFiles(newArray);
    //set error 
    handleError('', 'newDoc')
  };



  //handle upload file to state (handle save and proceed click)
  const handleSaveAndProceedClick = () => {
    // if form mode is update
    console.log(files);
    if (files.some(file => file === null || file === undefined || file === "")) {
      setFormError({ ...formError, saveProceedError: true }); // Set error for the specific docName
      return; // Exit the function early if the validation fails
    }

    if (mode === "update") {
      setFormEditData({
        ...formEditData,
        [docName]: files,
      });
    }
    //if verification count is > 0
    if (verificationCount > 0) {
      setFormUnverifiedData({
        ...formUnverifiedData,
        [docName]: files,
      });
    } else {
      setFormData({ ...formData, [docName]: files });
    }
    setFormError({ ...formError, [docName]: false });

    onClose()
  };



  // handle cancel document
  const handleCancelDocument = (docName) => {
    setFormUnverifiedData({ ...formUnverifiedData, [docName]: "" });
  };



  //handle View File
  const handleViewFile = (index, docType) => {
    const doc = docType === 'unverified' ? files[index] : verifiedFiles[index];
    setFileToView(doc);
    setFileViewer(true);
  };

  //Confirmation Modal --------------------//
  const openConfirmationModal = () => {
    setDocumentConfirmationModal(true)
  }
  const handleYourDocOk = () => {
    onClose();
    setDocumentConfirmationModal(false);
    setFormError({ ...formError, saveProceedError: false });
  }
  //Confirmation Modal --------------------//

  const title = {
    passport: "Passport",
    educationCertificates: "Education Certificates",
    medicalReport: "Medical Report",
  }[docName];

  const fileName = (file, index, docType) => {
    const fileNameInitial = (verificationCount > 0 && docType === 'unverified') ? 'New' : '';
    return (typeof file === 'string') ? `${fileNameInitial}${title.replace(' ', '_')}_${index + 1}${file.slice(file.indexOf("."))}` : file.name
  }

  return (
    <>
      <Modal
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        id="documentModal"
        dialogClassName={verificationCount > 0 && 'document-modal-dialog'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0 justify-content-end border-0">
          <div className="position-relative">
            <AiOutlineClose
              onClick={openConfirmationModal}
              className="icon close-icon position-absolute"
            />
          </div>
        </Modal.Header>

        <Modal.Body className="p-0">
          <section className="border-pink p-3 rounded">
            <h3 className="text-red fs-5 p-0">Upload {title}:</h3>
            <div className="d-flex">
              <p className="text-gray text-secondary flex-grow-1" style={{ fontSize: '14px' }}>
                You can upload .PNG, .JPEG, .PDF files. <br />
                Maximum File Size allowed is 2MB per file. <br />
                Maximum 5 Files can be uploaded.
              </p>
              {/*change button for deskTop  */}
              {
                (verificationCount > 0) && <div className="flex-grow-1 d-none d-sm-block">
                  <button className="btn btn-success mb-3" onClick={handleChangeDocumnet} disabled={isDocEdit}>Change {title}</button>
                  {isDocEdit && <h5>New {title}</h5>}
                </div>
              }
            </div>
            <section className="d-flex flex-column flex-sm-row">
              {/* verified files section  */}
              {
                verifiedFiles.length > 0 &&
                <div className="my-3 d-flex flex-column gap-2 verifiedFile__wrapper" >
                  {verifiedFiles.map((file, index) => (
                    <div key={Math.random()} className="d-flex gap-2">
                      <div className="flex-grow-1 input__file__wrapper">
                        <p className="input__fileName">
                          {file && fileName(file, index, 'verified')}
                        </p>
                      </div>

                      <div className="d-flex gap-2">
                        {file &&
                          <TfiEye
                            className="text-blue cursor-pointer"
                            size={30}
                            onClick={() => handleViewFile(index, 'verified')}
                          />
                        }
                      </div>
                    </div>
                  ))}
                </div>
              }
              {/*change button for mobile  */}
              {
                (verificationCount > 0) && <div className="flex-grow-1 d-block d-sm-none">
                  <button className="btn btn-success mb-3" onClick={handleChangeDocumnet} disabled={isDocEdit}>Change {title}</button>
                  {isDocEdit && <h5>New {title}</h5>}
                </div>
              }
              {/*Un verified files section  */}
              {
                (isDocEdit || verificationCount === 0) && <div className="my-3 d-flex flex-column gap-2 unVerifiedFile__wrapper">
                  {files.map((file, index) => (
                    <div key={Math.random()} className="d-flex gap-2">
                      <div className={`${(verificationCount === 0) && 'ms-3'} flex-grow-1 d-flex gap-3 input__file__wrapper requiredMark`}>
                        <label htmlFor={index} className="input__file">
                          Choose File
                          <input
                            id={index}
                            className="text-white d-none"
                            type="file"
                            onChange={(e) => handleFileSelection(e, index)}
                            accept="image/png,image/jpg,image/jpeg,application/pdf"
                          />
                        </label>
                        <p className="input__fileName">
                          {(file !== '') ? fileName(file, index, 'unverified') : "No file Chosen"}
                        </p>
                      </div>

                      <div className="d-flex gap-2">
                        {file &&
                          <TfiEye
                            className="text-blue cursor-pointer"
                            size={30}
                            onClick={() => handleViewFile(index, 'unverified')}
                          />
                        }

                        <IoIosCloseCircle
                          className="text-danger cursor-pointer"
                          size={30}
                          onClick={() => handleRemoveFile(index)}
                        />

                      </div>
                    </div>
                  ))}
                  {
                    error.newDoc && <p className="text-danger">{error.newDoc}</p>
                  }
                  <button className="btn__AddMoreFiles d-flex gap-2 align-items-center text-red p-1 rounded" onClick={handleAddMoreFiles}>
                    <IoIosAddCircle size={25} /> ADD MORE FILES
                  </button>
                </div>
              }
            </section>



            <div className="mt-4 text-center">
              <button className="btn btn-primary me-3" onClick={handleSaveAndProceedClick}>
                Save & Proceed
              </button>
              <button className="btn btn-outline-dark" onClick={openConfirmationModal}>
                Cancel
              </button>
              {(formError.saveProceedError) && <p className="text-danger">
                Please delete the Choose file record with no file selected
              </p>}
            </div>
          </section>
        </Modal.Body>

      </Modal>

      {/* Pass dynamic message and control dialog visibility */}
      <DialogAlert
        open={dialogAlert.isOpen}
        handleClose={() => setDialogAlert({ ...dialogAlert, isOpen: false })}
        message={dialogAlert.message}
        title="Warning"
      />

      {fileViewer && (
        <FileViewerModal
          isOpen={fileViewer}
          onClose={() => setFileViewer(false)}
          file={fileToView}
        />
      )}


      {/* your Document cancel confirmation model */}
      <Modal show={documentConfirmationModal} onHide={() => setDocumentConfirmationModal(false)}
        backdrop="static" id='viewModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable dialogClassName="w-min-content"
        size="md"
      >
        <Modal.Header className='p-0 justify-content-end border-0'>
          <div className="position-relative">
            <AiOutlineClose onClick={() => setDocumentConfirmationModal(false)}
              className='icon close-icon position-absolute' />
          </div>
        </Modal.Header>
        <Modal.Body className='p-5'>
          <p className='mb-4 h5'>Are you sure you want to exit before save document?</p>
          <div className='d-flex justify-content-center gap-4'>
            <button className='btn btn-primary' onClick={handleYourDocOk} style={{ width: '100px' }}> OK</button>
            <button className='btn btn-secondary' onClick={() => {
              setDocumentConfirmationModal(false);
              setFormError({ ...formError, saveProceedError: false });
            }} style={{ width: '100px' }}>CANCEL</button>
          </div>
        </Modal.Body >
      </Modal>
    </>
  );
};

export default memo(YourDocument);
