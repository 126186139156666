import React, { useState } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { AiOutlineClose } from "react-icons/ai";
import SignaturePad from "react-signature-canvas";
import getBaseUrl from "../../../../hooks/http";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import "./CndAgreementSing.css";

const CndAgreementSing = () => {
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();

  const [error, setError] = useState({
    name: false,
    identificationNo: false,
    document: false,
    signature: false,
    All: false,
  });

  const [data, setData] = useState({
    name: "",
    identificationNo: "",
    date: new Date()
      .toLocaleString("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/ /g, "-"),
    signature: "",
  });

  const [agreementModal, setAgreementModal] = useState({
    show: false,
    selectecdField: "",
    name: "",
    identificationNo: "",
  });

  const openModal = (fdNm) => {
    setAgreementModal({
      ...agreementModal,
      show: true,
      selectecdField: fdNm,
      name: "",
      identificationNo: "",
    });
    setError({ name: false, identificationNo: false });
  };
  const closeModal = () => {
    setAgreementModal({ ...agreementModal, show: false });
    setError({ name: false, identificationNo: false });
  };

  const updateFields = (e) => {
    setAgreementModal({ ...agreementModal, [e.target.name]: e.target.value });
    setError({ name: false, identificationNo: false });
  };

  const insertData = () => {
    const fdName = agreementModal.selectecdField;
    const fdvalue = agreementModal[fdName];
    if (fdName === "name") {
      if (fdvalue) {
        setData({ ...data, name: fdvalue });
      } else {
        setError({ ...error, name: true });
        return 0;
      }
    }

    if (fdName === "identificationNo") {
      if (fdvalue) {
        setData({ ...data, identificationNo: fdvalue });
      } else {
        setError({ ...error, identificationNo: true });
        return 0;
      }
    }

    closeModal();
  };
  // signature related stuff -------------------------
  const [signModal, setSignModal] = useState(false);
  const [sign, setSign] = useState();

  const handleSignModalShow = () => {
    setSignModal(true);
    setError({ ...error, document: false, All: false });
  };
  const handleSignModalClose = () => setSignModal(false);

  const clearSign = () => {
    sign.clear();
  };
  const strokeBegin = () => {
    setError({ ...error, signaturePad: false });
  };
  //trim left singnature canvas side
  const trimLeftWhiteSpace = () => {
    const canvas = sign.getCanvas();

    // Find the bounding box of the signature
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data, width, height } = imageData;
    let minX = width;
    let minY = height;
    let maxX = 0;
    let maxY = 0;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 4;
        if (data[pixelIndex + 3] > 0) {
          // Check if pixel is not transparent
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    // Create a new canvas for trimmed signature
    const trimmedWidth = maxX - minX + 1;
    const trimmedHeight = maxY - minY + 1;
    const trimmedCanvas = document.createElement("canvas");
    trimmedCanvas.width = trimmedWidth;
    trimmedCanvas.height = trimmedHeight;
    const trimmedCtx = trimmedCanvas.getContext("2d");

    // Draw the trimmed signature onto the new canvas
    trimmedCtx.drawImage(
      canvas,
      minX,
      minY,
      trimmedWidth,
      trimmedHeight,
      0,
      0,
      trimmedWidth,
      trimmedHeight
    );

    // Set the trimmed canvas as the reference for display
    return trimmedCanvas;
  };

  const saveSign = async () => {
    if (!sign.isEmpty()) {
      const trimedSign = await trimLeftWhiteSpace();
      setData({ ...data, signature: trimedSign.toDataURL("image/png") });
      setSignModal(false);
    } else {
      setError({ ...error, signaturePad: true });
    }
  };

  //update User data
  const userUpdate = (updatedData) => {
    const userInfo = {
      ...JSON.parse(localStorage.getItem("user")),
      ...updatedData,
    };
    /* update the auth context */
    dispatch({ type: "UPDATE-USER", payload: userInfo });
  };

  //save agreement
  const handleAgreementSave = () => {
    const { name, identificationNo, date, signature } = data;

    const formattedDate = date
      .toLocaleString("en-GB", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/ /g, "-");

    if (name && identificationNo && date && signature) {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("IdNumber", identificationNo);
      formData.append("sysDate", formattedDate);
      formData.append("signature", signature);

      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .post(`${getBaseUrl}/agreement/candidate/`, formData, config)
        .then(async (res) => {
          if (res.status === 200) {
            //Update User Data
            userUpdate({ Is_AgreementSign: "Yes" });
            navigate("/candidate/profile/interview");
          }
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    } else {
      setError({ ...error, document: true });
    }
  };

  return (
    <div className="cndAgreementSign">
      <div className="contractAgreement">
        {error.document && (
          <p className="text-danger mb-0">
            Required field -{!data.name && ` Name`}
            {!data.identificationNo &&
              `${!data.name ? "," : ""} Identification Number`}
            {!data.signature &&
              `${!data.name || !data.identificationNo ? "," : ""} Signature`}
            .
          </p>
        )}
        <div className="btn-wrapper d-flex flex-wrap justify-content-between">
          <button
            className="btn btn-primary m-1"
            onClick={() => openModal("name")}
          >
            Insert Name
          </button>
          <button
            className="btn btn-primary m-1"
            onClick={() => openModal("identificationNo")}
          >
            Insert Identification Number
          </button>
          <button className="btn btn-primary m-1" onClick={handleSignModalShow}>
            Insert Signature
          </button>
          <button
            className="btn btn-danger btn-red m-1"
            onClick={handleAgreementSave}
          >
            Save
          </button>
        </div>
        <div className="px-0 px-sm-5">
          <div className="d-flex justify-content-between my-4">
            <div className="fw-bold">
              <span className="text-blue">Helper</span>
              <span className="text-red">Wish</span>
            </div>
            <ul>
              <li className="fw-bold">Omniwish Pte.Ltd</li>
              <li>Co. Reg. No. 201000306M</li>
              <li>31A, Upper Cross Street,</li>
              <li>#04-95, Singapore 051531</li>
            </ul>
          </div>
          <h6 className="text-center text-decoration-underline">
            CONTRACT AGREEMENT
          </h6>
          <div className="agreement-text">
            <ul className="p-0">
              <li>I Agree - </li>
              <li>
                <span>1.</span>
                <span>
                  The personal details, documents provided by me are correct.
                </span>
              </li>
              <li>
                <span>2.</span>
                <span>
                  I will not exchange my personal contact details with Employer
                  during Interview Process, until HelperWish disclose the same
                  to Employer.
                </span>
              </li>
              <li>
                <span>3.</span>
                <span>
                  I will not contact Employer directly for my hiring during my
                  Hiring and after my Hiring process to the employer.
                </span>
              </li>
              <li>
                <span>4.</span>
                <span>
                  If I contact Employer from HelperWish directly then I am
                  liable to pay S$ 200 for breach of contract.
                </span>
              </li>
            </ul>

            <ul className="p-0">
              <li className="fw-bold">Signed By:</li>
              <li>
                <span className="fw-bold">Name:</span> {data.name}
              </li>
              <li>
                <span className="fw-bold">Identification No.</span>:{" "}
                {data.identificationNo}
              </li>
              <li>
                <span className="fw-bold">Date: </span>
                <span>
                  {" "}
                  {data.date.toLocaleString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>{" "}
              </li>
              <li className="align-items-center">
                <span className="fw-bold">Signature:</span>
                <div className="mx-2">
                  <img src={data.signature} alt="" style={{ width: "70px" }} />
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Modal
          show={agreementModal.show}
          onHide={closeModal}
          backdrop="static"
          id="cndagreementModal"
          centered
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={closeModal}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="px-5">
              {(error.name || error.identificationNo) && (
                <p className="text-danger text-center">
                  This field is required.
                </p>
              )}
              <div
                className={`floating-control flex-grow-1 requiredMark ${
                  (error.name || error.identificationNo) && "invalid"
                }`}
              >
                {agreementModal.selectecdField === "name" && (
                  <>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className={`floating-input`}
                      placeholder="none"
                      value={agreementModal.name}
                      onChange={(e) => updateFields(e)}
                      autoComplete="off"
                    />
                    <label htmlFor="name" className="floating-label">
                      Name as per Identification
                    </label>
                  </>
                )}
                {agreementModal.selectecdField === "identificationNo" && (
                  <>
                    <input
                      type="text"
                      name="identificationNo"
                      id="identificationNo"
                      className={`floating-input`}
                      placeholder="none"
                      value={agreementModal.identificationNo}
                      onChange={(e) => updateFields(e)}
                      autoComplete="off"
                    />
                    <label
                      htmlFor="identificationNo"
                      className="floating-label"
                    >
                      Identification Number
                    </label>
                  </>
                )}
              </div>
              <div className="d-flex flex-wrap justify-content-center mt-4">
                <button className="btn btn-primary m-1" onClick={insertData}>
                  Save
                </button>
                <button
                  className="btn btn-outline-secondary m-1"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* display signature pad  */}
        <Modal
          size="lg"
          show={signModal}
          onHide={handleSignModalClose}
          backdrop="static"
          keyboard={false}
          id="signaturePadModal"
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={handleSignModalClose}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="scontainer mt-1 mt-sm-3">
              <div className="sigContainer">
                <label className="sign-label mb-1">Signature</label>
                <div className="signature-wrapper">
                  <SignaturePad
                    velocityFilterWeight={0.8}
                    minWidth={0.5}
                    maxWidth={2.5}
                    dotSize={2}
                    throttle={0}
                    onBegin={strokeBegin}
                    canvasProps={{ className: "sigPad" }}
                    ref={(data) => setSign(data)}
                  />
                </div>
              </div>
              {error.signaturePad && (
                <div className="text-danger text-center">
                  Signature can not be blank.
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <button className="btn btn-primary mt-2 mx-2" onClick={saveSign}>
                Save Signature
              </button>
              <button className="btn btn-grey mt-2 mx-2" onClick={clearSign}>
                Clear Signature
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CndAgreementSing;
