import { useState } from "react";
import { useNavigate } from "react-router-dom";
import getBaseUrl from '../../../hooks/http.js'
import axios from 'axios';


export const useRegister = () => {
   const navigate = useNavigate()

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)

   //check candidate already exists
   const check = async (countryCallingCode, phoneNumber, password, name) => {
      setError(null)
      setIsLoading(true)

      phoneNumber = `(${countryCallingCode})${phoneNumber}`;

      try {
         const body = { phoneNumber }
         const res = await axios.post(`${getBaseUrl}/employer/check`, body);
         if (res.status === 200) {
            /*save the user to local storsge */
            localStorage.setItem('phoneNumber', res.data.phoneNumber)
            localStorage.setItem('password', password)
            localStorage.setItem('name', name)

            /*rediredt to send-verification-code*/
            navigate('/employer/send-verification-code')
         } else {
            setError(res.data.error)
         }
      } catch (error) {
         console.log(error);
         setError(error.response.data.error)
      } finally {
         setIsLoading(false)
      }

   }

   return { check, isLoading, error, setError }
}