import React, { useEffect, useState, useContext, useRef } from "react";
import axios from "axios";
import Datetime from "react-datetime";
import { TfiEye } from "react-icons/tfi";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { MdLocationPin } from "react-icons/md";
import DialogAlert from "../../../../components/customModal/customAlertModal";

import CustomModal from "../../../../components/customModal/CustomModal.jsx";
import getBaseUrl from "../../../../hooks/http.js";
import { useAuthContext } from "../../../../hooks/useAuthContext.js";
import { useSocketContext } from "../../../../hooks/useSocketContext.js";
import { CountContext } from "../../../../context/CountContext.js";
import {
  capitalizeFirstLetter,
  position,
  urlExists,
} from "../../../../utils/Helper";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import EmpInterviewList from "../../../../components/employer/interview/EmpInterviewList.jsx";
import EmpInterviewHistoryList from "../../../../components/employer/interview/EmpInterviewHistoryList.jsx";
import "../../../../components/candidateCard/candidateCard.css";
import "./interview.css";
import WorkExperience from "../../../../components/employer/workExprience/workExperience.jsx";

const EmpInterview = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const socket = useSocketContext();
  const { updateInterviewCount } = useContext(CountContext);
  const reschedulePopupRef = useRef(null);

  const [interviews, setInterviews] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [interviewHistory, setInterviewsHistory] = useState([]);
  const [dataType, setDataType] = useState("currentInterview");
  const empUrlId = localStorage.getItem("empId");
  const [classActiveName, setClassName] = useState("active");
  const [CandId, setCandId] = useState("");
  const [minTime, setMinTime] = useState(new Date());
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [hireModal, setHireModal] = useState({
    show: false,
    helper: "New",
    medicalExamination: false,
    workPermit: false,
  });
  const [isFileExists, setIsFileExists] = useState(false);
  //deleteConfirmationModal ----------------------------------------------
  const [formErrors, setFormErrors] = useState({});
  const [yesError, setYesError] = useState(false);
  const [yesMediError, setYesMediError] = useState(false);
  const [yesWorkPerError, setYesWorkPerError] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    show: false,
    index: "",
  });

  //for boot modals ----------------------------
  const [helperData, setHelperData] = useState({
    video: false,
    letter: false,
    refLetter: "",
  });

  const handleAlertClose = () => {
    setOpen(false);
  };

  const handleClose = (i) => {
    //set reasonforcancel value null
    interviews[i]["reasonforcancel"] = "";
    //hide modal
    setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
    //set error null
    setFormErrors("");
  };

  // get Interviews data
  const getInterviews = async (action = "") => {
    setIsLoading(true);
    try {
      let config = {
        headers: { Authorization: `Bearer ${user.token}` },
      };

      const res = await axios.get(`${getBaseUrl}/interviews/employer/`, config);
      updateInterviewCount(res.data.interviewData.length);
      const data = await res.data.interviewData;
      console.log("interview", data);

      // Extracting candidates from the interviews
      if (res.status === 200) {
        const interviewData = data.map((dt) => {
          const InterDate = new Date(dt.Interview_Date_Time);
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const newDateTime = InterDate.toLocaleString("en-US", {
            timeZone: `${timezone}`,
          });
          const Interview_Date_Time = newDateTime ? new Date(newDateTime) : "";
          const lc_id = localStorage.getItem("candId");
          const arrayOfIdAction = lc_id.split("_");
          const stateOfModel =
            arrayOfIdAction[0] === dt.Candidate_UserID &&
            arrayOfIdAction[1] === "Reschedule" &&
            action === ""
              ? true
              : false;

          return {
            id: dt?._id,
            date: new Date(Interview_Date_Time),
            reschedule: {
              date: new Date(Interview_Date_Time),
              isShow: stateOfModel,
            },
            interviewStatus: dt?.Interview_Status,
            interviewLink: dt?.Interview_Link,
            candidateName: dt?.candidateDetails[0]?.name.data ?? "Unknown",
            candID: dt?.Candidate_UserID,
            CandidateTempId: dt?.Candidate_TempId,
            EmpId: localStorage.getItem("empId"),
            LocalCandId: lc_id,
            typeOfCandidate: dt?.candidateDetails[0]?.typeOfCandidate?.data,
          };
        });

        setInterviews(interviewData);
        setInterviewsHistory([]);

        const candidateArray = data.map((dt) => {
          const singaporeExperience =
            dt?.candidateDetails[0].singaporeExperience.data;
          let hasworkedInSingapore = "No";
          if (singaporeExperience) {
            if (singaporeExperience.year || singaporeExperience.month)
              hasworkedInSingapore = "Yes";
          }

          return {
            ...dt.candidateDetails[0],
            hasworkedInSingapore: hasworkedInSingapore,
          };
        });

        setCandidates(candidateArray);

        setTimeout(() => {
          const lc_CanId = localStorage.getItem("candId");
          const arrayOfIdAction = lc_CanId.split("_");
          // const stateOfModel = (arrayOfIdAction[0] === dt.Employer_UserID) ? true : false;
          if (arrayOfIdAction[1] === "Reschedule" && action === "") {
            //window.scrollTo(0, 300);
            const list = interviewData;
            const icon = document.querySelectorAll(".icon1");
            for (let i = 0; i < list.length; i++) {
              const isShow =
                arrayOfIdAction[0] === list[i].candID ? true : false;
              if (icon.length > 0 && isShow === true) {
                let bodyRect = document.body.getBoundingClientRect();
                let btnRect = icon[i].getBoundingClientRect();
                let btnOffsetTop = btnRect.top - bodyRect.top;
                let scroll =
                  document.documentElement.scrollTop || document.body.scrollTop;
                let top = btnOffsetTop - scroll;
                window.scrollTo(0, top);
              }
            }
          }
        }, 1000);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // get Interviews History data
  const getInterviewsHistory = async () => {
    setIsLoading(true);
    try {
      if (user !== null) {
        let config = {
          headers: { Authorization: `Bearer ${user.token}` },
        };

        const res = await axios.get(
          `${getBaseUrl}/interviews/history/employer/`,
          config
        );
        console.log("history", res);

        if (res.status === 200) {
          const interviewHistoryData = await res.data.interviewHistoryData;

          const data = interviewHistoryData.map((ihd) => {
            const InterDate = new Date(ihd?.Interview_Date_Time);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newDateTime = InterDate.toLocaleString("en-US", {
              timeZone: `${timezone}`,
            });
            const Interview_Date_Time = newDateTime
              ? new Date(newDateTime)
              : "";

            return {
              id: ihd._id,
              candidateName: ihd?.candidateDetails[0]?.name ?? "Unknown",
              date: new Date(Interview_Date_Time),
              interviewStatus: ihd?.Interview_Status,
              reasonForCancel: ihd?.Reason_For_Cancel,
            };
          });

          setInterviews([]);
          setInterviewsHistory(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmpInterviewStatusUpdate = (data) => {
    if (user.EmpId === data.empId) {
      getInterviews(data.status);
    }
  };

  const handleOptionClick = (option) => {
    setHireModal({
      ...hireModal,
      helper: option,
      medicalExamination: false,
      workPermit: false,
    });
    setYesError(null);
    setYesMediError(false);
    setYesWorkPerError(false);
  };

  useEffect(() => {
    const id = localStorage.getItem("candId");
    const arrayOfIdAction = id.split("_");
    setCandId(arrayOfIdAction[0]);

    //Datapicker changes
    const currentDateTime = new Date();
    currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
    setMinTime(currentDateTime);
    // check data type and dispaly data
    if (dataType === "currentInterview") getInterviews();
    else getInterviewsHistory();
    //socket io stuff
    socket.on("empInterviewStatusUpdate", handleEmpInterviewStatusUpdate);

    return () => {
      socket.off("empInterviewStatusUpdate", handleEmpInterviewStatusUpdate);
    };
  }, [dataType, user]);

  useEffect(() => {
    window.addEventListener("scroll", handleWindowOnResize);

    return () => {
      window.removeEventListener("scroll", handleWindowOnResize);
    };
  }, [interviews]);

  const handleStatusUpdate = (cndId, status) => {
    socket.emit("employerUpdate", { cndId: cndId, status: status });
  };

  const interviewInvitationToCnd = (index) => {
    const listData = interviews[index];
    const user = JSON.parse(localStorage.getItem("user"));
    const { token } = user;
    let config = {
      headers: { Authorization: `Bearer ${user.token}` },
    };
    axios
      .post(
        `${getBaseUrl}/employer/interviewResheduleInvitationToCnd`,
        listData,
        config
      )
      .then(async (res) => {
        if (res.status === 200) {
          getInterviews("reshedule");
          //closeRescheduledDatePickier
          closeRescheduledDatePickier(index);
          handleStatusUpdate(listData.candID, "reshedule");
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  // open Rescheduled DatePickier
  const openRescheduledDatePickier = (e, i) => {
    const { top, left } = position(e, false, reschedulePopupRef);
    const list = [...interviews];
    const scheduleDate = list[i]["reschedule"]["date"];
    const currentDateTime = new Date();
    if (
      new Date(scheduleDate).toDateString() === currentDateTime.toDateString()
    ) {
      // If the current date or a past date is selected, update the excluded times
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);
    } else {
      // If a future date is selected, clear the excluded times
      const newd = new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
        0, // Hours (midnight)
        0 // Minutes
      );
      setMinTime(newd);
    }
    for (let index = 0; index < list.length; index++) {
      if (index !== i) {
        list[index]["reschedule"]["isShow"] = false;
        list[i]["reschedule"]["error"] = false;
        list[i]["reschedule"]["error2"] = false;
      }
    }
    list[i]["reschedule"]["isShow"] = list[i].reschedule.isShow ? false : true;
    list[i]["reschedule"]["top"] = top;
    list[i]["reschedule"]["left"] = left;
    list[i]["reschedule"]["date"] = list[i]["date"];
    setInterviews(list);
  };
  // closeRescheduledDatePickier
  const closeRescheduledDatePickier = (i) => {
    const list = [...interviews];
    list[i]["reschedule"]["isShow"] = false;
    list[i]["reschedule"]["error"] = false;
    list[i]["reschedule"]["error2"] = false;

    // const lc_id = localStorage.getItem('candId');
    // const arrayOfIdAction = lc_id.split("_");
    /*localStorage.setItem('candId', arrayOfIdAction[0]);*/
    setClassName("false");
    localStorage.setItem("candId", null);
    setInterviews(list);
  };

  const handleRescheduledDateChange = (date, i) => {
    const currentDateTime = new Date();

    if (new Date(date).toDateString() === currentDateTime.toDateString()) {
      // If a future date is selected, clear the excluded times
      //setExcludedTimes([]);
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);
    } else {
      // If the current date or a past date is selected, update the excluded times
      const newd = new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
        0, // Hours (midnight)
        0 // Minutes
      );
      setMinTime(newd);
    }

    const list = [...interviews];
    list[i]["reschedule"]["date"] = new Date(date);
    list[i]["reschedule"]["error"] = false;
    list[i]["reschedule"]["error2"] = false;
    setInterviews(list);
  };
  // handleRescheduleClick
  const handleRescheduleClick = (i) => {
    const list = [...interviews];

    var preDate = new Date(list[i].date);
    var newDate = new Date(list[i].reschedule.date);
    var currentDate = new Date();
    const timeDifference = newDate - currentDate;
    const timeDifference2 = Math.round(timeDifference / 1000 / 60);
    if (preDate.getTime() === newDate.getTime()) {
      list[i]["reschedule"]["error"] = true;
      setInterviews(list);
    } else if (timeDifference2 <= 30) {
      list[i]["reschedule"]["error2"] = true;
      setInterviews(list);
    } else {
      list[i]["reschedule"]["isShow"] = false;
      setInterviews(list);
      //WA msg
      interviewInvitationToCnd(i);
    }
  };

  //window resize change calendar position
  const handleWindowOnResize = async () => {
    console.log("scroll");

    const list = [...interviews];
    const icon = document.querySelectorAll(".icon1");
    for (let i = 0; i < list.length; i++) {
      const isShow = list[i].reschedule.isShow;
      if (icon.length > 0 && isShow) {
        const { top, left } = await position(icon[i], true, reschedulePopupRef);
        list[i]["reschedule"]["top"] = top;
        list[i]["reschedule"]["left"] = left;
        setInterviews(list);
      }
    }
  };

  //handle confirm Interview click
  const confirmInterview = (index) => {
    const listData = interviews[index];

    axios
      .post(`${getBaseUrl}/interviews/confirmByEmployer`, listData)
      .then(async (res) => {
        console.log(res.data);
        if (res.status === 200) {
          getInterviews("confirm");
          handleStatusUpdate(listData.candID, "confirm");
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
        // Handle error response
        if (error.response && error.response.status === 400) {
          setOpen(true);
          setDialogMessage(error.response.data.message);
          // Display error message from backend
        } else {
        }
      });
  };

  const handleCancelInterview = (i) => {
    //set reasonforcancel value null
    interviews[i]["reasonforcancel"] = "";
    //show modal
    setDeleteConfirmationModal({
      ...deleteConfirmationModal,
      show: true,
      index: i,
    });
  };

  const handleReasonOfCancal = (cancelreason, i) => {
    const list = [...interviews];
    setFormErrors("");
    list[i]["reasonforcancel"] = cancelreason.target.value;
    setInterviews(list);
  };

  const [selectCandidate, setSelectCandidate] = useState({
    show: false,
    index: "",
  });

  const handleSelectCandidate = (i) => {
    const listData = interviews[i];
    //show hiremodal
    setHireModal({
      ...hireModal,
      show: true,
      helper: listData.typeOfCandidate,
    });
    //set value
    setSelectCandidate({ ...selectCandidate, index: i });
  };

  const handleHireModalSelectCandidate = () => {
    const { helper, medicalExamination, workPermit } = hireModal;
    if (!workPermit) {
      setYesWorkPerError(true);
    }
    if (!medicalExamination) {
      setYesMediError(true);
    }
    if (helper === "Transfer" && !workPermit) {
      setYesError("*Mandatory, then only can proceed");
      return;
    }

    if (helper === "Transfer" && !medicalExamination) {
      setYesError("* Mandatory, then only can proceed");
      return;
    }

    setSelectCandidate({ ...selectCandidate, show: true, msg: 1 });
    setHireModal({ ...hireModal, show: false });
  };

  const [isSaving, setIsSaving] = useState(false);

  const handleSelectCandidateYes = () => {
    if (isSaving) return; // Prevent double clicks
    setIsSaving(true); // Disable the button
    const index = selectCandidate.index;
    // const listData = interviews[index];
    const capitalizedHelper =
      hireModal.helper.charAt(0).toUpperCase() + hireModal.helper.slice(1);
    const listData = {
      ...interviews[index], // Copy existing data from interviews[index]
      helper: capitalizedHelper, // Add the helper value from hireModal
    };
    axios
      .post(`${getBaseUrl}/interviews/selectCandidate`, listData)
      .then(async (res) => {
        if (res.status === 200) {
          setSelectCandidate({ ...selectCandidate, msg: 2 });
          handleStatusUpdate(listData.candID, "selectCandidate");
          let config = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const getInterviewsCount = await axios.get(
            `${getBaseUrl}/interviews/EmployergetCount`,
            config
          );
          updateInterviewCount(getInterviewsCount.data.interviewCount);
        } else {
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  const handleHelperTohire = (e) => {
    setHireModal({
      ...hireModal,
      helper: e.target.value,
      medicalExamination: false,
      workPermit: false,
    });
    setYesError(null);
  };

  const handleHelperTohireMedicalExamination = () => {
    setHireModal({
      ...hireModal,
      medicalExamination: !hireModal.medicalExamination,
    });
    setYesMediError(false);
  };

  const handleHelperTohireWorkPermit = () => {
    setHireModal({ ...hireModal, workPermit: !hireModal.workPermit });
    setYesWorkPerError(false);
  };

  // handle Zoom Effect
  const handleZoomIn = () => {
    const docViewerImage = document.getElementById("image-img");
    docViewerImage.style.minWidth = `${docViewerImage.offsetWidth - 20}px`;
  };

  let count = 1;
  const handleZoomOut = () => {
    const docViewerImage = document.getElementById("image-img");
    if (count <= 5)
      docViewerImage.style.minWidth = `${docViewerImage.offsetWidth + 20}px`;
    count++;
  };

  //handle cancle interview click
  const cancelInterview = async (index) => {
    const listData = interviews[index];
    let config = {
      headers: { Authorization: `Bearer ${user.token}` },
    };

    if (!!listData.reasonforcancel) {
      //code by mohsin
      try {
        const res = await axios.post(
          `${getBaseUrl}/interviews/cancelByEmployer`,
          listData
        );
        if (res.status === 200) {
          getInterviews("cancel");
          //hide modal
          setDeleteConfirmationModal({
            ...deleteConfirmationModal,
            show: false,
          });
          handleStatusUpdate(listData.candID, "cancel");

          //get Interviews Count
          const getInterviewsCount = await axios.get(
            `${getBaseUrl}/interviews/EmployergetCount`,
            config
          );
          console.log("selected candidate count", getInterviewsCount);
          updateInterviewCount(getInterviewsCount.data.interviewCount);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      let errors = {};
      errors.msg = "This is required field";
      setFormErrors(errors);
    }
  };

  const joinMeeting = (date, status, link) => {
    const ACTIVE_STATUSES = [
      "Candidate Confirmed for Interview Meeting",
      "Reschedule - Employer Confirmed Interview Meeting",
      "Reschedule - Candidate Confirmed Interview Meeting",
    ];
    const isConfirmed = ACTIVE_STATUSES.includes(status);
    const currentTime = new Date();
    const scheduleDate = new Date(date);
    const dateDiff = currentTime - scheduleDate;
    const timeDiff = Math.round(dateDiff / 1000 / 60);
    //display join meeting button before 30 minute and after 3o minutes of scheduled time
    return (
      <>
        {isConfirmed && timeDiff > -15 && timeDiff < 30 ? (
          <a
            className="btn btn-sm"
            href={`https://teams.microsoft.com/l/meetup-join/${link}`}
            role="button"
            target="_blank"
            style={{ background: "#95D03A", color: "#fff" }}
            rel="noopener noreferrer"
          >
            Join Meeting
          </a>
        ) : null}
      </>
    );
  };

  // View Candidate Details
  const [show, setShow] = useState({
    viewMore: false,
    helper: false,
  });
  const [showMore, setShowMore] = useState({
    viewMoreDetails: false,
    helper: false,
  });
  const [details, setDetails] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const handleViewDetails = async (i) => {
    const list = [...candidates];
    setDetails(list[i]);
    setSelectedUser(list[i]);
    setShow({ ...show, viewMore: true });
  };

  const handleViewMoreDetails = async (info) => {
    setShow({ ...show, viewMore: false });
    setShowMore({ ...show, viewMoreDetails: true });
  };

  const handleDetailsClose = async (i) => {
    setShow({ ...show, viewMore: false });
  };
  const handleMoreDetailsClose = async (i) => {
    setShowMore({ ...show, viewMoreDetails: false });
  };

  const handleShow = async (modal, data = null, refLetter = null) => {
    if (Modal === "viewMoreDetailsModal") {
      setShow({ ...show, viewMore: true });
    } else {
      setShow({ ...show, helper: true });
      let videoView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
      if (data === "video")
        setHelperData({ video: true, letter: false, refLetter: videoView });
      if (data === "letter") {
        const fileStatus = await checkFileExists(refLetter);
        setIsFileExists(fileStatus);
        let imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
        setHelperData({ video: false, letter: true, refLetter: imgView });
      }
    }
  };

  const handleHelperClose = (modal) => {
    if (modal === "viewMoreDetailsModal") {
      setShow({ ...show, viewMore: false });
    } else {
      setShow({ ...show, helper: false });
    }
    setIsFileExists(false);
    setHelperData({ video: false, letter: false, refLetter: "" });
  };

  const checkFileExists = async (refLetter) => {
    try {
      const response = await fetch(refLetter);
      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking document URL:", error);
      return false;
    }
  };

  //handle imageOnError
  const imageOnError = (event) => {
    event.target.src = "/uploads/userIcon.png";
  };

  //fun to convert array to string
  const arrayToString = (arr) => {
    return Array.isArray(arr) && arr.length > 0
      ? arr.toString().replace(/,/g, ", ")
      : "-";
  };

  return (
    <div className="employer-interviews">
      <Container>
        <Row className="my-5">
          <Col sm={12} className="pb-2">
            <div className="text-center pb-5">
              <div className="form-check form-check-inline mb-3 mb-sm-0">
                <input
                  className=" d-none"
                  type="radio"
                  name="interview"
                  id="currentInterview"
                  value="currentInterview"
                  checked={dataType === "currentInterview" ? true : false}
                  onChange={(e) => setDataType(e.target.value)}
                />
                <label
                  className="form-check-label btn btn-outline-blue btn-emp-interview"
                  htmlFor="currentInterview"
                >
                  Current Interviews
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="d-none"
                  type="radio"
                  name="interview"
                  id="interviewHistory"
                  value="interviewHistory"
                  checked={dataType === "interviewHistory" ? true : false}
                  onChange={(e) => setDataType(e.target.value)}
                />
                <label
                  className="form-check-label btn btn-outline-blue btn-emp-history"
                  htmlFor="interviewHistory"
                >
                  Interview History
                </label>
              </div>
            </div>
          </Col>
          <Col>
            <div className="table-responsive interview-list box">
              {/* current interview data  */}
              {dataType === "currentInterview" && (
                <EmpInterviewList
                  data={interviews}
                  isLoading={isLoading}
                  handleViewDetails={handleViewDetails}
                  openRescheduledDatePickier={openRescheduledDatePickier}
                  confirmInterview={confirmInterview}
                  handleCancelInterview={handleCancelInterview}
                  handleSelectCandidate={handleSelectCandidate}
                />
              )}
              {/* interview history data  */}
              {dataType === "interviewHistory" && (
                <EmpInterviewHistoryList
                  data={interviewHistory}
                  isLoading={isLoading}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      {/* reschedule-popup */}
      {interviews.map((interview, index) => (
        <CustomModal
          key={"popup" + index}
          isOpen={interview.reschedule.isShow}
          onClose={() => closeRescheduledDatePickier(index)}
        >
          <div
            ref={reschedulePopupRef}
            className={`reschedule-popup`}
            style={{
              top: interview.reschedule.top,
              left: interview.reschedule.left,
            }}
          >
            <div className="reschedule-popup-date bg-blue mb-2 text-center py-2">
              Current Interview : <br />
              {interview.reschedule.date
                .toLocaleString("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(/00:/g, "12:")}
              <br />
              {`${interview.reschedule.date}`.substring(
                `${interview.reschedule.date}`.indexOf("("),
                `${interview.reschedule.date}`.lastIndexOf(")") + 1
              )}
            </div>

            <Datetime
              className=""
              value={interview.reschedule.date}
              onChange={(date) => handleRescheduledDateChange(date, index)}
              dateFormat="DD/MM/YYYY"
              timeFormat="hh:mm A" // Set time format to AM/PM
              isValidDate={(current) =>
                current.isAfter(new Date()) ||
                current.isSameOrAfter(
                  new Date().setDate(new Date().getDate() - 1)
                )
              } // Set the minimum date
              input={false}
            />
            {interview.reschedule.error && (
              <div className="bg-white text-danger fs-6 mb-2 text-center">
                Please select Date and Time
              </div>
            )}
            {interview.reschedule.error2 && (
              <div className="bg-white text-danger fs-6 mb-2 text-center">
                Time selected should be <br /> later than Current Time
              </div>
            )}
            <div className="d-flex justify-content-center mt-2">
              <Button
               variant="primary"
                className="btn-sm me-1"
                onClick={() => handleRescheduleClick(index)}
              >
                Reschedule
              </Button>
              <Button
              variant="secondary"
                className="btn-sm ms-1"
                onClick={() => closeRescheduledDatePickier(index)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </CustomModal>
      ))}

      {/* delete confoirmation modal  */}
      <Modal
        show={deleteConfirmationModal.show}
        onHide={() => handleClose(deleteConfirmationModal.index)}
        id="deleteConfirmationModal"
        className="confirmationBox"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="p-0 justify-content-end">
          <div className="position-relative">
            <AiOutlineClose
              onClick={() => handleClose(deleteConfirmationModal.index)}
              className="icon close-icon-blue position-absolute"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Reason for Cancellation?</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="cancelreason"
                value={
                  interviews[deleteConfirmationModal.index]?.reasonforcancel
                }
                onChange={(cancelreason) =>
                  handleReasonOfCancal(
                    cancelreason,
                    deleteConfirmationModal.index
                  )
                }
                required
              />
              {formErrors.msg && (
                <div className="text-danger">{formErrors.msg}</div>
              )}
            </Form.Group>
          </Form>
          <h5>Are you sure you want to cancel interview?</h5>
          <div className="d-flex justify-content-center gap-10 mt-4">
            <Button
              variant="danger"
              type="button"
              onClick={() => cancelInterview(deleteConfirmationModal.index)}
            >
              Yes
            </Button>
            <Button
              variant="secondary"
              onClick={() => handleClose(deleteConfirmationModal.index)}
            >
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* select candidate modal  */}
      <Modal
        show={selectCandidate.show}
        onHide={() => setSelectCandidate({ ...selectCandidate, show: false })}
        id="selectCandidateModal"
        className="confirmationBox"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {selectCandidate.msg === 1 ? (
            <>
              <h3 className="text-center mb-3">Confirmation</h3>
              <p className="text-center">
                Are you sure you want to select this candidate for offer?
              </p>
            </>
          ) : (
            <p className="text-center" style={{ fontSize: "18px" }}>
              The Selected Candidate is Moved to Offer section. Please proceed
              with Job Offer process
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="flex-wrap justify-content-center">
          {selectCandidate.msg === 1 ? (
            <>
              <Button
                variant="primary"
                type="button"
                onClick={handleSelectCandidateYes}
                disabled={isSaving}
              >
                Yes
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  setSelectCandidate({ ...selectCandidate, show: false });
                  setHireModal({ ...hireModal, helper: "new" });
                  setYesError("");
                }}
              >
                No
              </Button>
            </>
          ) : (
            <Button
              variant="primary"
              type="button"
              onClick={() => navigate("/employer/profile/offers")}
            >
              Ok
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* hire modal  */}
      <Modal
        show={hireModal.show}
        onHide={() => {
          setHireModal({ ...hireModal, show: false });
          setYesError(null);
          setYesMediError(false);
          setYesWorkPerError(false);
        }}
        id="helperHireModal"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="p-0 justify-content-end border-0">
          <div className="position-relative">
            <AiOutlineClose
              onClick={() => {
                setHireModal({ ...hireModal, show: false });
                setYesError(null);
                setYesMediError(false);
                setYesWorkPerError(false);
              }}
              className="icon close-icon-blue position-absolute"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="helperModalFontFam">
          <div className="d-flex flex-column flex-sm-row flex-wrap justify-content-center gap-3 gap-lg-5">
            <div className="fs-5 mb-2 mb-sm-0 d-flex align-items-center justify-content-center">
              <p className="me-1">The Helper to hire is</p>
              <BsFillQuestionCircleFill />
            </div>
            <div className="requiredMark w-fit-content mx-auto mx-sm-0">
              <div className="toggle-container">
                <div className={`slider ${hireModal.helper}`}></div>
                <div
                  className={`option ${
                    hireModal.helper === "New" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("New")}
                >
                  New
                </div>
                <div
                  className={`option ${
                    hireModal.helper === "Transfer" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("Transfer")}
                >
                  Transfer
                </div>
                <div
                  className={`option ${
                    hireModal.helper === "Ex-Singapore" ? "active" : ""
                  }`}
                  onClick={() => handleOptionClick("Ex-Singapore")}
                >
                  Ex-Singapore
                </div>
              </div>
            </div>
          </div>

          {hireModal.helper === "Transfer" && (
            <div className="mb-4 mt-5 message-box">
              <label htmlFor="" className="box-label fs-4">
                For Transfer Helper
              </label>
              <p className="h5">I confirm that-</p>
              <div className="d-flex flex-wrap flex-lg-nowrap gap-10 align-items-center mb-4">
                <div className="d-flex">
                  <span style={{ marginRight: "7px" }}>1)</span>
                  <p className="m-0 flex-grow-1 me-3">
                    The Helper, I am hiring has Valid Work Permit which is more
                    than 30 days
                  </p>
                  <div className="wrapper__checkbox">
                    <label
                      htmlFor="helperMedicalExamination-1"
                      className="checkbox  requiredMark"
                    >
                      <input
                        className={`checkbox__input `}
                        type="checkbox"
                        name="helperMedicalExamination"
                        value="yes"
                        id="helperMedicalExamination-1"
                        onChange={handleHelperTohireMedicalExamination}
                        checked={hireModal.medicalExamination}
                      />
                      <svg
                        className="checkbox__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 22"
                      >
                        <rect
                          width="21"
                          height="21"
                          x=".5"
                          y=".5"
                          fill="#FFF"
                          stroke={`${yesMediError ? "#FF0000" : "#006F94"}`}
                          rx="3"
                        />
                        <path
                          className="tick"
                          stroke="#005EAB"
                          fill="none"
                          strokeLinecap="round"
                          strokeWidth="4"
                          d="M4 10l5 5 9-9"
                        />
                      </svg>
                      <span className="checkbox__label">Yes</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap flex-lg-nowrap gap-10 align-items-center ">
                <div className="d-flex">
                  <span style={{ marginRight: "7px" }}>2)</span>
                  <p className="m-0 flex-grow-1 me-3">
                    The Helper, I am hiring has completed 6-monthly Medical
                    Examination with current Employer
                  </p>

                  <div className="wrapper__checkbox ml-1">
                    <label
                      htmlFor="helperWorkPermitexpire-1"
                      className="checkbox requiredMark"
                    >
                      <input
                        className="checkbox__input"
                        type="checkbox"
                        name="helperWorkPermitexpire"
                        value="yes"
                        id="helperWorkPermitexpire-1"
                        onChange={handleHelperTohireWorkPermit}
                        checked={hireModal.workPermit}
                      />
                      <svg
                        className="checkbox__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 22"
                      >
                        <rect
                          width="21"
                          height="21"
                          x=".5"
                          y=".5"
                          fill="#FFF"
                          stroke={`${yesWorkPerError ? "#FF0000" : "#006F94"}`}
                          rx="3"
                        />
                        <path
                          className="tick"
                          stroke="#005EAB"
                          fill="none"
                          strokeLinecap="round"
                          strokeWidth="4"
                          d="M4 10l5 5 9-9"
                        />
                      </svg>
                      <span className="checkbox__label">Yes</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            {yesError && (
              <div className="text-danger text-center">{yesError}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex flex-wrap justify-content-center align-content-center gap-10">
            <Button
            variant="primary"
              className="m-1"
              onClick={handleHireModalSelectCandidate}
            >
              {" "}
              Select Candidate
            </Button>
            <Button
            variant="secondary"
              className="m-1"
              onClick={() => {
                setHireModal({ ...hireModal, show: false, helper: "New" });
                setYesError(null);
                setYesMediError(false);
                setYesWorkPerError(false);
              }}
            >
              {" "}
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/*short viewDetailsModal*/}
      <Modal
        show={show.viewMore}
        onHide={() => handleDetailsClose("viewDetailsModal")}
        backdrop="static"
        id="viewDetailsModal"
        className="empInterviewViewDetailsModal"
      >
        <Modal.Body className="p-0">
          <div className="position-relative">
            <AiOutlineClose
              onClick={() => handleDetailsClose("viewDetailsModal")}
              className="icon close-icon-blue position-absolute"
            />
          </div>
          <div className={`candidate-info`}>
            <div className="card-body">
              <div className="description-1 d-block d-md-flex">
                <div className="profile-wrapper d-flex align-items-center justify-content-center">
                  <img
                    src={
                      details?.photo?.data
                        ? `${getBaseUrl}/candidates/getMediaFileFromDb/${details?.photo?.data}`
                        : "/uploads/userIcon.png"
                    }
                    onError={imageOnError}
                    alt="profile"
                    className="profile"
                  />
                </div>
                <div className="flex-grow-1 px-3">
                  <p className="name text-center text-md-start">
                    {details?.name?.data}{" "}
                  </p>
                  <div className="d-flex flex-wrap justify-content-center justify-content-md-start align-items-center gap-4 gap-sm-2 ">
                    <p className="age"> Age: {details?.age}yrs</p>
                    <p className="availability text-center text-sm-start">
                      Availability: {details?.availability?.data || "-"}
                    </p>
                    <p className="country">
                      <MdLocationPin className="location-icon" />
                      {details?.currentCountry?.data}
                    </p>
                  </div>
                </div>
              </div>

              <div className="description-2">
                <div className="text-center text-md-start p-3">
                  <p className="">
                    Has worked in Singapore?
                    <span className="text-blue">
                      {" "}
                      {details.hasworkedInSingapore}
                    </span>
                  </p>
                  <p className="skill">
                    {" "}
                    Skills:
                    <span className="text-blue">
                      {" "}
                      {arrayToString(details?.skills?.data)}
                    </span>
                  </p>
                  <p className="cooking">
                    Cooking:
                    <span className="text-blue">
                      {" "}
                      {arrayToString(details?.cooking?.data)}
                    </span>
                  </p>
                  <p className="exp-salary">
                    Expected Salary:
                    <span className="text-blue">
                      {" "}
                      {details?.expectedSalary?.data}
                    </span>
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-center mb-2">
                <button
                  className="btn-red-reshedule btn-viewMore"
                  onClick={() => handleViewMoreDetails(details)}
                >
                  View More Details
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* viewMoreDetailsModal */}
      <Modal
        size="lg"
        show={showMore.viewMoreDetails}
        onHide={() => handleMoreDetailsClose("viewMoreDetailsModal")}
        backdrop="static"
        id="viewMoreDetailsModal"
        className="empInterviewViewDetailsModal"
      >
        <Modal.Body className="p-0 ">
          <div className="position-relative">
            <AiOutlineClose
              onClick={() => handleMoreDetailsClose("viewMoreDetailsModal")}
              className="icon close-icon position-absolute"
            />
          </div>

          <div className="candidate-details pb-5">
            <div className="description-1 d-lg-flex ">
              <div className="profile-wrapper mx-auto">
                <img
                  src={
                    details?.photo?.data
                      ? `${getBaseUrl}/candidates/getMediaFileFromDb/${details?.photo?.data}`
                      : "/uploads/userIcon.png"
                  }
                  onError={imageOnError}
                  alt="profile"
                  className="profile"
                />
              </div>
              <div className="flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1">
                <p className="name text-center text-lg-start ">
                  {details?.name?.data}
                </p>
                <div className="d-lg-flex">
                  <p className="dob me-lg-5 text-center text-lg-start">
                    <span className="text-nowrap">
                      {" "}
                      Date of Birth :{" "}
                      {new Date(details?.birthday?.data)
                        .toLocaleString("en-GB", {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        })
                        .replace(/ /g, "-")}
                    </span>
                    <span className="text-nowrap">{details?.dob}</span>
                  </p>
                  <p className="age text-center text-lg-start">
                    Age :{details?.age} years
                  </p>
                </div>
              </div>
            </div>

            <div className="description-2 px-3">
              <p className="nationality">
                Nationality : <span>{details?.nationality?.data}</span>
              </p>
              <p className="availability">
                {" "}
                Availability : <span>{details?.availability?.data}</span>
              </p>
              <p className="maritalStatus">
                Marital Status: <span>{details?.maritalStatus?.data}</span>
              </p>
              <div>
                <p>
                  No. of kids : <span>{details?.childAge?.data.length}</span>{" "}
                </p>
                {details?.childAge?.data && (
                  <div className="d-flex flex-wrap justify-content-center justify-content-sm-start ">
                    {details?.childAge?.data.map((childAge, index) => (
                      <p className="ms-2" key={index}>
                        kid{index + 1}: <span>{childAge}yrs</span>
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="workDetails ">
              <h3 className="px-3">Work Details:</h3>
              <div className="d-lg-flex flex-row-reverse justify-content-end px-3 gap-50">
                <div className="video-wrapper text-center d-flex flex-column">
                  {details?.video?.data ? (
                    <>
                      <video
                        src={
                          details?.video?.data
                            ? `${getBaseUrl}/candidates/getMediaFileFromDb/${details?.video?.data}`
                            : ""
                        }
                        className="cursor-pointer border border-secondary  mx-auto mx-lg-0 mb-2 mt-auto"
                      />
                      <button
                        className="btn btn-sm btn-primary mt-auto align-self-center"
                        onClick={() =>
                          handleShow(
                            "helperModal",
                            "video",
                            `${details?.video?.data}`
                          )
                        }
                      >
                        View Video
                      </button>
                    </>
                  ) : (
                    <img src="/images/record-icon.png" alt="profile" />
                  )}
                </div>
                <div className="video-wrapper d-flex flex-column align-items-center">
                  {
                    <img
                      src={
                        details?.fullPhoto?.data
                          ? `${getBaseUrl}/candidates/getMediaFileFromDb/${details?.fullPhoto?.data}`
                          : "/uploads/userIcon.png"
                      }
                      alt="profile"
                      className="cursor-pointer  mx-auto mb-2"
                    />
                  }
                  <button
                    className="btn btn-sm btn-primary mt-auto"
                    onClick={() =>
                      handleShow(
                        "helperModal",
                        "letter",
                        `${details?.fullPhoto?.data}`
                      )
                    }
                  >
                    View Photo
                  </button>
                </div>
                <div>
                  <p className="location">
                    <span className="text-nowrap me-1">
                      Present Location :{" "}
                    </span>
                    <span className="text-nowrap">
                      {details?.country?.data || "-"}
                    </span>
                  </p>
                  <p className="experience">
                    <span className="text-nowrap text-dark me-1">
                      Total Experience :
                    </span>
                    <span className="text-nowrap">
                      {details?.yearOfExperience?.data.year || 0} Years{" "}
                      {details?.yearOfExperience?.data.month || 0} Months
                    </span>
                  </p>
                  <p className="singaporeExperience">
                    <span className="text-nowrap text-dark me-1">
                      Singapore Experience :
                    </span>
                    <span className="text-nowrap">
                      {details?.singaporeExperience?.data.year || 0} Years{" "}
                      {details?.singaporeExperience?.month || 0} Months
                    </span>
                  </p>
                </div>
              </div>
              {details?.workExperience?.data.length > 0 && (
                <div className="table-responsive px-3">
                 <br/>
                 <WorkExperience data={details?.workExperience?.data} handleShow={handleShow}/>
                </div>
              )}
            </div>

            <div className="skills">
              <h3 className="px-3">Skills/Duties:</h3>
              <p className="px-3">
                <span className="text-nowrap me-1">Languages: </span>
                <span>
                  {details?.languages?.data.toString().replace(/,/g, ", ") ||
                    "-"}
                </span>
              </p>
              <p className="px-3">
                <span className="text-nowrap me-1">Skills : </span>
                <span>
                  {details?.skills?.data.toString().replace(/,/g, ", ") || "-"}
                </span>
              </p>
              <p className="px-3">
                <span className="text-nowrap me-1">Personality :</span>
                <span>
                  {details?.personality?.data.toString().replace(/,/g, ", ") ||
                    "-"}
                </span>
              </p>
              <p className="px-3">
                <span className="text-nowrap me-1">Cooking : </span>
                <span>
                  {details?.cooking?.data.toString().replace(/,/g, ", ") || "-"}
                </span>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* helpermodal for video and ref.letter   */}
      <Modal
        show={show.helper}
        onHide={() => handleHelperClose("helperModal")}
        backdrop="static"
        id="helperModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={`w-min-content ${
          helperData?.refLetter?.includes("pdf") ? "pdf" : ""
        }`}
      >
        <Modal.Body className="p-0 justify-content-end min-h-100">
          <div className="position-relative">
            <AiOutlineClose
              onClick={() => handleHelperClose("helperModal")}
              className="icon close-icon position-absolute"
            />
          </div>
          <div>
            {helperData?.letter && !helperData?.refLetter?.includes("pdf") && (
              <div className="d-flex justify-content-center gap-10 p-3">
                <button className="btn-zoom zoon-in" onClick={handleZoomIn}>
                  <FiZoomOut />{" "}
                </button>
                <button className="btn-zoom zoom-out" onClick={handleZoomOut}>
                  <FiZoomIn />
                </button>
              </div>
            )}
            {helperData?.video && (
              <video
                src={helperData?.refLetter}
                controls
                className="w-100"
              ></video>
            )}

            {helperData?.letter && (
              <DocViewer
                className="doc-viewer"
                documents={[
                  { uri: helperData?.refLetter, fileName: "Reference_letter" },
                ]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
              />
            )}
            {/*((!isFileExists) && (helperData?.letter)) && <p className='text-center p-3'>Error: Failed to load the document.</p>*/}
          </div>
        </Modal.Body>
      </Modal>
      <DialogAlert
        open={open}
        handleClose={handleAlertClose}
        message={dialogMessage}
        title="Warning"
      />
    </div>
  );
};

export default EmpInterview;
