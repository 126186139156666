import React from 'react';
import Carousel from 'react-multi-carousel';
import { useNavigate } from 'react-router-dom';
import { MdLocationPin } from 'react-icons/md';
import 'react-multi-carousel/lib/styles.css';
import './slider.css';



const Slider = ({ candidates, isLoading }) => {
   const navigate = useNavigate()

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };


   const responsive = {
      superLargeDesktop: {
         // the naming can be any, depends on you.
         breakpoint: { max: 4000, min: 3000 },
         items: 5
      },
      desktop: {
         breakpoint: { max: 3000, min: 1400 },
         items: 4
      },
      tablet: {
         breakpoint: { max: 1400, min: 1199 },
         items: 3
      },
      mobile: {
         breakpoint: { max: 1199, min: 991 },
         items: 2.5
      },
      smallMobile: {
         breakpoint: { max: 991, min: 0 },
         items: 1
      }
   };


   if (isLoading) {
      return <h3 className='text-center'>Loading...</h3>
   }

   return (
      <>
         <Carousel responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={1700}
         >
            {
               candidates && candidates.map((cnd, index) => {
                  return (
                     <div className='slider-container h-100' key={index}>
                        <div className="slider-wrapper h-100">
                           <div className="slider-card h-100" >
                              <div className='card__head'>
                                 <div className='profile__wrapper'>
                                    <img className="profile-img" src={cnd.photo} onError={imageOnError} alt='slider-img' />
                                 </div>
                                 <div className="name">
                                    <span>{cnd.name}</span>
                                 </div>
                              </div>
                              <div className='summary flex-grow-1'>
                                 <p className='age'>Age: {cnd.age}yrs</p>
                                 <p className='availability'>Availability: {cnd.availability}</p>
                                 <div className="country">
                                    <MdLocationPin className='icon' />{cnd.country}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  )
               })
            }
         </Carousel>
         {
            candidates &&
            <div className='text-center py-3'>
               <button className='btn-browse-candidates'
                  onClick={() => { navigate('/employer/profile/browse-candidates') }}>
                  Browse Candidates
               </button>
            </div>
         }
      </>

   )
}

export default React.memo(Slider)