import React, { useEffect, useMemo } from 'react'
import axios from "axios";
import { Routes, Route, useNavigate } from "react-router-dom";
//layouts
import FrontendLayout from "./layouts/FrontendLayout.js";
import AuthenticationLayout from "./layouts/AuthenticationLayout.js";
import EmpAuthenticationLayout from "./layouts/EmpAuthenticationLayout.js";
import CandidateLayout from "./layouts/CandidateLayout.jsx";
import EmployeeLayout from "./layouts/EmployeeLayout.jsx";
import AdminLayout from './layouts/AdminLayout.jsx'
import CndAgreement from "./pages/candidate/profile/agreement/CndAgreement.jsx";
import CndAgreementSing from "./pages/candidate/profile/agreement/CndAgreementSign.jsx";

// pages
import { Home } from './pages/home/index.js';
//candiate pages   ------------------------------------------------------
import {
  CandidateLogin, CandidateForgotPassword, CandidateResetPassword,
  CandidateRegister, CandidateSendVerificationCode, CandidateVerifyCode,
  CandidateVerifyRegister, CandidateResetPasswordVerificationCode,
  CandidateResetPasswordVerifyCode
} from './pages/candidateAuth/index.js'
import { CandPersonalDetails } from "./pages/candidate/profile/personalDetails/index.js";
import { CandInterview } from "./pages/candidate/profile/interview/index.js";
import { CandOffers } from "./pages/candidate/profile/offers/index.jsx";
import { CandAccount } from "./pages/candidate/profile/myAccount/index.js";
import { CandChangePassword } from "./pages/candidate/profile/changePassword/index.js";
//Employer pages  -------------------------------------------------------
import {
  EmployerLogin, EmployerRegister, EmployerForgotPassword,
  EmployerSendVerificationCode, EmployerVerifyCode,
  EmployerVerifyRegister, EmployerResetPassword,
  EmployerResetPasswordVerificationCode, EmployerResetPasswordVerifyCode
} from './pages/employerAuth/index.js';

import { EmpHome } from "./pages/employer/profile/home/index.jsx";
import { EmpBrowseCandidate } from "./pages/employer/profile/browseCandidate/index.js";
import { EmpInterview } from "./pages/employer/profile/interview/index.js";
import { Reschesule } from "./pages/reschedule/index.js";
import { EmpAccount } from './pages/employer/profile/empAccount/index.js';
import { EmpChangePassword } from './pages/employer/profile/empChangePassword/index.js';
import { EmpOffer } from './pages/employer/profile/empOffer/index.js';
import { EmpAgreement, EmpAgreementSign } from './pages/employer/profile/agreement/index.js';
import { SelectedCandidate } from './pages/employer/profile/selectedCandidate/index.js';
import Declaration from './pages/employer/profile/Mom-Declaration/MOMDeclaration.jsx'
//Error pages  -------------------------------------------------------
import { Error } from "./pages/error/index.js";
import LoginError from './pages/error/LoginError.jsx';
import MultiBrowserError from './pages/error/MultiBrowserError.jsx';
import MultiTabError from './pages/error/MultiTabError.jsx';

//admin pages --------------------------------------------------------
import { AdminLogin } from "./pages/admin/login/index.js";
import { Dashboard } from './pages/admin/dashboard/index.js'
import { AdminInterview } from './pages/admin/adminInterview/index.jsx';
import { AdminOffer } from './pages/admin/adminOffer/index.js';
import { AdminOnboarding } from './pages/admin/adminOnboarding/index.js';
import { AdminUser } from './pages/admin/adminUser/index.js';
import ProtectedRoute from './ProtectedRoute.jsx';
//----------------------------------------------------------------
import getBaseUrl from './hooks/http.js'
import { useAuthContext } from './hooks/useAuthContext.js';
import { useSocketContext } from './hooks/useSocketContext.js';
import Selected from './pages/candidate/profile/selectedCandidate/Selected.jsx';



const App = () => {

  const naviagte = useNavigate();

  const { dispatch } = useAuthContext();
  const socket = useSocketContext();


  const handleEmployerLoginHandler = data => {
    const usr = JSON.parse(localStorage.getItem('user'))

    if (usr !== null) {
      if (usr.sessionId !== data.sessionId && usr.EmpId === data.userId) {
        //logout();
        let config = {
          headers: { Authorization: `Bearer ${usr.token}`, },
        };
        axios.post(`${getBaseUrl}/logoutOtherDevices`, data, config)
          .then(async (res) => {
            if (res.status === 200) {
              //remove user from storage
              localStorage.clear();
              dispatch({ type: 'LOGOUT' })
              //rediredt to home
              naviagte('/MultiBrowserError')
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
      }
    }
  }

  const handleCandidateLoginHandler = data => {
    const usr = JSON.parse(localStorage.getItem('user'))

    if (usr !== null) {
      if (usr.sessionId !== data.sessionId && usr.candidateId === data.userId) {
        //logout();
        let config = {
          headers: { Authorization: `Bearer ${usr.token}`, },
        };
        axios.post(`${getBaseUrl}/logoutOtherDevices`, data, config)
          .then(async (res) => {
            if (res.status === 200) {
              //remove user from storage
              localStorage.clear();
              dispatch({ type: 'LOGOUT' })
              //rediredt to home
              naviagte('/MultiBrowserError')
            }
          })
          .catch((error) => {
            // handle error
            console.log(error);
          })
      }
    }
  }


  useEffect(() => {

    //socket io (multi browser logout functionality) -- employer
    socket.on('employerLoginHandle', handleEmployerLoginHandler);
    //socket io (multi browser logout functionality) -- candidate
    socket.on('candidateLoginHandle', handleCandidateLoginHandler);

    return () => {
      socket.off('employerLoginHandle', handleEmployerLoginHandler);
      socket.off('candidateLoginHandle', handleCandidateLoginHandler);
    };

  }, [])





  return (
    <>
      <div className='App' >
        <Routes>
          {/* Homepagelayout */}
          <Route exact path="/" element={<FrontendLayout />} >
            <Route index element={<Home />} />
            <Route path="/reschedule" element={<Reschesule />} />

            {/* Auth candidate routes  */}
            {/* candidate routes  */}
            <Route path="candidate" element={<AuthenticationLayout />} >
              <Route index element={<CandidateLogin />} />
              <Route path="login" element={<CandidateLogin />} />
              <Route path="login/:empId" element={<CandidateLogin />} />
              <Route path="forget-password" element={<CandidateForgotPassword />} />

              <Route path="register" element={<CandidateRegister />} />
              <Route path="send-verification-code" element={<CandidateSendVerificationCode />} />
              <Route path="verify-code" element={<CandidateVerifyCode />} />

              <Route path="reset-password" element={<CandidateResetPassword />} />
              <Route path="reset-password-verification-code" element={<CandidateResetPasswordVerificationCode />} />
              <Route path="reset-password-verify-code" element={<CandidateResetPasswordVerifyCode />} />

              <Route path="verify-register" element={<CandidateVerifyRegister />} />
            </Route>


            {/* Auth employer routes  */}
            <Route path="employer" element={<EmpAuthenticationLayout />} >
              <Route index element={<EmployerLogin />} />
              <Route path="login" element={<EmployerLogin />} />
              <Route path="login/:candiateId" element={<EmployerLogin />} />
              <Route path="forget-password" element={<EmployerForgotPassword />} />

              <Route path="register" element={<EmployerRegister />} />
              <Route path="send-verification-code" element={<EmployerSendVerificationCode />} />
              <Route path="verify-code" element={<EmployerVerifyCode />} />

              <Route path="reset-password" element={<EmployerResetPassword />} />
              <Route path="reset-password-verification-code" element={<EmployerResetPasswordVerificationCode />} />
              <Route path="reset-password-verify-code" element={<EmployerResetPasswordVerifyCode />} />

              <Route path="verify-register" element={<EmployerVerifyRegister />} />
            </Route>
          </Route>


          <Route element={<ProtectedRoute />}>
            {/* Candidate layout */}
            <Route exact path="/candidate/profile" element={<CandidateLayout />} >
              <Route index element={<CandPersonalDetails />} />
              <Route path="personal-details" element={<CandPersonalDetails />} />
              <Route path="interview" element={<CandInterview />} />
              <Route path="offers" element={<CandOffers />} />
              <Route path="my-account" element={<CandAccount />} />
              <Route path="change-password" element={<CandChangePassword />} />
              <Route path="CndAgreement" element={<CndAgreement />} />
              <Route path="CndAgreementSing" element={<CndAgreementSing />} />
              <Route path="selected" element={<Selected/>} />
            </Route>

            {/* Employee Profile */}
            <Route exact path="/employer/profile" element={<EmployeeLayout />} >
              <Route index element={<EmpHome />} />
              <Route path="home" element={<EmpHome />} />
              {/* <Route path="MOM-Declaration" element={<MOMDeclaration />}> */}
              <Route path="browse-candidates" element={<EmpBrowseCandidate />} />
              <Route path="interview" element={<EmpInterview />} />
              <Route path="offers" element={<EmpOffer />} />
              <Route path="selected-candidate" element={<SelectedCandidate />} />
              <Route path="mom-declaration" element={<Declaration />} />
              <Route path="account" element={<EmpAccount />} />
              <Route path="change-password" element={<EmpChangePassword />} />
              <Route path="agreement" element={<EmpAgreement />} />
              <Route path="agreement-sign" element={<EmpAgreementSign />} />
            </Route>
          </Route>


          {/* admin  panel  */}
          <Route path="admin" element={<AdminLogin />}></Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route element={<ProtectedRoute />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="interviews" element={<AdminInterview />} />
              <Route path="offer" element={<AdminOffer />} />
              <Route path="onboard" element={<AdminOnboarding />} />
              <Route path="user" element={<AdminUser />} />
            </Route>
          </Route>


          {/* error  */}
          <Route path="*" element={<Error />} />
          <Route path="/login-error" element={<LoginError />} />
          <Route path="/MultiBrowserError" element={<MultiBrowserError />} />
          <Route path="/MultiTabError" element={<MultiTabError />} />

        </Routes>
      </div>
    </>
  );
}

export default App;
