import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { TfiEye } from 'react-icons/tfi'
import { capitalizeFirstLetter ,arrayToString} from '../../../utils/Helper'

const tableColumns = [
    { label: 'Period', field: 'period' },
    { label: 'Country', field: 'country' },
    { label: 'Duties', field: 'duties' },
    { label: 'Any Ref.Letter', field: 'refLetter' }
]

const WorkExperience = ({ data, handleShow }) => {
    console.log("Testing78",data);

    const renderPeriod = (from, to, present, index) => {
        console.log(from, to, present);
        return (
            <div className="period tbl-td ">
                <span className="text-nowrap">
                    {new Date(from)
                        .toLocaleString("en-GB", {
                            year: "numeric",
                            month: "short",
                        })
                        .replace(/ /g, "-")}
                </span>
                <span className="mx-2 text-nowrap">To</span>
                <span className="text-nowrap">
                    {present
                        ? "Present"
                        : new Date(to)
                            .toLocaleString("en-GB", {
                                year: "numeric",
                                month: "short",
                            })
                            .replace(/ /g, "-")}
                </span>
            </div>
        )

    }

    const renderCountry = (country) => {
        return <>
            {
                capitalizeFirstLetter(country)
            }
        </>

    }

    const renderDuties = (duties) => {
        return <>{
            arrayToString(duties)
        }
        </>

    }
    const refLetter = (refLetter) => {
        return (
            (refLetter) ?
            <span
                className="eye-icon mx-2 cursor-pointer"
                onClick={() =>
                    handleShow(
                        "helperModal",
                        "letter",
                        `${refLetter}`
                    )
                }
            >
                <TfiEye className='text-blue fs-3'/>
            </span>: ""
            )
                                                    

    }



const convertRowDataToTableData = (data, index) => {
    console.log(data);
    return {
        ...data,
        period: renderPeriod(data.from, data.to, data.present),
        country: renderCountry(data.country),
        duties: renderDuties(data.duties),
        refLetter: refLetter(data.refLetter)
    }
}

return (
    <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} />
)
}

export default React.memo(WorkExperience)