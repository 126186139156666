import React, { useState } from "react";
import OTPInput from "otp-input-react";
import './NricInput.css'

const NricInputBox = ({
    value, onChange, name
}) => {
    // const [lastFour, setLastFour] = useState(""); // State for last 4 characters

    // const [otp, setOtp] = useState("");

    // // Handle OTP input change
    // const handleChange = (value) => {
    // const sanitizedValue = value
    //   .toUpperCase() // Convert to uppercase
    //   .replace(/[^A-Z0-9]/g, ""); // Remove invalid characters
    //    setOtp(sanitizedValue);

    //     // Pass the last 4 digits back to the parent
    //     if (value.length === 4) {
    //         onCapture(sanitizedValue);
    //     }else{
    //         onCapture();
    //     }
    // };



    return (
        <div style={styles.container}>
            <h2 style={styles.title}>Last 4 characters of your NRIC</h2>
            <div style={styles.inputContainer}>
                <span style={styles.maskedText}>XXXX</span>
                <span style={styles.dash}>-</span>
                <div className="otp-4-input">
                    <OTPInput value={value} OTPLength={4} onChange={onChange}
                        otpType="alphanumeric" disabled={false} name={name}/>
                </div>

            </div>
        </div>
    );
};

// CSS styles in JS
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    title: {
        fontSize: "0.875rem",
        fontWeight: "500",
        color: "black",
        transition: "all 0.2s ease-in-out",
        backgroundColor: "white",
        marginTop: "-10px",
        marginLeft: "12px",
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    inputContainer: {
        display: "flex",
        alignItems: "center",
        fontSize: "20px",
        padding: "5px 20px",
    },
    maskedText: {
        // fontWeight: "bold",
        color: "#808080",
        fontSize:"22px"
    },
    dash: {
        margin: "0 10px",
        color: "#000",
    },

};

export default NricInputBox;
