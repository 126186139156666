import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { formatedDate, renderName, renderNameInitial } from '../../../utils/Helper';

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Candidate', field: 'candidateName' },
    { label: 'Candidate Type', field: 'candidateType' },
    { label: 'Selected for Onboarding on', field: 'date' },
    { label: 'Status', field: 'status' },
]


const EmpSelectedCandidateHistoryList = ({ data, isLoading }) => {

    const renderStatus = (step1Status, step1Reason, step2Status, step2Reason) => {
        // Check if Step 1 is cancelled
        if (step1Status === "Candidate Selection Cancelled") {
            return `Step 1: ${step1Status} - ${step1Reason}`;
        }

        if (step1Status === `Work Permit Rejected`) {
            return `Step 1: ${step1Status}`;
        }

        // Check if Step 2 is cancelled
        if (step2Status === "Candidate Selection Cancelled") {
            return `Step 2: ${step2Status} - ${step2Reason}`;
        }

        // Check if Step 2 is work started
        if (step2Status === "Work Permit Issued and Work Started") {
            return `Step 2: Work Permit Issued and Work Started`;
        }

        // Default return if none of the conditions are met
        return null;
    };

    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.candidateName),
            candidateName: renderName(data.candidateName),
            date: formatedDate(data.date),
            status: renderStatus(data.step1Status, data.step1Reason, data.step2Status, data.step2Reason, index)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} isLoading={isLoading} />
    )
}

export default EmpSelectedCandidateHistoryList