import React from 'react'
import './verificationSuccess.css'
import { useNavigate } from 'react-router-dom'


function VerificationSuccess() {
   const navigate = useNavigate()
   return (
      <div className='employee__register__verificationSuccess text-center'>
         <h3 className='title'>Verification <span className='white-space-nowrap'>Successfully !!</span></h3>
         <p className='message mt-5'>Your account is created !!</p>
         <p className='message mb-3'>Please Login to your account</p>
         <button type="submit" className="btn btn-blue mt-1 w-100"
         onClick={()=>{navigate('/employer/login')}}>OK</button>
      </div>
   )
}

export default VerificationSuccess