import React, {
   useState,
   useEffect,
   useContext,
   useCallback,
   useRef,
} from "react";
import axios from "axios";
import { io } from "socket.io-client";
import { MdLocationPin } from "react-icons/md";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import {  Button} from "react-bootstrap";
import Datetime from "react-datetime";
import { TfiEye } from "react-icons/tfi";
import { AiOutlineClose } from "react-icons/ai";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import getBaseUrl from "../../hooks/http";
import { CustomModal } from "../customModal";
import { CountContext } from "../../context/CountContext";
import { capitalizeFirstLetter, urlExists } from "../../utils/Helper";
import WorkExperience from "../employer/workExprience/workExperience";
import "./candidateCard.css";

const CandidateCard = ({ candidates, setCandidates, isLoading }) => {
   const { updateInterviewCount } = useContext(CountContext);

   const reschedulePopupRef = useRef(null);

   const [details, setDetails] = useState(null);
   const [isFileExists, setIsFileExists] = useState(false);
   const [videoUrl, setVideoUrl] = useState("");
   const [isScheduling, setIsScheduling] = useState(false);

   //for boot modals ----------------------------
   const [helperData, setHelperData] = useState({
      video: false,
      letter: false,
      refLetter: "",
   });
   const [show, setShow] = useState({
      viewMore: false,
      helper: false,
   });

   // fun to close modals
   const handleClose = (modal) => {
      if (modal === "viewMoreDetailsModal") {
         setShow({ ...show, viewMore: false });
      } else {
         setShow({ ...show, helper: false });
      }
      setIsFileExists(false);
      setHelperData({ video: false, letter: false, refLetter: "" });
   };

   // fun to open modals
   const handleShow = async (modal, data = null, refLetter = null) => {
      if (Modal === "viewMoreDetailsModal") {
         setShow({ ...show, viewMore: true });
      } else {
         setShow({ ...show, helper: true });
         if (data === "video") setHelperData({ video: true, letter: false });
         if (data === "letter") {
            const fileStatus = await checkFileExists(refLetter);
            setIsFileExists(fileStatus);
            let imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
            setHelperData({ video: false, letter: true, refLetter: imgView });
         }
      }
   };
   //fun to check File Exists or not
   const checkFileExists = async (refLetter) => {
      try {
         const response = await fetch(refLetter);
         if (response.ok) {
            return true;
         } else {
            return false;
         }
      } catch (error) {
         console.error("Error checking document URL:", error);
         return false;
      }
   };

   //handle Rescheduled Date Change
   const handleRescheduledDateChange = (date, i) => {
      const list = [...candidates];
      list[i]["reschedule"]["date"] = new Date(date);
      list[i]["reschedule"]["error"] = false;
      list[i]["reschedule"]["error2"] = false;
      list[i]["reschedule"]["error3"] = false;

      setCandidates(list);
   };

   // function get position
   const position = (e, sl = false) => {
      const target = sl ? e : e.target;

      const btnRect = target.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const scroll =
         document.documentElement.scrollTop || document.body.scrollTop;

      const navbarHeight = 85;

      const popupWidth = reschedulePopupRef.current.offsetWidth;
      const popupHeight = reschedulePopupRef.current.offsetHeight;

      const btnOffsetTop = btnRect.top - navbarHeight;
      const btnOffsetBottom = window.innerHeight - btnRect.bottom;
      const btnOffsetRight = window.innerWidth - btnRect.right;

      let top = btnRect.top - popupHeight / 2;
      let left = btnRect.right + 5;

      if (btnOffsetTop < popupHeight / 2) {
         top =
            btnRect.bottom < navbarHeight
               ? btnRect.bottom
               : btnRect.top - popupHeight / 2 - (btnOffsetTop - popupHeight / 2);
      }

      if (btnOffsetBottom < popupHeight / 2) {
         top =
            btnOffsetBottom < 0
               ? btnRect.bottom - popupHeight
               : btnRect.bottom - popupHeight + btnOffsetBottom;
      }

      if (btnOffsetRight < popupWidth + 5) {
         left =
            btnRect.left > popupWidth ? btnRect.left - popupWidth : btnRect.left;
      }

      return { top, left };
   };

   // handle open Rescheduled Date Pickier
   const openRescheduledDatePickier = (e, i) => {
      const { top, left } = position(e);

      const list = [...candidates];
      const scheduleDate = list[i]["reschedule"]["date"];

      for (let index = 0; index < list.length; index++) {
         if (index !== i) {
            list[index]["reschedule"]["isShow"] = false;
            list[i]["reschedule"]["error"] = false;
            list[i]["reschedule"]["error2"] = false;
            list[i]["reschedule"]["error3"] = false;
         }
      }

      list[i]["reschedule"]["isShow"] = list[i].reschedule.isShow ? false : true;
      list[i]["reschedule"]["top"] = top;
      list[i]["reschedule"]["left"] = left;
      list[i]["reschedule"]["date"] = "";

      setCandidates(list);
   };

   // fun to close Rescheduled Date Pickier
   const closeRescheduledDatePickier = useCallback(
      (i) => {
         const list = [...candidates];
         list[i]["reschedule"]["isShow"] = false;
         list[i]["reschedule"]["error2"] = false;
         list[i]["reschedule"]["error"] = false;
         list[i]["reschedule"]["error3"] = false;
         setCandidates(list);
      },
      [candidates]
   );

   //fun to  handle Reschedule Click
   const handleRescheduleClick = (i) => {
      const list = [...candidates];

      var preDate = new Date(list[i].date);
      var newDate = new Date(list[i].reschedule.date);
      var currentDate = new Date();
      const timeDifference = newDate - currentDate;
      const timeDifference2 = Math.round(timeDifference / 1000 / 60);

      if (preDate.getTime() === newDate.getTime()) {
         list[i]["reschedule"]["error"] = true;
         setCandidates(list);
      } else if (timeDifference2 <= 30) {
         list[i]["reschedule"]["error2"] = true;
         setCandidates(list);
      } else if (newDate.toString() === "Invalid Date") {
         list[i]["reschedule"]["error3"] = true;
         setCandidates(list);
      } else {
         list[i]["reschedule"]["isShow"] = false;
         setCandidates(list);
         //WA msg
         interviewInvitationToCnd(i);
      }
   };

   //Code added by Namrata
   const handleStatusUpdate = (cndId, status) => {
      const url = `${getBaseUrl}`.replace(/\/api/g, "");
      const socket = io.connect(url);
      socket.emit("employerUpdate", { cndId: cndId, status: status });
   };

   // interview Invitation To Cnd to candidate
   const interviewInvitationToCnd = async (index) => {
      setIsScheduling(true);
      try {
         const list = [...candidates];
         const listData = list[index];
         const user = JSON.parse(localStorage.getItem("user"));
         const { token } = user;
         let config = {
            headers: { Authorization: `Bearer ${user.token}` },
         };
         const res = await axios.post(
            `${getBaseUrl}/employer/interviewInvitationToCnd`,
            listData,
            config
         );
         if (res.status === 200) {
            //closeRescheduledDatePickier
            closeRescheduledDatePickier(index);
            // inProgress
            list[index]["inProgress"] = "YES";
            setCandidates(list);
            handleStatusUpdate(listData.candID, "reshedule");
            //get Interviews Count
            const getInterviewsCount = await axios.get(
               `${getBaseUrl}/interviews/EmployergetCount`,
               config
            );
            updateInterviewCount(getInterviewsCount.data.interviewCount);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsScheduling(false);
      }
   };

   const changeCalendarPosition = async () => {
      if (candidates !== null) {
         const list = [...candidates];
         const btnScheduleInterview = document.querySelectorAll(
            ".btn-scheduleInterview"
         );

         for (let i = 0; i < list.length; i++) {
            const isShow = list[i].reschedule.isShow;
            if (btnScheduleInterview.length > 0 && isShow) {
               const { top, left } = await position(btnScheduleInterview[i], true);
               list[i]["reschedule"]["top"] = top;
               list[i]["reschedule"]["left"] = left;
               setCandidates(list);
            }
         }
      }
   };

   //window scroll change calendar position
   const handleWindowOnScroll = () => {
      changeCalendarPosition();
   };
   //window resize change calendar position
   const handleWindowOnResize = () => {
      changeCalendarPosition();
   };

   //handleViewDetails
   const handleViewDetails = async (i) => {
      const list = [...candidates];
      list[i][
         "photoPreviewUrl"
      ] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].photo}`;
      list[i][
         "videoPreviewUrl"
      ] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].video}`;
      list[i][
         "fullPhotoPreviewUrl"
      ] = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i].fullPhoto}`;
      setDetails(list[i]);
      setShow({ ...show, viewMore: true });
   };

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = "/uploads/userIcon.png";
   };

   // handle Zoom Effect
   const handleZoomIn = () => {
      const docViewerImage = document.getElementById("image-img");
      docViewerImage.style.minWidth = `${docViewerImage.offsetWidth - 20}px`;
   };

   let count = 1;
   const handleZoomOut = () => {
      const docViewerImage = document.getElementById("image-img");
      if (count <= 5)
         docViewerImage.style.minWidth = `${docViewerImage.offsetWidth + 20}px`;
      count++;
   };

   useEffect(() => {
      window.addEventListener("scroll", handleWindowOnScroll);
      window.addEventListener("resize", handleWindowOnResize);
      return () => {
         window.removeEventListener("scroll", handleWindowOnScroll);
         window.removeEventListener("resize", handleWindowOnResize);
      };
   }, [candidates]);

   //fun to convert array to string
   const arrayToString = (arr) => {
      return Array.isArray(arr) && arr.length > 0
         ? arr.toString().replace(/,/g, ", ")
         : "-";
   };

   // return ui on isLoading
   if (isLoading) {
      return (
         <div className="card-body">
            <p className="text-center p-5 fw-bold">Loading...</p>
         </div>
      );
   }

   return (
      <div className="box">
         <div className="row gx-4 gy-1">
            {candidates &&
               candidates.map((cnd, index) => {
                  const cardClass = index % 2 === 0 ? "even-card" : "odd-card";
                  return (
                     <div className="col-12 mb-2" key={index}>
                        <div className="CandidateCard mb-2">
                           <div
                              className={`candidate-info ${cnd.reschedule.isShow && "border-3-blue"
                                 }`}
                           >
                              <div className="card-body">
                                 <div
                                    className={`d-block d-md-flex description-1 ${cardClass}`}
                                 >
                                    <div className="profile-wrapper">
                                       <img
                                          src={
                                             cnd?.photo
                                                ? `${getBaseUrl}/candidates/getMediaFileFromDb/${cnd?.photo}`
                                                : "/uploads/userIcon.png"
                                          }
                                          onError={imageOnError}
                                          alt="profile"
                                          className="profile"
                                       />
                                    </div>
                                    <div className="flex-grow-1 px-3">
                                       <p className="name text-center text-md-start">
                                          {cnd.name}{" "}
                                       </p>
                                       <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center gap-1 gap-md-5">
                                          <p className="age"> Age: {cnd.age}yrs</p>
                                          <p className="availability text-center text-sm-start">
                                             Availability: {cnd.availability}
                                          </p>
                                          <p className="country">
                                             <MdLocationPin className="location-icon" />
                                             {cnd.country}
                                          </p>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="d-flex flex-column-reverse flex-md-row description-2">
                                    <div className="btn__wrapper d-flex flex-wrap flex-md-column  justify-content-center justify-content-md-end align-items-center gap-3">
                                       <button
                                          className="btn-red-reshedule btn-scheduleInterview"
                                          onClick={(event) =>
                                             openRescheduledDatePickier(event, index)
                                          }
                                          disabled={cnd?.inProgress === "YES" ? true : false}
                                       >
                                          {cnd?.inProgress === "YES"
                                             ? "In Progress"
                                             : "Schedule Interview"}
                                       </button>
                                       <button
                                          className="btn-red-reshedule btn-viewMore"
                                          onClick={() => handleViewDetails(index)}
                                       >
                                          View More Details
                                       </button>
                                    </div>

                                    <div className="summary flex-grow-1 text-md-start px-3 pt-2">
                                       <p className="">
                                          {" "}
                                          Type: <span>{cnd?.typeOfCandidate}</span>
                                       </p>
                                       <p className="">
                                          Has worked in Singapore?{" "}
                                          <span>{cnd.hasworkedInSingapore}</span>
                                       </p>
                                       <p className="skills">
                                          {" "}
                                          Skills: <span>{arrayToString(cnd.skills)}</span>
                                       </p>
                                       <p className="cooking">
                                          Cooking: <span>{arrayToString(cnd.cooking)}</span>
                                       </p>
                                       <p className="exp-salary">
                                          Expected Salary:{" "}
                                          <span>S$ {cnd.expectedSalary}</span>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  );
               })}
            {candidates?.length === 0 && (
               <div className="CandidateCard mb-5">
                  <div className="candidate-info">
                     <div className="card-body">
                        <p className="text-center p-5 fw-bold">Record Not Found.</p>
                     </div>
                  </div>
               </div>
            )}
         </div>

         {/* reschedule-popup */}
         {candidates &&
            candidates.map((candidates, index) => (
               <CustomModal
                  key={index}
                  isOpen={candidates.reschedule.isShow}
                  onClose={() => closeRescheduledDatePickier(index)}
               >
                  <div
                     ref={reschedulePopupRef}
                     className={`reschedule-popup`}
                     style={{
                        top: candidates.reschedule.top,
                        left: candidates.reschedule.left,
                     }}
                  >
                     <div className="reschedule-popup-date bg-blue text-white py-2 text-center">
                        DateTime:
                        <br />
                        {candidates.reschedule.date
                           .toLocaleString("en-GB", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                           })
                           .replace(/00:/g, "12:")
                           .replace(/,/g, " at ")}
                        <br />
                        {`${candidates.reschedule.date}`.substring(
                           `${candidates.reschedule.date}`.indexOf("("),
                           `${candidates.reschedule.date}`.lastIndexOf(")") + 1
                        )}
                     </div>
                     <Datetime
                        className=""
                        value={candidates.reschedule.date}
                        onChange={(date) => handleRescheduledDateChange(date, index)}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="hh:mm A" // Set time format to AM/PM
                        isValidDate={(current) =>
                           current.isAfter(new Date()) ||
                           current.isSameOrAfter(
                              new Date().setDate(new Date().getDate() - 1)
                           )
                        } // Set the minimum date
                        input={false}
                     />
                     {candidates.reschedule.error && (
                        <div className="bg-white text-danger text-center fs-6 mb-2 text-center">
                           Change date or time.
                        </div>
                     )}
                     {candidates.reschedule.error2 && (
                        <div className="bg-white text-danger fs-6 mb-2 text-center">
                           Time selected is not allowed.
                        </div>
                     )}
                     {candidates.reschedule.error3 && (
                        <div className="bg-white text-danger fs-6 mb-2 text-center">
                           Please select Date and Time.
                        </div>
                     )}

                     <div className="d-flex justify-content-center mt-2">
                        <Button
                           variant="primary"
                           className="btn-sm me-1"
                           onClick={() => handleRescheduleClick(index)}
                        >
                           Schedule
                        </Button>
                        <Button
                           variant="secondary"
                           className="btn-sm ms-1"
                           onClick={() => closeRescheduledDatePickier(index)}
                        >
                           Cancel
                        </Button>
                     </div>
                  </div>
               </CustomModal>
            ))}

         {/* viewMoreDetailsModal */}
         <Modal
            size="lg"
            show={show.viewMore}
            onHide={() => handleClose("viewMoreDetailsModal")}
            backdrop="static"
            id="viewMoreDetailsModal"
            className="candidateCardViewMoreDetailsModal"
         >
            <Modal.Body className="p-0 ">
               <div className="position-relative">
                  <AiOutlineClose
                     onClick={() => handleClose("viewMoreDetailsModal")}
                     className="icon close-icon position-absolute"
                  />
               </div>

               <div className="candidate-details pb-5">
                  <div className="description-1 d-lg-flex ">
                     <div className="profile-wrapper d-block mx-auto">
                        <img
                           src={details?.photoPreviewUrl}
                           onError={imageOnError}
                           alt="profile"
                           className="profile cursor-pointer"
                           onClick={() =>
                              handleShow("helperModal", "letter", `${details?.photo}`)
                           }
                        />
                     </div>
                     <div className="flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1">
                        <p className="name text-center text-lg-start ">
                           {details?.name}
                        </p>
                        <p className="age text-center text-lg-start ">
                           {details?.typeOfCandidate}
                        </p>
                        <div className="d-lg-flex">
                           <p className="dob me-lg-5 text-center text-lg-start">
                              <span className="text-nowrap"> Date of Birth :</span>
                              <span className="text-nowrap">{details?.dob}</span>
                           </p>
                           <p className="age text-center text-lg-start">
                              Age :{details?.age} years
                           </p>
                        </div>
                     </div>
                  </div>

                  <div className="description-2 px-3">
                     <p className="nationality">
                        Nationality : <span>{details?.nationality}</span>
                     </p>
                     <p className="availability">
                        {" "}
                        Availability : <span>{details?.availability}</span>
                     </p>
                     <p className="maritalStatus">
                        Marital Status:{" "}
                        <span>{capitalizeFirstLetter(details?.maritalStatus)}</span>
                     </p>
                     <div>
                        <p>
                           No. of kids : <span>{details?.childAge.length}</span>{" "}
                        </p>
                        {details?.childAge && (
                           <div className="d-flex flex-wrap justify-content-center justify-content-sm-start ">
                              {details?.childAge.map((childAge, index) => (
                                 <p className="ms-2" key={index}>
                                    kid{index + 1}: <span>{childAge}yrs</span>
                                 </p>
                              ))}
                           </div>
                        )}
                     </div>
                  </div>

                  <div className="workDetails ">
                     <h3 className="px-3">Work Details:</h3>
                     <div className="d-lg-flex flex-row-reverse justify-content-end px-3 gap-50">
                        <div className="video-wrapper text-center d-flex flex-column mb-2 mb-lg-0">
                           {details?.video ? (
                              <>
                                 <video
                                    src={details?.videoPreviewUrl}
                                    className="cursor-pointer border border-secondary  mx-auto mx-lg-0 mb-2 mt-auto"
                                 />
                                 <button
                                    className="btn btn-sm btn-primary mt-auto align-self-center"
                                    onClick={() => handleShow("helperModal", "video")}
                                 >
                                    View Video
                                 </button>
                              </>
                           ) : (
                              <img
                                 src="/images/record-icon.png"
                                 onError={imageOnError}
                                 alt="profile"
                              />
                           )}
                        </div>
                        <div className="video-wrapper d-flex flex-column align-items-center mb-2 mb-lg-0">
                           {
                              <img
                                 src={details?.fullPhotoPreviewUrl}
                                 onError={imageOnError}
                                 alt="profile"
                                 className="cursor-pointer  mx-auto mb-2"
                              />
                           }
                           <button
                              className="btn btn-sm btn-primary mt-auto"
                              onClick={() =>
                                 handleShow(
                                    "helperModal",
                                    "letter",
                                    `${details?.fullPhoto}`
                                 )
                              }
                           >
                              View Photo
                           </button>
                        </div>
                        <div className="mb-2 mb-lg-0">
                           <p className="location">
                              <span className="text-nowrap me-1">
                                 Present Location :{" "}
                              </span>
                              <span className="text-nowrap">
                                 {details?.country || "-"}
                              </span>
                           </p>
                           <p className="experience">
                              <span className="text-nowrap text-dark me-1">
                                 Total Experience :
                              </span>
                              <span className="text-nowrap">
                                 {details?.yearOfExperience.year || 0} Years{" "}
                                 {details?.yearOfExperience.month || 0} Months
                              </span>
                           </p>
                           <p className="singaporeExperience">
                              <span className="text-nowrap text-dark me-1">
                                 Singapore Experience :
                              </span>
                              <span className="text-nowrap">
                                 {details?.singaporeExperience?.year || 0} Years{" "}
                                 {details?.singaporeExperience?.month || 0} Months
                              </span>
                           </p>
                        </div>
                     </div>
                     {details?.workExperience.length > 0 && (
                        <div className="table-responsive px-3">
                           <br/>
                           <WorkExperience data={details?.workExperience} handleShow={handleShow}/>

                        </div>
                     )}
                  </div>

                  <div className="skills">
                     <h3 className="px-3">Skills/Duties:</h3>
                     <p className="px-3">
                        <span className="text-nowrap me-1">Languages: </span>
                        <span>{arrayToString(details?.languages)}</span>
                     </p>
                     <p className="px-3">
                        <span className="text-nowrap me-1">Skills : </span>
                        <span>{arrayToString(details?.skills)}</span>
                     </p>
                     <p className="px-3">
                        <span className="text-nowrap me-1">Personality :</span>
                        <span>{arrayToString(details?.personality)}</span>
                     </p>
                     <p className="px-3">
                        <span className="text-nowrap me-1">Cooking : </span>
                        <span>{arrayToString(details?.cooking)}</span>
                     </p>
                  </div>
               </div>
            </Modal.Body>
         </Modal>

         {/* helpermodal for video and ref.letter   */}
         <Modal
            show={show.helper}
            onHide={() => handleClose("helperModal")}
            backdrop="static"
            id="helperModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName={`w-min-content ${helperData?.refLetter?.includes("pdf") ? "pdf" : ""
               }`}
         >
            <Modal.Body className="p-0 justify-content-end min-h-100">
               <div className="position-relative">
                  <AiOutlineClose
                     onClick={() => handleClose("helperModal")}
                     className="icon close-icon position-absolute"
                  />
               </div>
               <div>
                  {helperData?.letter && !helperData?.refLetter?.includes("pdf") && (
                     <div className="d-flex justify-content-center gap-10 p-3">
                        <button className="btn-zoom zoon-in" onClick={handleZoomIn}>
                           <FiZoomOut />{" "}
                        </button>
                        <button className="btn-zoom zoom-out" onClick={handleZoomOut}>
                           <FiZoomIn />
                        </button>
                     </div>
                  )}
                  {helperData?.video && (
                     <video
                        src={details?.videoPreviewUrl}
                        controls
                        className="w-100"
                     ></video>
                  )}

                  {helperData?.letter && (
                     <DocViewer
                        className="doc-viewer"
                        documents={[
                           { uri: helperData?.refLetter, fileName: "Reference_letter" },
                        ]}
                        pluginRenderers={DocViewerRenderers}
                        config={{
                           header: {
                              disableHeader: true,
                              disableFileName: true,
                              retainURLParams: true,
                           },
                        }}
                     />
                  )}
                  {/*((!isFileExists) && (helperData?.letter)) && <p className='text-center p-3'>Error: Failed to load the document.</p>*/}
               </div>
            </Modal.Body>
         </Modal>
      </div>
   );
};

export default React.memo(CandidateCard);
