import React from 'react'
import CustomTable from '../../../../components/customTable/CustomTable'
import { TfiEye } from 'react-icons/tfi'
import { capitalizeFirstLetter ,arrayToString} from '../../../../utils/Helper'
import { RiDeleteBinFill } from "react-icons/ri";

const tableColumns = [
    { label: 'FROM', field: 'from' },
    { label: 'TO', field: 'to' },
    { label: 'COUNTRY', field: 'country' },
    { label: 'DUTIES', field: 'duties' },
    { label: 'Any Reference Letter', field: 'refLetter' },
    { label: 'Action', field: 'action'}
]

const WorkExpForVerifyData = ({ data, handleImgView, handleDelete}) => {
    const renderFrom = (from,index) => {
        return (
            <div className="period">
                <span className="text-nowrap">
                    {new Date(from)
                        .toLocaleString("en-GB", {
                            year: "numeric",
                            month: "short",
                        })
                        .replace(/ /g, "-")}
                </span>
               
            </div>
        )

    }

    const renderTo = ( to, present, index) =>{
        return (
            <span className="text-nowrap">
            {present
                ? "Present"
                : new Date(to)
                    .toLocaleString("en-GB", {
                        year: "numeric",
                        month: "short",
                    })
                    .replace(/ /g, "-")}
        </span>
        )
    }

    const renderCountry = (country) => {
        return <>
            {
                capitalizeFirstLetter(country)
            }
        </>

    }

    const renderDuties = (duties) => {
        return <>{
            arrayToString(duties)
        }
        </>

    }
    const refLetter = (refLetter) => {
        return (
            (refLetter) ?
            <span
                className="eye-icon mx-2 cursor-pointer"
                onClick={() =>
                    handleImgView(refLetter)
                }
            >
                <TfiEye className='text-blue fs-3'/>
            </span>: ""
            )
                                                    

    }

    const renderAction = (id,tempId) => {
        return (
            <button type="button" onClick={() => handleDelete(id, tempId)} className="btn btn-danger btn-sm" title="Delete"><RiDeleteBinFill /></button>
        )
    }
    



const convertRowDataToTableData = (data, index) => {
    return {
        ...data,
        from: renderFrom(data.from),
        to:renderTo(data.from,data.present),
        country: renderCountry(data.country),
        duties: renderDuties(data.duties),
        refLetter: refLetter(data.refLetter),
        action: renderAction(data.id,data.tempId),
    }
}

return (
    <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} />
)
}

export default React.memo(WorkExpForVerifyData)