import React, { useEffect, useState } from 'react';
import PhoneNumber from '../../../../components/PhoneNumber';
import { validateEmail } from '../../../../utils/Helper';



const EmpUserForm = ({ data, employer, email, updateFields, changeField, setcurrentStepIndex }) => {


   const [phoneNumber, setPhoneNumber] = useState('')
   const [dialingCode, setDialingCode] = useState('')
   const [error, setError] = useState('')


   useEffect(() => {
      updateFields({ phoneNumber: phoneNumber, dialingCode: dialingCode });
      setError('');
   }, [phoneNumber, dialingCode])


   //handleFormSubmit --------------------
   const handleFormSubmit = async (e) => {
      e.preventDefault();
      if (changeField === 'phone') {
         const to_Number = `${data.dialingCode}${data.phoneNumber}`;
         if (to_Number === `+${employer.phoneNumber}`) setError('Enter different number than Current Number');
         else setcurrentStepIndex(1);

      } else {
         const to_mail = data.email;

         if (to_mail === employer.email) { setError('Enter different email than Current email'); return; }
         if (!validateEmail(to_mail)) { setError('Invalid email address'); return; }
         setcurrentStepIndex(1);
      }
   }



   return (
      <>
         <form onSubmit={handleFormSubmit}>
            {
               (changeField === 'phone')
                  ?
                  <>
                     <h3 className='title mb-4 text-center'>Enter New Phone Number</h3>
                     <div className='my-4'>
                        <PhoneNumber value={{ phoneNumber, dialingCode }}
                           onChange={{ setPhoneNumber, setDialingCode }} inputDataType="number"
                           labelText="Phone Number" />
                     </div>
                  </>
                  :
                  <>
                     <h3 className='title mb-4 text-center'>Enter New Email</h3>
                     <div className='my-4 d-block d-sm-flex flex-wrap gap-10 align-items-center'>
                        <div className="floating-control flex-grow-1">
                           <input type="text" name="email" id='email' className="floating-input"
                              placeholder="none" autoComplete='off' required value={email}
                              onChange={(e) => { updateFields({ email: e.target.value }); setError('') }} />
                           <label htmlFor="email" className="floating-label">Email</label>
                        </div>
                     </div>
                  </>
            }
             {error && <div className="flex-grow-1 text-center text-danger mb-2">{error}</div>}
            <div className='text-center'>
               <button className='btn btn-primary mx-2' >Next</button>
            </div>
           
         </form>
      </>
   )
}

export default EmpUserForm