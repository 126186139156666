import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { formatedDate, renderName, renderNameInitial } from '../../../utils/Helper'
import { TfiEye } from 'react-icons/tfi'

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Candidate', field: 'name' },
    { label: 'Candidate Type', field: 'candidateType' },
    { label: 'Interview Conducted on', field: 'interviewConductedDate' },
    { label: 'Status', field: 'status' },
    { label: 'Action', field: 'action' },
]



const EmpOfferList = ({ data, isLoading,
    handleOfferFormShow,
    openRescheduledDatePickier,
    handleOfferPdf,
    confirmMeeting,
    handleCancelOfferClick,
    handleCancelMeeting,
    handleMakePaymentModalOpen
}) => {

    const renderAction = (Is_OfferCreated,status,Is_OfferSingByCnd, index) => {
        return <div >
            <div className='offer__action__container py-2'>
                <div className="offer__action__wrapper d-flex flex-wrap align-items-end justify-content-between flex-sm-nowrap justify-content-sm-center">
                    <div className='icon icon1 mx-1 mx-sm-3' onClick={() => handleOfferFormShow(index)}>
                        <img src="/images/makeOffer.png" alt="" />
                        <span className="text">MAKE OFFER</span>
                    </div>
                    <div className={`icon icon1 mx-1 mx-sm-3 ${(Is_OfferCreated === 'Yes') ? "NoStyle" : "disabled-image"}`} onClick={() => handleOfferPdf(index)}>
                        <TfiEye className='fs-4' size={30} />
                        <span className="text">View OFFER</span>
                    </div>
                    <div className={`icon icon1 mt-2 mt-0 mx-1 mx-sm-3`} onClick={() => handleCancelOfferClick(index)}>
                        <img src="/images/cancelOffer.png" />
                        <span className="text">CANCEL OFFER</span>
                    </div>
                </div>

                <div className="offer__action__wrapper d-flex flex-wrap align-items-end justify-content-between flex-sm-nowrap justify-content-sm-center">
                    <div className='icon icon2 mt-3 mx-1 mx-sm-3' onClick={(event) => openRescheduledDatePickier(event, index)}>
                        <img src="/images/schedule.png" alt="" />
                        <span className="text" >SCHEDULE MEETING</span>
                    </div>
                    <div className={`icon icon2 mt-4  mx-sm-3 ${(status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || status === "Pending Employer Confirmation for Offer Meeting") ? "NoStyle" : "pe-none"}`} onClick={() => confirmMeeting(index)}>
                        <img src="/images/confirm.png" alt=""
                            className={`${(status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || status === "Pending Employer Confirmation for Offer Meeting") ? "NoStyle" : "disabled-image"}`}
                        />
                        <span className={`${(status === 'Reschedule - Pending Employer Confirmation for Offer Meeting' || status === "Pending Employer Confirmation for Offer Meeting") ? "" : "text-secondary"} text`}>CONFIRM MEETING</span>
                    </div>
                    <div className='icon icon3 mt-3 mx-1 mx-sm-3' onClick={() => handleCancelMeeting(index)}>
                        <img src="/images/cancel.png" alt="" />
                        <span className="text">CANCEL MEETING</span>
                    </div>
                </div>
            </div>
            <div className="d-flex align-self-center mx-2 my-2 flex-sm-row">
               {(Is_OfferSingByCnd === "Yes") &&
                <button className='btn btn-green btn-makePayment mx-auto mt-1' onClick={() => { handleMakePaymentModalOpen(index) }}>Make Payment</button>}
            </div>
        </div>
    }

    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.name),
            name: renderName(data.name, index),
            interviewConductedDate: formatedDate(data.interviewConductedDate),
            action: renderAction(data.Is_OfferCreated, data.status,data.Is_OfferSingByCnd, index)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)}
            isLoading={isLoading} />
    )
}

export default EmpOfferList