import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { formatedDate, renderNameInitial ,renderName} from '../../../utils/Helper'


const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Employer', field: 'candidateName' },
    { label: 'Interview on', field: 'date' },
    { label: 'Current Status', field: 'status' },
    { label: 'Action', field: 'action' },
]


const CndInterviewList = ({ data, isLoading, openRescheduledDatePickier, confirmInterview, handleCancelInterview }) => {

    const joinMeeting = (date, status, link) => {
        const ACTIVE_STATUSES = ['Candidate Confirmed for Interview Meeting', 'Reschedule - Employer Confirmed Interview Meeting', 'Reschedule - Candidate Confirmed Interview Meeting'];
        const isConfirmed = ACTIVE_STATUSES.includes(status);
        const currentTime = new Date();
        const scheduleDate = new Date(date);
        const dateDiff = currentTime - scheduleDate;
        const timeDiff = Math.round((dateDiff / 1000) / 60);
        //display join meeting button before 30 minute and after 3o minutes of scheduled time
        return <>
            {
                (isConfirmed && (timeDiff > -15) && (timeDiff < 30)) ? <a className="btn btn-sm" href={`https://teams.microsoft.com/l/meetup-join/${link}`} role="button" target="_blank" style={{ background: '#95D03A', color: '#fff' }} rel="noopener noreferrer">Join Meeting</a>
                    : null
            }
        </>
    }

    const renderAction = (interviewStatus, index) => {
        return (
            <div id="action" className='d-flex flex-sm-row flex-wrap flex-sm-nowrap align-items-center justify-content-evenly justify-content-sm-center  gap-3 gap-sm-0'>
                <div className='icon icon1 mx-1 mx-sm-3' onClick={(event) => openRescheduledDatePickier(event, index)}>
                    <img src="/images/reschedule.png" alt="" />
                    <span className="text">RESCHEDULE</span>
                </div>
                <div className={`icon icon2 mx-1 mx-sm-3 ${(interviewStatus === 'Pending Candidate Confirmation for Interview Meeting' || interviewStatus === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "NoStyle" : "pe-none"}`} onClick={() => confirmInterview(index)}>
                    <img src="/images/confirm.png" alt=""
                        className={`${(interviewStatus === 'Pending Candidate Confirmation for Interview Meeting' || interviewStatus === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "NoStyle" : "disabled-image"}`}
                    />
                    <span className={`${(interviewStatus === 'Pending Candidate Confirmation for Interview Meeting' || interviewStatus === 'Reschedule - Pending Candidate Confirmation for Interview Meeting') ? "text" : "text-secondary"}`}>CONFIRM</span>
                </div>
                <div className='icon icon3 mx-1 mx-sm-3' onClick={() => handleCancelInterview(index)}>
                    <img src="/images/cancel.png" alt="" />
                    <span className="text">CANCEL</span>
                </div>
            </div>
        )
    }

    const renderInterviewOn = (date, status, link) => {
        return <>
            {
                formatedDate(date)
            }
            <br />
            {
                joinMeeting(date, status, link)
            }
        </>
    }

    // const renderName = (name, index) => {
    //     return <div className=''>
    //         {name?.length > 20 ? `${name?.slice(0, 20)}...` : name}

    //     </div>
    // }

    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.EmpTempName),
            candidateName: renderName(data.EmpTempName, index),
            date: renderInterviewOn(data.date, data.status, data.link),
            action: renderAction(data.status, index)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} isLoading={isLoading} />
    )
}

export default CndInterviewList