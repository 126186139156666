import React from 'react'
import { Outlet } from 'react-router-dom'
import { HomeNavbar } from '../components/homeNavbar';
import "./frontendLayout.css";

const FrontendLayout = () => {

   return (
      <>
         <div className='h-100 d-flex flex-column'>
            <HomeNavbar />
            <main className='flex-grow-1'>
               <Outlet />
            </main>
         </div>
      </>
   )
}

export default FrontendLayout