import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import Datetime from 'react-datetime';
import { Container, Row, Col, Button, Modal, Spinner, } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AiOutlineClose, AiFillMinusCircle } from 'react-icons/ai';
import { IoIosCloseCircle } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoAddCircleSharp } from "react-icons/io5";
import { TfiEye } from "react-icons/tfi";
import { FaCalendar } from "react-icons/fa";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DialogAlert from "../../../../components/customModal/customAlertModal";
import { animateScroll as scroll } from "react-scroll";

import generateOTP from "../../../../utils/GenerateOTP";
import Select from "../../../../components/select/Select";
import VideoRecorder from "../../../../components/media/VideoRecorder";
import getBaseUrl from "../../../../hooks/http";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useSocketContext } from "../../../../hooks/useSocketContext";


import VerifyWorkExprienceList from "./VerifyWorkExprienceList";
import WorkExperienceMobile from "./WorkExperienceMobile";
import { usePersonalDetails } from "./usePersonalDetails";
import "react-datepicker/dist/react-datepicker.css";
import "./personalDetails.css";
import {
  cooking, currentCountry, educationCountry, highestEducation, languages, nationality, oFFDays, personality, Religion, skills, table, typeOfCandidate, howSoonStart, workExpeCountry, maritalStatus
} from "../../../../constants";
import YourDocument from "../../../../components/personalDetails/YourDocument";
import EducationCertificateListModal from "../../../../components/personalDetails/EducationCertificateListModal";

/*-----------------------------------------------------------------------------------------*/
const initialFormData = {
  profilePreview: "/uploads/userIcon.png",
  photo: "",
  fullPhotoPreview: "/uploads/userIcon.png",
  fullPhoto: "",
  video: "",
  videoUploadingStatus: "",
  typeOfCandidate: "",
  name: "",
  gender: false,
  address: "",
  birthday: null,
  nationality: "",
  height: "",
  weight: "",
  maritalStatus: "",
  childAge: [],
  skills: [],
  languages: [],
  cooking: [],
  personality: [],
  currentCountry: "",
  currentlyWorking: "",
  religion: "",
  howSoonCanStart: "",
  expectedSalary: "",
  oFFDays: "",
  passport: [],
  educationCertificates: [],
  medicalReport: [],
  educationalDetailsCountry: "",
  HighestEducation: "",
  yearOfExperience: {
    year: 0,
    month: 0,
  },
  singaporeExperience: {
    year: 0,
    month: 0,
  },
  workExperience: [],
};
// initial Form Unverified Data ----
const initialFormUnverifiedData = {
  profilePreview: "",
  photo: "",
  fullPhotoPreview: "",
  fullPhoto: "",
  video: "",
  videoUploadingStatus: "",
  typeOfCandidate: "",
  name: "",
  gender: "",
  address: "",
  birthday: null,
  nationality: "",
  height: "",
  weight: "",
  maritalStatus: "",
  childAge: [],
  skills: [],
  languages: [],
  cooking: [],
  personality: [],
  currentCountry: "",
  currentlyWorking: "",
  religion: "",
  howSoonCanStart: "",
  expectedSalary: "",
  oFFDays: "",
  passport: [],
  educationCertificates: [],
  medicalReport: [],
  educationalDetailsCountry: "",
  HighestEducation: "",
  yearOfExperience: {
    year: 0,
    month: 0,
  },
  singaporeExperience: {
    year: 0,
    month: 0,
  },
  workExperience: [],
};

const PersonalDetails = () => {
  const naviagte = useNavigate();
  const { user, dispatch } = useAuthContext();
  const socket = useSocketContext();
  const { savePersonalDetails, deletePersonalDetails, savePersonalUnverifyDetails, isLoading, error, success } = usePersonalDetails();

  /* define useState ------------------------------------------*/
  const [mode, setMode] = useState(null); //form mode: save / update
  const [verificationCount, setVerificationCount] = useState(0);
  const [verificationCounter, setVerificationCounter] = useState(1);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [unverifyselectedIndices, setUnverifySelectedIndices] = useState([]);
  const [formError, setFormError] = useState({}); // form error
  const [formEditDataError, setFormEditDataError] = useState({});
  const [formStatusError, setFormStatusError] = useState({}); //set status error
  const [showStatusError, setShowStatusError] = useState({});
  const [maxLengthError, setMaxLengthError] = useState({});
  const [formPendingStatus, setFormPendingStatus] = useState({}); //form Pending Status
  const [deletedWorkExperience, setDeletedWorkExperience] = useState([]);
  const [deletedUnverifiedWorkExperience, setDeletedUnverifiedWorkExperience] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const [myVideoBlob, setMyVideoBlob] = useState("");
  const [myVideo, setMyVideo] = useState({
    data: "",
    unverifydata: "",
  });
  const [showEditCancel, setShowEditCancel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [readOnlyValue, setReadOnlyValue] = useState(false);
  const [PendingCounter, setPendingCounter] = useState(false);
  const [hiddenElements, setHiddenElements] = useState({});
  const [isFocused, setIsFocused] = useState(null);
  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [sessionDocuments, setSessionDocuments] = useState([]); // Temporary session changes
  //modal state -----------------------------------------------
  const [alertModal, setAlertModal] = useState(false);
  const [documentModal, setDocumentModal] = useState({
    show: false,
    docName: ''
  });
  const [viewModal, setViewModal] = useState({
    show: false,
    url: "",
    fullscreen: false,
    type: "",
  });

  // form Data states---------------------------------
  const [formData, setFormData] = useState(initialFormData);
  const [formEditData, setFormEditData] = useState({});
  const [formUnverifiedData, setFormUnverifiedData] = useState(
    initialFormUnverifiedData
  );
  const [documentConfirmationModal, setDocumentConfirmationModal] = useState(false);

  //Set Rejection Reason hide - code by mohsin
  const hideRejectionReason = (fieldName) => {
    if (PendingCounter) {
      setFormStatusError({ ...formStatusError, [fieldName]: false });
      setFormPendingStatus({ ...formPendingStatus, [fieldName]: false });
    } else {
      setFormStatusError({ ...formStatusError, [fieldName]: false });
      setFormPendingStatus({ ...formPendingStatus, [fieldName]: true });
    }
  };

  // This function will be triggered when the file (img) field change
  const imageChange = (e) => {
    const file = e.target.files[0];
    const fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    const validOtherTypes = ["application/pdf"];

    // file type validation
    if (e.target.name === "photo" || e.target.name === "fullPhoto") {
      if (!validImageTypes.includes(fileType)) {
        // invalid file type code goes here.
        setOpen(true);
        setDialogMessage(
          "Invalid file types. Only .jpg, .jpeg, .png, files allowed"
        );
        return 0;
      }
    } else {
      if (
        !validImageTypes.includes(fileType) &&
        !validOtherTypes.includes(fileType)
      ) {
        // invalid file type code goes here.
        setOpen(true);
        setDialogMessage("Invalid file types. Only .Pdf files allowed");
        return 0;
      }
    }
    // file size validation
    if (file.size > 1048576) {
      // invalid file type code goes here.
      setOpen(true);
      setDialogMessage("File size exceeds the limit (1 MB)");
      return 0;
    }

    if (e.target.files && e.target.files.length > 0) {
      if (e.target.name === "photo") {
        if (verificationCount > 0) {
          setFormUnverifiedData({
            ...formUnverifiedData,
            photo: e.target.files[0],
            profilePreview: URL.createObjectURL(e.target.files[0]),
          });
        } else {
          setFormData({
            ...formData,
            photo: e.target.files[0],
            profilePreview: URL.createObjectURL(e.target.files[0]),
          });
        }
        if (mode === "update")
          setFormEditData({ ...formEditData, photo: e.target.files[0] });
        setFormError({ ...formError, photo: false });
      } else if (e.target.name === "fullPhoto") {
        if (verificationCount > 0) {
          setFormUnverifiedData({
            ...formUnverifiedData,
            fullPhoto: e.target.files[0],
            fullPhotoPreview: URL.createObjectURL(e.target.files[0]),
          });
        } else {
          setFormData({
            ...formData,
            fullPhoto: e.target.files[0],
            fullPhotoPreview: URL.createObjectURL(e.target.files[0]),
          });
        }
        if (mode === "update")
          setFormEditData({ ...formEditData, fullPhoto: e.target.files[0] });
        setFormError({ ...formError, fullPhoto: false });
      } else {
        if (mode === "update")
          setFormEditData({
            ...formEditData,
            [e.target.name]: e.target.files[0],
          });
        if (verificationCount > 0) {
          setFormUnverifiedData({
            ...formUnverifiedData,
            [e.target.name]: e.target.files[0],
          });
        } else {
          setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        }
        setFormError({ ...formError, [e.target.name]: false });
      }
    }

    //Set Rejection Reason hide
    hideRejectionReason(e.target.name);
  };

  // check renders file is exists or not
  const checkFileExist = (url) => {
    var http = new XMLHttpRequest();
    /*if (url.length === 0) {
         return false;
      }*/
    if (url === "" || url === undefined) {
      return false;
    } else {
      http.open("HEAD", url, false);
      http.send();
      if (http.status === 200) {
        return true;
      } else {
        return false;
      }
    }
  };

  //show img view
  const handleImgView = (field) => {
    if (typeof field === "string") {
      const validImageTypes = ["jpg", "jpeg", "png", "webp"];
      const validOtherTypes = ["pdf"];
      let imgView;

      if (validImageTypes.includes(field)) {
        imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
        setViewModal({ ...viewModal, show: true, url: imgView, type: "image" });
      } else if (validOtherTypes.includes(field) || field.includes(".pdf")) {
        imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
        setViewModal({ ...viewModal, show: true, url: imgView, type: "pdf" });
      } else {
        imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
        setViewModal({ ...viewModal, show: true, url: imgView, type: "image" });
      }
    } else {
      const fileType = field.type;
      const validImageTypes = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/webp",
      ];
      const validOtherTypes = ["application/pdf"];
      let imgView;

      if (validImageTypes.includes(fileType)) {
        imgView = URL.createObjectURL(field);
        setViewModal({ ...viewModal, show: true, url: imgView, type: "image" });
      } else if (validOtherTypes.includes(fileType) || field.includes(".pdf")) {
        imgView = field;
        setViewModal({ ...viewModal, show: true, url: imgView, type: "pdf" });
      } else {
        imgView = field;
        setViewModal({ ...viewModal, show: true, url: imgView, type: "image" });
      }
    }
  };

  const handleCancelDocument = (docName) => {
    setFormUnverifiedData({ ...formUnverifiedData, [docName]: "" });
  };

  /*  handle form field onchange event  ----------------------------------------------------*/
  const handleGender = (e) => {
    if (mode === "save") {
      setFormData({ ...formData, gender: e.target.checked });
      formError.gender = false;
    }
  };
  const handleFormData = (e, index) => {
    //other condition and validation

    const { name, value } = e.target;
    if (name === "name") {
      if (value.length === 50) {
        setMaxLengthError({ ...maxLengthError, name: "Max 50 Character" });
        return;
      } else setMaxLengthError({ ...maxLengthError, name: "" });
    }
    if (name === "address") {
      if (value.length === 70) {
        setMaxLengthError({ ...maxLengthError, address: "Max 70 Character" });
        return;
      } else setMaxLengthError({ ...maxLengthError, address: "" });
    }
    if (name === "height") {
      if (value > 270) {
        setMaxLengthError({ ...maxLengthError, height: "Invalid height" });
        return;
      } else setMaxLengthError({ ...maxLengthError, height: "" });
    }
    if (name === "weight") {
      if (value > 250) {
        setMaxLengthError({ ...maxLengthError, weight: "Invalid weight" });
        return;
      } else setMaxLengthError({ ...maxLengthError, weight: "" });
    }

    if (name === "typeOfCandidate") {
      setTypeSelectedValue(value);
    }
    if (e.target.name === "height" || e.target.name === "weight") {
      //three digits before the decimal point and two digits after
      const regx1 = /^\d{0,3}(\.\d{0,2})?$/;
      if (!regx1.test(e.target.value)) {
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
      if (mode === "update")
        setFormEditData({ ...formEditData, [e.target.name]: e.target.value });
      if (!regx1.test(e.target.value)) {
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
      if (mode === "update")
        setFormEditData({ ...formEditData, [e.target.name]: e.target.value });
    } else if (
      e.target.name === "maritalStatus" &&
      e.target.value !== "married"
    ) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        childAge: [],
      });
      if (mode === "update")
        setFormEditData({
          ...formEditData,
          [e.target.name]: e.target.value,
          childAge: [],
        });
    }

    //check required fiels are valid
    const requiredFields = [
      "name",
      "gender",
      "address",
      "nationality",
      "height",
      "weight",
      "maritalStatus",
      "currentCountry",
      "oFFDays",
      "currentlyWorking",
      "religion",
      "yearOfExperience",
      "expectedSalary",
      "typeOfCandidate",
      "educationalDetailsCountry",
      "HighestEducation",
    ];
    if (requiredFields.includes(e.target.name) && e.target.value !== "") {
      if (e.target.name === "yearOfExperience") {
        const { year, month } = formData.yearOfExperience;
        if (year !== "" || month !== "") {
          formError.yearOfExperience = false;
        }
      }
      setFormError({
        ...formError,
        [e.target.name]: false,
        expectedSalaryMsg: false,
      });
    }

    if (e.target.name === "expectedSalary") {
      let inputValue = e.target.value;
      //const regx2 = /^[0-9\b]+$/;
      const regx2 = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (
        (Number(inputValue) >= 1 &&
          Number(inputValue) <= 3000 &&
          regx2.test(e.target.value) &&
          e.target.value.charAt(0) !== 0) ||
        e.target.value === ""
      ) {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (mode === "update")
          setFormEditData({ ...formEditData, [e.target.name]: e.target.value });
      } else {
        return 0;
      }
    }

    //set data when all condition true
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (mode === "update")
      setFormEditData({ ...formEditData, [e.target.name]: e.target.value });

    //Set Rejection Reason hide
    hideRejectionReason(e.target.name);
  };

  //handle Unverifield FormData
  const handleUnverifieldFormData = (e) => {
    //set data when all condition true
    const { name, value } = e.target;
    if (name === "name") {
      if (value.length === 50) {
        setMaxLengthError({ ...maxLengthError, name: "Max 50 Character" });
        return;
      } else setMaxLengthError({ ...maxLengthError, name: "" });
    }
    if (name === "address") {
      if (value.length === 50) {
        setMaxLengthError({ ...maxLengthError, address: "Max 70 Character" });
        return;
      } else setMaxLengthError({ ...maxLengthError, address: "" });
    }
    if (name === "height") {
      if (value > 270) {
        setMaxLengthError({ ...maxLengthError, height: "Invalid height" });
        return;
      } else setMaxLengthError({ ...maxLengthError, height: "" });
    }
    if (name === "weight") {
      if (value > 250) {
        setMaxLengthError({ ...maxLengthError, weight: "Invalid weight" });
        return;
      } else setMaxLengthError({ ...maxLengthError, weight: "" });
    }

    if (name === "typeNewCandidate") {
      setTypeSelectedValue(value);
    }

    if (name === "height" || name === "weight") {
      //three digits before the decimal point and two digits after
      const regx1 = /^\d{0,3}(\.\d{0,2})?$/;
      if (!regx1.test(e.target.value)) {
        return;
      }
      if (!regx1.test(e.target.value)) {
        return;
      }
      setFormUnverifiedData({
        ...formUnverifiedData,
        [e.target.name]: e.target.value,
      });
      if (mode === "update")
        setFormEditData({ ...formEditData, [e.target.name]: e.target.value });
    } else if (
      e.target.name === "maritalStatus" &&
      e.target.value !== "married"
    ) {
      setFormUnverifiedData({
        ...formUnverifiedData,
        [e.target.name]: e.target.value,
        childAge: [],
      });
      if (mode === "update")
        setFormEditData({
          ...formEditData,
          [e.target.name]: e.target.value,
          childAge: [],
        });
    }

    if (e.target.name === "expectedSalary") {
      let inputValue = e.target.value;
      const regx2 = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (
        (Number(inputValue) >= 1 &&
          Number(inputValue) <= 3000 &&
          regx2.test(e.target.value) &&
          e.target.value.charAt(0) !== 0) ||
        e.target.value === ""
      ) {
        setFormUnverifiedData({
          ...formUnverifiedData,
          [e.target.name]: e.target.value,
        });
        setFormEditData({ ...formEditData, [e.target.name]: e.target.value });
        setFormEditDataError({
          ...formEditDataError,
          expectedSalaryMsg: false,
        });
      } else {
        //setFormEditDataError({ ...formEditDataError, expectedSalaryMsg: true })
        return 0;
      }
    }
    setFormUnverifiedData({
      ...formUnverifiedData,
      [e.target.name]: e.target.value,
    });
    setFormEditDataError({ ...formEditDataError, [e.target.name]: false });
    setFormEditData({ ...formEditData, [e.target.name]: e.target.value });

    //Set Rejection Reason hide
    if (PendingCounter) {
      setFormStatusError({ ...formStatusError, [e.target.name]: false });
      setFormPendingStatus({ ...formPendingStatus, [e.target.name]: false });
    } else {
      setFormStatusError({ ...formStatusError, [e.target.name]: false });
      setFormPendingStatus({ ...formPendingStatus, [e.target.name]: true });
    }
  };

  // handle Birthday field's onchange event
  const handleBirthdayChange = (date) => {
    const today = new Date();
    const selectedDate = date;
    const difference = selectedDate
      ? today.getFullYear() - new Date(selectedDate).getFullYear()
      : null;

    if (difference <= 23 || difference >= 50) {
      if (verificationCount === 0) {
        setFormData({ ...formData, birthday: date });
        if (mode === "update")
          setFormEditData({ ...formEditData, birthday: date });
        setFormError({ ...formError, Minbirthday: true });
      } else {
        setFormUnverifiedData({ ...formUnverifiedData, birthday: date });
        setFormEditData({ ...formEditData, birthday: date });
        setFormEditDataError({ ...formEditDataError, Minbirthday: true });
        //Set Rejection Reason hide
        setFormStatusError({ ...formStatusError, birthday: false });
        setFormPendingStatus({ ...formPendingStatus, birthday: true });
      }
    } else {
      if (verificationCount === 0) {
        setFormData({ ...formData, birthday: date });
        if (mode === "update")
          setFormEditData({ ...formEditData, birthday: date });
        setFormError({ ...formError, birthday: false, Minbirthday: false });
      } else {
        setFormUnverifiedData({
          ...formUnverifiedData,
          birthday: date,
        });
        setFormEditDataError({
          ...formEditDataError,
          Minbirthday: false,
          birthday: false,
        });
        setFormEditData({ ...formEditData, birthday: date });
        setFormError({ ...formError, Minbirthday: false, birthday: false });
        //Set Rejection Reason hide
        if (PendingCounter) {
          setFormStatusError({ ...formStatusError, birthday: false });
          setFormPendingStatus({ ...formPendingStatus, birthday: false });
        } else {
          setFormStatusError({ ...formStatusError, birthday: false });
          setFormPendingStatus({ ...formPendingStatus, birthday: true });
        }
      }
    }

    if (date == null) {
      setFormError({ ...formError, Minbirthday: false });
    }

    //Set Rejection Reason hide
    hideRejectionReason("birthday");
  };

  const handleMultiselect = (value, name) => {
    setFormData({ ...formData, [name]: value });
    if (mode === "update") setFormEditData({ ...formEditData, [name]: value });
    //check required fiels are valid
    if (["skills", "languages"].includes(name) && value !== "") {
      setFormError({ ...formError, [name]: false });
    }
    //Set Rejection Reason hide
    hideRejectionReason(name);
  };
  const handleUnverifyMultiselect = (value, name) => {
    setFormUnverifiedData({ ...formUnverifiedData, [name]: value });

    setFormEditData({ ...formEditData, [name]: value });
    setFormEditDataError({ ...formEditDataError, [name]: false });
    //Set Rejection Reason hide
    if (PendingCounter) {
      setFormStatusError({ ...formStatusError, [name]: false });
      setFormPendingStatus({ ...formPendingStatus, [name]: false });
    } else {
      setFormStatusError({ ...formStatusError, [name]: false });
      setFormPendingStatus({ ...formPendingStatus, [name]: true });
    }

    //check required fiels are valid
    if (["skills", "languages"].includes(name) && value !== "") {
      setFormError({ ...formError, [name]: false });
    }
  };

  //Handle Educational Details

  const handleEducationalDeatails = (e) => {
    if (verificationCount === 0) {
      if (e.target.name === "country") {
        setFormData({
          ...formData,
          educationalDetailsCountry: e.target.value,
        });
        if (mode === "update")
          setFormEditData({
            ...formData,
            educationalDetailsCountry: e.target.value,
          });
      }
      if (e.target.name === "HighestEducation") {
        setFormData({
          ...formData,
          HighestEducation: e.target.value,
        });
        if (mode === "update")
          setFormEditData({
            ...formEditData,
            HighestEducation: e.target.value,
          });
      }
      if (e.target.name === "country" || e.target.name === "HighestEducation") {
        const { educationalDetailsCountry, HighestEducation } = {
          ...formData,
          [e.target.name === "country"
            ? "educationalDetailsCountry"
            : "HighestEducation"]: e.target.value,
        };
        console.log(educationalDetailsCountry, HighestEducation);

        if (educationalDetailsCountry !== "" && HighestEducation !== "") {
          setFormError({
            ...formError,
            educationalDetailsCountry: false,
            HighestEducation: false,
          });
        }
      }
    } else {
      if (e.target.name === "country") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          educationalDetailsCountry: e.target.value,
        });
      }
      if (e.target.name === "HighestEducation") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          HighestEducation: e.target.value,
        });
      }
      if (e.target.name === "country" || e.target.name === "HighestEducation") {
        const { educationalDetailsCountry, HighestEducation } = {
          ...formUnverifiedData,
          [e.target.name === "country"
            ? "educationalDetailsCountry"
            : "HighestEducation"]: e.target.value,
        };
        console.log(educationalDetailsCountry, HighestEducation);

        if (educationalDetailsCountry !== "" && HighestEducation !== "") {
          setFormEditData({ ...formEditData, NewEducationDetails: "" });
        }
      }
    }
  };

  // handle Year Of Experience
  const handleYearOfExperience = (e) => {
    setFormError({ ...formError, workExperienceMsg: false });
    if (verificationCount === 0) {
      // const { year, month } = formData.singaporeExperience;
      const year =
        formData.singaporeExperience.year === ""
          ? "0"
          : formData.singaporeExperience.year;
      const month =
        formData.singaporeExperience.month === ""
          ? "0"
          : formData.singaporeExperience.month;

      const totalExpInMonth = parseInt(year * 12) + parseInt(month);
      //setFormError({ ...formError, singaporeExperienceErrorMsg: true })
      //return 0;
      if (e.target.name === "year") {
        const totalMonth =
          formData.yearOfExperience.month === ""
            ? "0"
            : formData.yearOfExperience.month;
        const calculateYear =
          parseInt(e.target.value) * 12 + parseInt(totalMonth);
        if (calculateYear < totalExpInMonth) {
          setFormError({ ...formError, yearOfExperienceErrorMsg: true });
          return 0;
        }
      }

      if (e.target.name === "month") {
        const TotalYear =
          formData.yearOfExperience.year === ""
            ? "0"
            : formData.yearOfExperience.year;
        const calculateYearMonth =
          parseInt(TotalYear) * 12 + parseInt(e.target.value);
        if (calculateYearMonth < totalExpInMonth) {
          setFormError({ ...formError, yearOfExperienceErrorMsg: true });
          return 0;
        }
      }

      //------------------------------------------------------------------------
      const regx = /^[0-9\b]+$/;
      const validMonth =
        e.target.name === "month" ? e.target.value <= 12 : true;

      if (
        (e.target.value === "" || regx.test(e.target.value)) &&
        e.target.value.length < 3 &&
        validMonth
      ) {
        setFormData({
          ...formData,
          yearOfExperience: {
            ...formData.yearOfExperience,
            [e.target.name]: e.target.value,
          },
        });
        if (mode === "update")
          setFormEditData({
            ...formEditData,
            yearOfExperience: {
              ...formData.yearOfExperience,
              [e.target.name]: e.target.value,
            },
          });
        //check required fiels are valid
        setFormError({ ...formError, yearOfExperience: false });
      }
      setFormError({ ...formError, yearOfExperienceErrorMsg: false });
      //Set Rejection Reason hide
      hideRejectionReason("yearOfExperience");
    } else {
      const year =
        formUnverifiedData.singaporeExperience.year === ""
          ? "0"
          : formUnverifiedData.singaporeExperience.year;
      const month =
        formUnverifiedData.singaporeExperience.month === ""
          ? "0"
          : formUnverifiedData.singaporeExperience.month;
      const totalExpInMonth = parseInt(year * 12) + parseInt(month);
      //setFormError({ ...formError, singaporeExperienceErrorMsg: true })
      //return 0;
      if (e.target.name === "year") {
        const totalMonth =
          formUnverifiedData.yearOfExperience.month === ""
            ? "0"
            : formUnverifiedData.yearOfExperience.month;
        const calculateYear =
          parseInt(e.target.value) * 12 + parseInt(totalMonth);
        if (calculateYear < totalExpInMonth) {
          setFormError({ ...formError, yearOfExperienceErrorMsg: true });
          return 0;
        }
      }

      if (e.target.name === "month") {
        const TotalYear =
          formUnverifiedData.yearOfExperience.year === ""
            ? "0"
            : formUnverifiedData.yearOfExperience.year;
        const calculateYearMonth =
          parseInt(TotalYear) * 12 + parseInt(e.target.value);
        if (calculateYearMonth < totalExpInMonth) {
          setFormError({ ...formError, yearOfExperienceErrorMsg: true });
          return 0;
        }
      }

      //-------- ----------------
      const regx = /^[0-9\b]+$/;
      const validMonth =
        e.target.name === "month" ? e.target.value <= 12 : true;

      if (
        (e.target.value === "" || regx.test(e.target.value)) &&
        e.target.value.length < 3 &&
        validMonth
      ) {
        setFormUnverifiedData({
          ...formUnverifiedData,
          yearOfExperience: {
            ...formUnverifiedData.yearOfExperience,
            [e.target.name]: e.target.value,
          },
        });
        setFormEditData({
          ...formEditData,
          yearOfExperience: {
            ...formEditData.yearOfExperience,
            [e.target.name]: e.target.value,
          },
        });
      }
      //Set Rejection Reason hide
      hideRejectionReason("yearOfExperience");
    }
  };

  // handle Singapore Experience
  const handleSingaporeExperience = (e) => {

    if (verificationCount === 0) {
      // Singapore Experience should be less than or equal to total experience --
      //const { year, month } = formData.yearOfExperience;
      const year = (formData.yearOfExperience.year === "") ? "0" : formData.yearOfExperience.year;
      const month = (formData.yearOfExperience.month === "") ? "0" : formData.yearOfExperience.month;
      const totalExpInMonth = parseInt(year * 12) + parseInt(month);
      //setFormError({ ...formError, singaporeExperienceErrorMsg: true })
      //return 0;
      if (e.target.name === 'year') {
        const singMonth = (formData.singaporeExperience.month === "") ? "0" : formData.singaporeExperience.month;
        const calculateYear = (parseInt(e.target.value) * 12) + (parseInt(singMonth));
        if ((calculateYear > totalExpInMonth)) {
          setFormError({ ...formError, singaporeExperienceErrorMsg: true })
          return 0;
        }
      }

      if (e.target.name === 'month') {
        const singYear = (formData.singaporeExperience.year === "") ? "0" : formData.singaporeExperience.year;
        const calculateYearMonth = (parseInt(singYear) * 12) + parseInt(e.target.value);
        if ((calculateYearMonth > totalExpInMonth)) {
          setFormError({ ...formError, singaporeExperienceErrorMsg: true })
          return 0;
        }
      }
      //------------------------------------------------------------------------
      const regx = /^[0-9\b]+$/;
      const validMonth = (e.target.name === 'month') ? e.target.value <= 12 : true

      if ((e.target.value === '' || regx.test(e.target.value)) && e.target.value.length < 3 && validMonth) {
        setFormData({
          ...formData,
          singaporeExperience: {
            ...formData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
        if (mode === 'update') setFormEditData({
          ...formEditData, singaporeExperience: {
            ...formData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
        //check required fiels are valid
        setFormError({ ...formError, singaporeExperience: false })
      }
      if ((e.target.value === '' || regx.test(e.target.value)) && e.target.value.length < 3 && validMonth) {
        setFormData({
          ...formData,
          singaporeExperience: {
            ...formData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
        if (mode === 'update') setFormEditData({
          ...formEditData, singaporeExperience: {
            ...formData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
        //check required fiels are valid
        setFormError({ ...formError, singaporeExperience: false })
        //Set Rejection Reason hide
        hideRejectionReason('singaporeExperience')
      }

      setFormError({ ...formError, singaporeExperienceErrorMsg: false })
    } else {
      const year = (formData.yearOfExperience.year === "") ? "0" : formData.yearOfExperience.year;
      const month = (formData.yearOfExperience.month === "") ? "0" : formData.yearOfExperience.month;
      const totalExpInMonth = parseInt(year * 12) + parseInt(month);


      const unverifiedYear = (formUnverifiedData.yearOfExperience.year === "") ? "0" : formUnverifiedData.yearOfExperience.year;
      const unverifiedmonth = (formUnverifiedData.yearOfExperience.month === "") ? "0" : formUnverifiedData.yearOfExperience.month;
      const unverifiedtotalExpInMonth = parseInt(unverifiedYear * 12) + parseInt(unverifiedmonth);

      //setFormError({ ...formError, singaporeExperienceErrorMsg: true })
      //return 0;
      if (e.target.name === 'year') {
        const singMonth = (formUnverifiedData.singaporeExperience.month === "") ? "0" : formUnverifiedData.singaporeExperience.month;
        const calculateYear = (parseInt(e.target.value) * 12) + (parseInt(singMonth));
        if ((calculateYear > totalExpInMonth) && (calculateYear > unverifiedtotalExpInMonth)) {
          setFormError({ ...formError, singaporeExperienceErrorMsg: true })
          return 0;
        }
      }

      if (e.target.name === 'month') {
        const singYear = (formUnverifiedData.singaporeExperience.year === "") ? "0" : formUnverifiedData.singaporeExperience.year;
        const calculateYearMonth = (parseInt(singYear) * 12) + parseInt(e.target.value);
        if ((calculateYearMonth > totalExpInMonth) && (calculateYearMonth > unverifiedtotalExpInMonth)) {
          setFormError({ ...formError, singaporeExperienceErrorMsg: true })
          return 0;
        }
      }

      //--------
      const regx = /^[0-9\b]+$/;
      const validMonth = (e.target.name === 'month') ? e.target.value <= 12 : true

      if ((e.target.value === '' || regx.test(e.target.value)) && e.target.value.length < 3 && validMonth) {
        setFormUnverifiedData({
          ...formUnverifiedData,
          singaporeExperience: {
            ...formUnverifiedData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
        setFormEditData({
          ...formEditData,
          singaporeExperience: {
            ...formEditData.singaporeExperience,
            [e.target.name]: e.target.value
          }
        })
      }
      setFormStatusError({ ...formStatusError, singaporeExperience: false })
      setFormPendingStatus({ ...formPendingStatus, singaporeExperience: true })
    }
  }

  /* childAge --------------------------------------------------------------- */
  //add child
  const addChild = () => {
    if (hiddenElements.childAge) {
      // this cndtion for unverified data
      //add new age div
      if (formUnverifiedData.childAge.length >= 1) {
        const arrayAgeValue = formUnverifiedData.childAge.some(
          (value) => value === null || value === undefined || value === ""
        );
        if (arrayAgeValue) {
          setFormError({ ...formError, blankage: true });
          return 0;
        } else {
          setFormError({ ...formError, blankage: false });
        }
      }

      if (formUnverifiedData.childAge.length < 10) {
        setFormUnverifiedData({
          ...formUnverifiedData,
          childAge: [...formUnverifiedData.childAge, ""],
        });
      }
    } else {
      //add age div
      if (formData.childAge.length >= 1) {
        const arrayAgeValue = formData.childAge.some(
          (value) => value === null || value === undefined || value === ""
        );
        if (arrayAgeValue) {
          setFormError({ ...formError, blankage: true });
          return 0;
        } else {
          setFormError({ ...formError, blankage: false });
        }
      }

      if (formData.childAge.length < 10) {
        setFormData({ ...formData, childAge: [...formData.childAge, ""] });
        if (mode === "update")
          setFormEditData({
            ...formEditData,
            childAge: [...formData.childAge, ""],
          });
      }
    }
  };
  //remove child
  const removeChild = () => {
    if (hiddenElements.childAge) {
      // this cndtion for unverified data
      // setChildInput(value)
      setFormError({ ...formError, blankage: false });
      //remove age div
      const value = [...formUnverifiedData.childAge];
      value.splice(-1);
      setFormUnverifiedData({ ...formUnverifiedData, childAge: value });
    } else {
      // setChildInput(value)
      setFormError({ ...formError, blankage: false });
      //remove age div
      const value = [...formData.childAge];
      value.splice(-1);
      setFormData({ ...formData, childAge: value });
      if (mode === "update")
        setFormEditData({ ...formEditData, childAge: value });
    }
  };

  // handle Child Age input
  const handleChildAge = (e, index) => {
    if (e.target.name !== "childAge") {
      return;
    }

    const ageRegx = /^[1-9][0-9]*/; //Accept numeric only. First character can't be 0

    if (
      (e.target.value === "" || ageRegx.test(e.target.value)) &&
      e.target.value.length < 3
    ) {
      if (hiddenElements.childAge) {
        //this is for unverified
        const newAge = [...formUnverifiedData.childAge];
        newAge[index] = e.target.value;
        setFormUnverifiedData({ ...formUnverifiedData, childAge: newAge });
        setFormEditData({ ...formEditData, childAge: newAge }); //for validation
      } else {
        const newAge = [...formData.childAge];
        newAge[index] = e.target.value;
        setFormData({ ...formData, childAge: newAge });
        if (mode === "update")
          setFormEditData({ ...formEditData, childAge: newAge });
      }
      setFormError({ ...formError, childAge: false });
      setFormError({ ...formError, blankage: false });
    }
  };

  /*  Work Experience ------------------------------------------------------------- */

  const addWorkExperience = () => {
    //check yearOfExperience
    const { year, month } = formData.yearOfExperience;
    const { year: unverifiedYear, month: unverifiedMonth } =
      formUnverifiedData.yearOfExperience;
    if (verificationCount === 0) {
      if (year <= 0 && month <= 0) {
        setFormError({ ...formError, workExperienceMsg: true });
        return;
      } else {
        setFormError({ ...formError, workExperienceMsg: false });
      }
    } else {
      if (
        year <= 0 &&
        month <= 0 &&
        unverifiedYear <= 0 &&
        unverifiedMonth <= 0
      ) {
        setFormError({ ...formError, workExperienceMsg: true });
        return;
      } else {
        setFormError({ ...formError, workExperienceMsg: false });
      }
    }

    //check Verification counter
    if (verificationCount === 0) {
      // work experience details
      let flag = true;
      setSelectedIndices([]);

      formData.workExperience.forEach(function (workExp, index) {
        const dutiesFlag = Array.isArray(workExp.duties)
          ? workExp.duties.length === 0
          : workExp.duties === null;
        if (workExp.Form === null || workExp.country === "" || dutiesFlag) {
          setSelectedIndices((oldArray) => [...oldArray, index]);
          setFormError({ ...formError, workExperienceFillMsg: true });
          flag = false;
        }
        if (
          (workExp.to === null || workExp.to === "") &&
          workExp.present === false
        ) {
          setSelectedIndices((oldArray) => [...oldArray, index]);
          setFormError({ ...formError, workExperienceFillMsg: true });
          flag = false;
        }
      });

      if (flag) {
        setFormData({
          ...formData,
          workExperience: [
            ...formData.workExperience,
            {
              from: null,
              to: null,
              country: "",
              duties: [],
              present: false,
            },
          ],
        });
        if (mode === "update")
          setFormEditData({
            ...formEditData,
            workExperience: [
              ...formData.workExperience,
              {
                from: null,
                to: null,
                country: "",
                duties: [],
                present: false,
              },
            ],
          });
      }
    } else {
      const { year, month } = formUnverifiedData.yearOfExperience;
      const dataYear = formData.yearOfExperience.year;
      const dataMonth = formData.yearOfExperience.month;
      if (dataYear > 0 || dataMonth > 0 || year > 0 || month > 0) {
        let flag = true;
        setUnverifySelectedIndices([]);
        formUnverifiedData.workExperience.forEach(function (workExp, index) {
          const dutiesFlag = Array.isArray(workExp.duties)
            ? workExp.duties.length === 0
            : workExp.duties === null;
          if (workExp.Form === null || workExp.country === "" || dutiesFlag) {
            setUnverifySelectedIndices((oldArray) => [...oldArray, index]);
            setFormError({ ...formError, workExperienceFillMsg: true });
            flag = false;
          }
          if (
            (workExp.to === null || workExp.to === "") &&
            workExp.present === false
          ) {
            setUnverifySelectedIndices((oldArray) => [...oldArray, index]);
            setFormError({ ...formError, workExperienceFillMsg: true });
            flag = false;
          }
        });
        if (flag) {
          const list = [
            ...formUnverifiedData.workExperience,
            {
              from: null,
              to: null,
              country: "",
              duties: [],
              present: false,
            },
          ];
          setFormUnverifiedData({
            ...formUnverifiedData,
            workExperience: list,
          });
          setFormEditData({
            ...formEditData,
            workExperience: list,
          });
        }
      }
    }
  };
  const removeWorkExperience = (id, type = "") => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience].filter(
        (workExp) => workExp.id !== id
      );
      // list.splice(index, 1);
      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
      setFormError({ ...formError, workExperienceFillMsg: false });
    } else {
      const list = [...formUnverifiedData.workExperience].filter(
        (workExp) => workExp.id !== id
      );
      // list.splice(index, 1);
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    }

    if (type === "unverified")
      setDeletedUnverifiedWorkExperience([
        ...deletedUnverifiedWorkExperience,
        id,
      ]);
    else setDeletedWorkExperience([...deletedWorkExperience, id]);
  };
  const handlePeriodFromChange = (date, i) => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["from"] = date;
      list[i]["to"] = "";
      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    } else {
      const list = [...formUnverifiedData.workExperience];
      list[i]["from"] = date;
      list[i]["to"] = "";
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    }
    //Set Rejection Reason hide
    hideRejectionReason("workExperience");
  };
  const handlePeriodToChange = (date, i) => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["to"] = date;
      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    } else {
      const list2 = [...formUnverifiedData.workExperience];
      list2[i]["to"] = date;
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list2 });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list2 });
    }
    //Set Rejection Reason hide
    hideRejectionReason("workExperience");
  };
  const handleWorkExperienceCountry = (e, i) => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["country"] = e.target.value;
      //setFormData({ ...formData, workExperience: list })
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
      else setFormData({ ...formData, workExperience: list });
    } else {
      const list2 = [...formUnverifiedData.workExperience];
      list2[i]["country"] = e.target.value;
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list2 });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list2 });
    }
    //Set Rejection Reason hide
    hideRejectionReason("workExperience");
  };
  const handleWorkExperienceDuties = (value, i) => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["duties"] = value;
      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    } else {
      const list2 = [...formUnverifiedData.workExperience];
      list2[i]["duties"] = value;
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list2 });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list2 });
    }
    //Set Rejection Reason hide
    hideRejectionReason("workExperience");
  };

  const handleRefLetter = (e, i) => {
    const file = e.target.files[0];
    const fileType = file["type"];
    const validImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    const validOtherTypes = ["application/pdf"];

    if (
      !validImageTypes.includes(fileType) &&
      !validOtherTypes.includes(fileType)
    ) {
      // invalid file type code goes here.
      setOpen(true);
      setDialogMessage("Invalid file type");
      return 0;
    }
    if (file.size > 1048576) {
      setOpen(true);
      setDialogMessage("File size exceeds the limit (1 MB)");
      return 0;
    }

    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["refLetter"] = e.target.files[0];
      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    } else {
      const list2 = [...formUnverifiedData.workExperience];
      list2[i]["refLetter"] = e.target.files[0];
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list2 });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list2 });
    }
    e.target.value = ""; //reset file input
  };

  const removeRefLetter = (i) => {
    const list =
      verificationCount === 0
        ? [...formData.workExperience]
        : [...formUnverifiedData.workExperience];
    list[i]["refLetter"] = "";

    if (verificationCount === 0)
      setFormData({ ...formData, workExperience: list });
    else setFormUnverifiedData({ ...formUnverifiedData, workExperience: list });

    if (mode === "update")
      setFormEditData({ ...formEditData, workExperience: list });
  };

  //get candidate personal detaiils data
  const getCandidateData = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user !== null) {
      const { token } = user;
      let config = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      try {
        const response = await axios.get(
          `${getBaseUrl}/candidates/personalDetails/${token}`,
          config
        );
        console.log(response);

        if (response.data.personalDetail === undefined) {
          setVerificationCounter(null);
        } else {
          setVerificationCounter(
            response.data.personalDetail.verificationCounter
          );
        }
        //set candidate Verification Count
        if (verificationCount !== response.data.verificationCount) {
          setVerificationCount(response.data.verificationCount || 0);
          // userUpdate({ Verification_counter: response.data.verificationCount }) //Update User context Data
        }
        //set Pending Counter
        if (response.data.personalDetail === undefined) {
          setPendingCounter(true);
        }
        //set mode of form
        if (response.data.mode) {
          setMode(response.data.mode);
        }
        //check form modeb
        if (response.data.mode === "update") {
          let { photo, fullPhoto, video, videoUploadingStatus, name, gender, address, birthday, nationality, height, weight, maritalStatus, childAge, skills, languages, cooking, personality, currentCountry, currentlyWorking, religion, howSoonCanStart, yearOfExperience, singaporeExperience, expectedSalary, oFFDays, passport, educationCertificates, medicalReport, workExperience, typeOfCandidate, educationalDetailsCountry, HighestEducation,
          } = response.data.personalDetail;

          const oldname = name ? name.data : "";
          const newName = name ? name?.unverifydata === undefined ? "" : name?.unverifydata : "";
          if (newName !== "" && name?.unverifydata !== undefined)
            showNewInput("NewInputName");
          const oldGender = gender ? gender.data : "";
          const newGender = gender
            ? gender?.unverifydata === undefined
              ? ""
              : gender?.unverifydata
            : "";
          if (newGender !== "" && gender?.unverifydata !== undefined)
            showNewInput("NewGender");
          const oldAddress = address ? address.data : "";
          const newAddress = address
            ? address?.unverifydata === undefined
              ? ""
              : address?.unverifydata
            : "";
          if (newAddress !== "" && address?.unverifydata !== undefined)
            showNewInput("NewInputAddress");
          const oldheight = height ? height.data : "";
          const newHeight = height
            ? height?.unverifydata === undefined
              ? ""
              : height?.unverifydata
            : "";
          if (newHeight !== "" && height?.unverifydata !== undefined)
            showNewInput("NewHeight");
          const oldweight = weight ? weight.data : "";
          const newWeight = weight
            ? weight?.unverifydata === undefined
              ? ""
              : weight?.unverifydata
            : "";
          if (newWeight !== "" && weight?.unverifydata !== undefined)
            showNewInput("NewWeight");

          const work = workExperience.data.map((we) => {
            const from = we.from !== null ? new Date(we.from) : null;
            const to = we.to !== null ? new Date(we.to) : "";
            const duties = we.duties !== null ? we.duties : null;
            const present = we.present !== false ? we.present : false;
            // const refLetter = (we.refLetter) ? checkFileExist(we.refLetter) ? we.refLetter : '' : '';
            const refLetter = we.refLetter ? we.refLetter : "";
            const oldRefLetter = we.refLetter ? we.refLetter : "";
            const tempId = generateOTP(5);
            const id = we._id;
            return {
              country: we.country,
              duties: duties,
              from: from,
              to: to,
              refLetter: refLetter,
              oldRefLetter: oldRefLetter,
              present: present,
              tempId: tempId,
              id: id,
            };
          });
          const profilePreview =
            !photo || photo === null || photo === ""
              ? "/uploads/userIcon.png"
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${photo.data}`;
          const newProfilePreview =
            photo.unverifydata === ""
              ? ""
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${photo.unverifydata}`;
          const profilePhoto =
            !photo || photo === null || photo === "" ? "" : photo.data;
          const newProfilePhoto =
            !photo || photo === null || photo === "" ? "" : photo.unverifydata;
          const oldProfile =
            !photo || photo === null || photo === "" ? "" : photo.unverifydata;

          const fullPhotoPreview =
            !fullPhoto || fullPhoto === null || fullPhoto === ""
              ? "/uploads/userIcon.png"
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${fullPhoto.data}`;
          const newfullPhotoPreview =
            fullPhoto.unverifydata === ""
              ? ""
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${fullPhoto.unverifydata}`;
          const fullSizePhoto =
            !fullPhoto || fullPhoto === null || fullPhoto === ""
              ? ""
              : fullPhoto.data;
          const newfullSizePhoto =
            !fullPhoto || fullPhoto === null || fullPhoto === ""
              ? ""
              : fullPhoto.unverifydata;
          const oldfullPhoto =
            !fullPhoto || fullPhoto === null || fullPhoto === ""
              ? ""
              : fullPhoto.unverifydata;

          const oldyearOfExperience = !yearOfExperience
            ? { year: 0, month: 0 }
            : yearOfExperience.data;
          const oldsingaporeExperience = !singaporeExperience
            ? { year: 0, month: 0 }
            : singaporeExperience.data;

          const oldeducationalDetailsCountry =
            !educationalDetailsCountry ||
              educationalDetailsCountry === null ||
              educationalDetailsCountry === ""
              ? ""
              : educationalDetailsCountry.data;

          const oldHighestEducation =
            !HighestEducation ||
              HighestEducation === null ||
              HighestEducation === ""
              ? ""
              : HighestEducation.data;

          const docPassport = passport ? passport.data : "";
          const newdocPassport = passport
            ? passport?.unverifydata === undefined
              ? ""
              : passport.unverifydata
            : "";
          const docEducationCertificates = educationCertificates
            ? educationCertificates.data
            : "";
          const newdocEducationCertificates = educationCertificates
            ? educationCertificates?.unverifydata === undefined
              ? ""
              : educationCertificates.unverifydata
            : "";
          const docMedicalReport = medicalReport ? medicalReport.data : "";

          const newdocMedicalReport = medicalReport
            ? medicalReport?.unverifydata === undefined
              ? ""
              : medicalReport.unverifydata
            : "";
          //childAge = childAge.data
          const oldbirthday = birthday.data ? new Date(birthday.data) : null;
          const childAgeArray = childAge.data;
          const nationalityData = nationality.data;
          const maritalStatusData = maritalStatus.data;
          const skillsData = skills.data;
          const languagesData = languages.data;
          const cookingData = cooking.data ? cooking.data : null;
          const personalityData = personality.data;
          const expectedSalaryData = expectedSalary.data;
          const oFFDaysData = oFFDays.data;
          const currentCountryData = currentCountry.data;
          const currentlyWorkingData = currentlyWorking.data;
          const howSoonCanStartData = howSoonCanStart?.data;
          const religionData = religion?.data;
          const typeOfCandidateData = typeOfCandidate?.data;

          //New Data
          const newbirthday = birthday.unverifydata
            ? new Date(birthday.unverifydata)
            : "";
          if (newbirthday !== "" && birthday?.unverifydata !== undefined)
            showNewInput("NewBirthday");
          const newchildAgeArray = childAge.unverifydata; //code by mohsin
          if (newchildAgeArray !== "" && childAge?.unverifydata.length !== 0)
            showNewInput("childAge");
          const newnationalityData = nationality
            ? nationality.unverifydata === undefined
              ? ""
              : nationality.unverifydata
            : "";
          if (
            newnationalityData !== "" &&
            nationality?.unverifydata !== undefined
          )
            showNewInput("NewNationality");
          const newmaritalStatusData = maritalStatus
            ? maritalStatus.unverifydata === undefined
              ? ""
              : maritalStatus.unverifydata
            : "";
          if (
            newmaritalStatusData !== "" &&
            maritalStatus?.unverifydata !== undefined
          )
            showNewInput("Maritalstatus");
          const newskillsData = skills
            ? skills.unverifydata === undefined
              ? ""
              : skills.unverifydata
            : "";
          if (newskillsData !== "" && skills?.unverifydata.length !== 0)
            showNewInput("NewSkills");
          const newlanguagesData = languages
            ? languages.unverifydata === undefined
              ? ""
              : languages.unverifydata
            : "";
          if (newlanguagesData !== "" && languages?.unverifydata.length !== 0)
            showNewInput("NewLanguages");
          const newcookingData = cooking
            ? cooking.unverifydata === undefined
              ? ""
              : cooking.unverifydata
            : "";
          if (newcookingData !== "" && cooking?.unverifydata.length !== 0)
            showNewInput("NewCooking");
          const newpersonalityData = personality
            ? personality.unverifydata === undefined
              ? ""
              : personality.unverifydata
            : "";
          if (
            newpersonalityData !== "" &&
            personality?.unverifydata.length !== 0
          )
            showNewInput("NewPersonality");
          const newexpectedSalaryData = expectedSalary
            ? expectedSalary.unverifydata === undefined ||
              expectedSalary.unverifydata === null
              ? 0
              : expectedSalary.unverifydata
            : 0;
          if (
            newexpectedSalaryData !== 0 &&
            expectedSalary?.unverifydata !== undefined &&
            expectedSalary?.unverifydata !== null
          )
            showNewInput("NewExpectedSalary");
          const newoFFDaysData = oFFDays
            ? oFFDays.unverifydata === undefined
              ? ""
              : oFFDays.unverifydata
            : "";
          if (newoFFDaysData !== "" && oFFDays?.unverifydata !== undefined)
            showNewInput("NewOffDays");
          const newcurrentCountryData = currentCountry
            ? currentCountry?.unverifydata === undefined
              ? ""
              : currentCountry?.unverifydata
            : "";
          if (
            newcurrentCountryData !== "" &&
            currentCountry?.unverifydata !== undefined
          )
            showNewInput("NewCountry");
          const newcurrentlyWorkingData = currentlyWorking
            ? currentlyWorking.unverifydata === undefined
              ? ""
              : currentlyWorking.unverifydata
            : "";
          if (
            newcurrentlyWorkingData !== "" &&
            currentlyWorking?.unverifydata !== undefined
          )
            showNewInput("NewCurrentlyWorking");
          const newreligionData = religion
            ? religion.unverifydata === undefined
              ? ""
              : religion.unverifydata
            : "";
          if (newreligionData !== "" && religion?.unverifydata !== undefined)
            showNewInput("Newreligion");
          const newhowSoonCanStartData = howSoonCanStart
            ? howSoonCanStart.unverifydata === undefined
              ? ""
              : howSoonCanStart.unverifydata
            : "";
          if (
            newhowSoonCanStartData !== "" &&
            howSoonCanStart?.unverifydata !== undefined
          )
            showNewInput("NewCanStart");

          const newyearOfExperience = !yearOfExperience?.unverifydata
            ? { year: 0, month: 0 }
            : yearOfExperience.unverifydata;
          if (newyearOfExperience.year > 0 || newyearOfExperience.month > 0)
            showNewInput("YearOfExperience");
          const summaryVideo =
            !video || video === null || video === "" ? "" : video.data;
          const newsummaryVideo =
            !video || video === null || video === "" ? "" : video.unverifydata;
          const oldSummaryVideo =
            !video || video === null || video === "" ? "" : video.unverifydata;
          const summaryVideoPreview =
            !video || video === null || video === ""
              ? ""
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${video.data}`;
          const summaryNewVideoPreview =
            !video || video === null || video.unverifydata === ""
              ? ""
              : `${getBaseUrl}/candidates/getMediaFileFromDb/${video.unverifydata}`;

          const statusVideo =
            !videoUploadingStatus ||
              videoUploadingStatus === null ||
              videoUploadingStatus === ""
              ? ""
              : videoUploadingStatus.data;
          const newStatusVideo =
            !videoUploadingStatus ||
              videoUploadingStatus === null ||
              videoUploadingStatus === ""
              ? ""
              : videoUploadingStatus.unverifydata;

          const newsingaporeExperience = !singaporeExperience?.unverifydata
            ? { year: 0, month: 0 }
            : singaporeExperience.unverifydata;
          if (
            newsingaporeExperience.year > 0 ||
            newsingaporeExperience.month > 0
          )
            showNewInput("SingYearOfExperience");

          const newTypeOfCndData = typeOfCandidate
            ? typeOfCandidate.unverifydata === undefined
              ? ""
              : typeOfCandidate.unverifydata
            : "";
          if (
            newTypeOfCndData !== "" &&
            typeOfCandidate?.unverifydata !== undefined
          )
            showNewInput("NewTypeCnd");

          const newEducationalCountry = educationalDetailsCountry
            ? educationalDetailsCountry.unverifydata === undefined
              ? ""
              : educationalDetailsCountry.unverifydata
            : "";
          if (
            newEducationalCountry !== "" &&
            educationalDetailsCountry?.unverifydata !== undefined
          )
            showNewInput("NewEducationDetails");

          const newHighestEducation = HighestEducation
            ? HighestEducation.unverifydata === undefined
              ? ""
              : HighestEducation.unverifydata
            : "";
          if (
            newHighestEducation !== "" &&
            HighestEducation?.unverifydata !== undefined
          )
            showNewInput("NewEducationDetails");

          // if(newsingaporeExperience!=="")showNewInput('NewWeight');

          const workUnverified = workExperience.unverifydata.map((we) => {
            const from = we.from !== null ? new Date(we.from) : null;
            const to = we.to !== null ? new Date(we.to) : "";
            const duties = we.duties !== null ? we.duties : null;
            const present = we.present !== false ? we.present : false;
            const oldRefLetter = we.refLetter ? we.refLetter : "";
            const refLetter = we.refLetter ? we.refLetter : "";
            const id = we._id;
            return {
              country: we.country,
              duties: duties,
              from: from,
              to: to,
              refLetter: refLetter,
              oldRefLetter: oldRefLetter,
              present: present,
              id: id,
            };
          });

          if (workUnverified.length > 0) showNewInput("workExperience");
          const rejectedItems = {};

          const obj = response.data.personalDetail;
          const errors = {};
          const ErrorStatus = {};
          const PendingStatus = {};
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              if (
                typeof obj[key] === "object" &&
                obj[key].status === "reject"
              ) {
                errors[key] = true;
                ErrorStatus[key] = obj[key].rejectionReason;
                rejectedItems[key] = obj[key];
              } else if (typeof obj[key] === "object") {
              } else {
                delete errors[key];
                delete ErrorStatus[key];
              }
              setFormStatusError(errors);
              setShowStatusError(ErrorStatus);
              if (
                (typeof obj[key] === "object" && obj[key].status === "") ||
                obj[key].status === undefined
              ) {
                PendingStatus[key] = true;
              } else {
                delete PendingStatus[key];
              }
              setFormPendingStatus(PendingStatus);
            }
          }
          setFormData({
            ...formData,
            profilePreview,
            photo: profilePhoto,
            fullPhotoPreview,
            fullPhoto: fullSizePhoto,
            video: summaryVideo,
            summaryVideoPreview,
            videoUploadingStatus: statusVideo,
            name: oldname,
            gender: oldGender,
            address: oldAddress,
            birthday: oldbirthday,
            nationality: nationalityData,
            height: oldheight,
            weight: oldweight,
            maritalStatus: maritalStatusData,
            childAge: childAgeArray,
            skills: skillsData,
            languages: languagesData,
            cooking: cookingData,
            personality: personalityData,
            currentCountry: currentCountryData,
            currentlyWorking: currentlyWorkingData,
            religion: religionData,
            howSoonCanStart: howSoonCanStartData,
            yearOfExperience: oldyearOfExperience,
            singaporeExperience: oldsingaporeExperience,
            expectedSalary: expectedSalaryData,
            oFFDays: oFFDaysData,
            passport: docPassport,
            educationCertificates: docEducationCertificates,
            medicalReport: docMedicalReport,
            workExperience: work,
            typeOfCandidate: typeOfCandidateData,
            educationalDetailsCountry: oldeducationalDetailsCountry,
            HighestEducation: oldHighestEducation,
          });
          setFormUnverifiedData({
            ...formUnverifiedData,
            profilePreview: newProfilePreview,
            photo: newProfilePhoto,
            fullPhotoPreview: newfullPhotoPreview,
            oldProfile: oldProfile,
            fullPhoto: newfullSizePhoto,
            oldfullPhoto: oldfullPhoto,
            video: newsummaryVideo,
            oldSummaryVideo: oldSummaryVideo,
            summaryNewVideoPreview,
            videoUploadingStatus: newStatusVideo,
            name: newName,
            gender: newGender,
            address: newAddress,
            birthday: newbirthday,
            nationality: newnationalityData,
            height: newHeight,
            weight: newWeight,
            maritalStatus: newmaritalStatusData,
            childAge: newchildAgeArray,
            skills: newskillsData,
            languages: newlanguagesData,
            cooking: newcookingData,
            personality: newpersonalityData,
            currentCountry: newcurrentCountryData,
            currentlyWorking: newcurrentlyWorkingData,
            religion: newreligionData,
            howSoonCanStart: newhowSoonCanStartData,
            yearOfExperience: newyearOfExperience,
            singaporeExperience: newsingaporeExperience,
            expectedSalary: newexpectedSalaryData,
            oFFDays: newoFFDaysData,
            passport: newdocPassport,
            educationCertificates: newdocEducationCertificates,
            medicalReport: newdocMedicalReport,
            workExperience: workUnverified,
            typeOfCandidate: newTypeOfCndData,
            educationalDetailsCountry: newEducationalCountry,
            HighestEducation: newHighestEducation,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAlertModalRefresh = () => {
    setHiddenElements({});
    getCandidateData();
    setAlertModal(false);
  };

  const handleKeyPress = (event) => {
    // Check if the pressed key is Enter (key code 13)
    if (event.key === "Enter") {
      // Prevent the default behavior (form submission, etc.)
      event.preventDefault();
    }
  };

  // socket on handler functions
  const handlePersonalDetailsVerified = (data) => {
    if (user.candidateId === data.cndId) setAlertModal(true);
  };

  const handleVideoUploadingStatus = (data) => {
    if (user.candidateId === data.cndId) {
      getCandidateData();
    }
  };



  useEffect(() => {
    getCandidateData();
    // Attach the global key press event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);
    //socket.io stuff
    socket.on("videoUploadingStatusUpdate", handleVideoUploadingStatus);
    socket.on("personalDetailsVerified", handlePersonalDetailsVerified);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
      socket.off("videoUploadingStatusUpdate", handleVideoUploadingStatus);
      socket.off("personalDetailsVerified", handlePersonalDetailsVerified);
    };
  }, []);

  useEffect(() => {
    if (verificationCount === 0) {
      setReadOnlyValue(false);
      setShowEditCancel(false);
      setShowAddButton(true);
    } else {
      setReadOnlyValue(true);
      setShowEditCancel(true);
      setShowAddButton(false);
    }
  }, [verificationCount]);

  //update User data
  const userUpdate = (updatedData) => {
    const userInfo = {
      ...JSON.parse(localStorage.getItem("user")),
      ...updatedData,
    };
    /* update the auth context */
    dispatch({ type: "UPDATE-USER", payload: userInfo });
  };

  /* ------------------------------------------------------------------------------------ */

  const handleRecording = (videoBlob, videoUrl) => {
    if (verificationCount > 0) {
      setMyVideo({ ...myVideo, unverifydata: videoUrl });
    } else {
      setMyVideo({ ...myVideo, data: videoUrl });
    }

    setMyVideoBlob(videoBlob);
    setFormError({ ...formError, video: false });

    //Set Rejection Reason hide
    hideRejectionReason("video");
  };

  //validate form data
  const validation = (values) => {
    const error = {};
    const requiredFields = [
      "photo",
      "video",
      "fullPhoto",
      "typeOfCandidate",
      "name",
      "gender",
      "address",
      "birthday",
      "nationality",
      "height",
      "weight",
      "maritalStatus",
      "currentCountry",
      "skills",
      "languages",
      "yearOfExperience",
      "currentlyWorking",
      "oFFDays",
      "religion",
      "expectedSalary",
      "passport",
      "educationCertificates",
      "medicalReport",
      "educationalDetailsCountry",
      "HighestEducation",
    ];
    requiredFields.forEach(function (rField) {
      if (!values[rField]) {
        error[rField] = true;
        if (rField === "video") {
          myVideoBlob === "" && formData.video === ""
            ? (error[rField] = true)
            : delete error.video;
        }
      } else if (rField === "gender" && !formData.gender) {
        error.gender = true;
      } else if (rField === "maritalStatus") {
        for (var age of formData.childAge) {
          if (age === "") {
            error.childAge = true;
            break;
          }
        }
      } else if (["passport", "educationCertificates", "medicalReport", "skills", "languages"].includes(`${rField}`)) {
        const flag = Array.isArray(values[rField])
          ? values[rField].length === 0
          : values[rField] === null;
        if (flag) error[rField] = true;
      } else if (rField === "birthday") {
        const today = new Date();
        const selectedDate = formData.birthday;
        const difference = selectedDate
          ? today.getFullYear() - new Date(selectedDate).getFullYear()
          : null;
        if (difference <= 18 || difference >= 50) {
          error.Minbirthday = true;
        }
      } else if (rField === "yearOfExperience") {
        const { year, month } = formData.yearOfExperience;
        if (year === "" && month === "") error.yearOfExperience = true;
        if ((year === 0 && month === 0) || (year === "0" && month === "0")) {
          if (
            formData.singaporeExperience.year !== 0 ||
            formData.singaporeExperience.month !== 0
          ) {
            const year =
              formData.yearOfExperience.year === ""
                ? "0"
                : formData.yearOfExperience.year;
            const month =
              formData.yearOfExperience.month === ""
                ? "0"
                : formData.yearOfExperience.month;
            const singYear =
              formData.singaporeExperience.year === ""
                ? "0"
                : formData.singaporeExperience.year;
            const singMonth =
              formData.singaporeExperience.month === ""
                ? "0"
                : formData.singaporeExperience.month;
            const totalExpInMonth = parseInt(year * 12) + parseInt(month);
            const totalSignExpInMonth =
              parseInt(singYear * 12) + parseInt(singMonth);
            if (totalExpInMonth < totalSignExpInMonth) {
              error.yearOfExperienceErrorMsg = true;
              error.yearOfExperience = true;
            }
          }
        }

        if (formData.workExperience.length > 0) {
          if (year === 0 && month === 0) {
            error.workExpErrorDelete = true;
          }
        }
        if (year > 0 || month > 0) {
          setSelectedIndices([]);
          let presentCount = 0;
          let errorFlag = false;
          formData.workExperience.forEach(function (workExp, index) {
            const dutiesFlag = Array.isArray(workExp.duties)
              ? workExp.duties.length === 0
              : workExp.duties === null;
            if (workExp.Form === null || workExp.country === "" || dutiesFlag) {
              setSelectedIndices((oldArray) => [...oldArray, index]);
              //workExpError.push("Work experience details are required.");
              errorFlag = true;
            }
            if (
              (workExp.to === null || workExp.to === "") &&
              workExp.present === false
            ) {
              setSelectedIndices((oldArray) => [...oldArray, index]);
              //workExpError.push("Work experience details are required.");
              errorFlag = true;
            }

            if (errorFlag) {
              error.workExpErrorRequired = true;
            }

            if (workExp.present === true) {
              presentCount++;
            }
          });

          if (presentCount > 1) {
            error.workExpErrorPresent = true;
          }

          // let { year, month } = formData.yearOfExperience;
          const year =
            formData.yearOfExperience.year === ""
              ? "0"
              : formData.yearOfExperience.year;
          const month =
            formData.yearOfExperience.month === ""
              ? "0"
              : formData.yearOfExperience.month;
          const singYear =
            formData.singaporeExperience.year === ""
              ? "0"
              : formData.singaporeExperience.year;
          const singMonth =
            formData.singaporeExperience.month === ""
              ? "0"
              : formData.singaporeExperience.month;
          const totalExpInMonth = parseInt(year * 12) + parseInt(month);
          const totalSignExpInMonth =
            parseInt(singYear * 12) + parseInt(singMonth);

          if (totalExpInMonth < totalSignExpInMonth) {
            error.yearOfExperienceErrorMsg = true;
            error.yearOfExperience = true;
          }
        }
      }
    });
    if (Object.keys(error).length !== 0) {
      error.msg = "(*) marked fields are Required";
    }
    setFormError(error);

    return Object.keys(error).length === 0;
  };

  function scrollToTop() {
    scroll.scrollToTop({
      duration: 500, // Scroll duration in milliseconds
      delay: 100, // Delay before the scroll begins
      smooth: "easeInOutQuart", // Custom easing
    });
  }

  /* submit personal details  */
  const handleSubmit = (e) => {
    e.preventDefault();

    //check user verification count
    if (verificationCount === 0) { //----------------save Verified data  -----------------------//
      //check form data is valid
      if (validation(formData)) {
        let data = new FormData();
        let frmData;
        //mode vise append data
        if (mode === "update") {
          frmData = formEditData;

          data.append("EditData", Object.keys(formEditData));
          data.append("mode", mode);
        } else {
          frmData = formData;
        }
        //check VideoBlob hava data and append data
        if (myVideoBlob) {
          data.append("videoBlob", myVideoBlob, "recorded_video.webm");
        }
        //append remaining formData
        for (const [key, value] of Object.entries(frmData)) {
          if (key === "yearOfExperience") {
            const { year, month } = frmData.yearOfExperience;
            data.append("yearOfExperience_year", year);
            data.append("yearOfExperience_month", month);
          } else if (key === "singaporeExperience") {
            const { year, month } = frmData.singaporeExperience;
            data.append("singaporeExperience_year", year);
            data.append("singaporeExperience_month", month);
          } else if (key === "workExperience") {
            frmData.workExperience.forEach((object, index) => {
              for (let key in object) {
                data.append(`workExperience_${key}_${index}`, object[key]);
              }
            });
          } else if (key === "passport" || key === "educationCertificates" || key === "medicalReport") {
            value.forEach((file, index) => {
              data.append(`${key}_${index}`, file);
            });
          } else {
            data.append(key, value);
          }
        }
        //append deleted work experience index
        data.append(`deletedWorkExperience`, deletedWorkExperience);
        //pass data to save function
        savePersonalDetails(data);
        userUpdate({ name: formData.name });
      } else {
        // window.scrollTo(0, 0) //page scroll to top
        if(Object.keys(formError).length === 2 && Object.keys(formError).includes("workExpErrorRequired")){

        }else{
          scrollToTop();
        }
      }
    } else if (verificationCount > 0) { //----------------save Unverified data  -----------------------//

      //Unverifield field validation
      const blankValue = {};
      if (formUnverifiedData.workExperience.length > 0) {
        if (
          formUnverifiedData.yearOfExperience.year === 0 &&
          formUnverifiedData.yearOfExperience.month
        ) {
          blankValue.workExpErrorDelete = true;
        }

        setUnverifySelectedIndices([]);

        let presentCount = 0;
        formUnverifiedData.workExperience.forEach(function (workExp, index) {
          console.log(formUnverifiedData.workExperience);
          const dutiesFlag = Array.isArray(workExp.duties)
            ? workExp.duties.length === 0
            : workExp.duties === null;
          if (workExp.Form === null || workExp.country === "" || dutiesFlag) {
            setUnverifySelectedIndices((oldArray) => [...oldArray, index]);
            blankValue.workExpErrorRequired = true;
          }
          if (
            (workExp.to === null || workExp.to === "") &&
            workExp.present === false
          ) {
            setUnverifySelectedIndices((oldArray) => [...oldArray, index]);
            blankValue.workExpErrorRequired = true;
          }

          if (workExp.present === true) {
            presentCount++;
          }
        });
        if (presentCount > 1) {
          blankValue.workExpErrorPresent = true;
        }
      }
      for (const [key, value] of Object.entries(formEditData)) {
        if (key !== "cooking" && key !== "personality") {
          if (value === "") {
            blankValue[key] = true;
          }
        }
        if (
          formUnverifiedData.educationalDetailsCountry !== "" &&
          formUnverifiedData.HighestEducation !== ""
        ) {
          delete blankValue.NewEducationDetails;
        }
      }
      setFormEditDataError(blankValue);
      if (Object.keys(blankValue).length !== 0) {
        let msg = "(*) marked fields are Required";
        if (Object.keys(blankValue).includes("workExperience")) {
          msg += " check work experience details.";
        }
        setFormError({ ...formError, msg: msg });
        if(Object.keys(blankValue).length === 1 && Object.keys(blankValue).includes("workExpErrorRequired")){

        }else{
          window.scrollTo(0, 0);
        }
      } else {
        let data = new FormData();
        // Create a new object with undefined values replaced by ''
        //append data
        if (myVideoBlob) {
          data.append("videoBlob", myVideoBlob, "recorded_video.webm");
        }
        for (const [key, value] of Object.entries(formUnverifiedData)) {
          if (key === "yearOfExperience") {
            const { year, month } = formUnverifiedData.yearOfExperience;
            data.append("yearOfExperience_year", year);
            data.append("yearOfExperience_month", month);
          } else if (key === "singaporeExperience") {
            const { year, month } = formUnverifiedData.singaporeExperience;
            data.append("singaporeExperience_year", year);
            data.append("singaporeExperience_month", month);
          } else if (key === "passport" || key === "educationCertificates" || key === "medicalReport") {
            value.forEach((file, index) => {
              data.append(`${key}_${index}`, file);
            });
          } else if (key !== "workExperience") {
            data.append(key, value);
          }
        }
        if (formUnverifiedData.workExperience.length > 0) {
          formUnverifiedData.workExperience.forEach((object, index) => {
            for (let key in object) {
              data.append(`workExperience_${key}_${index}`, object[key]);
            }
          });
        } else {
          data.append(`workExperience`, []);
        }
        //append deleted work experience index
        data.append(`deletedWorkExperience`, deletedWorkExperience);
        data.append(
          `deletedUnverifiedWorkExperience`,
          deletedUnverifiedWorkExperience
        );
        //pass data to save func
        savePersonalUnverifyDetails(data);
      }
        window.scrollTo(0, 0);
    } else {
      
      
    }
  };

  const deleteCandidate = () => {
    //Update User Data
    userUpdate({ photo: "" });
    deletePersonalDetails();
  };

  // Custom function to determine if the clear button should be shown
  const showNewInput = (elementId, event = null) => {
    if (event) {
      const DataId = event.target.getAttribute("data-field");
      if (DataId === "workExperience") {
        setShowAddButton(true);
        if (formUnverifiedData.workExperience.length === 0) {
          setFormUnverifiedData({
            ...formUnverifiedData,
            workExperience: JSON.parse(JSON.stringify(formData.workExperience)),
          });
        }
        if (formData.workExperience.length > 0) {
          setFormEditData({
            ...formEditData,
            workExperience: JSON.parse(JSON.stringify(formData.workExperience)),
          });
        }
      } else {
        setFormEditData({ ...formEditData, [DataId]: "" });
      }
    }
    setHiddenElements((prevHiddenElements) => ({
      ...prevHiddenElements,
      [elementId]: true,
    }));
  };
  const hideNewInput = (elementId, event = null) => {
    console.log(elementId);

    if (event) {
      const DataId = event.target.getAttribute("data-field");
      const newState = { ...formEditData };
      delete newState[DataId];

      setFormEditData(newState);
      setFormPendingStatus({ ...formPendingStatus, [DataId]: false });
      setFormStatusError({ ...formStatusError, [DataId]: false });

      if (DataId === "workExperience") {
        setShowAddButton(false);
        setFormUnverifiedData({ ...formUnverifiedData, [DataId]: [] });
      } else if (
        DataId === "childAge" ||
        DataId === "skills" ||
        DataId === "languages" ||
        DataId === "cooking" ||
        DataId === "personality"
      ) {
        setFormUnverifiedData({ ...formUnverifiedData, [DataId]: [] });
      } else if (DataId === "yearOfExperience") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          [DataId]: { year: 0, month: 0 },
        });
      } else if (DataId === "singaporeExperience") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          [DataId]: { year: 0, month: 0 },
        });
      } else if (DataId === "photo") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          [DataId]: "",
          profilePreview: "",
        });
      } else if (DataId === "fullPhoto") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          [DataId]: "",
          fullPhotoPreview: "",
        });
      } else if (DataId === "NewEducationDetails") {
        setFormUnverifiedData({
          ...formUnverifiedData,
          educationalDetailsCountry: "",
          HighestEducation: "",
        });
      } else {
        //code by mohsin
        setFormUnverifiedData({ ...formUnverifiedData, [DataId]: "" });
      }
    }
    setHiddenElements((prevHiddenElements) => ({
      ...prevHiddenElements,
      [elementId]: false,
    }));
  };

  /* work-expriance Handle present*/
  const handleCheckboxChange = (e, i) => {
    if (verificationCount === 0) {
      const list = [...formData.workExperience];
      list[i]["present"] = e.target.checked;
      if (e.target.checked === true) {
        list[i]["to"] = "";
      }

      setFormData({ ...formData, workExperience: list });
      if (mode === "update")
        setFormEditData({ ...formEditData, workExperience: list });
    } else {
      const list2 = [...formUnverifiedData.workExperience];
      list2[i]["present"] = e.target.checked;
      if (e.target.checked === true) {
        list2[i]["to"] = "";
      }
      setFormUnverifiedData({ ...formUnverifiedData, workExperience: list2 });
    }
  };
  //Remove new image, video
  const handleRemoveNewMedia = (mediaType) => {
    switch (mediaType) {
      case "photo":
        setFormUnverifiedData({
          ...formUnverifiedData,
          photo: "",
          profilePreview: "",
        });
        break;
      case "video":
        setFormUnverifiedData({
          ...formUnverifiedData,
          video: "",
          summaryNewVideoPreview: "",
        });
        setMyVideo({ unverifydata: "" });
        break;
      case "fullPhoto":
        setFormUnverifiedData({
          ...formUnverifiedData,
          fullPhoto: "",
          fullPhotoPreview: "",
        });
        break;
      default:
        break;
    }
  };

  const onDeleteWorkExp = (id, tempId) => {
    const newArray = [...formData.workExperience].filter(
      (data) => data.tempId !== tempId
    );
    setFormData({ ...formData, workExperience: newArray });
    setDeletedWorkExperience([...deletedWorkExperience, id]);
  };

  const handleClose = useCallback(() => setOpen(false), [open])

  //handle imageOnError
  const imageOnError = (event) => {
    event.target.src = "/uploads/userIcon.png";
  };
  // Handle focus event
  const handleFocus = (divId, e) => {
    setIsFocused(divId);
  };
  // Handle blur event
  const handleBlur = () => {
    setIsFocused(null);
  };

  // ---------------- react-datetime utils -----------------------------
  const renderInput = (props, openCalendar) => {
    return (
      <div className="d-flex align-items-center">
        <input
          {...props}
          onClick={openCalendar}
          className="flex-grow-1 border-0 p-0 h-100"
        />
        <FaCalendar onClick={openCalendar} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  // ----------------react-datetime End -----------------------------
  const [type, setType] = useState("");
  const [typeSelectedValue, setTypeSelectedValue] = useState("New");

  // Define the tooltip messages for each option
  const tooltips = {
    New: "You have never worked in Singapore and will be coming to Singapore 1st time for work.",
    Transfer:
      "You are currently working in Singapore and have valid Work Permit.",
    "Ex-Singapore":
      "You are not currently in Singapore but had worked in Singapore before.",
  };

  //-- Handle Educational Cerificate---

  const [openEducationCert, setOpenEducationCert] = useState(false);
  const handleEducationCertOpen = () => {
    setOpenEducationCert(true);
  };




  const handleEducationCertClose = useCallback(() => setOpenEducationCert(false), [openEducationCert])

  const tooltip = (
    <Tooltip id="tooltip" style={{ position: "fixed" }}>
      Pending Verification
    </Tooltip>
  );

  return (
    <>
      <div className={`candidate_profile personalDetails`}>
        <main className="bg-white py-3">
          <form onSubmit={handleSubmit}>
            <Container>
              {(verificationCounter === null ||
                verificationCounter === undefined) && (
                  <p className={`text-danger`}>
                    Please complete Personal Details section.
                  </p>
                )}
              {verificationCounter === 0 && (
                <p className={`text-danger`}>
                  Personal details are pending for approval. Please contact
                  Admin at{" "}
                  <a
                    href={`mailto:support@helperwish.com`}
                    className="text-danger"
                  >
                    support@helperwish.com
                  </a>{" "}
                  for any questions
                </p>
              )}
              <div className="photoInfoContainer">
                <div className="item">
                <Col className="mt-5 mt-md-0">
                  <Row>
                    <Col xs={12} className="mb-4">
                      <div className="d-flex align-items-center gap-10 helperwish">
                        <input
                          className={`form-check-input requiredMark ${formError.gender && "invalid"
                            }`}
                          type="checkbox"
                          id="gender"
                          name="gender"
                          checked={formData.gender}
                          onChange={handleGender}
                        />
                        <label className="form-check-label" htmlFor="gender">
                          Confirm you are Female
                        </label>
                      </div>
                    </Col>
                    <Col xs={12} sm={8} className="cmb-40">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div
                            className={`floating-control m-0 requiredMark ${formError.typeOfCandidate && "invalid"
                              } ${readOnlyValue ? "read-only-input" : ""}`}
                            id="candidateType"
                            onFocus={
                              !readOnlyValue
                                ? (e) => handleFocus("candidateType", e)
                                : null
                            }
                            onBlur={!readOnlyValue ? handleBlur : null}
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "candidateType"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                          >
                            <div
                              className={`d-flex  ${readOnlyValue && "pe-none"
                                }`}
                            >
                              <div className="input-container flex-grow-1">
                                <select
                                  className={`floating-input`}
                                  name="typeOfCandidate"
                                  id="typeOfCandidate"
                                  onChange={handleFormData}
                                  value={formData.typeOfCandidate}
                                >
                                  <option value="">Select</option>
                                  {typeOfCandidate.map((option, index) => {
                                    return (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label
                                  htmlFor="Type"
                                  className="floating-label"
                                >
                                  Type
                                </label>
                                <IoIosArrowDown className="arrowDown" />
                              </div>

                              <div className="align-self-center p-1 d-flex">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${!readOnlyValue &&
                                    formStatusError?.typeOfCandidate
                                    ? ""
                                    : "d-none"
                                    }`}
                                  loading="lazy"
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${!readOnlyValue &&
                                      formPendingStatus.typeOfCandidate
                                      ? ""
                                      : "d-none"
                                      }`}
                                    loading="lazy"
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`floating-control m-0 requiredMark mt-3 ${formEditDataError.typeOfCandidate && "invalid"
                              } ${hiddenElements.NewTypeCnd ? "" : "d-none"}`}
                            id="NewTypeCnd"
                            onFocus={() => handleFocus("NewTypeCnd")}
                            onBlur={handleBlur}
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "NewTypeCnd"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                          >
                            <div className={`d-flex `} id="NewTypeCndDiv">
                              <div className="input-container flex-grow-1">
                                <select
                                  className={`floating-input `}
                                  name="typeOfCandidate"
                                  id="typeOfCandidate"
                                  onChange={handleUnverifieldFormData}
                                  value={formUnverifiedData.typeOfCandidate}
                                >
                                  <option value="">Select</option>
                                  {typeOfCandidate.map((option, index) => {
                                    return (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    );
                                  })}
                                </select>

                                <label
                                  htmlFor="TypeOfCnd"
                                  className="floating-label"
                                >
                                  New Type
                                </label>
                                <IoIosArrowDown className="arrowDown" />
                              </div>
                              <div className="align-self-center p-1 d-flex">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${formStatusError?.typeOfCandidate
                                    ? ""
                                    : "d-none"
                                    }`}
                                  loading="lazy"
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${formPendingStatus.typeOfCandidate
                                      ? ""
                                      : "d-none"
                                      }`}
                                    loading="lazy"
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          {!readOnlyValue ? (
                            <p className={`dropdown-text   mx-1 my-1`}>
                              {tooltips[formData.typeOfCandidate] || ""}
                            </p>
                          ) : (
                            <p className={`dropdown-text  mx-1 my-1`}>
                              {tooltips[formUnverifiedData.typeOfCandidate] ||
                                ""}
                            </p>
                          )}
                          {formStatusError.typeOfCandidate && (
                            <span className="text-danger text-center">
                              {" "}
                              {showStatusError.typeOfCandidate}
                            </span>
                          )}
                        </div>
                        <div
                          className={`ps-1 pt-3 ${showEditCancel ? "" : "d-none"
                            }`}
                        >
                          <div className="position-relative d-flex">
                            {hiddenElements.NewTypeCnd ? (
                              <img
                                src="/images/DISCARD.png"
                                alt="discard"
                                data-field="typeOfCandidate"
                                className="imageWidth"
                                onClick={(e) => hideNewInput("NewTypeCnd", e)}
                              />
                            ) : (
                              <img
                                src="/images/EDIT.png"
                                alt="edit"
                                data-field="typeOfCandidate"
                                className="imageWidth"
                                onClick={(e) => showNewInput("NewTypeCnd", e)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} sm={8} className="cmb-40">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div
                            className={`floating-control m-0 requiredMark flex-grow-1 ${formError.name && "invalid"
                              } ${readOnlyValue ? "read-only-input" : ""}`}
                            onFocus={
                              !readOnlyValue
                                ? (e) => handleFocus("nameDiv", e)
                                : null
                            }
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "nameDiv"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                            id="nameDiv"
                          >
                            <div className={`input-container d-flex `}>
                              <div className="flex-grow-1">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className={`floating-input`}
                                  placeholder="none"
                                  autoComplete="off"
                                  value={formData.name || ""}
                                  onChange={handleFormData}
                                  readOnly={readOnlyValue}
                                  maxLength={50}
                                />
                                <label
                                  htmlFor="name"
                                  className="floating-label"
                                >
                                  Name
                                </label>
                              </div>
                              <div className="align-self-center p-1">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${!readOnlyValue && formStatusError.name
                                    ? ""
                                    : "d-none"
                                    }`}
                                  loading="lazy"
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${!readOnlyValue && formPendingStatus.name
                                      ? ""
                                      : "d-none"
                                      }`}
                                    loading="lazy"
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`floating-control m-0 requiredMark mt-3   ${formEditDataError.name && "invalid"
                              } ${hiddenElements.NewInputName ? "" : "d-none"}`}
                            id="NewInputName"
                            onFocus={() => handleFocus("NewInputName")}
                            onBlur={handleBlur}
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "NewInputName"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                          >
                            <div className={`input-container`}>
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className={`floating-input `}
                                placeholder="none"
                                autoComplete="off"
                                value={formUnverifiedData.name || ""}
                                onChange={handleUnverifieldFormData}
                                maxLength={50}
                              />
                              <label htmlFor="name" className="floating-label">
                                New Name
                              </label>
                              <div className="right-float">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${formStatusError.name ? "" : "d-none"
                                    }`}
                                  loading="lazy"
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${formPendingStatus.name ? "" : "d-none"
                                      }`}
                                    loading="lazy"
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          {formStatusError.name && (
                            <span className="text-danger text-center">
                              {" "}
                              {showStatusError.name}
                            </span>
                          )}
                          {maxLengthError.name && (
                            <span className="text-danger text-center">
                              {" "}
                              {maxLengthError.name}
                            </span>
                          )}
                        </div>
                        <div
                          className={`ps-1 pt-3 ${showEditCancel ? "" : "d-none"
                            }`}
                        >
                          <div className="position-relative d-flex">
                            {hiddenElements.NewInputName ? (
                              <img
                                src="/images/DISCARD.png"
                                alt="discard"
                                data-field="name"
                                className="imageWidth"
                                onClick={(e) => hideNewInput("NewInputName", e)}
                              />
                            ) : (
                              <img
                                src="/images/EDIT.png"
                                alt="edit"
                                data-field="name"
                                className="imageWidth"
                                onClick={(e) => showNewInput("NewInputName", e)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xs={12} sm={8} className="cmb-40">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div
                            className={`floating-control m-0 requiredMark ${formError.address && "invalid"
                              } ${readOnlyValue ? "read-only-input" : ""}`}
                            id="addressDiv"
                            onFocus={
                              !readOnlyValue
                                ? (e) => handleFocus("addressDiv", e)
                                : null
                            }
                            onBlur={!readOnlyValue ? handleBlur : null}
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "addressDiv"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                          >
                            <div className={`input-container d-flex `}>
                              <div className="flex-grow-1">
                                <input
                                  className={`floating-input`}
                                  type="text"
                                  name="address"
                                  id="address"
                                  placeholder="none"
                                  autoComplete="off"
                                  value={formData.address || ""}
                                  onChange={handleFormData}
                                  readOnly={readOnlyValue}
                                  maxLength={70}
                                />
                                <label
                                  htmlFor="address"
                                  className="floating-label"
                                >
                                  Address
                                </label>
                              </div>
                              <div className="align-self-center p-1">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${!readOnlyValue && formStatusError.address
                                    ? ""
                                    : "d-none"
                                    }`}
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${!readOnlyValue &&
                                      formPendingStatus.address
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`floating-control m-0 requiredMark mt-3 ${formEditDataError.address && "invalid"
                              } ${hiddenElements.NewInputAddress ? "" : "d-none"
                              }`}
                            id="NewInputAddress"
                            onFocus={() => handleFocus("NewInputAddress")}
                            onBlur={handleBlur}
                            tabIndex={0} // Make the div focusable by adding a tabIndex
                            style={{
                              border:
                                isFocused === "NewInputAddress"
                                  ? "2px solid var(--color-pri-blue)"
                                  : "", // Example border change when focused
                            }}
                          >
                            <div className={`input-container`}>
                              <input
                                className={`floating-input`}
                                type="text"
                                name="address"
                                id="address"
                                placeholder="none"
                                autoComplete="off"
                                value={formUnverifiedData.address || ""}
                                onChange={handleUnverifieldFormData}
                                maxLength={70}
                              />
                              <label
                                htmlFor="address"
                                className="floating-label"
                              >
                                New Address
                              </label>
                              <div className="right-float">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${formStatusError.address ? "" : "d-none"
                                    }`}
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${formPendingStatus.address ? "" : "d-none"
                                      }`}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                          {formStatusError.address && (
                            <span className="text-danger text-center">
                              {" "}
                              {showStatusError.address}
                            </span>
                          )}
                          {maxLengthError.address && (
                            <span className="text-danger text-center">
                              {" "}
                              {maxLengthError.address}
                            </span>
                          )}
                        </div>
                        <div
                          className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                            }`}
                        >
                          <div className="position-relative d-flex pt-2">
                            {hiddenElements.NewInputAddress ? (
                              <img
                                src="/images/DISCARD.png"
                                alt="discard"
                                data-field="address"
                                className="imageWidth"
                                onClick={(e) =>
                                  hideNewInput("NewInputAddress", e)
                                }
                              />
                            ) : (
                              <img
                                src="/images/EDIT.png"
                                alt="edit"
                                data-field="address"
                                className="imageWidth"
                                onClick={(e) =>
                                  showNewInput("NewInputAddress", e)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={11} className="cmb-40">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div className={`Educationaldetails`}>
                            <div
                              className={`floating-control m-0 requiredMark ${(formError.educationalDetailsCountry ||
                                formError.HighestEducation) &&
                                "invalid"
                                } ${readOnlyValue ? "read-only-input" : ""}`}
                              id="educationalDetails"
                            >
                              <div className="d-flex">
                                <div className="input-container flex-grow-1 p-2">
                                  <p
                                    className={`mt-2 mb-1 ml-1 ${readOnlyValue
                                      ? "text-muted"
                                      : "text-danger"
                                      }`}
                                  >
                                    * Must have a minimum of 8 years of formal
                                    education Country Highest Education
                                    <br />{" "}
                                    <span
                                      className={`${readOnlyValue
                                        ? "text-muted"
                                        : "text-danger"
                                        }`}
                                      onClick={handleEducationCertOpen}
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click for the List of Valid Education
                                      Certificate
                                    </span>
                                  </p>
                                  <div
                                    className={`floating-input d-flex   flex-md-row flex-column pb-1 gap-1 ${readOnlyValue ? "read-only-input" : ""
                                      }`}
                                  >
                                    <div className="floating-control mt-1 countryDiv">
                                      <select
                                        className={`floating-input `}
                                        name="country"
                                        id="country"
                                        onChange={handleEducationalDeatails}
                                        value={
                                          formData.educationalDetailsCountry
                                        }
                                      >
                                        <option value="">Select</option>
                                        {educationCountry.map(
                                          (option, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>

                                      <label
                                        htmlFor="Country"
                                        className="floating-label"
                                      >
                                        Country
                                      </label>
                                      <IoIosArrowDown className="arrowDown" />
                                    </div>
                                    <div className="floating-control mt-1">
                                      <select
                                        className={`floating-input `}
                                        name="HighestEducation"
                                        id="HighestEducation"
                                        onChange={handleEducationalDeatails}
                                        value={formData.HighestEducation}
                                      >
                                        <option value="">Select</option>
                                        {highestEducation.map(
                                          (option, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      <label
                                        htmlFor="HighestEducation"
                                        className="floating-label"
                                      >
                                        Highest Education
                                      </label>
                                      <IoIosArrowDown className="arrowDown" />
                                    </div>
                                    <div className="align-self-center p-1 d-flex">
                                      <img
                                        src="/images/REASON.png"
                                        alt="reason"
                                        className={`imageWidth ${!readOnlyValue &&
                                          (formStatusError?.educationalDetailsCountry ||
                                            formStatusError?.HighestEducation)
                                          ? ""
                                          : "d-none"
                                          }`}
                                        loading="lazy"
                                      />
                                      <OverlayTrigger
                                        overlay={tooltip}
                                        placement="top"
                                      >
                                        <img
                                          src="/images/VeriPending.jpeg"
                                          alt="VeriPending"
                                          className={`imageWidth ${!readOnlyValue &&
                                            formPendingStatus.educationalDetailsCountry &&
                                            formPendingStatus?.HighestEducation
                                            ? ""
                                            : "d-none"
                                            }`}
                                          loading="lazy"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <label className="floating-label">
                                    Education Details
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`floating-control m-0 requiredMark mt-4 ${formEditDataError.NewEducationDetails &&
                                "invalid"
                                } ${hiddenElements.NewEducationDetails
                                  ? ""
                                  : "d-none"
                                }`}
                              id="NewEducationDetails"
                              onFocus={() => handleFocus("NewEducationDetails")}
                              onBlur={handleBlur}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "NewEducationDetails"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div className="d-flex">
                                <div className="input-container flex-grow-1 p-2">
                                  <p className={`mt-2 mb-1 ml-1 text-danger`}>
                                    * Must have a minimum of 8 years of formal
                                    education Country Highest Education
                                    <br />
                                    <span
                                      variant="text"
                                      className={`mb-1 text-danger`}
                                      onClick={handleEducationCertOpen}
                                      style={{
                                        textDecoration: "underline",
                                        marginTop: "-15px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Click for the List of Valid Education
                                      Certificate
                                    </span>
                                  </p>

                                  <div
                                    className={`floating-input d-flex pb-1 gap-1 flex-md-row flex-column`}
                                  >
                                    <div className="floating-control mt-1 countryDiv">
                                      <select
                                        className={`floating-input `}
                                        name="country"
                                        id="country"
                                        onChange={handleEducationalDeatails}
                                        value={
                                          formUnverifiedData.educationalDetailsCountry
                                        }
                                      >
                                        <option value="">Select</option>
                                        {educationCountry.map(
                                          (option, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      <label
                                        htmlFor="Country"
                                        className="floating-label"
                                      >
                                        Country
                                      </label>
                                      <IoIosArrowDown className="arrowDown" />
                                    </div>
                                    <div className="floating-control mt-1">
                                      <select
                                        className={`floating-input `}
                                        name="HighestEducation"
                                        id="HighestEducation"
                                        onChange={handleEducationalDeatails}
                                        value={
                                          formUnverifiedData.HighestEducation
                                        }
                                      >
                                        <option value="">Select</option>
                                        {highestEducation.map(
                                          (option, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                      <label
                                        htmlFor="Highest Education"
                                        className="floating-label"
                                      >
                                        Highest Education
                                      </label>
                                      <IoIosArrowDown className="arrowDown" />
                                    </div>

                                    <div className="align-self-center p-1 d-flex">
                                      <img
                                        src="/images/REASON.png"
                                        alt="reason"
                                        className={`imageWidth ${formStatusError?.educationalDetailsCountry ||
                                          formStatusError?.HighestEducation
                                          ? ""
                                          : "d-none"
                                          }`}
                                        loading="lazy"
                                      />
                                      <OverlayTrigger
                                        overlay={tooltip}
                                        placement="top"
                                      >
                                        <img src="/images/VeriPending.jpeg"
                                          alt="VeriPending"
                                          className={`imageWidth ${formPendingStatus.educationalDetailsCountry ||
                                            formPendingStatus?.HighestEducation
                                            ? ""
                                            : "d-none"
                                            }`}
                                          loading="lazy"
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                  <label className="floating-label">
                                    New Education Details
                                  </label>
                                </div>
                              </div >
                            </div >
                          </div >
                        </div >
                        <div
                          className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                            }`}
                        >
                          <div className="position-relative d-flex pt-2">
                            {hiddenElements.NewEducationDetails ? (
                              <img
                                src="/images/DISCARD.png"
                                alt="discard"
                                data-field="NewEducationDetails"
                                className="imageWidth"
                                onClick={(e) =>
                                  hideNewInput("NewEducationDetails", e)
                                }
                              />
                            ) : (
                              <img
                                src="/images/EDIT.png"
                                alt="edit"
                                data-field="NewEducationDetails"
                                className="imageWidth"
                                onClick={(e) =>
                                  showNewInput("NewEducationDetails", e)
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div >
                      {
                        formStatusError.educationalDetailsCountry && (
                          <span className="text-danger text-center">
                            {" "}
                            Country - {showStatusError.educationalDetailsCountry}
                            <br />
                          </span>
                        )
                      }
                      {
                        formStatusError.HighestEducation && (
                          <span className="text-danger text-center">
                            Education - {showStatusError.HighestEducation}
                          </span>
                        )
                      }
                    </Col >

                    <Row>
                      <Col xs="auto" sm={6} md={6} className="mb-4">
                        <div className="d-flex birthdayWrapper">
                          <div className="flex-grow-1">
                            <div
                              className={`floating-control m-0 requiredMark ${formError.birthday && "invalid"
                                } ${formError.Minbirthday && "invalid"} ${readOnlyValue ? "read-only-input" : ""
                                }`}
                              id="birthdayDiv"
                              onFocus={
                                !readOnlyValue
                                  ? (e) => handleFocus("birthdayDiv", e)
                                  : null
                              }
                              onBlur={!readOnlyValue ? handleBlur : null}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "birthdayDiv"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div className={`d-flex`}>
                                <div className=" input-container flex-grow-1">
                                  <div className="floating-input d-flex justify-content-center align-items-center">
                                    <Datetime
                                      value={formData.birthday}
                                      onChange={handleBirthdayChange}
                                      dateFormat="DD/MM/YYYY"
                                      timeFormat={false}
                                      renderInput={renderInput}
                                      inputProps={{ placeholder: "Select" }}
                                      closeOnSelect={true}
                                      isValidDate={(current) =>
                                        current.isBefore(new Date())
                                      } // Set the today as maximum date
                                    />
                                  </div>
                                  <label
                                    htmlFor="birthday"
                                    className="floating-label"
                                  >
                                    Birthday
                                  </label>
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${!readOnlyValue && formStatusError.birthday
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${!readOnlyValue &&
                                        formPendingStatus.birthday
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`floating-control m-0 mt-3 requiredMark ${formEditDataError.birthday && "invalid"
                                } ${formEditDataError.Minbirthday && "invalid"} ${hiddenElements.NewBirthday ? "" : "d-none"
                                }`}
                              id="newbirthdayDiv"
                              onFocus={() => handleFocus("newbirthdayDiv")}
                              onBlur={handleBlur}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "newbirthdayDiv"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div className={`d-flex`}>
                                <div className=" input-container flex-grow-1">
                                  <div className="floating-input d-flex justify-content-center align-items-center">
                                    <Datetime
                                      value={formUnverifiedData.birthday}
                                      onChange={handleBirthdayChange}
                                      dateFormat="DD/MM/YYYY"
                                      timeFormat={false}
                                      renderInput={renderInput}
                                      inputProps={{ placeholder: "Select" }}
                                      closeOnSelect={true}
                                      isValidDate={(current) =>
                                        current.isBefore(new Date())
                                      } // Set the today as maximum date
                                    />
                                  </div>
                                  <label
                                    htmlFor="birthday"
                                    className="floating-label"
                                  >
                                    New Birthday
                                  </label>
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${formStatusError.birthday ? "" : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${formPendingStatus.birthday
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>

                            {(formError.Minbirthday ||
                              formEditDataError.Minbirthday) && (
                                <span className="text-danger">
                                  Min. 23yrs - Max. 50yrs allowed
                                </span>
                              )}

                            {formStatusError.birthday && (
                              <span className="text-danger text-center">
                                {" "}
                                {showStatusError.birthday}
                              </span>
                            )}
                          </div>
                          <div
                            className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                              }`}
                          >
                            <div className="position-relative d-flex pt-2">
                              {hiddenElements.NewBirthday ? (
                                <img
                                  src="/images/DISCARD.png"
                                  alt="discard"
                                  data-field="birthday"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    hideNewInput("NewBirthday", e)
                                  }
                                />
                              ) : (
                                <img
                                  src="/images/EDIT.png"
                                  alt="edit"
                                  data-field="birthday"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    showNewInput("NewBirthday", e)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" sm={6} md={6} className="mb-4">
                        <div className="d-flex nationalityWrapper">
                          <div className="flex-grow-1">
                            <div
                              className={`floating-control m-0 requiredMark ${formError.nationality && "invalid"
                                } ${readOnlyValue ? "read-only-input" : ""}`}
                              id="nationalityDiv"
                              onFocus={
                                !readOnlyValue
                                  ? (e) => handleFocus("nationalityDiv", e)
                                  : null
                              }
                              onBlur={!readOnlyValue ? handleBlur : null}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "nationalityDiv"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div
                                className={`d-flex  ${readOnlyValue && "pe-none"
                                  }`}
                              >
                                <div className="input-container flex-grow-1">
                                  <select
                                    className={`floating-input`}
                                    name="nationality"
                                    id="nationality"
                                    onChange={handleFormData}
                                    value={formData.nationality}
                                  >
                                    <option value="">Select</option>
                                    {nationality.map((option, index) => {
                                      return (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label
                                    htmlFor="nationality"
                                    className="floating-label"
                                  >
                                    Nationality
                                  </label>
                                  <IoIosArrowDown className="arrowDown" />
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${!readOnlyValue &&
                                      formStatusError.nationality
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${!readOnlyValue &&
                                        formPendingStatus.nationality
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`floating-control m-0 requiredMark mt-3 ${formEditDataError.nationality && "invalid"
                                } ${hiddenElements.NewNationality ? "" : "d-none"
                                }`}
                              id="NewNationality"
                              onFocus={() => handleFocus("NewNationality")}
                              onBlur={handleBlur}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "NewNationality"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div className={`d-flex `} id="newnationalityDiv">
                                <div className="input-container flex-grow-1">
                                  <select
                                    className={`floating-input `}
                                    name="nationality"
                                    id="nationality"
                                    onChange={handleUnverifieldFormData}
                                    value={formUnverifiedData.nationality}
                                  >
                                    <option value="">Select</option>
                                    {nationality.map((option, index) => {
                                      return (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  <label
                                    htmlFor="nationality"
                                    className="floating-label"
                                  >
                                    New Nationality
                                  </label>
                                  <IoIosArrowDown className="arrowDown" />
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${formStatusError.nationality
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${formPendingStatus.nationality
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            {formStatusError.nationality && (
                              <span className="text-danger text-center">
                                {" "}
                                {showStatusError.nationality}
                              </span>
                            )}
                          </div>
                          <div
                            className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                              }`}
                          >
                            <div className="position-relative d-flex pt-2">
                              {hiddenElements.NewNationality ? (
                                <img
                                  src="/images/DISCARD.png"
                                  alt="discard"
                                  data-field="nationality"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    hideNewInput("NewNationality", e)
                                  }
                                />
                              ) : (
                                <img
                                  src="/images/EDIT.png"
                                  alt="edit"
                                  data-field="nationality"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    showNewInput("NewNationality", e)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      
                    </Row>
                  </Row >
                </Col >
                </div>
                <div className="item">
                  <Col>
                  <div>
                    <div className="profile_photo mb-3 d-flex flex-column align-items-center">
                      <div className="d-flex flex-wrap gap-10 justify-content-center">
                        <img
                          src={
                            formData.profilePreview || "/uploads/userIcon.png"
                          }
                          onError={imageOnError}
                          alt="profile"
                          data-name="profile_photo"
                          className="profile_photo-img mb-3 align-self-end"
                          loading="lazy"
                        />
                        {formUnverifiedData.profilePreview && (
                          <div>
                            {" "}
                            <p className="text-center">
                              New Photo{" "}
                              <IoIosCloseCircle
                                onClick={() => handleRemoveNewMedia("photo")}
                                className="mediaCrossIcon fs-3"
                              />
                            </p>
                            <img
                              src={
                                formUnverifiedData.profilePreview ||
                                "/uploads/userIcon.png"
                              }
                              onError={imageOnError}
                              alt="profile"
                              data-name="profile_photo"
                              className="profile_photo-img mb-3"
                              loading="lazy"
                            />
                          </div>
                        )}
                      </div>
                      <div className="d-flex requiredMark">
                        <div className="input-container flex-grow-1">
                          <input
                            className="d-none"
                            type="file"
                            name="photo"
                            id="fileBtnPhoto"
                            accept="image/*"
                            onChange={imageChange}
                          />
                          <label
                            htmlFor="fileBtnPhoto"
                            className="btn btn-primary"
                          >
                            {showEditCancel ? "Change" : "Upload"} Profile Photo
                          </label>
                        </div>
                        <div className="align-self-center p-1">
                          <img
                            src="/images/REASON.png"
                            alt="reason"
                            className={`imageWidth ${formStatusError.photo ? "" : "d-none"
                              }`}
                          />
                          <OverlayTrigger overlay={tooltip} placement="top">
                            <img
                              src="/images/VeriPending.jpeg"
                              alt="VeriPending"
                              className={`imageWidth ${formPendingStatus.photo ? "" : "d-none"
                                }`}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                      {formStatusError.photo && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.photo}
                        </span>
                      )}
                      {formError.photo && (
                        <p className="m-0 py-1 text-danger">
                          Photo is Required
                        </p>
                      )}
                    </div>
                    <div className="record-container d-flex flex-column align-items-center ">
                      <div className="d-flex flex-wrap gap-10 justify-content-center">
                        <div className="record-wrapper mb-3 position-relative align-self-end">
                          {formData.videoUploadingStatus === "processing" &&
                            myVideo.data === "" ? (
                            <>
                              <img
                                src="/images/record-icon.png"
                                alt="record"
                                className="img-fluid"
                              />
                              <p className="videoUploadingStatus position-absolute">
                                processing...
                              </p>
                            </>
                          ) : myVideo.data === "" && formData.video === "" ? (
                            <img
                              src="/images/record-icon.png"
                              alt="record"
                              className="img-fluid"
                            />
                          ) : (
                            <video
                              src={myVideo.data || formData.summaryVideoPreview}
                              controls
                              className="w-100"
                            ></video>
                          )}
                        </div>
                        {formUnverifiedData.videoUploadingStatus ===
                          "processing" && myVideo.unverifydata === "" ? (
                          <>
                            <div className="record-wrapper mb-3 position-relative align-self-end">
                              <img
                                src="/images/record-icon.png"
                                alt="record"
                                className="img-fluid"
                              />
                              <p className="videoUploadingStatus position-absolute">
                                processing...
                              </p>
                            </div>
                          </>
                        ) : (
                          (myVideo.unverifydata !== "" ||
                            formUnverifiedData.video !== "") && (
                            <div className="record-wrapper mb-3">
                              <p className="text-center">
                                New Video{" "}
                                <IoIosCloseCircle
                                  onClick={() => handleRemoveNewMedia("video")}
                                  className="mediaCrossIcon fs-3"
                                />
                              </p>
                              <video
                                src={
                                  myVideo.unverifydata ||
                                  formUnverifiedData.summaryNewVideoPreview
                                }
                                controls
                                className="w-100"
                              ></video>
                            </div>
                          )
                        )}
                      </div>
                      <div className="d-flex requiredMark">
                        <div className="input-container flex-grow-1">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setVideoModal(true)}
                          >
                            {showEditCancel ? "Change" : "Record"} Video
                          </button>
                        </div>
                        <div className="align-self-center p-1">
                          <img
                            src="/images/REASON.png"
                            alt="reason"
                            className={`imageWidth ${formStatusError.video ? "" : "d-none"
                              }`}
                          />
                          <OverlayTrigger overlay={tooltip} placement="top">
                            <img
                              src="/images/VeriPending.jpeg"
                              alt="VeriPending"
                              className={`imageWidth ${formPendingStatus.video ? "" : "d-none"
                                }`}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                      {formStatusError.video && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.video}
                        </span>
                      )}
                      {formError.video && (
                        <p className="m-0 py-1 text-danger">
                          Video is Required
                        </p>
                      )}
                      <VideoRecorder
                        show={videoModal}
                        hide={() => setVideoModal(false)}
                        oldVideo={formData.video}
                        onRecordingComplete={handleRecording}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="d-flex flex-wrap gap-10 justify-content-center">
                      <div className="fullPhotoWrapper align-self-end">
                        <img
                          src={
                            formData.fullPhotoPreview || "/uploads/userIcon.png"
                          }
                          onError={imageOnError}
                          alt="Full profile"
                          className="fullPhoto-img"
                          loading="lazy"
                        />
                      </div>
                      {formUnverifiedData.fullPhotoPreview && (
                        <div>
                          <p className="text-center">
                            New Full Size Photo{" "}
                            <IoIosCloseCircle
                              onClick={() => handleRemoveNewMedia("fullPhoto")}
                              className="mediaCrossIcon fs-3"
                            />
                          </p>
                          <div className="fullPhotoWrapper">
                            <img
                              src={
                                formUnverifiedData.fullPhotoPreview ||
                                "/uploads/userIcon.png"
                              }
                              onError={imageOnError}
                              alt="Full profile"
                              className="fullPhoto-img"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="m-2 text-center">
                      <div className="d-inline-flex flex-wrap requiredMark">
                        <div className="input-container flex-grow-1">
                          <input
                            className="d-none"
                            type="file"
                            name="fullPhoto"
                            id="fileFullPhoto"
                            accept="image/*"
                            onChange={imageChange}
                          />
                          <label
                            htmlFor="fileFullPhoto"
                            className="btn btn-primary"
                          >
                            {showEditCancel ? "Change" : "Upload"} Full Size
                            Photo
                          </label>
                        </div>
                        <div className="align-self-center p-1">
                          <img
                            src="/images/REASON.png"
                            alt="reason"
                            className={`imageWidth ${formStatusError.fullPhoto ? "" : "d-none"
                              }`}
                          />
                          <OverlayTrigger overlay={tooltip} placement="top">
                            <img
                              src="/images/VeriPending.jpeg"
                              alt="VeriPending"
                              className={`imageWidth ${formPendingStatus.fullPhoto ? "" : "d-none"
                                }`}
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formStatusError.fullPhoto && (
                    <div className="text-danger text-center">
                      {showStatusError.fullPhoto}
                    </div>
                  )}
                  {formError.fullPhoto && (
                    <div className="m-0 py-1 text-danger text-center">
                      Full Photo is Required
                    </div>
                  )}
                  <br />
                  <section className="d-flex flex-column align-items-center gap-3 yourDocSection">
                    <div className='d-flex'>
                      <div>
                        <Button variant="primary" className="requiredMark btn-yourDocument"
                          onClick={() => setDocumentModal({ show: true, docName: 'passport' })}>
                          Your Passport
                        </Button>
                        {formError.passport && (
                          <div className="text-danger text-center">
                            Passport is Required
                          </div>
                        )}
                        {formStatusError.passport && (
                          <div className="text-danger text-center">
                            {showStatusError.passport}
                          </div>
                        )}
                      </div>

                      <div className="p-1" style={{ width: '60px' }}>
                        <img
                          src="/images/REASON.png"
                          alt="reason"
                          className={`imageWidth ${formStatusError.passport ? "" : "d-none"
                            }`}
                        />
                        <OverlayTrigger overlay={tooltip} placement="top">
                          <img
                            src="/images/VeriPending.jpeg"
                            alt="VeriPending"
                            className={`imageWidth ${formPendingStatus.passport ? "" : "d-none"
                              }`}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div>
                        <Button variant="primary" className="requiredMark btn-yourDocument"
                          onClick={() => setDocumentModal({ show: true, docName: 'educationCertificates' })}>
                          Your Education Certificate
                        </Button>
                        {formError.educationCertificates && (
                          <div className="text-danger text-center">
                            Education Certificate is Required
                          </div>
                        )}
                        {formStatusError.educationCertificates && (
                          <div className="text-danger text-center">
                            {showStatusError.educationCertificates}
                          </div>
                        )}

                      </div>

                      <div className="p-1" style={{ width: '60px' }}>
                        <img
                          src="/images/REASON.png"
                          alt="reason"
                          className={`imageWidth ${formStatusError.educationCertificates ? "" : "d-none"
                            }`}
                        />
                        <OverlayTrigger overlay={tooltip} placement="top">
                          <img
                            src="/images/VeriPending.jpeg"
                            alt="VeriPending"
                            className={`imageWidth ${formPendingStatus.educationCertificates ? "" : "d-none"
                              }`}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className='d-flex'>
                      <div>
                        <Button variant="primary" className="requiredMark btn-yourDocument"
                          onClick={() => setDocumentModal({ show: true, docName: 'medicalReport' })}>
                          Your Medical Report
                        </Button>
                        {formError.medicalReport && (
                          <div className="text-danger text-center ">
                            Medical Report is Required
                          </div>
                        )}
                        {formStatusError.medicalReport && (
                          <div className="text-danger text-center">
                            {showStatusError.medicalReport}
                          </div>
                        )}

                      </div>

                      <div className="p-1" style={{ width: '60px' }}>
                        <img
                          src="/images/REASON.png"
                          alt="reason"
                          className={`imageWidth ${formStatusError.medicalReport ? "" : "d-none"
                            }`}
                        />
                        <OverlayTrigger overlay={tooltip} placement="top">
                          <img
                            src="/images/VeriPending.jpeg"
                            alt="VeriPending"
                            className={`imageWidth ${formPendingStatus.medicalReport ? "" : "d-none"
                              }`}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                  </section>
                </Col>
                </div>
                <div className="item mt-4">
                <Col className="mt-md-0">
                    <Row>
                    <Col xs="auto" sm={6} md={6} className="cmb-40">
                        <div className="d-flex heightWrapper">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center gap-1">
                              <div
                                className={`floating-control requiredMark m-0 ${formError.height && "invalid"
                                  } ${readOnlyValue ? "read-only-input" : ""}`}
                                id="heightDiv"
                                onFocus={
                                  !readOnlyValue
                                    ? (e) => handleFocus("heightDiv", e)
                                    : null
                                }
                                onBlur={!readOnlyValue ? handleBlur : null}
                                tabIndex={0} // Make the div focusable by adding a tabIndex
                                style={{
                                  border:
                                    isFocused === "heightDiv"
                                      ? "2px solid var(--color-pri-blue)"
                                      : "", // Example border change when focused
                                }}
                              >
                                <div className="d-flex">
                                  <div className="input-container flex-grow-1">
                                    <input
                                      className="floating-input text-center px-2"
                                      name="height"
                                      id="height"
                                      type="number"
                                      placeholder="none"
                                      value={formData.height || ""}
                                      onChange={handleFormData}
                                      readOnly={readOnlyValue}
                                      autoComplete="off"
                                      pattern="[0-9.,]*" inputMode="decimal"
                                    />
                                    <label
                                      htmlFor="height"
                                      className="floating-label"
                                    >
                                      Height
                                    </label>
                                  </div>
                                  <div className="align-self-center p-1">
                                    <img
                                      src="/images/REASON.png"
                                      alt="reason"
                                      className={`imageWidth ${!readOnlyValue && formStatusError.height
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                    <OverlayTrigger
                                      overlay={tooltip}
                                      placement="top"
                                    >
                                      <img
                                        src="/images/VeriPending.jpeg"
                                        alt="VeriPending"
                                        className={`imageWidth ${!readOnlyValue &&
                                          formPendingStatus.height
                                          ? ""
                                          : "d-none"
                                          }`}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                              <span className="fs-5">cm</span>
                            </div>
                            <div
                              className={`d-flex align-items-center gap-1 mt-3 ${hiddenElements.NewHeight ? "" : "d-none"
                                }`}
                              id="NewHeight"
                            >
                              <div
                                className="floating-control m-0 "
                                id="newheightDiv"
                                onFocus={() => handleFocus("newheightDiv")}
                                onBlur={handleBlur}
                                tabIndex={0} // Make the div focusable by adding a tabIndex
                                style={{
                                  border:
                                    isFocused === "newheightDiv"
                                      ? "2px solid var(--color-pri-blue)"
                                      : "", // Example border change when focused
                                }}
                              >
                                <div className="d-flex">
                                  <div className="input-container flex-grow-1">
                                    <input
                                      className="floating-input text-center px-2"
                                      name="height"
                                      id="height"
                                      type="number"
                                      placeholder="none"
                                      value={formUnverifiedData.height || ""}
                                      onChange={handleUnverifieldFormData}
                                      autoComplete="off"
                                      pattern="[0-9.,]*" inputMode="decimal"
                                    />
                                    <label
                                      htmlFor="height"
                                      className="floating-label"
                                    >
                                      New Height
                                    </label>
                                  </div>
                                  <div className="align-self-center p-1">
                                    <img
                                      src="/images/REASON.png"
                                      alt="reason"
                                      className={`imageWidth ${formStatusError.height ? "" : "d-none"
                                        }`}
                                    />
                                    <OverlayTrigger
                                      overlay={tooltip}
                                      placement="top"
                                    >
                                      <img
                                        src="/images/VeriPending.jpeg"
                                        alt="VeriPending"
                                        className={`imageWidth ${formPendingStatus.height
                                          ? ""
                                          : "d-none"
                                          }`}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                              <span className="fs-5">cm</span>
                            </div>
                            {formStatusError.height && (
                              <span className="text-danger text-center">
                                {" "}
                                {showStatusError.height}
                              </span>
                            )}
                            {maxLengthError.height && (
                              <span className="text-danger text-center">
                                {" "}
                                {maxLengthError.height}
                              </span>
                            )}
                          </div>
                          <div
                            className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                              }`}
                          >
                            <div className="position-relative d-flex pt-2">
                              {hiddenElements.NewHeight ? (
                                <img
                                  src="/images/DISCARD.png"
                                  alt="discard"
                                  data-field="height"
                                  className="imageWidth"
                                  onClick={(e) => hideNewInput("NewHeight", e)}
                                />
                              ) : (
                                <img
                                  src="/images/EDIT.png"
                                  alt="edit"
                                  data-field="height"
                                  className="imageWidth"
                                  onClick={(e) => showNewInput("NewHeight", e)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} sm={6} md={6} className="cmb-40">
                        <div className="d-flex weightWrapper">
                          <div className="flex-grow-1">
                            <div className="d-flex align-items-center gap-1">
                              <div
                                className={`floating-control requiredMark m-0 ${formError.weight && "invalid"
                                  } ${readOnlyValue ? "read-only-input" : ""}`}
                                id="WeightDiv"
                                onFocus={
                                  !readOnlyValue
                                    ? (e) => handleFocus("WeightDiv", e)
                                    : null
                                }
                                onBlur={!readOnlyValue ? handleBlur : null}
                                tabIndex={0} // Make the div focusable by adding a tabIndex
                                style={{
                                  border:
                                    isFocused === "WeightDiv"
                                      ? "2px solid var(--color-pri-blue)"
                                      : "", // Example border change when focused
                                }}
                              >
                                <div className="d-flex">
                                  <div className="input-container flex-grow-1">
                                    <input
                                      type="number"
                                      className="floating-input text-center px-2"
                                      name="weight"
                                      id="weight"
                                      placeholder="none"
                                      value={formData.weight || ""}
                                      onChange={handleFormData}
                                      readOnly={readOnlyValue}
                                      autoComplete="off"
                                      pattern="[0-9.,]*" inputMode="decimal"
                                    />
                                    <label
                                      htmlFor="weight"
                                      className="floating-label "
                                    >
                                      Weight
                                    </label>
                                  </div>
                                  <div className="align-self-center p-1">
                                    <img
                                      src="/images/REASON.png"
                                      alt="reason"
                                      className={`imageWidth ${!readOnlyValue && formStatusError.weight
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                    <OverlayTrigger
                                      overlay={tooltip}
                                      placement="top"
                                    >
                                      <img
                                        src="/images/VeriPending.jpeg"
                                        alt="VeriPending"
                                        className={`imageWidth ${!readOnlyValue &&
                                          formPendingStatus.weight
                                          ? ""
                                          : "d-none"
                                          }`}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                              <span className="fs-5">Kg</span>
                            </div>
                            <div
                              className={`d-flex align-items-center gap-1 mt-3 ${hiddenElements.NewWeight ? "" : "d-none"
                                }`}
                              id="NewWeight"
                            >
                              <div
                                className="floating-control m-0 "
                                id="newWeightDiv"
                                onFocus={() => handleFocus("newWeightDiv")}
                                onBlur={handleBlur}
                                tabIndex={0} // Make the div focusable by adding a tabIndex
                                style={{
                                  border:
                                    isFocused === "newWeightDiv"
                                      ? "2px solid var(--color-pri-blue)"
                                      : "", // Example border change when focused
                                }}
                              >
                                <div className="d-flex">
                                  <div className="input-container flex-grow-1">
                                    <input
                                      type="number"
                                      className="floating-input text-center px-2"
                                      name="weight"
                                      id="weight"
                                      placeholder="none"
                                      value={formUnverifiedData.weight || ""}
                                      onChange={handleUnverifieldFormData}
                                      autoComplete="off"
                                      pattern="[0-9.,]*" inputMode="decimal"
                                    />
                                    <label
                                      htmlFor="weight"
                                      className="floating-label "
                                    >
                                      New Weight
                                    </label>
                                  </div>
                                  <div className="align-self-center p-1">
                                    <img
                                      src="/images/REASON.png"
                                      alt="reason"
                                      className={`imageWidth ${formStatusError.weight ? "" : "d-none"
                                        }`}
                                    />
                                    <OverlayTrigger
                                      overlay={tooltip}
                                      placement="top"
                                    >
                                      <img
                                        src="/images/VeriPending.jpeg"
                                        alt="VeriPending"
                                        className={`imageWidth ${formPendingStatus.weight
                                          ? ""
                                          : "d-none"
                                          }`}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                              <span className="fs-5">Kg</span>
                            </div>
                            {formStatusError.weight && (
                              <span className="text-danger text-center">
                                {" "}
                                {showStatusError.weight}
                              </span>
                            )}
                            {maxLengthError.weight && (
                              <span className="text-danger text-center">
                                {" "}
                                {maxLengthError.weight}
                              </span>
                            )}
                          </div>
                          <div
                            className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                              }`}
                          >
                            <div className="position-relative d-flex pt-2">
                              {hiddenElements.NewWeight ? (
                                <img
                                  src="/images/DISCARD.png"
                                  alt="dicard"
                                  data-field="weight"
                                  className="imageWidth"
                                  onClick={(e) => hideNewInput("NewWeight", e)}
                                />
                              ) : (
                                <img
                                  src="/images/EDIT.png"
                                  alt="edit"
                                  data-field="weight"
                                  className="imageWidth"
                                  onClick={(e) => showNewInput("NewWeight", e)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs="auto" className="cmb-40">
                        <div className="d-flex maritalStatusWrapper">
                          <div className="flex-grow-1">
                            <div
                              className={`floating-control m-0 requiredMark ${formError.maritalStatus && "invalid"
                                } ${readOnlyValue ? "read-only-input" : ""}`}
                              id="maritalStatus"
                              onFocus={
                                !readOnlyValue
                                  ? (e) => handleFocus("maritalStatus", e)
                                  : null
                              }
                              onBlur={!readOnlyValue ? handleBlur : null}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "maritalStatus"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div
                                className={`d-flex  ${readOnlyValue && "pe-none"
                                  }`}
                              >
                                <div className="input-container flex-grow-1">
                                  <select
                                    className="floating-input"
                                    name="maritalStatus"
                                    id="maritalStatus"
                                    value={formData.maritalStatus}
                                    onChange={handleFormData}
                                  >
                                    <option value="">Select</option>
                                    {maritalStatus.map((option, index) => {
                                      return (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      );
                                    })}

                                  </select>
                                  <label
                                    htmlFor="maritalStatus"
                                    className="floating-label"
                                  >
                                    Marital status
                                  </label>
                                  <IoIosArrowDown className="arrowDown" />
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${!readOnlyValue &&
                                      formStatusError.maritalStatus
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${!readOnlyValue &&
                                        formPendingStatus.maritalStatus
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            {formError.childAge && (
                              <p className="text-danger">Invalid Children Age.</p>
                            )}

                            <div
                              className={`floating-control m-0 mt-3 requiredMark ${formEditDataError.maritalStatus && "invalid"
                                } ${hiddenElements.Maritalstatus ? "" : "d-none"}`}
                              id="Maritalstatus"
                              onFocus={() => handleFocus("Maritalstatus")}
                              onBlur={handleBlur}
                              tabIndex={0} // Make the div focusable by adding a tabIndex
                              style={{
                                border:
                                  isFocused === "Maritalstatus"
                                    ? "2px solid var(--color-pri-blue)"
                                    : "", // Example border change when focused
                              }}
                            >
                              <div className={`d-flex`}>
                                <div className="input-container flex-grow-1">
                                  <select
                                    className="floating-input"
                                    name="maritalStatus"
                                    id="maritalStatus"
                                    value={formUnverifiedData.maritalStatus || ""}
                                    onChange={handleUnverifieldFormData}
                                  >
                                    <option value="">Select</option>
                                    {maritalStatus.map((option, index) => {
                                      return (
                                        <option key={index} value={option}>
                                          {option}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label
                                    htmlFor="maritalStatus"
                                    className="floating-label"
                                  >
                                    Marital status
                                  </label>
                                  <IoIosArrowDown className="arrowDown" />
                                </div>
                                <div className="align-self-center p-1">
                                  <img
                                    src="/images/REASON.png"
                                    alt="reason"
                                    className={`imageWidth ${formStatusError.maritalStatus
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                  <OverlayTrigger
                                    overlay={tooltip}
                                    placement="top"
                                  >
                                    <img
                                      src="/images/VeriPending.jpeg"
                                      alt="VeriPending"
                                      className={`imageWidth ${formPendingStatus.maritalStatus
                                        ? ""
                                        : "d-none"
                                        }`}
                                    />
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </div>
                            {formStatusError.maritalStatus && (
                              <span className="text-danger text-center">
                                {" "}
                                {showStatusError.maritalStatus}
                              </span>
                            )}
                          </div>
                          <div
                            className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                              }`}
                          >
                            <div className="position-relative d-flex pt-2">
                              {hiddenElements.Maritalstatus ? (
                                <img
                                  src="/images/DISCARD.png"
                                  alt="discard"
                                  data-field="maritalStatus"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    hideNewInput("Maritalstatus", e)
                                  }
                                />
                              ) : (
                                <img
                                  src="/images/EDIT.png"
                                  alt="edit"
                                  data-field="maritalStatus"
                                  className="imageWidth"
                                  onClick={(e) =>
                                    showNewInput("Maritalstatus", e)
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      {formData.maritalStatus !== "" && (
                        <Col xs={12} md={6} className="cmb-40">
                          <div className="h-100">
                            <div className="d-flex align-items-center">
                              <span className="me-3 fs-5 text-nowrap">
                                Children ?
                              </span>
                              <div className="d-flex  align-items-center gap-10 ">
                                <AiFillMinusCircle
                                  className={`removeChildBtn ${showEditCancel &&
                                    !hiddenElements?.childAge &&
                                    "poniter-event-none"
                                    }`}
                                  size={33}
                                  onClick={removeChild}
                                />
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="children"
                                  id="children"
                                  readOnly={true}
                                  value={formData.childAge.length || "0"}
                                  className=""
                                />
                                <IoAddCircleSharp
                                  className={`addChildBtn ${showEditCancel &&
                                    !hiddenElements?.childAge &&
                                    "poniter-event-none"
                                    }`}
                                  size={35}
                                  onClick={addChild}
                                />
                              </div>
                              <div
                                className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                                  }`}
                              >
                                <div className="position-relative d-flex ">
                                  {hiddenElements.childAge ? (
                                    <img
                                      src="/images/DISCARD.png"
                                      alt="discard"
                                      data-field="childAge"
                                      className="imageWidth"
                                      onClick={(e) => hideNewInput("childAge", e)}
                                    />
                                  ) : (
                                    <img
                                      src="/images/EDIT.png"
                                      alt="edit"
                                      data-field="childAge"
                                      className="imageWidth"
                                      onClick={(e) => showNewInput("childAge", e)}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex flex-wrap gap-10 mt-2">
                              {formData.childAge.map((age, index) => (
                                <div
                                  key={index}
                                  className={`childAge mb-2 ${readOnlyValue ? "read-only-input" : ""
                                    }`}
                                >
                                  <div
                                    className={`floating-control m-0 ${age === "" &&
                                      formError.blankage &&
                                      "invalid"
                                      }`}
                                  >
                                    <input
                                    pattern="[0-9]*" 
                                    inputMode="numeric"
                                      type="number"
                                      id={`childAge${index}`}
                                      name="childAge"
                                      className={`floating-input text-center`}
                                      autoComplete="off"
                                      value={age || ""}
                                      onChange={(e) => handleChildAge(e, index)}
                                    />
                                    <label
                                      htmlFor={`childAge${index}`}
                                      className="floating-label"
                                    >
                                      Child {index + 1} age
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div
                              className={`d-flex flex-wrap gap-10 mt-2 ${hiddenElements.childAge ? "" : "d-none"
                                }`}
                            >
                              {formUnverifiedData?.childAge.map((age, index) => (
                                <div
                                  key={index}
                                  className={`childAge newChildAge mb-2 `}
                                >
                                  <div
                                    className={`floating-control m-0 ${age === "" &&
                                      formError.blankage &&
                                      "invalid"
                                      }`}
                                  >
                                    <input
                                      type="text"
                                      id={`childAge${index}`}
                                      name="childAge"
                                      className={`floating-input text-center`}
                                      autoComplete="off"
                                      value={age || ""}
                                      onChange={(e) => handleChildAge(e, index)}
                                    />
                                    <label
                                      htmlFor={`childAge${index}`}
                                      className="floating-label"
                                    >
                                      New Child {index + 1} age
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div style={{ marginTop: -12 }}>
                            {formError.blankage && (
                              <p className="m-0 p-0 text-danger text-center">
                                <small> Children Age can not be Blank</small>
                              </p>
                            )}
                          </div>
                          {formStatusError.childAge && (
                            <span className="text-danger text-center">
                              {" "}
                              {showStatusError.childAge}
                            </span>
                          )}
                        </Col>
                      )}
                  </Row>
                  <Row>
                <Col xs={12} sm={8} lg={8} className="cmb-40">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control requiredMark ${formError.skills && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="skillsStatus"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("skillsStatus", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "skillsStatus"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div
                          className={`d-flex   ${readOnlyValue && "pe-none"
                            }`}
                        >
                          <div className="input-container flex-grow-1">
                            <div
                              id="skills"
                              className="w-100 floating-input px-0 cpy-5"
                            >
                              <Select
                                selectedValue={formData.skills}
                                options={skills}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleMultiselect(value, "skills")
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="skills"
                              className="floating-label"
                            >
                              Skills
                            </label>
                          </div>

                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue && formStatusError.skills
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue && formPendingStatus.skills
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control requiredMark mt-3  ${formEditDataError.skills && "invalid"
                          } ${hiddenElements.NewSkills ? "" : "d-none"}`}
                        id="NewSkills"
                        onFocus={() => handleFocus("NewSkills")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewSkills"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <div
                              id="skills"
                              className={`w-100 floating-input px-0 cpy-5 `}
                            >
                              <Select
                                selectedValue={formUnverifiedData.skills}
                                options={skills}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleUnverifyMultiselect(value, "skills")
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="skills"
                              className="floating-label"
                            >
                              New Skills
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.skills ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.skills ? "" : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.skills && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.skills}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewSkills ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="skills"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewSkills", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="skills"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewSkills", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8} xs={12} sm={8} className="cmb-40">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control requiredMark ${formError.languages && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="languagesStatus"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("languagesStatus", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "languagesStatus"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <div
                              id="languages"
                              className={`w-100 floating-input px-0 cpy-5  ${readOnlyValue && "pe-none"
                                }`}
                            >
                              <Select
                                selectedValue={formData.languages}
                                options={languages}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleMultiselect(value, "languages")
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="languages"
                              className="floating-label"
                            >
                              Languages
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue && formStatusError.languages
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.languages
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control requiredMark mt-3 ${formEditDataError.languages && "invalid"
                          } ${hiddenElements.NewLanguages ? "" : "d-none"}`}
                        id="NewLanguages"
                        onFocus={() => handleFocus("NewLanguages")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewLanguages"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex`} id="NewLanguages">
                          <div className="input-container flex-grow-1">
                            <div
                              id="languages"
                              className={`w-100 floating-input px-0 cpy-5`}
                            >
                              <Select
                                selectedValue={formUnverifiedData.languages}
                                options={languages}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleUnverifyMultiselect(
                                    value,
                                    "languages"
                                  )
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="languages"
                              className="floating-label"
                            >
                              New Languages
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.languages ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.languages
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.languages && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.languages}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewLanguages ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="languages"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewLanguages", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="languages"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewLanguages", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

                </Col>
               
                </div>
              </div>

              {/* Div 1 And 2 End */}
              <Row>
               
              </Row>
              <Row>
                <Col xs={12} lg={5} sm={8} className="cmb-40">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control ${readOnlyValue ? "read-only-input" : ""
                          }`}
                        id="cookingStatus"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("cookingStatus", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "cookingStatus"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <div
                              id="cooking"
                              className={`w-100 floating-input px-0 cpy-5 ${readOnlyValue && "pe-none"
                                }`}
                            >
                              <Select
                                selectedValue={formData.cooking}
                                options={cooking}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleMultiselect(value, "cooking")
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="cooking"
                              className="floating-label"
                            >
                              Cooking
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue && formStatusError.cooking
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.cooking
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control mt-3 ${hiddenElements.NewCooking ? "" : "d-none"
                          }`}
                        id="NewCooking"
                        onFocus={() => handleFocus("NewCooking")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewCooking"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex" id="newcookingStatus">
                          <div className="input-container flex-grow-1">
                            <div
                              id="cooking"
                              className="w-100 floating-input px-0 cpy-5"
                            >
                              <Select
                                selectedValue={formUnverifiedData.cooking}
                                options={cooking}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleUnverifyMultiselect(
                                    value,
                                    "cooking"
                                  )
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="cooking"
                              className="floating-label"
                            >
                              New Cooking
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.cooking ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.cooking ? "" : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.cooking && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.cooking}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewCooking ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="cooking"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewCooking", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="cooking"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewCooking", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={5} sm={8} className="cmb-40">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control ${readOnlyValue ? "read-only-input" : ""
                          }`}
                        id="personalityStatus"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("personalityStatus", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "personalityStatus"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <div
                              id="personality"
                              className={`w-100 floating-input px-0 cpy-5  ${readOnlyValue && "pe-none"
                                }`}
                            >
                              <Select
                                selectedValue={formData.personality}
                                options={personality}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleMultiselect(value, "personality")
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="personality"
                              className="floating-label"
                            >
                              Personality
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.personality
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.personality
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control  mt-3 ${hiddenElements.NewPersonality ? "" : "d-none"
                          }`}
                        id="NewPersonality"
                        onFocus={() => handleFocus("NewPersonality")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewPersonality"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <div
                              id="personality"
                              className="w-100 floating-input px-0 cpy-5"
                            >
                              <Select
                                selectedValue={
                                  formUnverifiedData.personality
                                }
                                options={personality}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                  handleUnverifyMultiselect(
                                    value,
                                    "personality"
                                  )
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect"
                              />
                            </div>
                            <label
                              htmlFor="personality"
                              className="floating-label"
                            >
                              New Personality
                            </label>
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.personality ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.personality
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.personality && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.personality}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewPersonality ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="personality"
                            className="imageWidth"
                            onClick={(e) =>
                              hideNewInput("NewPersonality", e)
                            }
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="personality"
                            className="imageWidth"
                            onClick={(e) =>
                              showNewInput("NewPersonality", e)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex divWidth">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control m-0  requiredMark ${formError.currentCountry && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="currentCountryStatus"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("currentCountryStatus", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "currentCountryStatus"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex`}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input  ${readOnlyValue && "pe-none"
                                }`}
                              name="currentCountry"
                              id="currentCountry"
                              value={formData.currentCountry}
                              onChange={handleFormData}
                            >
                              <option value="">Select</option>
                              {currentCountry.map((country, index) => {
                                return (
                                  <option key={index} value={country}>
                                    {country}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="currentCountry"
                              className="floating-label"
                            >
                              Current Country
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.currentCountry
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.currentCountry
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0 mt-3 requiredMark ${formEditDataError.currentCountry && "invalid"
                          } ${hiddenElements.NewCountry ? "" : "d-none"}`}
                        id="NewCountry"
                        onFocus={() => handleFocus("NewCountry")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewCountry"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div
                          className={`d-flex`}
                          id="newcurrentCountryStatus"
                        >
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input`}
                              name="currentCountry"
                              id="currentCountry"
                              value={formUnverifiedData.currentCountry}
                              onChange={handleUnverifieldFormData}
                            >
                              <option value="">Select</option>
                              {currentCountry.map((country, index) => {
                                return (
                                  <option key={index} value={country}>
                                    {country}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="currentCountry"
                              className="floating-label"
                            >
                              New Current Country
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.currentCountry
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.currentCountry
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.currentCountry && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.currentCountry}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewCountry ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="currentCountry"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewCountry", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="currentCountry"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewCountry", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex divWidth">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control m-0 requiredMark ${formError.currentlyWorking && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="currentlyWorkingDiv"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("currentlyWorkingDiv", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "currentlyWorkingDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input  ${readOnlyValue && "pe-none"
                                }`}
                              name="currentlyWorking"
                              id="currentlyWorking"
                              value={formData.currentlyWorking}
                              onChange={handleFormData}
                            >
                              <option value="">Select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                            <label
                              htmlFor="currentlyWorking"
                              className="floating-label"
                            >
                              Currently working ?{" "}
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.currentlyWorking
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.currentlyWorking
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0 requiredMark mt-3 ${formEditDataError.currentlyWorking && "invalid"
                          } ${hiddenElements.NewCurrentlyWorking ? "" : "d-none"
                          }`}
                        id="NewCurrentlyWorking"
                        onFocus={() => handleFocus("NewCurrentlyWorking")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewCurrentlyWorking"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input `}
                              name="currentlyWorking"
                              id="currentlyWorking"
                              value={formUnverifiedData.currentlyWorking}
                              onChange={handleUnverifieldFormData}
                            >
                              <option value="">Select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                            <label
                              htmlFor="currentlyWorking"
                              className="floating-label"
                            >
                              New Currently working ?{" "}
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.currentlyWorking
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.currentlyWorking
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.currentlyWorking && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.currentlyWorking}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewCurrentlyWorking ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="currentlyWorking"
                            className="imageWidth"
                            onClick={(e) =>
                              hideNewInput("NewCurrentlyWorking", e)
                            }
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="currentlyWorking"
                            className="imageWidth"
                            onClick={(e) =>
                              showNewInput("NewCurrentlyWorking", e)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex divWidth">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control m-0 requiredMark ${formError.religion && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="religionDiv"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("religionDiv", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "religionDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input  ${readOnlyValue && "pe-none"
                                }`}
                              name="religion"
                              id="religion"
                              value={formData.religion}
                              onChange={handleFormData}
                            >
                              <option value="">Select</option>
                              {Religion.map((religion, index) => {
                                return (
                                  <option key={index} value={religion}>
                                    {religion}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="religion"
                              className="floating-label"
                            >
                              Religion
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue && formStatusError.religion
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.religion
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0  requiredMark mt-3 ${formEditDataError.religion && "invalid"
                          } ${hiddenElements.Newreligion ? "" : "d-none"}`}
                        id="Newreligion"
                        onFocus={() => handleFocus("Newreligion")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "Newreligion"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input `}
                              name="religion"
                              id="religion"
                              value={formUnverifiedData.religion}
                              onChange={handleUnverifieldFormData}
                            >
                              <option value="">Select</option>
                              {Religion.map((religion, index) => {
                                return (
                                  <option key={index} value={religion}>
                                    {religion}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="religion"
                              className="floating-label"
                            >
                              New Religion
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.religion ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.religion ? "" : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.religion && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.religion}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.Newreligion ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="religion"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("Newreligion", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="religion"
                            className="imageWidth"
                            onClick={(e) => showNewInput("Newreligion", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex divWidth">
                    <div className="flex-grow-1">
                      <div
                        className={`floating-control m-0 ${readOnlyValue ? "read-only-input" : ""
                          }`}
                        id="howSoonCanStartDiv"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("howSoonCanStartDiv", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "howSoonCanStartDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input ${readOnlyValue && "pe-none"
                                }`}
                              name="howSoonCanStart"
                              id="howSoonCanStart"
                              value={formData.howSoonCanStart}
                              onChange={handleFormData}
                            >
                              <option value="">Select</option>
                              {howSoonStart.map((option, index) => {
                                return (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="howSoonCanStart"
                              className="floating-label"
                            >
                              How soon can Start ?
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.howSoonCanStart
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.howSoonCanStart
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0 mt-4 ${hiddenElements.NewCanStart ? "" : "d-none"
                          }`}
                        id="NewCanStart"
                        onFocus={() => handleFocus("NewCanStart")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewCanStart"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex" id="newhowSoonCanStartDiv">
                          <div className="input-container flex-grow-1">
                            <select
                              className="floating-input"
                              name="howSoonCanStart"
                              id="howSoonCanStart"
                              value={formUnverifiedData.howSoonCanStart}
                              onChange={handleUnverifieldFormData}
                            >
                              <option value="">Select</option>
                              {howSoonStart.map((option, index) => {
                                return (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="howSoonCanStart"
                              className="floating-label"
                            >
                              How soon can Start ?
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.howSoonCanStart
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.howSoonCanStart
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.howSoonCanStart && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.howSoonCanStart}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewCanStart ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="howSoonCanStart"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewCanStart", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="howSoonCanStart"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewCanStart", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex divWidth">
                    <div className="flex-grow-1">
                      <div className="d-flex align-items-center gap-1">
                        <div
                          className={`floating-control m-0 requiredMark ${(formError.expectedSalary ||
                            formError.expectedSalaryMsg) &&
                            "invalid"
                            } ${readOnlyValue ? "read-only-input" : ""}`}
                          id="expectedSalaryDiv"
                          onFocus={
                            !readOnlyValue
                              ? (e) => handleFocus("expectedSalaryDiv", e)
                              : null
                          }
                          onBlur={!readOnlyValue ? handleBlur : null}
                          tabIndex={0} // Make the div focusable by adding a tabIndex
                          style={{
                            border:
                              isFocused === "expectedSalaryDiv"
                                ? "2px solid var(--color-pri-blue)"
                                : "", // Example border change when focused
                          }}
                        >
                          <div className={`d-flex `}>
                            <div className="input-container flex-grow-1">
                              <input
                                type="number"
                                className={`floating-input text-center px-2  ${readOnlyValue && "pe-none"
                                  }`}
                                name="expectedSalary"
                                id="expectedSalary"
                                placeholder="none"
                                value={formData.expectedSalary || ""}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(
                                    evt.key
                                  ) && evt.preventDefault()
                                }
                                onChange={handleFormData}
                                readOnly={readOnlyValue}
                              />
                              <label
                                htmlFor="expectedSalary"
                                className="floating-label "
                              >
                                Expected Salary
                              </label>
                            </div>
                            <div className="align-self-center p-1">
                              <img
                                src="/images/REASON.png"
                                alt="reason"
                                className={`imageWidth ${!readOnlyValue &&
                                  formStatusError.expectedSalary
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                              <OverlayTrigger
                                overlay={tooltip}
                                placement="top"
                              >
                                <img
                                  src="/images/VeriPending.jpeg"
                                  alt="VeriPending"
                                  className={`imageWidth ${!readOnlyValue &&
                                    formPendingStatus.expectedSalary
                                    ? ""
                                    : "d-none"
                                    }`}
                                />
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <span className="fs-5">S$</span>
                      </div>
                      {formError.expectedSalaryMsg && (
                        <span className="text-danger">
                          Invalid Salary - Max. allowed $3,000
                        </span>
                      )}
                      <div
                        className={`d-flex align-items-center gap-1 mt-3 ${hiddenElements.NewExpectedSalary ? "" : "d-none"
                          }`}
                        id="NewExpectedSalary"
                      >
                        <div
                          className={`floating-control m-0 requiredMark ${(formEditDataError.expectedSalary ||
                            formEditDataError.expectedSalaryMsg) &&
                            "invalid"
                            }`}
                          id="newexpectedSalaryDiv"
                          onFocus={() =>
                            handleFocus("newexpectedSalaryDiv")
                          }
                          onBlur={handleBlur}
                          tabIndex={0} // Make the div focusable by adding a tabIndex
                          style={{
                            border:
                              isFocused === "newexpectedSalaryDiv"
                                ? "2px solid var(--color-pri-blue)"
                                : "", // Example border change when focused
                          }}
                        >
                          <div className="d-flex">
                            <div className="input-container">
                              <input
                                type="number"
                                className="floating-input text-center px-2"
                                name="expectedSalary"
                                id="expectedSalary"
                                placeholder="none"
                                value={
                                  formUnverifiedData.expectedSalary || ""
                                }
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(
                                    evt.key
                                  ) && evt.preventDefault()
                                }
                                onChange={handleUnverifieldFormData}
                              />
                              <label
                                htmlFor="expectedSalary"
                                className="floating-label "
                              >
                                New Salary
                              </label>
                              <div className="right-float">
                                <img
                                  src="/images/REASON.png"
                                  alt="reason"
                                  className={`imageWidth ${formStatusError.expectedSalary
                                    ? ""
                                    : "d-none"
                                    }`}
                                />
                                <OverlayTrigger
                                  overlay={tooltip}
                                  placement="top"
                                >
                                  <img
                                    src="/images/VeriPending.jpeg"
                                    alt="VeriPending"
                                    className={`imageWidth ${formPendingStatus.expectedSalary
                                      ? ""
                                      : "d-none"
                                      }`}
                                  />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="fs-5">S$</span>
                      </div>
                      {formEditDataError.expectedSalaryMsg && (
                        <span className="text-danger">
                          Invalid Salary - Max. allowed $3,000
                        </span>
                      )}
                      {formStatusError.expectedSalary && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.expectedSalary}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2  ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewExpectedSalary ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="expectedSalary"
                            className="imageWidth"
                            onClick={(e) =>
                              hideNewInput("NewExpectedSalary", e)
                            }
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="expectedSalary"
                            className="imageWidth"
                            onClick={(e) =>
                              showNewInput("NewExpectedSalary", e)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12} sm={6} md={3} className="cmb-40">
                  <div className="d-flex">
                    <div className="oFFDays">
                      <div
                        className={`floating-control m-0 requiredMark ${formError.oFFDays && "invalid"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="oFFDaysDiv"
                        onFocus={
                          !readOnlyValue
                            ? (e) => handleFocus("oFFDaysDiv", e)
                            : null
                        }
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "oFFDaysDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input  ${readOnlyValue && "pe-none"
                                }`}
                              name="oFFDays"
                              id="oFFDays"
                              onChange={handleFormData}
                              value={formData.oFFDays}
                            >
                              <option value="">Select</option>
                              {oFFDays.map((option, index) => {
                                return (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="oFFDays"
                              className="floating-label"
                            >
                              No. of OFF Days
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue && formStatusError.oFFDays
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.oFFDays
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0 mt-3 requiredMark ${formEditDataError.oFFDays && "invalid"
                          } ${hiddenElements.NewOffDays ? "" : "d-none"}`}
                        id="NewOffDays"
                        onFocus={() => handleFocus("NewOffDays")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "NewOffDays"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex`}>
                          <div className="input-container flex-grow-1">
                            <select
                              className={`floating-input`}
                              name="oFFDays"
                              id="oFFDays"
                              onChange={handleUnverifieldFormData}
                              value={formUnverifiedData.oFFDays}
                            >
                              <option value="">Select</option>
                              {oFFDays.map((option, index) => {
                                return (
                                  <option key={index} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </select>
                            <label
                              htmlFor="oFFDays"
                              className="floating-label"
                            >
                              New No.OFF Days
                            </label>
                            <IoIosArrowDown className="arrowDown" />
                          </div>
                          <div className="align-self-center p-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.oFFDays ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger
                              overlay={tooltip}
                              placement="top"
                            >
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.oFFDays
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.oFFDays && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.oFFDays}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"
                        }`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.NewOffDays ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="oFFDays"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("NewOffDays", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="oFFDays"
                            className="imageWidth"
                            onClick={(e) => showNewInput("NewOffDays", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md="auto" className="cmb-40">
                  <div
                    className={`d-flex ${formError.yearOfExperience && "invalid"
                      }`}
                  >
                    <div className={`yearsOfExperience `}>
                      <div
                        className={`floating-control m-0 ${readOnlyValue && "pe-none"
                          } ${readOnlyValue ? "read-only-input" : ""}`}
                        id="yearOfExperienceDiv"
                        onFocus={() => handleFocus("yearOfExperienceDiv")}
                        onBlur={!readOnlyValue ? handleBlur : null}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "yearOfExperienceDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container flex-grow-1">
                            <div
                              className={`floating-input d-flex px-4 pb-1 gap-1  
                               `}
                            >
                              <div className="floating-control mt-1">
                                <input
                                pattern="[0-9]*" 
                                inputMode="numeric"
                                  type="number"
                                  name="year"
                                  id="year"
                                  placeholder="year"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={formData.yearOfExperience.year}
                                  onChange={handleYearOfExperience}
                                />
                                <label
                                  htmlFor="year"
                                  className="floating-label"
                                >
                                  Year
                                </label>
                              </div>
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="month"
                                  id="month"
                                  placeholder="month"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={formData.yearOfExperience.month}
                                  onChange={handleYearOfExperience}
                                />
                                <label
                                  htmlFor="month"
                                  className="floating-label"
                                >
                                  Month
                                </label>
                              </div>
                            </div>
                            <label className="floating-label">
                              Total Years of Experience
                            </label>
                          </div>

                          <div className="align-self-center pe-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.yearOfExperience
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger overlay={tooltip} placement="top">
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.yearOfExperience
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`floating-control m-0 yearsOfExperience mt-3 ${formError.yearOfExperience && "invalid"
                          } ${hiddenElements.YearOfExperience ? "" : "d-none"}`}
                        id="YearOfExperience"
                        onFocus={() => handleFocus("YearOfExperience")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "YearOfExperience"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className="d-flex">
                          <div className="input-container">
                            <div
                              className={`floating-input d-flex px-4 pb-1 gap-1  
                                      `}
                            >
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="year"
                                  id="year"
                                  placeholder="year"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={
                                    formUnverifiedData.yearOfExperience.year
                                  }
                                  onChange={handleYearOfExperience}
                                />
                                <label
                                  htmlFor="year"
                                  className="floating-label"
                                >
                                  Year
                                </label>
                              </div>
                              <div className="floating-control mt-1">
                                <input
                                  type="text"
                                  name="month"
                                  id="month"
                                  placeholder="month"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={
                                    formUnverifiedData.yearOfExperience.month
                                  }
                                  onChange={handleYearOfExperience}
                                />
                                <label
                                  htmlFor="month"
                                  className="floating-label"
                                >
                                  Month
                                </label>
                              </div>
                            </div>
                            <label className="floating-label">
                              Total Years of Experience
                            </label>
                          </div>
                          <div className="align-self-center pe-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.yearOfExperience ? "" : "d-none"
                                }`}
                            />
                            <OverlayTrigger overlay={tooltip} placement="top">
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.yearOfExperience
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formError.yearOfExperienceErrorMsg && (
                        <p className="text-danger text-center">
                          Total Years of Experience cannot be less than Total
                          Years of Singapore Experience
                        </p>
                      )}
                      {formStatusError.yearOfExperience && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.yearOfExperience}
                        </span>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"}`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.YearOfExperience ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="yearOfExperience"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("YearOfExperience", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="yearOfExperience"
                            className="imageWidth"
                            onClick={(e) => showNewInput("YearOfExperience", e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs="auto" className="cmb-40">
                  <div className="d-flex singaporeExperienceWpapper">
                    <div className="singaporeExperience">
                      <div
                        className={`floating-control m-0 ${formError.singaporeExperience && "invalid"
                          } ${readOnlyValue && "pe-none"} ${readOnlyValue ? "read-only-input" : ""
                          }`}
                        id="singaporeYearDiv"
                        onFocus={() => handleFocus("singaporeYearDiv")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "singaporeYearDiv"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <div
                              className={`floating-input d-flex px-4 pb-1 gap-1 
                              `}
                            >
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="year"
                                  id="singaporeYear"
                                  placeholder="year"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2 text-center"
                                  value={formData.singaporeExperience.year}
                                  onChange={handleSingaporeExperience}
                                />
                                <label
                                  htmlFor="singaporeYear"
                                  className="floating-label"
                                >
                                  Year
                                </label>
                              </div>
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="month"
                                  id="singaporemonth"
                                  placeholder="month"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={formData.singaporeExperience.month}
                                  onChange={handleSingaporeExperience}
                                />
                                <label
                                  htmlFor="singaporemonth"
                                  className="floating-label"
                                >
                                  Month
                                </label>
                              </div>
                            </div>
                            <label className="floating-label">
                              Total Years of Singapore Experience
                            </label>
                          </div>
                          <div className="align-self-center pe-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${!readOnlyValue &&
                                formStatusError.singaporeExperience
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger overlay={tooltip} placement="top">
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${!readOnlyValue &&
                                  formPendingStatus.singaporeExperience
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`floating-control m-0 mt-3 ${formError.singaporeExperience && "invalid"
                          } ${hiddenElements.SingYearOfExperience ? "" : "d-none"
                          }`}
                        id="SingYearOfExperience"
                        onFocus={() => handleFocus("SingYearOfExperience")}
                        onBlur={handleBlur}
                        tabIndex={0} // Make the div focusable by adding a tabIndex
                        style={{
                          border:
                            isFocused === "SingYearOfExperience"
                              ? "2px solid var(--color-pri-blue)"
                              : "", // Example border change when focused
                        }}
                      >
                        <div className={`d-flex `}>
                          <div className="input-container flex-grow-1">
                            <div
                              className={`floating-input d-flex px-4 pb-1 gap-1 
                                     `}
                            >
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="year"
                                  id="singaporeYear"
                                  placeholder="year"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2 text-center"
                                  value={
                                    formUnverifiedData.singaporeExperience.year
                                  }
                                  onChange={handleSingaporeExperience}
                                />
                                <label
                                  htmlFor="singaporeYear"
                                  className="floating-label"
                                >
                                  Year
                                </label>
                              </div>
                              <div className="floating-control mt-1">
                                <input
                                  type="number"
                                  pattern="[0-9]*" 
                                  inputMode="numeric"
                                  name="month"
                                  id="singaporemonth"
                                  placeholder="month"
                                  autoComplete="off"
                                  className="floating-input minH-auto p-2"
                                  value={
                                    formUnverifiedData.singaporeExperience.month
                                  }
                                  onChange={handleSingaporeExperience}
                                />
                                <label
                                  htmlFor="singaporemonth"
                                  className="floating-label"
                                >
                                  Month
                                </label>
                              </div>
                            </div>
                            <label className="floating-label">
                              Total Years of Singapore Experience
                            </label>
                          </div>
                          <div className="align-self-center pe-1">
                            <img
                              src="/images/REASON.png"
                              alt="reason"
                              className={`imageWidth ${formStatusError.singaporeExperience
                                ? ""
                                : "d-none"
                                }`}
                            />
                            <OverlayTrigger overlay={tooltip} placement="top">
                              <img
                                src="/images/VeriPending.jpeg"
                                alt="VeriPending"
                                className={`imageWidth ${formPendingStatus.singaporeExperience
                                  ? ""
                                  : "d-none"
                                  }`}
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                      {formStatusError.singaporeExperience && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.singaporeExperience}
                        </span>
                      )}
                      {formError.singaporeExperienceErrorMsg && (
                        <p className="text-danger text-center">
                          Total Years of Singapore Experience cannot be more
                          than Total Years of Experience
                        </p>
                      )}
                    </div>
                    <div
                      className={`ps-1 pt-2 ${showEditCancel ? "" : "d-none"}`}
                    >
                      <div className="position-relative d-flex pt-2">
                        {hiddenElements.SingYearOfExperience ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="singaporeExperience"
                            className="imageWidth"
                            onClick={(e) =>
                              hideNewInput("SingYearOfExperience", e)
                            }
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="singaporeExperience"
                            className="imageWidth"
                            onClick={(e) =>
                              showNewInput("SingYearOfExperience", e)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="cmb-40">
                  <div className="workExperience">
                    <div className="position-relative d-flex">
                      <div className="fs-5 text-nowrap text-blue">
                        Work Experience details:{" "}
                      </div>
                      <div
                        className={`mx-2 pt-1 ${showEditCancel ? "d-flex" : "d-none"
                          }`}
                      >
                        {hiddenElements.workExperience ? (
                          <img
                            src="/images/DISCARD.png"
                            alt="discard"
                            data-field="workExperience"
                            className="imageWidth"
                            onClick={(e) => hideNewInput("workExperience", e)}
                          />
                        ) : (
                          <img
                            src="/images/EDIT.png"
                            alt="edit"
                            data-field="workExperience"
                            className="imageWidth"
                            onClick={(e) => showNewInput("workExperience", e)}
                          />
                        )}
                        <img
                          src="/images/REASON.png"
                          alt="reason"
                          className={`imageWidth ${formStatusError.workExperience ? "" : "d-none"
                            }`}
                        />
                        <OverlayTrigger overlay={tooltip} placement="top">
                          <img
                            src="/images/VeriPending.jpeg"
                            alt="VeriPending"
                            className={`imageWidth ${formPendingStatus.workExperience ? "" : "d-none"
                              }`}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                    {(hiddenElements.workExperience || showAddButton) && (
                      <Button
                        variant="dark"
                        className={`my-3 cw-200`}
                        onClick={addWorkExperience}
                      >
                        {" "}
                        + Add{" "}
                      </Button>
                    )}
                    <div className={`workExperienceTable`}>
                      {formStatusError.workExperience && (
                        <span className="text-danger text-center">
                          {" "}
                          {showStatusError.workExperience}
                        </span>
                      )}
                      <ul>
                        <li>
                          {formError.workExperienceFillMsg && (
                            <span className="text-danger">
                              Need to fill-in required data
                            </span>
                          )}
                        </li>
                        <li>
                          {formError.workExperienceMsg && (
                            <span className="text-danger">
                              Total Years of Work Experience Can not be Zero
                            </span>
                          )}
                        </li>
                        <li>
                          {(formError.workExpErrorDelete ||
                            formEditDataError.workExpErrorDelete) && (
                              <span className="text-danger">
                                Total Work experience can not be Zero because
                                record present in Work details. Please delete the
                                detail record
                              </span>
                            )}
                        </li>
                        <li>
                          {(formError.workExpErrorRequired ||
                            formEditDataError.workExpErrorRequired) && (
                              <span className="text-danger">
                                Work experience details are Required
                              </span>
                            )}
                        </li>
                        <li>
                          {(formError.workExpErrorPresent ||
                            formEditDataError.workExpErrorPresent) && (
                              <span className="text-danger">
                                More than 1 Present job selected, please correct
                              </span>
                            )}
                        </li>
                      </ul>
                      {readOnlyValue && (
                        <VerifyWorkExprienceList
                          data={formData.workExperience}
                          deletedWorkExperience={deletedWorkExperience}
                          onDelete={(id, tempId) => onDeleteWorkExp(id, tempId)}
                        />
                      )}
                      {!readOnlyValue && (
                        <div className="desktop-view">
                          <Table className="table table-bordered m-0 tb text-center">
                            {formData.workExperience.length > 0 && (
                              <Thead className="bg-blue">
                                <Tr>
                                  <Th className="border-R-transparent py-3"></Th>
                                  {table.map((value, index) => (
                                    <Th
                                      key={index}
                                      className={`${value === "From"
                                        ? "border-R-transparent"
                                        : value === "Any Reference Letter?"
                                          ? "text-nowrap"
                                          : ""
                                        } py-3`}
                                    >
                                      {value}
                                    </Th>
                                  ))}
                                </Tr>
                              </Thead>
                            )}
                            <Tbody>
                              {formData.workExperience.map((value, index) => (
                                <Tr
                                  key={value.id}
                                  className={`${selectedIndices.includes(index)
                                    ? "invalid"
                                    : ""
                                    }`}
                                >
                                  <Td className="border-R-transparent">
                                    <div className="requiredMark"></div>
                                  </Td>
                                  <Td
                                    className={`tbl-td notInherit ${readOnlyValue ? "read-only-input" : ""
                                      }`}
                                  >
                                    <div className="PresentDiv">
                                      <div className="d-flex">
                                        <div className="col">From: </div>
                                        <div className="col">
                                          <Datetime
                                            value={value.from}
                                            onChange={(date) =>
                                              handlePeriodFromChange(date, index)
                                            }
                                            dateFormat="MM/YYYY"
                                            timeFormat={false}
                                            renderInput={renderInput}
                                            isValidDate={(current) =>
                                              current.isBefore(new Date())
                                            } // Set the today as maximum date
                                            inputProps={{ placeholder: "Select" }}
                                            closeOnSelect={true}
                                            className="from-date"
                                          />
                                        </div>
                                      </div>
                                      <br />
                                      <div className="d-flex">
                                        <div className="col">To: </div>
                                        <div className="form-check col">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name={`present-${index}`}
                                            id={`present-${index}`}
                                            checked={value.present}
                                            onChange={(e) =>
                                              handleCheckboxChange(e, index)
                                            }
                                          ></input>
                                          <label
                                            className="form-check-label"
                                            htmlFor={`present-${index}`}
                                          >
                                            Present
                                          </label>
                                        </div>
                                      </div>
                                      <div className="d-flex mt-2">
                                        <div className="col"></div>
                                        <div className="col">
                                          {value.present ? (
                                            <Datetime
                                              key="refreshed"
                                              dateFormat="MM/YYYY"
                                              timeFormat={false}
                                              renderInput={renderInput}
                                              isValidDate={(current) =>
                                                current.isBefore(new Date())
                                              } //set min and max date
                                              inputProps={{
                                                placeholder: "Select",
                                              }}
                                              closeOnSelect={true}
                                              className={`to-date ${value.present
                                                ? "read-only-input"
                                                : ""
                                                }`}
                                            />
                                          ) : (
                                            <Datetime
                                              key="initial"
                                              value={value.to}
                                              onChange={(date) =>
                                                handlePeriodToChange(date, index)
                                              }
                                              dateFormat="MM/YYYY"
                                              timeFormat={false}
                                              renderInput={renderInput}
                                              isValidDate={(current) =>
                                                current.isBefore(new Date())
                                              } //set min and max date
                                              inputProps={{
                                                placeholder: "Select",
                                              }}
                                              closeOnSelect={true}
                                              className={`to-date ${value.present
                                                ? "read-only-input"
                                                : ""
                                                }`}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </Td>
                                  <Td
                                    className={`tbl-td notInherit ${readOnlyValue ? "read-only-input" : ""
                                      }`}
                                  >
                                    <div
                                      className={`position-relative cw-200 mx-auto ${readOnlyValue && "pe-none"
                                        }`}
                                    >
                                      <select
                                        name="country"
                                        className="c-select"
                                        onChange={(e) =>
                                          handleWorkExperienceCountry(e, index)
                                        }
                                        value={value.country}
                                      >
                                        <option value="">Select</option>

                                        {workExpeCountry.map((option, index) => {
                                          return (
                                            <option key={index} value={option}>
                                              {option}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <IoIosArrowDown className="arrowDown" />
                                    </div>
                                  </Td>
                                  <Td
                                    className={`workExperience_duties notInherit ${readOnlyValue && "read-only-input"
                                      }`}
                                  >
                                    <div
                                      className={`c-min-w-195 duties ${readOnlyValue && "pe-none"
                                        }`}
                                    >
                                      <Select
                                        selectedValue={value.duties}
                                        options={skills}
                                        optionsArrayObject={false}
                                        onChange={(value) =>
                                          handleWorkExperienceDuties(value, index)
                                        }
                                        arrow={<IoIosArrowDown />}
                                        multiple
                                        classNamePrefix="multiSelect-workExperience"
                                      />
                                    </div>
                                  </Td>
                                  <Td className="tbl-td notInherit">
                                    <div>
                                      <label
                                        htmlFor={`refLetter${index}`}
                                        className={`btn btn-success uploadBtn w-auto text-nowrap ${readOnlyValue && "pe-none"
                                          }`}
                                      >
                                        Upload Ref. Letter
                                      </label>
                                      <input
                                        type="file"
                                        name="refLetter"
                                        id={`refLetter${index}`}
                                        accept="image/*,application/pdf"
                                        onChange={(event) =>
                                          handleRefLetter(event, index)
                                        }
                                        hidden
                                      />
                                      <div className="d-flex gap-10 justify-content-center">
                                        {value.refLetter && (
                                          <>
                                            <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                              <span className="icon-text">
                                                view
                                              </span>
                                              {
                                                <TfiEye
                                                  className="icon-eye"
                                                  onClick={() =>
                                                    handleImgView(value.refLetter)
                                                  }
                                                />
                                              }
                                            </span>
                                            <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                              <span className="icon-text">
                                                Remove
                                              </span>
                                              <IoIosCloseCircle
                                                className="mediaCrossIcon fs-3"
                                                onClick={() =>
                                                  removeRefLetter(index)
                                                }
                                              />
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    </div >
                                  </Td >
                                  <Td className="notInherit">
                                    <div className="h-100 text-center">
                                      <Button
                                        variant="danger"
                                        className="uploadBtn w-auto"
                                        onClick={() =>
                                          removeWorkExperience(value.id)
                                        }
                                        disabled={readOnlyValue}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      )}
                      {!readOnlyValue && (
                        <WorkExperienceMobile
                          workExperience={formData?.workExperience}
                          renderInput={renderInput}
                          workExpeCountry={workExpeCountry}
                          skills={skills}
                          handlePeriodFromChange={handlePeriodFromChange}
                          handleCheckboxChange={handleCheckboxChange}
                          handlePeriodToChange={handlePeriodToChange}
                          handleWorkExperienceCountry={handleWorkExperienceCountry}
                          handleWorkExperienceDuties={handleWorkExperienceDuties}
                          handleRefLetter={handleRefLetter}
                          handleImgView={handleImgView}
                          removeRefLetter={removeRefLetter}
                          removeWorkExperience={removeWorkExperience}
                          selectedIndices={selectedIndices}
                        />
                      )}
                      <br />
                      <br />
                      <div className="desktop-view">
                        <Table className="table table-bordered m-0 tb text-center">
                          {formUnverifiedData?.workExperience?.length > 0 && (
                            <Thead className="bg-blue">
                              <Tr>
                                <Th className="border-R-transparent py-3"></Th>
                                {table.map((value, index) => (
                                  <Th
                                    key={index}
                                    className={`${value === "From"
                                      ? "border-R-transparent"
                                      : value === "Any Reference Letter?"
                                        ? "text-nowrap"
                                        : ""
                                      } py-3`}
                                  >
                                    {value}
                                  </Th>
                                ))}
                              </Tr>
                            </Thead>
                          )}

                          <Tbody>
                            {formUnverifiedData?.workExperience?.length > 0 &&
                              formUnverifiedData?.workExperience?.map(
                                (workExp, index) => (
                                  <Tr
                                    key={workExp.id}
                                    className={
                                      unverifyselectedIndices.includes(index)
                                        ? "invalid"
                                        : ""
                                    }
                                  >
                                    <Td className="border-R-transparent">
                                      <div className="requiredMark"></div>
                                    </Td>
                                    <Td className="tbl-td notInherit" data-label="Period">
                                      <div className="PresentDiv">
                                        <div className="d-flex">
                                          <div className="col">From:</div>
                                          <div className="col">
                                            <Datetime
                                              value={
                                                workExp.from
                                                  ? new Date(workExp.from)
                                                  : ""
                                              }
                                              onChange={(date) =>
                                                handlePeriodFromChange(
                                                  date,
                                                  index
                                                )
                                              }
                                              dateFormat="MM/YYYY"
                                              timeFormat={false}
                                              renderInput={renderInput}
                                              isValidDate={(current) =>
                                                current.isBefore(new Date())
                                              } // Set the today as maximum date
                                              inputProps={{
                                                placeholder: "Select",
                                              }}
                                              closeOnSelect={true}
                                              className="from-date"
                                            />
                                          </div>
                                        </div>
                                        <br />
                                        <div className="d-flex">
                                          <div className="col">To:</div>
                                          <div className="form-check col">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name={`present-${index}`}
                                              id={`present-${index}`}
                                              checked={workExp.present}
                                              onChange={(e) =>
                                                handleCheckboxChange(e, index)
                                              }
                                            ></input>
                                            <label
                                              className="form-check-label"
                                              htmlFor={`present-${index}`}
                                            >
                                              Present
                                            </label>
                                          </div>
                                        </div>

                                        <div className="d-flex mt-2">
                                          <div className="col"></div>
                                          <div className="col">
                                            {workExp.present ? (
                                              <Datetime
                                                key="refreshed"
                                                dateFormat="MM/YYYY"
                                                timeFormat={false}
                                                renderInput={renderInput}
                                                isValidDate={(current) =>
                                                  current.isBefore(new Date())
                                                } //set min and max date
                                                inputProps={{
                                                  placeholder: "Select",
                                                }}
                                                closeOnSelect={true}
                                                className={`to-date ${workExp.present
                                                  ? "read-only-input"
                                                  : ""
                                                  }`}
                                              />
                                            ) : (
                                              <Datetime
                                                key="initial"
                                                value={
                                                  workExp.to
                                                    ? new Date(workExp.to)
                                                    : ""
                                                }
                                                onChange={(date) =>
                                                  handlePeriodToChange(
                                                    date,
                                                    index
                                                  )
                                                }
                                                dateFormat="MM/YYYY"
                                                timeFormat={false}
                                                renderInput={renderInput}
                                                isValidDate={(current) =>
                                                  current.isBefore(new Date())
                                                } //set min and max date
                                                inputProps={{
                                                  placeholder: "Select",
                                                }}
                                                closeOnSelect={true}
                                                className={`to-date ${workExp.present
                                                  ? "read-only-input"
                                                  : ""
                                                  }`}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Td>
                                    <Td className="tbl-td notInherit" data-label="Country">
                                      <div className="position-relative cw-200 mx-auto">
                                        <select
                                          name="country"
                                          className="c-select"
                                          onChange={(e) =>
                                            handleWorkExperienceCountry(e, index)
                                          }
                                          value={workExp.country}
                                        >
                                          <option value="">Select</option>
                                          {workExpeCountry.map((option, index) => {
                                            return (
                                              <option key={index} value={option}>
                                                {option}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        <IoIosArrowDown className="arrowDown" />
                                      </div>
                                    </Td>
                                    <Td className="workExperience_duties notInherit" data-label="Duties">
                                      <div className="duties">
                                        <Select
                                          selectedValue={workExp.duties}
                                          options={skills}
                                          optionsArrayObject={false}
                                          onChange={(value) =>
                                            handleWorkExperienceDuties(
                                              value,
                                              index
                                            )
                                          }
                                          arrow={<IoIosArrowDown />}
                                          multiple
                                          classNamePrefix="multiSelect-workExperience"
                                        />
                                      </div>
                                    </Td>
                                    <Td className="tbl-td notInherit" data-label="Reference Letter">
                                      <div>
                                        <label
                                          htmlFor={`refLetter${index}`}
                                          className="btn btn-success uploadBtn w-auto text-nowrap"
                                        >
                                          Upload Ref. Letter
                                        </label>
                                        <input
                                          type="file"
                                          name="refLetter"
                                          id={`refLetter${index}`}
                                          accept="image/*,application/pdf"
                                          onChange={(event) =>
                                            handleRefLetter(event, index)
                                          }
                                          hidden
                                        />

                                        <div className="d-flex gap-10 justify-content-center">
                                          {workExp.refLetter && (
                                            <>
                                              <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                                <span className="icon-text">
                                                  view
                                                </span>
                                                {
                                                  <TfiEye
                                                    className="icon-eye"
                                                    onClick={() =>
                                                      handleImgView(
                                                        workExp.refLetter
                                                      )
                                                    }
                                                  />
                                                }
                                              </span>
                                              <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                                <span className="icon-text">
                                                  Remove
                                                </span>
                                                <IoIosCloseCircle
                                                  className="mediaCrossIcon fs-3"
                                                  onClick={() =>
                                                    removeRefLetter(index)
                                                  }
                                                />
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </Td>
                                    <Td className="notInherit" data-label="Action">
                                      <div className="h-100 text-center">
                                        <Button
                                          variant="danger"
                                          className="uploadBtn w-auto"
                                          onClick={() =>
                                            removeWorkExperience(
                                              workExp.id,
                                              "unverified"
                                            )
                                          }
                                        >
                                          Delete
                                        </Button>
                                      </div>
                                    </Td>
                                  </Tr>
                                )
                              )}
                          </Tbody>
                        </Table>
                      </div>
                      <WorkExperienceMobile
                        workExperience={formUnverifiedData?.workExperience}
                        renderInput={renderInput}
                        workExpeCountry={workExpeCountry}
                        skills={skills}
                        handlePeriodFromChange={handlePeriodFromChange}
                        handleCheckboxChange={handleCheckboxChange}
                        handlePeriodToChange={handlePeriodToChange}
                        handleWorkExperienceCountry={handleWorkExperienceCountry}
                        handleWorkExperienceDuties={handleWorkExperienceDuties}
                        handleRefLetter={handleRefLetter}
                        handleImgView={handleImgView}
                        removeRefLetter={removeRefLetter}
                        removeWorkExperience={removeWorkExperience}
                        selectedIndices={unverifyselectedIndices}
                      />
                    </div>
                  </div>
                </Col>

                <Col className="cmb-40">
                  <div className="d-flex flex-wrap gap-10 justify-content-center align-items-center">
                    <Button
                      variant="primary"
                      className="mb-3 btn-save"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      )}
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      className="mb-3 btn-cancel"
                      onClick={() => naviagte("/candidate/profile/interview")}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="dark"
                      className="mb-3"
                      onClick={deleteCandidate}
                    >
                      Delete
                    </Button>
                  </div>
                  {error && <div className="error text-center">{error}</div>}
                  {success && (
                    <div className="success text-center">{success}</div>
                  )}
                </Col>
              </Row >
              {/* document modal for video and ref.letter   */}
              < YourDocument
                isOpen={documentModal.show}
                onClose={() => setDocumentModal({ ...documentModal, show: false })}
                docName={documentModal.docName}
                formStatusError={formStatusError}
                showStatusError={showStatusError}
                verificationCount={verificationCount}
                formUnverifiedData={formUnverifiedData}
                setFormUnverifiedData={setFormUnverifiedData}
                formData={formData}
                setFormData={setFormData}
                mode={mode}
                formEditData={formEditData}
                setFormEditData={setFormEditData}
                formError={formError}
                setFormError={setFormError}
                PendingCounter={PendingCounter}
                setFormStatusError={setFormStatusError}
                setFormPendingStatus={setFormPendingStatus}
                formPendingStatus={formPendingStatus}
                showEditCancel={showEditCancel}
                viewModal={viewModal}
                setViewModal={setViewModal}
              />

              {/* viewModal   */}
              < Modal
                fullscreen={viewModal.fullscreen}
                show={viewModal.show}
                onHide={() => setViewModal({ ...viewModal, show: false })}
                backdrop="static"
                id="viewModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                dialogClassName="w-min-content"
              >
                <Modal.Header className="p-0 justify-content-end">
                  <div className="position-relative">
                    <AiOutlineClose
                      onClick={() =>
                        setViewModal({ ...viewModal, show: false })
                      }
                      className="icon close-icon position-absolute"
                    />
                  </div>
                </Modal.Header>
                <Modal.Body className="p-0 min-h-100">
                  <div>
                    {viewModal.type === "image" && (
                      <div className="">
                        <DocViewer
                          documents={[{ uri: viewModal.url }]}
                          pluginRenderers={DocViewerRenderers}
                          config={{
                            header: {
                              disableHeader: true,
                              disableFileName: true,
                              retainURLParams: true,
                            },
                          }}
                        />
                      </div>
                    )}
                    {viewModal.url.type === "application/pdf" && (
                      <div className="d-flex justify-content-center">
                        <DocViewer
                          documents={[viewModal.url].map((file) => ({
                            uri: window.URL.createObjectURL(file),
                            fileName: file.name,
                          }))}
                          pluginRenderers={DocViewerRenderers}
                          config={{
                            header: {
                              disableHeader: true,
                              disableFileName: true,
                              retainURLParams: true,
                            },
                          }}
                        />
                      </div>
                    )}
                    {typeof viewModal.url === "string" &&
                      viewModal.url.includes(".pdf") && (
                        <div className="">
                          <DocViewer
                            documents={[{ uri: viewModal.url }]}
                            pluginRenderers={DocViewerRenderers}
                            config={{
                              header: {
                                disableHeader: true,
                                disableFileName: true,
                                retainURLParams: true,
                              },
                            }}
                          />
                        </div>
                      )}
                  </div>
                </Modal.Body>
              </Modal >

              {/* message modal   */}
              < Modal
                show={alertModal}
                onHide={() => setAlertModal(false)}
                backdrop="static"
                id="messageModal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body className="p-5">
                  <p className="mb-4">
                    Your details are verified. Need to refresh.
                  </p>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-green"
                      onClick={handleAlertModalRefresh}
                    >
                      Refresh
                    </button>
                  </div>
                </Modal.Body>
              </Modal >

              {/* Education Certificate List MOdal*/}
              < EducationCertificateListModal
                isOpen={openEducationCert}
                onClose={handleEducationCertClose}
              />
              {/* Pass dynamic message and control dialog visibility */}
              {
                open && (
                  <DialogAlert
                    open={open}
                    handleClose={handleClose}
                    message={dialogMessage}
                    title="Warning"
                  />
                )
              }
            </Container >
          </form >
        </main >
      </div >
    </>
  );
};

export default PersonalDetails;
