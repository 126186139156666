import React, { memo } from "react";
import "./customModal.css";
const CustomModal = ({ isOpen, onClose, children }) => {
  return (
    <div className={`custom-modal ${isOpen ? "open" : "closed"}`}>
      <div className="custom-modal-overlay" onClick={onClose}></div>
      {/* <div className="custom-modal-body"> */}
      {children}
      {/* </div> */}
    </div>
  );
};

export default memo(CustomModal);
