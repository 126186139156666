import React from 'react'
import { useNavigate } from 'react-router-dom';
import './empAgreement.css'

const EmpAgreement = () => {
  const navigate = useNavigate();
  return (
    <div className='empAgreement h-100'>
      <div className='instruction'>
        <p>You need to agree and sign the
          Contact with HelperWish before
          you can view the detail</p>
          <br/>
        <button className='btn-rounded proceedToC' onClick={() => navigate('/employer/profile/agreement-sign')}>Proceed to Contract</button>
      </div>
    </div>
  )
}

export default EmpAgreement