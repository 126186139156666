
import React, { useState, useEffect, useContext } from 'react'
import Select from "react-select";


const SelectSearch = ({ options, onChange, value, fromError }) => {
  return (
    <div className={`floating-control requiredMark flex-grow-1 mt-5 ${fromError.Citizenship ? 'invalid' : ''}`}>
      <div className="input-container flex-grow-1">
        <Select className={`floating-input px-2`}
          onChange={(selectedOption) => {
            onChange(selectedOption)
          }}
          options={options}
          isSearchable
          placeholder="Search and select a country..."
          value={value}
          styles={{
            control: (base) => ({
              ...base,
              border: 'none',
              borderColor: "#ced4da",
              borderRadius: "5px",
              padding: "2px",
              textAlign: "left"
            }),
            menu: (base) => ({
              ...base,
              zIndex: 9999, // Ensure dropdown renders above other elements
              textAlign: "left"
            }),
          }}
        />
        <label htmlFor="Citizenship" className="floating-label">Citizenship</label>
      </div>
    </div >


  );
};

export default SelectSearch