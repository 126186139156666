import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { Container, Modal, Col, Form } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { FaCalendar } from 'react-icons/fa';
import getBaseUrl from '../../../../hooks/http'
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { CountContext } from '../../../../context/CountContext';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { AiOutlineClose } from 'react-icons/ai';
import { TfiEye } from 'react-icons/tfi';
import { useCandidateOnboarding } from './useCandidateOnboarding';
import { useNavigate } from 'react-router-dom';
import { Document, Page, pdfjs } from "react-pdf";
import CndInProcess from '../../../../components/candidate/selectCandidate/CndInProcess';
import CndCompleted from '../../../../components/candidate/selectCandidate/CndCompleted';

import './selectedCandidate.css';



const Selected = () => {
   const { user } = useAuthContext();
   const { saveStatus } = useCandidateOnboarding()
   const navigate = useNavigate();

   const [onboardingData, setOnboardingData] = useState([]);
   const [onboardingHistoryData, setOnboardingHistoryData] = useState([]);
   const { updateSelectedCanCount } = useContext(CountContext)
   const [verificationCount, setVerificationCount] = useState(1);
   // const [stepsData, setStepsData] = useState(null);
   const [step, setStep] = useState(null);
   const [index, setIndex] = useState(null);
   const [modal, setModal] = useState(false);
   const [isFileExists, setIsFileExists] = useState(false);
   const [dataType, setDataType] = useState('currentin-process')

   const [helperData, setHelperData] = useState({
      letter: false,
      refLetter: ''
   })

   const [isLoading, setIsLoading] = useState(false)

   // ---------------- react-datetime utils -----------------------------
   const renderInput = (props, openCalendar) => {

      return (
         <div className='d-flex align-items-center arrivalDateWrapper'>
            <input {...props} onClick={openCalendar} className='flex-grow-1 border-0 h-100' />
            <FaCalendar onClick={openCalendar} style={{ cursor: 'pointer' }} />
         </div>
      );
   };
   // ----------------react-datetime End -----------------------------

   useEffect(() => {
      getOnboardingData();
      // check data type and dispaly data
      if (dataType === 'currentin-process') getOnboardingData();
      else getOnboardingHistoryData();
   }, [user, dataType])


   // get Onboarding candidate Data
   const getOnboardingData = async () => {
      try {
         setIsLoading(true)
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/onboarding/getCandidateOnboarding`, config)

            if (res.data.personalDetailsData !== null) {
               setVerificationCount(res.data.personalDetailsData.verificationCounter);
            } else {
               setVerificationCount(null);
            }
            updateSelectedCanCount(res.data.onboardingData.length)
            if (res.status === 200) {
               const data = res.data.onboardingData;
               console.log(data);
             const onboardingData = data.map(dt => {
            const employerName = dt.empDetails[0].name ?? 'Unknown';
            const onBoardingDate = new Date(dt.onboarding_date_time);
            const status = dt.status;
            const step1Reason=dt.step1Reason;
            const step2Reason=dt.step2Reason;

            return {
               employerName:employerName,
               date:onBoardingDate,
               status:status,
               step1Reason:step1Reason,
               step2Reason:step2Reason
            }
          })
          setOnboardingData(onboardingData);
            }
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false)
      }
   }

   // get Onboarding  history candidate Data
   const getOnboardingHistoryData = async () => {
      try {
         setIsLoading(true)
         if (user !== null) {
            let config = {
               headers: { Authorization: `Bearer ${user.token}`, },
            };

            const res = await axios.get(`${getBaseUrl}/onboarding/onboardingCand-history/`, config)
            if (res.status === 200) {
               const data = res.data.onboardingDataHistory;
             const onboardingData = data.map(dt => {
            const employerName = dt.empDetails[0].name ?? 'Unknown';
            const onBoardingDate = new Date(dt.onboarding_date_time);
            const status = dt.status;
            const step1Reason=dt.step1Reason;
            const step2Reason=dt.step2Reason;
            return {
               employerName:employerName,
               date:onBoardingDate,
               status:status,
               step1Reason:step1Reason,
               step2Reason:step2Reason
            }
          })
          setOnboardingHistoryData(onboardingData);
            }
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsLoading(false)
      }
   }


   //handle ArrivalDate on change
   const handleArrivalDate = (date, index, step) => {
      const newArr = [...onboardingData];
      newArr[index][`${step}`]['arrivalDate'] = date;
      newArr[index][`${step}`]['arrivalDateError'] = false;
      setOnboardingData(newArr);
   }
   const handleSIPTrainingDate = (date, index, step) => {
      const newArr = [...onboardingData];
      newArr[index][`${step}`]['SIPTrainingDate'] = date;
      newArr[index][`${step}`]['SIPTrainingDateError'] = false;
      setOnboardingData(newArr);
   }
   //handle Cancellation Reason
   const handleCancellationReason = (e, index, step) => {
      const newArr = [...onboardingData];
      newArr[index][`${step}`]['cancellationReason'] = e.target.value;
      newArr[index][`${step}`]['cancellationReasonError'] = false;
      setOnboardingData(newArr);
   }
   //handle stattus change event
   const handleStatus = async (e, index, steps) => {
      console.log(e.target.value, index, steps);
      const newArr = [...onboardingData]
      newArr[index][`${steps}`]['value'] = e.target.value;
      newArr[index][`${steps}`]['error'] = false;
      setOnboardingData(newArr);
   }
   //handle Confirm Status btn click event
   const handleConfirmStatus = (e, index) => {
      const step = e.target.getAttribute('data-step'); // get data id (step no)
      setIndex(index); setStep(step)
      console.log('step', step);
      //data array 
      const newArr = [...onboardingData];
      //destructor object
      const { beforeArrivalSteps, onArrivalSteps } = newArr[index];
      //validation for beforeArrivalSteps 
      if (step === '1') {
         const { value, arrivalDate, SIPTrainingDate, cancellationReason } = beforeArrivalSteps;

         if (!value) {
            newArr[index]['beforeArrivalSteps']['error'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'ArrivalDateConfirmed' && !arrivalDate) {
            newArr[index]['beforeArrivalSteps']['arrivalDateError'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'SIPTrainingBooked' && !SIPTrainingDate) {
            newArr[index]['beforeArrivalSteps']['SIPTrainingDateError'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'CandidateSelectionCancelled' && !cancellationReason) {
            newArr[index]['beforeArrivalSteps']['cancellationReasonError'] = true
            setOnboardingData(newArr);
            return;
         }
      }
      //validation for  onArrivalSteps
      if (step === '2') {
         const { value, cancellationReason } = onArrivalSteps;
         if (!value) {
            newArr[index]['onArrivalSteps']['error'] = true
            setOnboardingData(newArr);
            return;
         }
         if (value === 'CandidateSelectionCancelled' && !cancellationReason) {
            newArr[index]['onArrivalSteps']['cancellationReasonError'] = true
            setOnboardingData(newArr);
            return;
         }
      }

      setModal(true);
   }

   //handle Confirmation ok click event
   const handleConfirmationOk = async (e) => {
      setModal(false);
      const newArr = [...onboardingData];
      const { candidate_id, beforeArrivalSteps, onArrivalSteps } = newArr[index];
      // define data object for api
      const data = {
         cndId: candidate_id,
         EmpId: user.EmpId,
         beforeArrivalSteps: beforeArrivalSteps,
         onArrivalSteps: onArrivalSteps,
      }
      // send data to save status
      await saveStatus(data);
      // set isLoading true
      newArr[index][`isLoading${step}`] = true;
      setOnboardingData(newArr);

      setTimeout(() => {
         // set isLoading false
         newArr[index][`isLoading${step}`] = false;
         setOnboardingData(newArr);
         // run getOnboardingData for updated data
         getOnboardingData();
      }, 1000);
   }

   //View Document Functionality
   const [show, setShow] = useState({
      viewMore: false,
   });

   const [documentModal, setdocumentModal] = useState({
      show: false,
      file: ''
   })
   const [details, setDetails] = useState([])
   const handleViewDetails = async (i) => {


      const list = [...onboardingData];
      list[i]["candidateDetails"][0].photoPreviewUrl = `${getBaseUrl}/candidates/getMediaFileFromDb/${list[i]["candidateDetails"][0].photo.data}`;
      const dob = new Date(list[i]["candidateDetails"][0].birthday?.data).toLocaleString('en-GB', {
         year: 'numeric', month: 'short', day: '2-digit',
      }).replace(/ /g, '-')
      list[i]["candidateDetails"][0].dob = dob;


      // console.log(list[i]);
      setDetails(list[i]["candidateDetails"]);
      setShow({ ...show, viewMore: true })

   }
   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };
   const handleClose = (modal) => {
      console.log(modal);
      if (modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: false });
      } else {
         setShow({ ...show, helper: false });
      }
      setIsFileExists(false);
      setHelperData({ letter: false, refLetter: '' });
   }

   //Handle View Documents
   const [pageNum, setPageNum] = useState(1);
   const [totalPages, setTotalPages] = useState(0);
   const [pageDetails, setPageDetails] = useState(null);

   //pdf -----------------------------------------------
   const pdfPrevPage = () => {
      setPageNum(pageNum - 1)
   }
   const pdfNextPage = () => {
      setPageNum(pageNum + 1)
   }

   const viewDocuments = async (fileName) => {
      try {
         console.log(fileName);
         setdocumentModal({ ...documentModal, show: true, file: `${getBaseUrl}/candidates/getMediaFileFromDb/${fileName}` })
      }
      catch (error) {
         console.log(error);
      }

   }
   const downloadDocuments = async () => {
      try {
         if (user !== null) {
            const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${documentModal.file.split('/').pop()}`, {
               responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
            });

            // Create a blob from the file data and download it
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Extract the file name from the URL
            const originalFileName = documentModal.file.split('/').pop().split('?')[0];
            // Get the file extension
            const extension = originalFileName.split('.').pop();
            let fileName = "";
            if (originalFileName.toLowerCase().includes("passport")) {
               fileName = `Passport.${extension}`;
            } else if (originalFileName.toLowerCase().includes("edu")) {
               fileName = `Educational_Ceritificate.${extension}`;
            } else if (originalFileName.toLowerCase().includes("medrep")) {
               fileName = `Medical_Reports.${extension}`
            } else { }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Set the filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up
         }
      } catch (error) {
         console.log(error);

      }
   }

   const printFile = async () => {
      const baseUrl = `${getBaseUrl}`.replace(/\/api/g, '');
      const fileUrl = `${documentModal.file}`; // Assume this is the URL to your file

      // Get the file extension to determine the type of file
      const fileExtension = fileUrl.split('.').pop().toLowerCase();

      // Open the file in a new tab
      const newTab = window.open(fileUrl, '_blank');

      // Handle different file types for printing
      newTab.onload = () => {
         if (['pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp', 'txt'].includes(fileExtension)) {
            // These types can be rendered in the browser and printed
            newTab.print();
         } else {
            // For unsupported types (e.g., Word, Excel), we notify the user that the file can't be printed directly
            alert('This file type cannot be printed directly in the browser. Please download and print it manually.');
         }
      };
   };

   // Function to determine file type based on file extension
   const getFileType = (url) => {
      const extension = url.split('.').pop().toLowerCase();
      if (['pdf'].includes(extension)) {
         return 'pdf';
      } else if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
         return 'image';
      } else if (['txt'].includes(extension)) {
         return 'text';
      } else {
         return 'unknown';
      }
   };

   const handleShow = async (modal, data = null, refLetter = null) => {
      console.log(modal)
      if (Modal === 'viewMoreDetailsModal') {
         setShow({ ...show, viewMore: true })
      } else {
         setShow({ ...show, helper: true })
         if (data === 'letter') {
            const fileStatus = await checkFileExists(refLetter);
            console.log('this is file status', refLetter);
            setIsFileExists(fileStatus);
            let imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${refLetter}`;
            setHelperData({ letter: true, refLetter: imgView });
         }
      }
   }

   const checkFileExists = async (refLetter) => {
      try {
         const response = await fetch(refLetter);
         if (response.ok) {
            return true;
         } else {
            return false;
         }
      } catch (error) {
         console.error('Error checking document URL:', error);
         return false;
      }
   };

   // handle Zoom Effect 
   const handleZoomIn = () => {
      const docViewerImage = document.getElementById('image-img');
      docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth - 20)}px`;
   }

   let count = 1;
   const handleZoomOut = () => {
      const docViewerImage = document.getElementById('image-img');
      if (count <= 5) docViewerImage.style.minWidth = `${(docViewerImage.offsetWidth + 20)}px`; count++;
   }

  

   const fileType = getFileType(documentModal.file);

   return (
      <div className='selectedCandidate-costicky-topnatiner'>
         {
            (verificationCount === null || verificationCount === undefined) &&
            <div className='Banner'>
            <p>You need to complete Personal Details section 1<sup>st</sup> to Start. <span onClick={(() => navigate('/candidate/profile/personal-details'))}>Click Here</span> to goto Personals Deatils.</p>
          </div>
         }
         {
            (verificationCount === 0) &&
            <div className='Banner'>
              <p>Personal details are pending for approval. Please contact Admin at <a href={`mailto:support@helperwish.com`} style={{ color: 'white' }}>support@helperwish.com</a> for any questions</p>
            </div>
         }

         <div className='selectedCandidate-wrapper py-5'>
            <Container>
               <Col sm={12}>
                  <div className="text-center pb-5">
                     <div className="form-check form-check-inline mb-3 mb-sm-0">
                        <input className=" d-none" type="radio" name="in-process" id="currentin-process" value="currentin-process"
                           checked={(dataType === 'currentin-process') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-pink" htmlFor="currentin-process">In-Process</label>
                     </div>
                     <div className="form-check form-check-inline">
                        <input className="d-none" type="radio" name="Completed" id="Completed" value="Completed"
                           checked={(dataType === 'Completed') ? true : false} onChange={(e) => setDataType(e.target.value)} />
                        <label className="form-check-label btn btn-outline-pink" htmlFor="Completed">Completed</label>
                     </div>
                  </div>
               </Col>
               <div className='onboarding-list'>
                  <div className="table-responsive onboarding-list-table">
                     {
                        (dataType === 'currentin-process') &&
                        <CndInProcess data={onboardingData} isLoading={isLoading}/>
                     }
                     {
                        (dataType === 'Completed') &&
                        // <Table className="table table-striped m-0 tb ">
                        //    <Thead className='table-dark text-center'>
                        //       <Tr key={'A'}>
                        //          <Th className='py-3 hash' scope="col">#</Th>
                        //          <Th></Th>
                        //          <Th className='py-3' scope="col">Employer</Th>
                        //          <Th className='py-3' scope="col">Selected for Onboarding on</Th>
                        //          <Th className='py-3' scope="col">Status</Th>
                        //       </Tr>
                        //    </Thead>
                        //    <Tbody className="text-center">
                        //       {
                        //          onboardingHistoryData?.map((obdata, index) => {
                        //             return (<>
                        //                <Tr key={index}>
                        //                   <Td className='srNo'>{index + 1}</Td>
                        //                   <Td className='profile-wrapper'>
                        //                      <div className="profile-picture">
                        //                         {obdata.empDetails[0]?.name.charAt(0)?.toUpperCase()}
                        //                      </div>
                        //                   </Td>
                        //                   <Td> {obdata.empDetails[0]?.name.length > 20 ? `${obdata.empDetails[0]?.name.slice(0, 20)}...` : obdata.empDetails[0]?.name}</Td>
                        //                   <Td>{
                        //                      new Date(obdata.onboarding_date_time).toLocaleString('en-GB', {
                        //                         year: 'numeric', month: '2-digit', day: '2-digit',
                        //                      })
                        //                   }</Td>
                        //                   <Td>{getStatusMessage(obdata.status.step1, obdata.step1Reason, obdata.status.step2, obdata.step2Reason)}
                        //                   </Td>
                        //                </Tr>
                        //             </>)
                        //          })
                        //       }

                        //       {
                        //          (onboardingHistoryData.length === 0) && <Tr>
                        //             <Th colSpan='6'>{isLoading ? 'Loading...' : 'Record Not Found.'}</Th>
                        //          </Tr>
                        //       }

                        //    </Tbody>
                        // </Table>
                        <CndCompleted data={onboardingHistoryData} isLoading={isLoading} />
                     }
                  </div>
               </div>
            </Container>
         </div>

         {/* confirmation modal  */}
         <Modal show={modal} onHide={() => setModal(false)}
            id='selectCandidateModal'>
            <Modal.Body>
               <h5 className='text-center'>Are you sure ?</h5>
            </Modal.Body>
            <Modal.Footer className='flex-wrap justify-content-center'>
               <div className="">
                  <button className='btn btn-secondary mx-1' onClick={() => setModal(false)}>
                     Cancel
                  </button>
                  <button className='btn btn-primary mx-1' type="button" onClick={handleConfirmationOk} >
                     YES
                  </button>
               </div>
            </Modal.Footer>
         </Modal>

         {/* viewMoreDetailsModal */}
         <Modal size='lg' show={show.viewMore} onHide={() => handleClose('viewMoreDetailsModal')} backdrop='static' id='viewMoreDetailsModal'>
            {details.map((details, index) => (
               <Modal.Body key={index} className='p-0'>
                  <div className='position-relative'>
                     <AiOutlineClose onClick={() => handleClose('viewMoreDetailsModal')}
                        className='icon close-icon position-absolute' />
                  </div>
                  <div className='candidate-details pb-5'>
                     <div className='description-1 d-lg-flex '>
                        <div className='profile-wrapper d-block'>
                           <img src={details?.photoPreviewUrl} onError={imageOnError}
                              alt='profile' className='profile cursor-pointer' />
                        </div>
                        <div className='flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1'>
                           <p className='name text-center text-lg-start '>{details?.name.data}</p>

                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Employment Type :</span>
                                 <span className='text-nowrap'>{details?.employmentType?.data}</span>
                              </p>
                           </div>
                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Address :</span>
                                 <span className='text-nowrap'>{details?.address?.data}</span>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>

                     </div>
                     <div className='description-2 px-3'>
                        <p className='nationality'>Nationality : <span>{details?.nationality.data}</span></p>
                        <p>Date Of Birth: <span>{details?.dob}</span></p>
                        <p className='maritalStatus'>Marital Status: <span>{details?.maritalStatus.data}</span></p>
                        <p>No. of kids : <span>{details?.childAge?.data.length || "--"}</span> </p>
                     </div>
                     <br />
                     <div className='skills mb-2'>
                        <h3 className='px-3'>View/Download Documents:</h3>
                        <div className="row justify-content-center">
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.passport.data)}>View Passport</button>
                           </div>
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.educationCertificates.data)}>View Educational Certificate</button>
                           </div>
                           <div className="col-auto">
                              <button className="btn btn-primary" onClick={() => viewDocuments(details.medicalReport.data)}>View Medical Reports</button>
                           </div>
                        </div>
                     </div>
                     <br />
                     <div className='workDetails '>
                        <h3 className='px-3'>Work Details:</h3>
                        {(details?.workExperience?.data.length > 0) ?
                           <div className='table-responsive px-3'>
                              <Table className='table table-bordered mt-3'>
                                 <Thead className='table-dark text-center'>
                                    <Tr>
                                       <Th>Period</Th>
                                       <Th>Country</Th>
                                       <Th>Duties</Th>
                                       <Th className='text-nowrap'>Any Ref.Letter</Th>
                                    </Tr>
                                 </Thead>
                                 <Tbody className='text-center'>
                                    {
                                       details?.workExperience?.data.map((workExperience, index) => (
                                          <Tr key={index}>
                                             <Td className='period tbl-td '>
                                                <span className='text-nowrap'>
                                                   {
                                                      new Date(workExperience.from).toLocaleString('en-GB', {
                                                         year: 'numeric', month: 'short'
                                                      }).replace(/ /g, '-')
                                                   }
                                                </span>
                                                <span className='mx-2 text-nowrap'>to</span>
                                                <span className='text-nowrap'>
                                                   {
                                                      (workExperience.present) ? 'Present' : new Date(workExperience.to).toLocaleString('en-GB', {
                                                         year: 'numeric', month: 'short'
                                                      }).replace(/ /g, '-')
                                                   }
                                                </span>
                                             </Td>
                                             <Td className='tbl-td'>{workExperience.country || '-'}</Td>
                                             <Td>{workExperience.duties.toString() || '-'}</Td>
                                             <Td className='tbl-td'>
                                                {
                                                   (workExperience?.refLetter) ?
                                                      <>
                                                         <span className='eye-icon mx-2 cursor-pointer' onClick={() => handleShow('helperModal', 'letter', `${workExperience?.refLetter}`)}>
                                                            <TfiEye />
                                                         </span>
                                                      </>
                                                      : 'No'
                                                }

                                             </Td>
                                          </Tr>
                                       ))
                                    }
                                 </Tbody>
                              </Table>
                           </div> : "Nil"
                        }
                     </div>
                  </div>
               </Modal.Body>
            ))}
         </Modal>
         {/* view Document file  */}
         <Modal
            size="lg"
            show={documentModal.show}
            onHide={() => setdocumentModal({ ...documentModal, show: false })}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => setdocumentModal({ ...documentModal, show: false })}
                     className='icon close-icon-blue position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               <div className='d-flex justify-content-center'>
                  <div className='icon mx-1 mx-sm-3' onClick={downloadDocuments}>
                     <img src="/images/download.png" alt="download" className='download' />
                  </div>
                  <div className='icon mx-1 mx-sm-3' onClick={printFile}>
                     <img src="/images/printer.png" alt="printer" className='printer' />
                  </div>
               </div>
               <div className="p-4">
                  {fileType === 'pdf' && (
                     <>
                        <Document
                           file={documentModal.file}
                           onLoadSuccess={(data) => {
                              setTotalPages(data.numPages);
                           }}
                        >
                           <Page
                              renderTextLayer={false}
                              renderAnnotationLayer={false}
                              pageNumber={pageNum}
                              className='w-100 border'
                           />
                        </Document>
                        {totalPages > 1 && (
                           <div className="page-controls d-flex justify-content-center align-items-center mt-2">
                              <button type="button" className='btn btn-primary' onClick={pdfPrevPage}
                                 disabled={pageNum === 1}>‹</button>
                              <span className='mx-2'>{pageNum} of {totalPages}</span>
                              <button type="button" className='btn btn-primary' onClick={pdfNextPage}
                                 disabled={pageNum === totalPages}>›</button>
                           </div>
                        )}
                     </>
                  )}
                  {fileType === 'image' && (
                     <div className="text-center">
                        <img src={documentModal.file} alt="Document" style={{ maxWidth: '100%' }} />
                     </div>
                  )}
                  {fileType === 'text' && (
                     <div className="text-content">
                        <iframe
                           src={documentModal.file}
                           title="Text Document"
                           style={{ width: '100%', height: '80vh', border: 'none' }}
                        />
                     </div>
                  )}

                  {fileType === 'unknown' && <p>Cannot display this file type</p>}
               </div>
            </Modal.Body>
         </Modal>

         {/* helpermodal for video and ref.letter   */}
         <Modal show={show.helper} onHide={() => handleClose('helperModal')} backdrop='static' id='helperModal'
            aria-labelledby='contained-modal-title-vcenter' centered dialogClassName={`w-min-content ${(helperData?.refLetter?.includes('pdf')) ? 'pdf' : ''}`}
         >
            <Modal.Body className='p-0 justify-content-end min-h-100'>
               <div className='position-relative'>
                  <AiOutlineClose onClick={() => handleClose('helperModal')}
                     className='icon close-icon position-absolute' />
               </div>
               <div>
                  {(helperData?.letter && (!helperData?.refLetter?.includes('pdf'))) &&
                     <div className='d-flex justify-content-center gap-10 p-3'>
                        <button className='btn-zoom zoon-in' onClick={handleZoomIn}><FiZoomOut /> </button>
                        <button className='btn-zoom zoom-out' onClick={handleZoomOut}><FiZoomIn /></button>
                     </div>
                  }
                  {(helperData?.letter) && <DocViewer
                     className="doc-viewer"
                     documents={[{ uri: helperData?.refLetter, fileName: "Reference_letter", }]}
                     pluginRenderers={DocViewerRenderers}
                     config={{
                        header: {
                           disableHeader: true,
                           disableFileName: true,
                           retainURLParams: true,
                        }
                     }} />
                  }
                  {/*((!isFileExists) && (helperData?.letter)) && <p className='text-center p-3'>Error: Failed to load the document.</p>*/}
               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
}

export default Selected;