import React, { useState, useEffect, useContext } from 'react'
import { Container, Nav, Navbar,Button  } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsFillBellFill } from 'react-icons/bs';
import { FaAngleDown } from "react-icons/fa";
import getBaseUrl from "../../hooks/http";
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from '../../hooks/useAuthContext';
import { CountContext } from '../../context/CountContext';
import Badge from '@mui/material/Badge';
import './candidateNavbar.css';


const CandidateNavbar = () => {
   const { logout } = useLogout()
   const { user } = useAuthContext()
   const { interviewCount, offerCount, selectedCandidate } = useContext(CountContext);
   const [candidate, seCandidate] = useState({
      name: '',
      photo: '/uploads/userIcon.png'
   })

   const location = useLocation();
   const [isNavbarHidden, setIsNavbarHidden] = useState(false);
   const [isContractSign, setIsContractSign] = useState(false);





   useEffect(() => {
      if (user) {
         const UserName = user?.name || '';
         const UserPhoto = `${getBaseUrl}/candidates/getMediaFileFromDb/${user?.photo?.data}` || '/uploads/userIcon.png';

         seCandidate({ ...candidate, name: UserName, photo: UserPhoto })
      }
      if (user?.Is_AgreementSign == "No" || location.pathname === '/candidate/profile/CndAgreement' || location.pathname === '/candidate/profile/CndAgreementSing') {
         setIsNavbarHidden(true);
         setIsContractSign(true);
      } else {
         setIsNavbarHidden(false);
         setIsContractSign(false);
      }
      //setIsNavbarHidden(location.pathname === '/candidate/profile/CndAgreement' || location.pathname === '/candidate/profile/CndAgreementSing');

   }, [user, location.pathname])

   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };


   return (
      <header className='candidate_profile-navbar sticky-top'>
         <Navbar collapseOnSelect expand="xl" bg="" variant="dark">
            <Container fluid className="px-lg-5">
               <Link to={isContractSign ? 'CndAgreement' : 'interview'} className='text-decoration-none text-white navbar-brand'>
                  <img src="/images/HelperWish_Logo.svg" className="Helperwishlogo" alt="Logo" />
                  <img src="/images/HelperWish_CompnayName.svg" className="HelperwishlogoText" alt="Company Name" />
               </Link>

               <Navbar.Toggle aria-controls="responsive-navbar-nav" className='text-white' />

               <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className={`m-auto ${isNavbarHidden ? 'hidden-navbar' : ''}`}>
                     <NavLink to='interview' className='mx-3 nav-link'>
                        {interviewCount > 0 ? (
                           <Badge badgeContent={interviewCount}>
                              <span className="nav-text">Interviews</span>
                           </Badge>
                        ) : <Badge badgeContent={0}>
                           <span className="nav-text">Interviews</span>
                        </Badge>}
                     </NavLink>
                     <NavLink to='offers' className='mx-3 nav-link'>
                        {offerCount > 0 ? (
                           <Badge badgeContent={offerCount}>
                              <span className="nav-text">Offers</span>
                           </Badge>
                        ) : <Badge badgeContent={0}>
                           <span className="nav-text">Offers</span>
                        </Badge>}
                     </NavLink>
                     <NavLink to='personal-details' className='text-white mx-3 nav-link'>
                        <Badge badgeContent={0}>
                           <span className="nav-text">Personal Details</span>
                        </Badge>
                     </NavLink>
                     <NavLink to='selected' className='mx-3 nav-link selectCand'>
                        {selectedCandidate > 0 ? (
                           <Badge badgeContent={selectedCandidate}>
                              <span className="nav-text">Selected</span>
                           </Badge>
                        ) : <Badge badgeContent={0}>
                           <span className="nav-text">Selected</span>
                        </Badge>}
                     </NavLink>
                  </Nav>
                  <Nav className='align-items-lg-center'>
                     <span className='ms-2 me-3 my-3'>
                        <BsFillBellFill color='#F04DA1' className='bell cnd-bell' />
                     </span>
                     <div className='mini-profile-wrapper ms-2'>
                        <img src={candidate?.photo} className='mini-profile-photo' alt="profile"
                           onError={imageOnError} />
                     </div>
                     <div className="dropdown cnd-dropdown ms-2">
                        <button className="dropbtn name">{candidate?.name.length > 10 ? `${candidate.name.slice(0, 10)}...` : candidate?.name} <FaAngleDown /></button>
                        <div className="dropdown-content">
                           <NavLink to='my-account' className={`dropdown-item ${isNavbarHidden ? 'd-none' : ''}`} >My Account</NavLink>
                           <NavLink to='change-password' className={`dropdown-item ${isNavbarHidden ? 'd-none' : ''}`}>Change Password</NavLink>
                        </div>
                     </div>
                  </Nav>
                  <br></br>
                  <Nav>
                  <button className='btn btn-outline-pink cursor-pointer' id="NavBarLogOut" onClick={() => logout()}>Logout</button>
                  </Nav>
               </Navbar.Collapse>

            </Container>
         </Navbar>
      </header>

   )
}

export default CandidateNavbar