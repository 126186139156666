import { useState } from "react";
import getBaseUrl from '../../../hooks/http.js'
import axios from "axios";



export const useVerifyCode = () => {

   const [error, setError] = useState(null)
   const [isLoading, setIsLoading] = useState(null)
   const [success, setSuccess] = useState(null)


   const register = async (phoneNumber, password, name) => {

      setSuccess(null)
      setError(null);
      setIsLoading(true)

      phoneNumber = phoneNumber.replace(/[+()]/g, "");

      try {
         const body = { phoneNumber, password, name };
         const res = await axios.post(`${getBaseUrl}/employer/register`, body);

         if (res.status === 200) {
            setSuccess(true)
         } else {
            setSuccess(false)
            setError(res.data.error)
         }

      } catch (error) {
         console.log(error);
         setError(error)
      } finally {
         setIsLoading(false)
      }
   }

   return { register, error, isLoading, success }
}