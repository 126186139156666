import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { TfiEye } from "react-icons/tfi";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import getBaseUrl from '../../../../hooks/http';
import WorkExpForVerifyData from './WorkExpForVerifyData'


const VerifyWorkExprienceList = ({ data, onDelete, deletedWorkExperience }) => {
    const [viewModal, setViewModal] = useState({
        show: false,
        url: '',
        type: ''
    })

    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
        show: false,
        id: "",
        tempId: ""
    })

    //handle doc img view model
    const handleImgView = (field) => {
        const validImageTypes = ['jpg', 'jpeg', 'png', 'webp'];
        const validOtherTypes = ['pdf'];
        let imgView;

        if (validImageTypes.includes(field)) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
        } else if (validOtherTypes.includes(field) || field.includes('.pdf')) {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'pdf' })
        } else {
            imgView = `${getBaseUrl}/candidates/getMediaFileFromDb/${field}`;
            setViewModal({ ...viewModal, show: true, url: imgView, type: 'image' })
        }
    }

    const handleDelete = (id, tempId) => {
        onDelete(id, tempId);
        setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, id: "" });
    }

    const handleShowDelete = (id, tempId) => {
        setDeleteConfirmationModal({ ...deleteConfirmationModal, show: true, tempId: tempId, id: id })
    }


    return (
        <div>
            {
                    (data.length > 0) &&
            <WorkExpForVerifyData data={data}  handleImgView={handleImgView} handleDelete={handleShowDelete} />
            }
            {/* viewModal   */}
            <Modal show={viewModal.show} onHide={() => setViewModal({ ...viewModal, show: false })}
                backdrop="static" id='viewModal' aria-labelledby="contained-modal-title-vcenter" centered scrollable dialogClassName="w-min-content"
            >
                <Modal.Header className='p-0 justify-content-end border-0'>
                    <div className="position-relative">
                        <AiOutlineClose onClick={() => setViewModal({ ...viewModal, show: false })}
                            className='icon close-icon position-absolute' />
                    </div>
                </Modal.Header>
                <Modal.Body className='p-0 min-h-100'>
                    <div>
                        {
                            (viewModal?.type === 'image') &&
                            <div className=''>
                                <DocViewer
                                    documents={[{ uri: viewModal?.url }]}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            (viewModal?.url?.type === 'application/pdf') &&
                            <div className='d-flex justify-content-center'>
                                <DocViewer
                                    documents={[viewModal?.url].map((file) => ({
                                        uri: window.URL.createObjectURL(file),
                                        fileName: file.name,
                                    }))}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            (typeof viewModal?.url === 'string' && viewModal?.url.includes('.pdf')) &&
                            <div className=''>
                                <DocViewer
                                    documents={[{ uri: viewModal.url }]}
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: true,
                                            retainURLParams: true,
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            {/*Delete confirmation Modal*/}
            <Modal backdrop="static" id='deleteModal' size="sm" show={deleteConfirmationModal.show} onHide={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}>
                <Modal.Header className='p-0 justify-content-end border-0'>
                    <div className="position-relative">
                        <AiOutlineClose onClick={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}
                            className='icon close-icon position-absolute' />
                    </div>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <p>Do you want to delete?</p>
                    <div className='d-flex justify-content-center gap-10 mt-4'>
                        <Button variant="outline-secondary" onClick={() => setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false, index: "" })}>
                            Cancel
                        </Button>&nbsp;&nbsp;
                        <Button variant="primary" type="button" onClick={() => handleDelete(deleteConfirmationModal?.id, deleteConfirmationModal?.tempId)}>
                            Yes
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default React.memo(VerifyWorkExprienceList)