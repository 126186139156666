import React, { useEffect, useState } from "react";
import axios from "axios";
import getBaseUrl from "../../../../hooks/http";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { Slider } from "../../../../components/slider/";
import CustomTable from "../../../../components/customTable/CustomTable";
import { formatedDate, renderName, renderNameInitial } from "../../../../utils/Helper";
import "./empHome.css";

const tableColumns = [
  { label: "", field: "nameInitial" },
  { label: "Candidate", field: "candidateName" },
  { label: "Interview on", field: "date" },
  { label: "Status", field: "status" },
];

const EmpHome = () => {
  const { user } = useAuthContext();
  const [interviews, setInterviews] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //fetch interviews data -------------------
  const getInterviews = async () => {
    setIsLoading(true);

    try {
      if (user !== null) {
        let config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const response = await axios.get(
          `${getBaseUrl}/interviews/employer/`,
          config
        );
        if (response.status === 200) {
          const data = await response.data.interviewData;

          if (data) {
            const interviewsData = data.map((dt) => {
              const InterDate = new Date(dt.Interview_Date_Time);
              const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              const newDateTime = InterDate.toLocaleString("en-US", {
                timeZone: `${timezone}`,
              });
              const Interview_Date_Time = newDateTime
                ? new Date(newDateTime)
                : "";
              return {
                id: dt?._id,
                date: Interview_Date_Time,
                reschedule: {
                  date: new Date(Interview_Date_Time),
                  isShow: false,
                },
                status: dt?.Interview_Status,
                EmpId: localStorage.getItem("empId"),
                candID: dt?.Candidate_UserID,
                candidate_id: localStorage.getItem("CandidateId"),
                empTempId: dt?.Employer_TempId,
                candidateName: dt?.candidateDetails[0]?.name.data ?? "Unknown",
              };
            });

            setInterviews(interviewsData);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getCandidates = async () => {
    setIsLoading(true);

    try {
      let config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const res = await axios.get(
        `${getBaseUrl}/employer/browse-candidates/?''`,
        config
      );

      if (res.status === 200) {
        const candidateData = res.data.personalDetail.map((cnd) => {
          const profilePhoto = cnd.photo?.data
            ? `${getBaseUrl}/candidates/getMediaFileFromDb/${cnd.photo?.data}`
            : "/uploads/userIcon.png";
          return {
            photo: profilePhoto,
            name: cnd.name?.data,
            age: cnd.age,
            availability: cnd.howSoonCanStart?.data,
            country: cnd.currentCountry?.data,
          };
        });

        setCandidates(candidateData);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getInterviews();
      getCandidates();
    }
  }, [user]);

  const convertRowDataToTableData = (data, index) => {
    return {
      ...data,
      nameInitial: renderNameInitial(data.candidateName),
      candidateName: renderName(data.candidateName),
      date: formatedDate(data.date),
    };
  };

  return (
    <>
      <div className="container">
        <div className="emp-home">
          <div className="carousel-wrapper box-shadow-bottom">
            <Slider candidates={candidates} isLoading={isLoading} />
          </div>

          <div className="current-interview py-4">
            <h3 className="text-center">Current Interviews in Progress</h3>
            <div className="row">
              <div className="col">
                <div className="table-responsive interview-list candidate_interview-list box">
                  <CustomTable
                    columns={tableColumns}
                    rows={interviews.map(convertRowDataToTableData)}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpHome;
