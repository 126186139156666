import React, { memo, useState } from "react";
import { TfiEye } from "react-icons/tfi";
import getBaseUrl from "../../hooks/http";
import './documentPreview.css'
import FileViewerModal from "./FileViewerModal";

const DocumentPreview = ({ file, fileName, viewDocument, cancelDocument }) => {
  const [fileViewer, setFileViewer] = useState(false);
  const [fileToView, setFileToView] = useState("");

  let src = "";

  if (typeof file === "string") {
    src = file.includes("pdf")
      ? "/images/pdf-icon.jpeg"
      : `${getBaseUrl}/candidates/getMediaFileFromDb/${file}`;
  } else {
    src =
      file.type === "application/pdf"
        ? "/images/pdf-icon.jpeg"
        : URL.createObjectURL(file);
  }


  //handle View File
  const handleViewFile = (file) => {
    setFileToView(file);
    setFileViewer(true);
  };

  return (
    <>
      {file && (
        <div className="d-flex flex-column align-items-center justify-content-end cursor-pointer doument__preview">
          <img src={src} alt="document" className="preview" />
          <p className="file__name text-blue">{fileName}</p>

          <span className="d-flex flex-column align-items-center text-icon-view" onClick={() => handleViewFile(file)}>
            <TfiEye className="icon-view" /> view
          </span>

        </div>
      )}

      {fileViewer && (
        <FileViewerModal
          isOpen={fileViewer}
          onClose={() => setFileViewer(false)}
          file={fileToView}
          isDownloadShow={true}
          fileName={fileName}
        />
      )}
    </>
  );
};

export default memo(DocumentPreview);
