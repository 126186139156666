import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal';
import OTPInput from "otp-input-react";
import { AiOutlineClose } from "react-icons/ai";
import getBaseUrl from '../../../../hooks/http'
import './momdeclaration.css'
import Datetime from 'react-datetime';
import { FaCalendar } from 'react-icons/fa';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { TfiEmail } from "react-icons/tfi";
import { useMailHelper } from '../../../../hooks/useMailHelper'
import { IoArrowBackOutline } from "react-icons/io5";
import generateOTP from '../../../../utils/GenerateOTP';
import CountrySelector from '../../../../components/selectWithSearch/selectSearch'
import Select from 'react-select';
import { validateEmail } from '../../../../utils/Helper';



const MOMDeclaration = () => {
   const navigate = useNavigate();
   const [activeButton, setActiveButton] = useState('YES');
   const [selectedDate, setSelectedDate] = useState(null);
   const [countries, setCountries] = useState([]);
   const { user, dispatch } = useAuthContext();
   const [momStep, setMomStep] = useState(1); // Track the current step
   const [emailStep, setEmailStep] = useState(1); // Track the current step
   const [email, setEmail] = useState(""); // Email input value
   const [otp, setOtp] = useState(""); // OTP input value
   const [fromError, setFromError] = useState(""); // Error messages
   const [error, setError] = useState(""); // Error messages
   const [yesError, setYesError] = useState(""); // Error messages


   const initialEmployerState = {
      id: '',
      fullname: '',
      email: '',
      ResidentialStatus: '',
      Citizenship: '',
      WorkerForSingapore: '',
   };

   const [employer, setEmployer] = useState(initialEmployerState);
   const [emailError, setEmailError] = useState(false);
   const { sendMail } = useMailHelper();

   const getEmployer = async (action = '') => {
      try {
         if (user !== null) {
            let config = {
               headers: {
                  Authorization: `Bearer ${user.token}`,
               },
            };
            const response = await axios.get(`${getBaseUrl}/employer/account`, config);
            const { _id, email } = response.data.details;
            const newEmail = (email) ? email : "";

            setEmployer({ ...employer, id: _id, email: newEmail })
         }
      } catch (error) {
         console.log(error);
      }
   }


   const getRestCountries = async () => {
      try {
         const res = await axios.get("https://restcountries.com/v3.1/all?fields=name,flags")
         const countryOptions = res.data.map((country) => ({
            value: country.name.common,
            label: (
               <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                     src={country.flags.svg}
                     alt={country.name.common}
                     style={{ width: "20px", height: "15px", marginRight: "10px" }}
                  />
                  {country.name.common}
               </div>
            ),
         }));

         const singapore = countryOptions.filter((c) => c.value === "Singapore");
         const otherCountries = countryOptions.filter((c) => c.value !== "Singapore").sort((a, b) => a.value.localeCompare(b.value));

         setCountries([...singapore, ...otherCountries]);
      } catch (error) {
         console.log(error);

      }
   }

   useEffect(() => {
      getRestCountries();

      if (user !== null) {
         getEmployer();
      }

   }, [user])

   const handleClick = (button) => {
      setActiveButton(button);
   };

   const ResidentialStatusArray = [
      { value: 'Singapore Citizen', label: 'Singapore Citizen' },
      { value: 'Permanent Residence', label: 'Permanent Residence' },
      { value: 'Foreigner', label: 'Foreigner' }
   ]
   const Citizenship = ['Singapore', 'Indonesia']
   const handleDateChange = (date) => {
      setSelectedDate(date);
      setError("");
   }


   const handleStep1Continue = () => {
      const newErrors = {};

      // Validate email
      if (!employer.email) {
         newErrors.email = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(employer.email)) {
         newErrors.email = 'Invalid email format';
      }

      // Validate name
      if (!employer.fullname) {
         newErrors.fullname = 'FullName is required';
      }

      //validate Residential 
      if (!employer.ResidentialStatus) {
         newErrors.ResidentialStatus = 'Residential Status is required';
      }

      if (!employer.Citizenship) {
         newErrors.Citizenship = 'Citizenship is required';
      }
      setFromError(newErrors);
      // Check if there are no errors
      if (Object.keys(newErrors).length === 0) {
         setError("");
         setYesError("");
         setMomStep(2);
         // Perform continue logic here
      }

   }

   const handleContinue = () => {
      if (employer.WorkerForSingapore === "" || employer.WorkerForSingapore === false) {
         setYesError("*Mandatory, then only can proceed");
         return;
      }
      if (activeButton === "YES") {
         if (!selectedDate) {
            setError("Date is required");
            return;
         }

         const now = new Date();
         const selected = new Date(selectedDate);
         if (selected > now) {
            setError("Date cannot be in the future.");
            return;
         }
      }


      // If no errors, store the data in the database
      const formData = {
         activeButton,
         selectedDate: selectedDate,
         fullname: employer.fullname,
         ResidentialStatus: employer.ResidentialStatus.value,
         Citizenship: employer.Citizenship.label.props.children[1],
         email: employer.email,
         WorkerForSingapore: employer.WorkerForSingapore
      };
      let config = {
         headers: {
            Authorization: `Bearer ${user.token}`,
         },
      };
      axios.post(`${getBaseUrl}/employer/momdeclaration`, formData, config)
         .then(async (res) => {
            if (res.status === 200) {
               navigate('/employer/profile/home')
               localStorage.setItem('First_time_Employer', res.data.First_time_Employer);
            }
         })
         .catch((error) => {
         })
   };


   const [modal, setmodal] = useState({
      show: false,
      changeField: '',
   });




   // modal for Email Sent-------------------------------
   const handleShow = (changeField) => {
      setmodal({ ...modal, show: true, changeField });

   }

   const handleNext = () => {
      if (!email) {
         setEmailError(true)
         return 0;
      }
      if (!validateEmail(email)) { setEmailError('Invalid email address'); return; }
      if (emailStep < 5) setEmailStep(emailStep + 1);
   };

   const handleClose = () => {
      // Reset all states and close modal
      setEmailStep(1);
      setEmail("");
      setOtp("");
      setmodal({ ...modal, show: false });
   };

   // handle otp send
   const handleSendClick = async () => {
      //get otp
      const newOtp = generateOTP(6);
      //store the otp to localstorage
      localStorage.setItem('otp', newOtp)
      //store the otp to localstorage
      const to_mail = email;
      await sendMail(to_mail, newOtp)
      setEmailStep(3)
   }



   //handle form submit event --------------
   const handleFormSubmit = async (e) => {
      e.preventDefault();
      const confirm_otp = localStorage.getItem("otp");

      if (otp === '') {
         setError('OTP is required')
      } else {
         if (confirm_otp === otp) {
            setEmailStep(4)
            updateAccount()
            setFromError({ ...fromError, email: "" });
         } else {
            setError('Invalid OTP')
            setOtp('')
         }
      }
   }

   const updateAccount = async () => {
      try {
         let config = {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         };
         const body = {
            email: email
         }
         const responace = await axios.post(`${getBaseUrl}/employer/update-account`, body, config);
         setEmployer({ ...employer, email: email })
         //  setEmailStep(4)
      } catch (error) {
         setError(error);
      }
   }


   const handleChange = (e) => {
      const { name, value } = e.target;
      setEmployer({ ...employer, [name]: value });
      setFromError({
         ...fromError,
         [name]: '', // Clear error on change
      });
   };

   const handleCitizenChange = (selectedOption) => {
      setEmployer({ ...employer, Citizenship: selectedOption })
      setFromError({ ...fromError, Citizenship: "" })
   };

   const handleResidentialChange = (selectedOption) => {
      setEmployer({ ...employer, ResidentialStatus: selectedOption });
      setFromError({ ...fromError, ResidentialStatus: "" })
   };


   const handleBack = () => {
      setSelectedDate(null);
      setMomStep(1);
      setEmployer({...employer,WorkerForSingapore:""});
   };

   /*  handle form field onchange event  ----------------------------------------------------*/
   const handleSingaporeWorker = (e) => {
      setEmployer({ ...employer, WorkerForSingapore: e.target.checked });
      setYesError('');
   }


   //Handle Back
   const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
   ]

   // ---------------- react-datetime utils -----------------------------
   const renderInput = (props, openCalendar) => {
      return (
         <div className='d-flex align-items-center'>
            <input {...props} onClick={openCalendar} className='flex-grow-1 border-0 p-0 h-100' />
            <FaCalendar onClick={openCalendar} style={{ cursor: 'pointer' }} />
         </div>
      );
   };
   // ----------------react-datetime End -----------------------------
   return (
      <div className='momDecl h-100 mt-5'>
         {momStep === 1 && (
            <div className='empInfo'>
               <h4 className='headingColor'>Employer Personal Information</h4>
               <br />
               <div className='mx-auto  col-10 col-sm-8 col-md-7' >
                  <div className={`floating-control requiredMark flex-grow-1 ${fromError.fullname ? 'invalid' : ''}`}>
                     <input type="text" name="fullname" id='fullname' className="floating-input"
                        placeholder="none" onChange={handleChange} value={employer.fullname} />
                     <label htmlFor="fullName" className="floating-label">Full Name</label>
                  </div>
                  <div className={`floating-control requiredMark flex-grow-1 mt-5  ${fromError.ResidentialStatus ? 'invalid' : ''}`}>
                     <div className="input-container flex-grow-1">
                        <Select className={`floating-input px-2`}
                           name="ResidentialStatus" id='ResidentialStatus' value={employer.ResidentialStatus} onChange={handleResidentialChange}
                           options={ResidentialStatusArray}
                           placeholder="Select"
                           styles={{
                              control: (base) => ({
                                 ...base,
                                 border: 'none',
                                 borderColor: "#ced4da",
                                 borderRadius: "5px",
                                 padding: "2px",
                                 textAlign: "left"
                              }),
                              menu: (base) => ({
                                 ...base,
                                 zIndex: 9999, // Ensure dropdown renders above other elements
                                 textAlign: "left"
                              }),
                           }}
                        />
                        <label htmlFor="ResidentialStatus" className="floating-label">Residential Status</label>
                     </div>
                  </div>
                  <CountrySelector
                     options={countries}
                     onChange={handleCitizenChange}
                     value={employer.Citizenship}
                     fromError={fromError}
                  />

                  <div className={`floating-control requiredMark flex-grow-1 mt-5 ${fromError.email ? 'invalid' : ''}`}>
                     <input type="text" name="email" id='email' className="floating-input"
                        placeholder="none" readOnly defaultValue={employer.email} onClick={(e) => {
                           // Only call handleShow if the input is empty
                           handleShow('email');
                        }} onChange={handleChange} />
                     <label htmlFor="email" className="floating-label">Email address</label>
                  </div>
               </div>
               <br />
               <div className='d-flex flex-wrap justify-content-center gap-3 px-2'>
                  <button type="button" onClick={() => handleStep1Continue()} className="btn continueBtn">
                     Continue
                  </button>
                  <button className='btn cancelBtn btn-secondary' onClick={() => {
                     setMomStep(1);
                     setEmployer(initialEmployerState);
                  }}>
                     Cancel
                  </button>
               </div>
               <div className='d-flex justify-content-left' style={{ 'marginTop': '-30px' }}>
                  <span className='text-secondary h6'>1/2</span>
               </div>
            </div>)}
         {/* step two form ----------------------------------------------------------------------------- */}
         {momStep === 2 && (
            <div className='instruction'>
               <h4 className='headingColor'>Employer Personal Information</h4>
               <div className='d-flex align-items-center justify-content-center mt-3'>
                  <h5 className='mt-1'>You are hiring Foreign Domestic Worker for Singapore:</h5>
                  <div className="wrapper__checkbox" style={{ marginLeft: '24px' }}>
                     <label htmlFor="" className="checkbox  requiredMark">
                        <input className={`checkbox__input `} type="checkbox" name="WorkerForSingapore" value='yes' id="WorkerForSingapore" onChange={handleSingaporeWorker} checked={employer.WorkerForSingapore} />
                        <svg className="checkbox__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                           <rect width="23" height="23" x=".5" y=".5" fill="#FFF" stroke={`${(yesError) ? '#FF0000' : '#006F94'}`} rx="3" />
                           <path className="tick" stroke="#005EAB" fill="none" strokeLinecap="round" strokeWidth="6" d="M4 10l5 5 9-9" />
                        </svg>
                        <span className="checkbox__label">YES</span>
                     </label>
                  </div>
               </div>
               <br />
               <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 gap-sm-5">
                  <h5 className='m-0'>Are you 1st time employing Foreign Domestic Worker?</h5>
                  <div className="requiredMark gap-5">
                     <div className="toggle-container">
                        {/*slider box */}
                        <div className="slider" style={{ left: activeButton === "YES" ? "0" : "50%", }} />

                        {/* Yes button */}
                        <div className={`option ${activeButton === "YES" ? "active" : ""}`} onClick={() => handleClick("YES")} >
                           YES
                        </div>

                        {/* No button */}
                        <div className={`option ${activeButton === "NO" ? "active" : ""}`} onClick={() => handleClick("NO")}>
                           NO
                        </div>
                     </div>
                  </div>
               </div>
               <br />
               <p className="h6">
                  If you have not hired any Foreign Domestic Worker on your NRIC before then you MUST attend the three-hour
                  Employer Orientation Programme with Ministry of Manpower (MOM)
               </p>
               <p className="h6">Please visit <a href='https://www.mom.gov.sg/passes-and-permits/work-permit-for-foreign-domestic-worker/eligibility-and-requirements/employers-orientation-programme-eop' target="_blank" >https://www.mom.gov.sg/passes-and-permits/work-permit-for-foreign-domestic-worker/eligibility-and-requirements/employers-orientation-programme-eop</a></p>
               <br />

               {
                  (activeButton === "YES") &&
                  <div className='d-flex flex-column flex-sm-row gap-2 gap-sm-5'>
                     <h6 className='m-0 mt-3'>Date of Completion of Employer Orientation Programme with MOM </h6>

                     <div className={`col-9 col-sm-auto mx-auto p-3 rounded requiredMark ${error ? 'invalid' : 'border border-secondary'}`} >
                        <Datetime
                           dateFormat="DD/MM/YYYY"
                           timeFormat={false}
                           onChange={(date) => handleDateChange(date)}
                           inputProps={{ placeholder: "Select" }}
                           closeOnSelect={true} l
                           renderInput={renderInput}
                           isValidDate={(current) => current.isBefore(new Date())}
                        // Set the today as maximum date
                        />
                     </div>
                  </div>
               }

               <br />

               <div className='d-flex flex-wrap justify-content-center gap-3 px-2'>
                  <button type="button" onClick={() => handleContinue()} className="btn continueBtn">
                     Save
                  </button>
                  <button className='btn cancelBtn btn-secondary' onClick={() => {
                     setMomStep(1);
                     setEmployer(initialEmployerState);
                  }}>
                     Cancel
                  </button>
               </div>
               <div className='d-flex justify-content-left' style={{ 'marginTop': '-30px' }}>
                  <span className='text-secondary h6'>2/2</span>
               </div>
               <br />
               {error && <span className="text-danger mt-2">{error}<br /></span>}
               {yesError && <span className="text-danger mt-2">{yesError}</span>}
               <span className='btn-back d-flex align-items-center mt-3' style={{ width: '50px' }} onClick={handleBack}>
                  <IoArrowBackOutline /> Back
               </span>
            </div>)}

         {/* change name */}
         <Modal show={modal.show} onHide={() => handleClose()} backdrop="static" id="changeNameModal" centered>
            <Modal.Header className='p-0 justify-content-end border-0'>
               <div className="position-relative">
                  <AiOutlineClose onClick={() => handleClose()}
                     className='icon close-icon-blue position-absolute' />
               </div>
            </Modal.Header>
            <Modal.Body>
               {emailStep === 1 && (
                  <div className=''>
                     <h3 className='text-center mb-4'>Enter Your Email</h3>
                     <div className={`floating-control flex-grow-1 ${(emailError) ? 'invalid' : ''}`}>
                        <input type="text" name="email" id='email' className={`floating-input`}
                           placeholder="none" value={email}
                           required onChange={(e) => { setEmail(e.target.value); setEmailError(false) }} />
                        <label htmlFor="email" className="floating-label">Email</label>

                     </div>
                     {(setEmailError) && <div className="flex-grow-1 text-center text-danger">{emailError}</div>}
                     <div className='d-flex flex-wrap justify-content-evenly mt-4'>
                        <button
                           className="btn btn-primary" id="NextBtn"
                           onClick={handleNext}
                        // Disable button if email is empty
                        >
                           Next
                        </button>
                     </div>
                  </div>
               )}
               {/* Step 2: Email Sent */}
               {emailStep === 2 && (
                  <div>
                     <p className="message text-center">
                        One time Password is sent by Email to
                        <br />
                        <TfiEmail color="#25D366" /> {email}
                     </p>
                     <div className="d-flex flex-wrap justify-content-evenly mt-5">
                        <button className="btn btn-outline-primary mb-3" onClick={() => setEmailStep(1)}>
                           CANCEL
                        </button>
                        <button className="btn btn-primary mb-3" onClick={handleSendClick}>SEND</button>
                     </div>
                  </div>
               )}

               {/* Step 2: Email Otp Verification */}
               {emailStep === 3 && (
                  <form onSubmit={handleFormSubmit}>
                     <h3 className='title mb-4 text-center'>Enter verification code</h3>
                     {
                        <p className="message text-center">
                           Your verification code is sent by Email to
                           <br />
                           <TfiEmail color="#25D366" /> {email}
                        </p>

                     }
                     <div className="d-flex justify-content-center my-5 otp-mom-wrapper h4">
                        <OTPInput value={otp} onChange={setOtp} autoFocus="true" OTPLength={6}
                           otpType="number" disabled={false} style={{ border: 'none' }} />
                     </div>
                     <div className='text-center'>
                        <button className='btn btn-primary mx-2' >Verify</button>
                     </div>
                     {error && <p className='text-danger text-center'> {error}</p>}
                     {/* Step 2: Email Otp Verification */}

                  </form>
               )}
               {emailStep === 4 && (
                  <div>
                     <h5 className='text-center'>Email verified and updated successfully</h5>
                     <div className='d-flex flex-wrap justify-content-center mt-4'>
                        <button className="btn btn-primary m-1"
                           onClick={() => handleClose()}>OK</button>
                     </div>
                  </div>
               )}
            </Modal.Body>
         </Modal>
      </div>

   )
}

export default MOMDeclaration