import React from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { TfiEye } from 'react-icons/tfi';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import DocumentPreview from '../../personalDetails/DocumentPreview';
import { capitalizeFirstLetter } from "../../../utils/Helper";
import WorkExperience from "../workExprience/workExperience";


const ViewMoreDetailsModal = ({ show, onClose, details, imageOnError, viewDocuments, handleShow }) => {


   return (
      <Modal size='lg' show={show} onHide={onclose} backdrop='static' id='viewMoreDetailsModal'>

         <Modal.Body className='p-0 '>
            {details.map((details, index) => (
               <section key={index}>
                  <div className='position-relative'>
                     <AiOutlineClose onClick={onClose}
                        className='icon close-icon position-absolute' />
                  </div>
                  <div className='candidate-details pb-5'>
                     <div className='description-1 d-lg-flex '>
                        <div className='profile-wrapper d-block mx-auto'>
                           <img src={details?.photoPreviewUrl} onError={imageOnError}
                              alt='profile' className='profile cursor-pointer' />
                        </div>
                        <div className='flex-grow-1 d-flex flex-column justify-content-between mt-2 mt-lg-0 p-1'>
                           <p className='name text-center text-lg-start '>{details?.name.data}</p>
                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Type :</span>
                                 <span className='text-nowrap'>{details?.typeOfCandidate?.data}</span>
                              </p>
                           </div>
                           <div className='d-lg-flex'>
                              <p className='dob me-lg-5 text-center text-lg-start'>
                                 <span className='text-nowrap' > Address :</span>
                                 <span className='text-nowrap'>{details?.address?.data}</span>
                              </p>
                           </div>
                        </div>
                     </div>
                     <div>

                     </div>
                     <div className='description-2 px-3'>
                        <p className='nationality'>Nationality : <span>{details?.nationality.data}</span></p>
                        <p>Date Of Birth: <span>{details?.dob}</span></p>
                        <p className='maritalStatus'>Marital Status: <span>{capitalizeFirstLetter(details?.maritalStatus.data)}</span></p>
                        <p>No. of kids : <span>{details?.childAge?.data.length || "--"}</span> </p>
                     </div>
                     <br />
                     <div className='skills mb-2'>
                        <h3 className='px-3'>View/Download Documents:</h3>
                        <div className="d-flex flex-wrap document__wrapper">
                           <div className="col-12 col-sm-6 col-xl-4 passport">
                              <p className='justify-content-center mb-2'>Passport</p>
                              <div className='ps-3 d-flex flex-wrap justify-content-center gap-4'>
                                 {
                                    details.passport.data.map((ppFile, index) => (
                                       <DocumentPreview key={'pp' + index} file={ppFile} fileName={'Passport' + (index + 1)} viewDocument={viewDocuments} />
                                    ))
                                 }
                              </div>
                           </div>
                           <div className="col-12 col-sm-6 col-xl-4 text-center educationCertificates">
                              <p className='justify-content-center mb-2'>Educational Certicates</p>
                              <div className='ps-3 d-flex flex-wrap justify-content-center gap-4'>
                                 {
                                    details.educationCertificates.data.map((eFile, index) => (
                                       <DocumentPreview key={'pp' + index} file={eFile} fileName={'Edu' + (index + 1)} viewDocument={viewDocuments} />
                                    ))
                                 }
                              </div>
                           </div>
                           <div className="col-12 col-sm-6 col-xl-4 medicalReport">
                              <p className='justify-content-center mb-2'>Medical Reports</p>
                              <div className='ps-3 d-flex flex-wrap justify-content-center gap-4'>
                                 {
                                    details.medicalReport.data.map((mrFile, index) => (
                                       <DocumentPreview key={'mr' + index} file={mrFile} fileName={'Medical' + (index + 1)} viewDocument={viewDocuments} />
                                    ))
                                 }
                              </div>
                           </div>
                        </div>
                     </div>
                     <br />
                     <div className='workDetails '>
                        <h3 className='px-3'>Work Details:</h3>
                        {(details?.workExperience?.data.length > 0) ?
                           <div className='table-responsive px-3'>
                              <WorkExperience data={details?.workExperience?.data} handleShow={handleShow}/>
                           </div> : "Nil"
                        }
                     </div>
                  </div>
               </section>
            ))}
         </Modal.Body>

      </Modal>
   )
}

export default React.memo(ViewMoreDetailsModal)