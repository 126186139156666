import React from "react";
import { Modal, Table } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const EducationCertificateListModal = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal
        size="lg"
        show={isOpen}
        onHide={onClose}
        backdrop="static"
        id="documentModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="p-0 justify-content-end border-0">
          <div className="position-relative">
            <AiOutlineClose
              onClick={onClose}
              className="icon close-icon position-absolute"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Table responsive bordered>
            <thead className="bg-blue">
              <tr>
                <td>Country</td>
                <td>Name Of Education Certificate</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Bangladesh</td>
                <td>
                  Secondary School Certificate (SSC) <br />
                  Higher Secondary Certificate (HSC), also called Intermediate
                </td>
              </tr>
              <tr>
                <td>India</td>
                <td>
                  Matriculation Certificate
                  <br />
                  Secondary School Certificate
                  <br />
                  All India Secondary School Certificate
                  <br />
                  Indian Certificate of Education
                  <br />
                  Indian School Certificate (ISC)
                  <br />
                  Intermediate Certificate
                  <br />
                  Higher school Certificate
                  <br />
                  Higher Secondary Certificate
                  <br />
                  All India Senior School Certificate
                </td>
              </tr>
              <tr>
                <td>Indonesia</td>
                <td>
                  Surat Tanda Tamat Belajar Sekolah Menengah Umum Tingkat
                  Pertama (STTB SMP), also known as Junior Secondary School
                  Certificate of Completion
                  <br />
                  Surat Tanda Tamat Belajar Sekolah Lanjutan Tingkat Pertama
                  <br />
                  (STTB SLTP)
                  <br />
                  Surat Tanda Tamat Belajar Madrasah Tsanawiyah (STTB MTs)
                  <br />
                  Surat Tanda Tamat Belajar Sekolah Menengah Umum Tingkat Atas
                  (STTB SMA), also known as Senior Secondary School Certificate
                  of Completion
                  <br />
                  Surat Tanda Tamat Belajar Sekolah Menengah Umum (STTB SMU)
                  <br />
                  Surat Tanda Tamat Belajar Sekolah Menengah Kejuruan
                  <br />
                  (STTB SMK)
                  <br />
                  Surat Tanda Tamat Belajar Madrasah Aliyah (STTB MA)
                </td>
              </tr>
              <tr>
                <td>Malaysia</td>
                <td>
                  Penilaian Menengah Rendah (PMR), also known as Lower Secondary
                  <br />
                  Sijil Pelajaran Malaysia (SPM), also called the Malaysian
                  Certificate of Examination
                  <br />
                  Sijil Pelajaran Malaysia Vokesyenal (SPMV) also called the
                  Malaysian Vocational Education Certificate
                  <br />
                </td>
              </tr>
              <tr>
                <td>Myanmar</td>
                <td>
                  Basic Education High School Examination (Matriculation)
                  <br />
                </td>
              </tr>
              <tr>
                <td>The Philippines</td>
                <td>
                  High School Diploma
                  <br />
                </td>
              </tr>
              <tr>
                <td>Sri Lanka</td>
                <td>
                  Sri Lankan General Certificate of Education (Ordinary Level)
                  <br />
                </td>
              </tr>
              <tr>
                <td>Thailand</td>
                <td>
                  Maw 3 (M3) Examination
                  <br />
                  Maw 6 (M6) Examination
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(EducationCertificateListModal);
