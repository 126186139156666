import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { TfiEye } from 'react-icons/tfi'
import { formatedDate } from '../../../utils/Helper'

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Candidate', field: 'candidateName' },
    { label: 'Candidate Type', field: 'candidateType' },
    { label: 'Offer Process Ended on', field: 'date' },
    { label: 'Status', field: 'status' },
    { label: 'Remark', field: 'reasonForCancel' },
]

const EmpOfferHistoryList = ({ data, isLoading, handleHistoryOfferPdf }) => {



    

    const renderStatus = (status, index) => {
        return <>
            {status}

            <div className={`icon icon1 mx-1 mx-sm-3`} onClick={() => handleHistoryOfferPdf(index)}>
                <TfiEye className='fs-4' />
                <span className="text">View OFFER</span>
            </div>
        </>
    }

    const renderNameInitial = (name) => {
        return <div className="profile-picture">
            {name.charAt(0).toUpperCase()}
        </div>
    }

    const renderName = (name) => {
        return (name.length) ? `${name.slice(0, 20)}...` : name;
    }

    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.candidateName),
            candidateName: renderName(data.candidateName),
            date: formatedDate(data.date),
            status: renderStatus(data.status, index)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} isLoading={isLoading} />
    )
}

export default React.memo(EmpOfferHistoryList)