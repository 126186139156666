import React from "react";
import axios from 'axios'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import getBaseUrl from '../../hooks/http'
import { useAuthContext } from '../../hooks/useAuthContext'

const FileViewerModal = ({ isOpen, onClose, file,isDownloadShow=false,fileName}) => {
  const { user } = useAuthContext();
  const fileType = (typeof file === "object") ? file.type : "";
  console.log(fileName);


  let docs = "";
  if (typeof file === "object" && fileType === "application/pdf") {
    docs = [file].map((f) => ({
      uri: window.URL.createObjectURL(f),
      fileName: f.name,
    }))
  } else if (typeof file === "object") {
    docs = [
      {
        uri: window.URL.createObjectURL(file),
        fileName: file.name,
      },
    ];
  } else {
    docs = [
      {
        uri: `${getBaseUrl}/candidates/getMediaFileFromDb/${file}`,
      },
    ];
  }

   //download pdf --------------------------------------------
   const downloadPdf = async () => {
    try {
       if (user !== null) {
          const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${file.split('/').pop()}`, {
             responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
          });

          // Create a blob from the file data and download it
          const url = window.URL.createObjectURL(new Blob([response.data]));
          // Extract the file name from the URL
          const originalFileName = file.split('/').pop().split('?')[0];
          // Get the file extension
          const extension = originalFileName.split('.').pop();
          let fileNewName = `${fileName}.${extension}`;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileNewName); // Set the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up
       }

    } catch (error) {
       console.log(error);
    }
 }

 //print doc ------------------------------------------------
    const printPdf = async () => {
 
       const url = `${getBaseUrl}`.replace(/\/api/g, '')
       // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
      const pdfUrl = `http://localhost:3001/api/candidates/getMediaFileFromDb/${file}`;
       // Open the PDF in a new tab
       const newTab = window.open(pdfUrl, '_blank');
 
       // Wait for the PDF to load, then trigger the print dialog
       newTab.onload = () => {
          newTab.print();
       };
    }

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      backdrop="static"
      id="viewModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      dialogClassName={fileType === "application/pdf" ? "w-min-content" : ""}
    >
      <Modal.Header className="p-0 justify-content-end">
        <div className="position-relative">
          <AiOutlineClose
            onClick={onClose}
            className="icon close-icon position-absolute"
          />
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 min-h-100">
       {(isDownloadShow) && <div className='d-flex justify-content-center mt-3 mb-3'>
          <div className='icon mx-1 mx-sm-3' >
            <img src="/images/download.png" alt="" className='download' onClick={downloadPdf} />
          </div>
          <div className='icon mx-1 mx-sm-3' >
            <img src="/images/printer.png" alt="" className='printer' onClick={printPdf}/>
          </div>
        </div>}
        <div className="w-100">
          <DocViewer
            style={{ width: "100%" }}
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />

          {/* {file === "image" && (
            <div className="">
              <DocViewer
                documents={[{ uri: file }]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
              />
            </div>
          )}
          {file.type === "application/pdf" && (
            <div className="d-flex justify-content-center">
              <DocViewer
                documents={[file].map((f) => ({
                  uri: window.URL.createObjectURL(f),
                  fileName: f.name,
                }))}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
              />
            </div>
          )}
          {typeof file === "string" && file.includes(".pdf") && (
            <div className="">
              <DocViewer
                documents={[{ uri: file }]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true,
                  },
                }}
              />
            </div>
          )} */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(FileViewerModal);
