import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { formatedDate, renderName, renderNameInitial } from '../../../utils/Helper'

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Candidate', field: 'candidateName' },
    { label: 'Interview Process Ended on', field: 'date' },
    { label: 'Status', field: 'interviewStatus' },
    { label: 'Remarks', field: 'reasonForCancel' },
]

const EmpInterviewHistoryList = ({ data, isLoading }) => {


    const convertRowDataToTableData = (data, index) => {
        return {
            ...data,
            nameInitial: renderNameInitial(data.candidateName),
            candidateName: renderName(data.candidateName, index),
            date: formatedDate(data.date),
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)}
            isLoading={isLoading} />
    )
}

export default EmpInterviewHistoryList