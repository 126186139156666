import React from 'react'
import './verificationSuccess.css'
import { useNavigate } from 'react-router-dom'


function VerificationSuccess() {
   const navigate = useNavigate()
   return (
      <div className='candidate__register__verificationSuccess text-center'>
         <h3 className='title'>Verification <span className='white-space-nowrap'>Successfully !!</span></h3>
         <p className='message mt-5'>Your account is created !!</p>
         <p className='message'>Please Login to your account</p>
         <button type="submit" className="btn btn-red mt-1 w-100"
         onClick={()=>{navigate('/candidate/login')}}>OK</button>
      </div>
   )
}

export default VerificationSuccess