export const nationality = ['Bangladesh','India','Indoensia','Malaysia','Mynamaar','Philippines','Sri Lanka','Thailand']
export const skills = ['Child/Infant Care','Elderly Care','Care for Disabled','Pet Care','House Cleaning','Cooking','Washing & Ironing Clothes','Gardening','Driving']
export const languages = ['English','Bahasa Indoensia','Malay','Bengali','Burmese','Chinese','Hindi','Gujrati','Marathi','Punjabi','Tamil','Telgu','Bangla','Nepali','Sinhala','Tagalog','Thai']
export const cooking = ['Chinese','Malay','Western','Indian','Indian Vegetarian','Thai','SriLankan']
export const personality = ['Good Listener','Caring','Love Cooking','Independent','Willing to Learn','Polit']
export const currentCountry = ['Singapore','India','Indonesia','Malaysia','Mynamaar','Philippines','Sri Lanka','Thailand','Hong Kong','Gulf Countries/UAE','Others']
export const oFFDays = ['0 Days', '1 Day', '2 Days', '3 Days', '4 Days']
export const Religion = ['Buddhist', 'Christian', 'Hindu', 'Jain', 'Muslim', 'Sikh']
export const table = ['Period', 'Country', 'Duties', 'Any Reference Letter?', 'Action']
export const howSoonStart=['Immediate','1 Week','2 Weeks','3 Weeks','1 Month','More than 1 Month']
export const typeOfCandidate = ['New', 'Transfer', 'Ex-Singapore']
export const educationCountry = ['Bangladesh', 'India', 'Indonesia', 'Malaysia', 'Myanmar', 'Philippines', 'Sri Lanka', 'Thailand'];
export const highestEducation = ['Secondary 8 Passed', 'Secondary 9 Passed', 'Secondary 10 Passed', 'College Year 1 Passed', 'College Year 2 Passed', 'College Year 3 Passed', 'College Year 4 Passed', 'College Year 5 Passed', 'Diploma Passed', 'Bachelors Passed', 'Masters Passed'];
export const workExpeCountry=['Singapore','India','Indonesia','Malaysia','Mynamaar','Philippines','Sri Lanka','Thailand','Hong Kong','Gulf Countries/UAE','Others']
export const maritalStatus=['Married','Single','Not Married']

// Declare arrays
export const NewBeforeArrival = ["Work Permit Application In-Process","Work Permit Approved","Work Permit Rejected","Arrival Date Confirmed","Insurance Activated","SIP Training Booked","Before Arrival Process Completed","Candidate Selection Cancelled"]
export const TransferBeforeArrival = ["Work Permit Application In-Process", "Work Permit Approved", "Work Permit Rejected","Before Arrival Process Completed", "Candidate Selection Cancelled"];
export const ExSingaporeBeforeArrival = ["Work Permit Application In-Process", "Work Permit Approved", "Work Permit Rejected","Arrival Date Confirmed","Insurance Activated", "Before Arrival Process Completed","Candidate Selection Cancelled"];
export const NewOnArrival = ["Candidate Arrival to Singapore In-Progress", "Candidate Arrived", "SIP Training Completed", "Work Permit Issued and Work Started","Candidate Selection Cancelled"];
export const TransferOnArrival = ["Work Permit Issued ", "Work Permit Issued and Work Started", "Candidate Selection Cancelled"];
export const ExSingaporeOnArrival = ["Candidate Arrival to Singapore In-Progress", "Candidate Arrived", "Work Permit Issued and Work Started", "Candidate Selection Cancelled"];