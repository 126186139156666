import React from "react";
import Datetime from "react-datetime";
import { IoIosArrowDown, IoIosCloseCircle } from "react-icons/io";
import { TfiEye } from "react-icons/tfi";
import Select from "../../../../components/select/Select";
import { Button } from "react-bootstrap";
import "./personalDetails.css";

const WorkExperienceMobile = ({
    workExperience,
    workExpeCountry,
    skills,
    handlePeriodFromChange,
    handleCheckboxChange,
    handlePeriodToChange,
    handleWorkExperienceCountry,
    handleWorkExperienceDuties,
    handleRefLetter,
    handleImgView,
    removeRefLetter,
    removeWorkExperience,
    renderInput,
    selectedIndices
}) => {
    return (
        <div className="mobile-view">
            {workExperience?.map((workExp, index) => (
                <div  key={index} className={`data-card ${selectedIndices.includes(index)
                    ? "invalid"
                    : ""
                    }`}>
                    <div className="d-flex field justify-content-center">
                        <label></label>
                        <div className="SrNo">{index + 1}</div>
                    </div>
                    <div className="field">
                        <label>Period</label>
                        <div className="PresentDiv">
                            <div className="d-flex">
                                <div className="col mt-2" style={{marginLeft:"10px"}}>From:</div>
                                <div className="col">
                                    <Datetime
                                        value={
                                            workExp.from
                                                ? new Date(workExp.from)
                                                : ""
                                        }
                                        onChange={(date) =>
                                            handlePeriodFromChange(
                                                date,
                                                index
                                            )
                                        }
                                        dateFormat="MM/YYYY"
                                        timeFormat={false}
                                        renderInput={renderInput}
                                        isValidDate={(current) =>
                                            current.isBefore(new Date())
                                        } // Set the today as maximum date
                                        inputProps={{
                                            placeholder: "Select",
                                        }}
                                        closeOnSelect={true}
                                        className="from-date w-200"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="d-flex">
                                <div className="col" style={{marginLeft:"31px",marginTop:"-3px"}}>To:</div>
                                <div className="form-check col">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name={`present-${index}`}
                                        id={`present-${index}`}
                                        checked={workExp.present}
                                        onChange={(e) =>
                                            handleCheckboxChange(e, index)
                                        }
                                    ></input>
                                    <label
                                        className="form-check-label"
                                        htmlFor={`present-${index}`}
                                    >
                                        Present
                                    </label>
                                </div>
                            </div>

                            <div className="d-flex mt-2">
                                <div className="col"></div>
                                <div className="col">
                                    {workExp.present ? (
                                        <Datetime
                                            key="refreshed"
                                            dateFormat="MM/YYYY"
                                            timeFormat={false}
                                            renderInput={renderInput}
                                            isValidDate={(current) =>
                                                current.isBefore(new Date())
                                            } //set min and max date
                                            inputProps={{
                                                placeholder: "Select",
                                            }}
                                            closeOnSelect={true}
                                            className={`to-date ${workExp.present
                                                ? "read-only-input"
                                                : ""
                                                } w-200`}
                                        />
                                    ) : (
                                        <Datetime
                                            key="initial"
                                            value={
                                                workExp.to
                                                    ? new Date(workExp.to)
                                                    : ""
                                            }
                                            onChange={(date) =>
                                                handlePeriodToChange(
                                                    date,
                                                    index
                                                )
                                            }
                                            dateFormat="MM/YYYY"
                                            timeFormat={false}
                                            renderInput={renderInput}
                                            isValidDate={(current) =>
                                                current.isBefore(new Date())
                                            } //set min and max date
                                            inputProps={{
                                                placeholder: "Select",
                                            }}
                                            closeOnSelect={true}
                                            className={`to-date ${workExp.present
                                                ? "read-only-input"
                                                : ""
                                                } w-200`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="field">
                        <label>Country</label>
                        <div className="position-relative mx-auto">
                            <select
                                name="country"
                                className="c-select"
                                onChange={(e) => handleWorkExperienceCountry(e, index)}
                                value={workExp.country}
                            >
                                <option value="">Select</option>
                                {workExpeCountry.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <IoIosArrowDown className="arrowDown" />
                        </div>
                    </div>

                    <div className="field">
                        <label>Duties</label>
                        <div className="duties">
                            <Select
                                selectedValue={workExp.duties}
                                options={skills}
                                optionsArrayObject={false}
                                onChange={(value) =>
                                    handleWorkExperienceDuties(
                                        value,
                                        index
                                    )
                                }
                                arrow={<IoIosArrowDown />}
                                multiple
                                classNamePrefix="multiSelect-workExperience"
                            />
                        </div>
                    </div>

                    <div className="field">
                        <label>Any Reference Letter?</label>
                        <div>
                            <label
                                htmlFor={`refLetter${index}`}
                                className="btn btn-success uploadBtn w-auto text-nowrap"
                            >
                                Upload Ref. Letter
                            </label>
                            <input
                                type="file"
                                name="refLetter"
                                id={`refLetter${index}`}
                                accept="image/*,application/pdf"
                                onChange={(event) => handleRefLetter(event, index)}
                                hidden
                            />
                            <div className="d-flex gap-10 justify-content-center">
                                {workExp.refLetter && (
                                    <>
                                        <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                            <span className="icon-text">View</span>
                                            <TfiEye
                                                className="icon-eye"
                                                onClick={() => handleImgView(workExp.refLetter)}
                                            />
                                        </span>
                                        <span className="d-flex flex-column align-items-center ms-2 cursor-pointer">
                                            <span className="icon-text">Remove</span>
                                            <IoIosCloseCircle
                                                className="mediaCrossIcon fs-3"
                                                onClick={() => removeRefLetter(index)}
                                            />
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="actions">
                        <div className="h-100 text-center">
                            <Button
                                variant="danger"
                                className="uploadBtn w-auto"
                                onClick={() =>
                                    removeWorkExperience(workExp.id, "unverified")
                                }
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WorkExperienceMobile;
