import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";

//hooks
import { useAuthContext } from '../../../../hooks/useAuthContext';
import getBaseUrl from '../../../../hooks/http'

//form
import UserForm from './UserForm';
import ConfirmForm from './ConfirmForm';
import OtpForm from './OtpForm';
import AccountSuccess from './AccountSuccess';
//css
import './account.css'
import { useNavigate } from 'react-router-dom';


const INITIAL_DATA = {
   dialingCode: '',
   phoneNumber: '',
   email: '',
   otp: ''
}


const Account = () => {
   const { user, dispatch } = useAuthContext();
   const navigate = useNavigate();

   const [error, setError] = useState({
      name: false,
   })

   const [candidate, setCandidate] = useState({
      id: '', name: '', phoneNumber: '', email: '', photo: '',
      profilePreview: '', oldPhoto: '', agreement: ''
   })

   const [modal, setmodal] = useState({
      show: false,
      changeField: '',
   });

   const [agreementModel, setAgreementModel] = useState({
      show: false,
      file: null
   })

   //multi step form --------------------------------
   const [data, setData] = useState(INITIAL_DATA)
   const [currentStepIndex, setcurrentStepIndex] = useState(0)
   const updateFields = (fields) => {
      setData(prev => {
         return { ...prev, ...fields }
      })
      //set error false
      setError({ ...error, name: false })
   }
   
   const pages = [
      <UserForm data={data} candidate={candidate} updateFields={updateFields} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
      <ConfirmForm data={data} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
      <OtpForm data={data} updateFields={updateFields} changeField={modal.changeField} setcurrentStepIndex={setcurrentStepIndex} />,
      <AccountSuccess data={data} candidate={candidate} changeField={modal.changeField} />
   ]

   //End  --------------------------------

   // modal -------------------------------
   const handleClose = () => setmodal({ ...modal, show: false });
   const handleShow = (changeField) => {
      setmodal({ ...modal, show: true, changeField });
      setcurrentStepIndex(0);
   }
   // End -------------------------------

   //---------------------
   const [url, setUrl] = useState()
   const [pageNum, setPageNum] = useState(1);
   const [totalPages, setTotalPages] = useState(0);
   const [pageDetails, setPageDetails] = useState(null);
   const [position, setPosition] = useState(null);

   //handle view Agreement Button
   const [isButtonDisabled, setIsButtonDisabled] = useState(true);

   const getCandiate = async () => {
      try {
         if (user !== null) {
            let config = {
               headers: {
                  Authorization: `Bearer ${user.token}`,
               },
            };
            const response = await axios.get(`${getBaseUrl}/candidates/my-account`, config);
            console.log(response);
            const { _id, phoneNumber, email, Agreement } = response.data.details[0];
            const name=response?.data?.details[0]?.otherData[0]?.name;
            const photo = response?.data?.details[0]?.otherData[0]?.photo;
            const newPhoto=`${getBaseUrl}/candidates/getMediaFileFromDb/${photo}`
            const newemail = (email) ? email : "demo@example.com";


          
            //set use state dtata
            setCandidate({ ...candidate, id: _id, name:name, phoneNumber, email:newemail, newPhoto, profilePreview: newPhoto ?? '/uploads/userIcon.png', oldPhoto: newPhoto, agreement: Agreement })
         }
      } catch (error) {
         console.log(error);
      }

   }



   useEffect(() => {
      if (user !== null) {
         const isAgreementSign = user.Is_AgreementSign;
         if (isAgreementSign === "Yes") {
            setIsButtonDisabled(false)
         }
         else {
            setIsButtonDisabled(true)
         }
      }
      getCandiate();
   }, [user])

   //update User data
   const userUpdate = (updatedData) => {
      const userInfo = {
         ...JSON.parse(localStorage.getItem('user')),
         ...updatedData
      };
      /* update the auth context */
      dispatch({ type: 'UPDATE-USER', payload: userInfo })
   }


   //handle imageOnError
   const imageOnError = (event) => {
      event.target.src = '/uploads/userIcon.png';
   };

   //Handle View Agreement
   const viewAgreement = async () => {
      try {
         const candidateId = candidate.id;
         setAgreementModel({ ...agreementModel, show: true, file: `${getBaseUrl}/candidates/getMediaFileFromDb/${candidate.agreement}` })
      }
      catch (error) {
         console.log(error);
      }

   }

   //Download Agreement
   const downloadAgreement = async () => {
      if (user !== null) {
         let config = {
            headers: {
               Authorization: `Bearer ${user.token}`,
            },
         };

         const data = { filePath: agreementModel.file }

         const response = await axios.get(`${getBaseUrl}/agreement/download-pdf/${candidate.agreement}`, {
            responseType: 'blob', // Important: tells Axios to treat the response as binary data (file)
          });
    
          // Create a blob from the file data and download it
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const filename = "agreement.pdf"
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename); // Set the filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link); // Clean up
      }
   }

   //print doc ------------------------------------------------
   const printAgreement = async () => {

      const url = `${getBaseUrl}`.replace(/\/api/g, '')
      // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
      const pdfUrl = `${agreementModel.file}`;

      // Open the PDF in a new tab
      const newTab = window.open(pdfUrl, '_blank');

      // Wait for the PDF to load, then trigger the print dialog
      newTab.onload = () => {
         newTab.print();
      };
   }

   //pdf -----------------------------------------------
   const pdfPrevPage = () => {
      setPageNum(pageNum - 1)
   }
   const pdfNextPage = () => {
      setPageNum(pageNum + 1)
   }




   return (
      <div className='candidate_account py-5'>
         <Container>
            <h2 className='title'>My Account</h2>
            <h4 className='sub-title'>Manage your Account</h4>
            <Row className='mt-5 justify-content-center'>
               <Col sm={10} md={6} lg={5} className='order-1 order-lg-0'>
                  <div className='w-100 h-100 d-flex flex-column justify-content-center'>
                     <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                        <div className="floating-control flex-grow-1">
                           <input type="text" name="name" id='name' className="floating-input"
                              placeholder="none" readOnly defaultValue={candidate.name} />
                           <label htmlFor="phone" className="floating-label">Name</label>
                        </div>
                        <span className='change-text' onClick={() => navigate('/candidate/profile/personal-details')}>Change</span>
                     </div>
                     <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                        <div className="floating-control flex-grow-1">
                           <input type="text" name="phone" id='phone' className="floating-input"
                              placeholder="none" readOnly defaultValue={candidate.phoneNumber ? `+${candidate.phoneNumber}` : ''}  />
                           <label htmlFor="phone" className="floating-label">Phone Number</label>
                        </div>
                        <span className='change-text' onClick={() => handleShow('phone')}>Change</span>
                     </div>
                     <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                        <div className="floating-control flex-grow-1">
                           <input type="text" name="email" id='email' className="floating-input"
                              placeholder="none" readOnly defaultValue={candidate.email} />
                           <label htmlFor="email" className="floating-label">Email</label>
                        </div>
                        <span className='change-text' onClick={() => handleShow('email')}>
                           {(candidate.email) ? 'Change' : 'Add'}</span>
                     </div>

                     <div className='mb-4 d-block d-sm-flex gap-10 align-items-center'>
                        <button className='btn btn-primary' onClick={viewAgreement} disabled={isButtonDisabled} >View Agreement</button>
                     </div>


                  </div>
               </Col>
               <Col lg={4} className='order-0 order-lg-1 mb-4'>
                  <div className=''>
                     <div className="account-photo mx-auto">
                        <img src={candidate.profilePreview} alt="profile"
                           className='account-photo-img mb-3 w-100 h-100'
                           onError={imageOnError} />
                     </div>
                     <Button variant="outline-primary" className='px-5 mt-4 mx-auto d-block'
                        onClick={() => navigate('/candidate/profile/personal-details')}>Change Profile Photo</Button>
                  </div>
               </Col>
            </Row>

            {/* accountModal */}
            <Modal show={modal.show} onHide={handleClose} backdrop="static" id="accountModal" centered>
               {
                  (currentStepIndex !== 3) &&
                  <Modal.Header className='p-0 justify-content-end border-0'>
                     <div className="position-relative">
                        <AiOutlineClose onClick={handleClose}
                           className='icon close-icon position-absolute' />
                     </div>
                  </Modal.Header>
               }
               <Modal.Body>

                  <div className='mb-3'>
                     {pages[currentStepIndex]}
                  </div>
               </Modal.Body>
            </Modal>

   
            {/* view pdf file  */}
            <Modal
               size="lg"
               show={agreementModel.show}
               onHide={() => setAgreementModel({ ...agreementModel, show: false })}
               backdrop="static"
               keyboard={false}
            >
               <Modal.Header className='p-0 justify-content-end border-0'>
                  <div className="position-relative">
                     <AiOutlineClose onClick={() => setAgreementModel({ ...agreementModel, show: false })}
                        className='icon close-icon position-absolute' />
                  </div>
               </Modal.Header>
               <Modal.Body>
                  <div className='d-flex justify-content-center'>
                     <div className='icon mx-1 mx-sm-3' onClick={downloadAgreement}>
                        <img src="/images/download.png" alt="" className='download' />
                     </div>
                     <div className='icon mx-1 mx-sm-3' onClick={printAgreement}>
                        <img src="/images/printer.png" alt="" className='printer' />
                     </div>
                  </div>
                  <div className="p-4">
                     {
                        (agreementModel.file) &&
                        <>
                           <Document
                            loading="Loading Agreement"
                              file={agreementModel.file}
                              onLoadSuccess={(data) => {
                                 setTotalPages(data.numPages);
                              }}
                           >
                              <Page
                              loading="Loading Agreement Pages"
                                 renderTextLayer={false}
                                 renderAnnotationLayer={false}
                                 customTextRenderer={false}
                                 pageNumber={pageNum}
                                 className='w-100 border'
                                 onLoadSuccess={(data) => {
                                    setPageDetails(data);
                                 }}
                              />
                           </Document>
                           {
                              (totalPages > 1) &&
                              <div className="page-controls d-flex justify-content-center align-items-center">
                                 <button type="button" className='btn btn-primary' onClick={pdfPrevPage}
                                    disabled={(pageNum === 1) ? true : false}>‹</button>
                                 <span className='mx-2'>{pageNum} of {totalPages}</span>
                                 <button type="button" className='btn btn-primary' onClick={pdfNextPage}
                                    disabled={(pageNum === totalPages) ? true : false}>›</button>
                              </div>
                           }
                        </>
                     }
                  </div>
               </Modal.Body>
            </Modal>
         </Container>
      </div>
   )
}

export default Account