import React from 'react'
import CustomTable from '../../customTable/CustomTable'
import { TfiEye } from 'react-icons/tfi'
import { formatedDate,renderNameInitial, renderName} from '../../../utils/Helper'

const tableColumns = [
    { label: '', field: 'nameInitial' },
    { label: 'Employer', field: 'employerName' },
    { label: 'Selected for Onboarding on', field: 'date' },
    { label: 'Status', field: 'status' },
]

const CndCompleted = ({ data, isLoading }) => {

    const getStatusMessage = (step1Status, step1Reason, step2Status, step2Reason) => {
        // Check if Step 1 is cancelled
        if (step1Status === "Candidate Selection Cancelled") {
           return <> 
           {`Step 1: ${step1Status} - ${step1Reason}`}
           </>
        }else if (step1Status === `Work Permit Rejected`) {
            return <>{`Step 1: ${step1Status}`}</> 
        }else if (step2Status === "Candidate Selection Cancelled") {// Check if Step 2 is cancelled
           return <>{`Step 2: ${step2Status} - ${step2Reason}`}</>
        }else if (step2Status === "Work Permit Issued and Work Started") {
           return <>{`Step 2: Work Permit Issued and Work Started`}</>
        }else{
            if(step1Status!==""){
                return <>{(step1Status)}</>
            }
            if(step2Status!==""){
                return <>{(step2Status)}</>
            }
        } 
     };

    const convertRowDataToTableData = (data, index) => {
        console.log("Data for conversion",data);
        return {
            ...data,
            nameInitial: renderNameInitial(data.employerName),
            name: renderName(data.employerName),
            date: formatedDate(data.date),
            status: getStatusMessage(data.status.step1,data.step1Reason,data.status.step2,data.step2Reason)
        }
    }

    return (
        <CustomTable columns={tableColumns} rows={data.map(convertRowDataToTableData)} isLoading={isLoading} />
    )
}

export default React.memo(CndCompleted)