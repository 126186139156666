import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import './customTable.css'

const CustomTable = ({ columns, rows, isLoading }) => {

    const [isMobile, setIsMobile] = useState(false);

    const handleWindowSize = (e) => {
        const w = e.target;
        if (w.innerWidth <= 769) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        setIsMobile(window.innerWidth <= 769)

        window.addEventListener('resize', handleWindowSize)
        return () => window.removeEventListener('resize', handleWindowSize)
    }, [])


    const renderDesktopView = () => {
        return (
            <Table responsive striped hover className='rounded-top desktop-table-container'>
                <thead className='table-dark text-center'>
                    <tr>
                        <th>#</th>
                        {columns.map((column) => (
                            <th key={column.field} className='white-space-nowrap'>{column.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        rows.map((row, index) => (
                            <tr key={row.id}>
                                <td className='px-3'>{index + 1}</td>
                                {columns.map((column, index) => (
                                    <td key={row.id + column.field}>{row[column.field]}</td>
                                ))}
                            </tr>
                        ))
                    }
                    {
                        (rows.length === 0) && <tr>
                            <th colSpan={columns.length + 1}>{isLoading ? 'Loading...' : 'Record Not Found.'}</th>
                        </tr>
                    }
                </tbody>
            </Table>
        );
    }

    const renderMobileView = () => {
        return (
            <div className='mobile-table-container'>
                {
                    rows.map((row, index) => (
                        <div key={row.id} className='table__card border border-dark p-3 mb-3'>
                            <div className='table__card__row mb-2'>
                                <h6 className='label d-flex align-items-center'># {index + 1}</h6>
                                {row['nameInitial']}
                            </div>

                            {columns.filter((ele) => ele.field !== 'nameInitial').map((column, index) => (
                                <div key={row.id + column.field} className='table__card__row mb-2'>
                                    <h6 className='label m-0'>{column.label} : </h6>
                                    <div className='field'>{row[column.field]}</div>
                                </div>
                            ))}
                        </div>
                    ))
                }
                {
                    (rows.length === 0) &&
                    <div className='table__card border border-dark p-3 mb-3 '>
                        <h5 className='text-center justify-content-center'>{isLoading ? 'Loading...' : 'Record Not Found.'}</h5>
                    </div>
                }
            </div>
        )
    }


    return <>
        {isMobile ? renderMobileView() : renderDesktopView()}
    </>
}

export default React.memo(CustomTable);