// program to convert first letter of a string to uppercase
export function capitalizeFirstLetter(str) {
   // converting first letter to uppercase
   if (typeof str === 'string') {
      const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
      return capitalized;
   }
}

//program to check email is in valid format
export const validateEmail = (email) => {
   const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
   return regex.test(email.toLowerCase());
};

//program to check url Exists or not
export const urlExists = (url = '') => {
   if (url) {
      var http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      return http.status !== 404;
   }
}

//function to formatedDate for list
export const formatedDate = (date) => {
   return <>
      <div>
         <p>
            <span className="white-space-nowrap">
               {
                  date.toLocaleString('en-GB', {
                     year: 'numeric', month: '2-digit', day: '2-digit',
                  }).replace(/,/g, ' on ').replace(/00:/g, '12:')
               }
            </span>
            <span> On </span>
            <span className="white-space-nowrap">
               {
                  date.toLocaleString('en-GB', {
                     hour: '2-digit', minute: '2-digit', hour12: true
                  }).replace(/,/g, ' on ').replace(/00:/g, '12:')
               }
            </span>
         </p>
         <p className="white-space-nowrap">
            {
               `${date}`.substring(
                  `${date}`.indexOf("("),
                  `${date}`.lastIndexOf(")") + 1
               )
            }
         </p>
      </div>


   </>
}

//function to render NameInitial for list
export const renderNameInitial = (name) => {
   return <div className="profile-picture">
      {(name) ? name.charAt(0).toUpperCase() : 'O'}
   </div>
}

//function to render name for list
export const renderName = (name) => {
   return <p className="name">{name}</p>;
}

//fun to convert array to string
export const arrayToString = (arr) => {
   return Array.isArray(arr) && arr.length > 0
      ? arr.toString().replace(/,/g, ", ")
      : "-";
};



// function get calendar position
export const position = (e, sl = false, reschedulePopupRef) => {
   const target = (sl) ? e : e.target;

   const btnRect = target.getBoundingClientRect();
   const bodyRect = document.body.getBoundingClientRect();
   const scroll = document.documentElement.scrollTop || document.body.scrollTop;

   const navbarHeight = 85;

   const popupWidth = reschedulePopupRef.current.offsetWidth
   const popupHeight = reschedulePopupRef.current.offsetHeight

   const btnOffsetTop = btnRect.top - navbarHeight;
   const btnOffsetBottom = window.innerHeight - btnRect.bottom;
   const btnOffsetRight = window.innerWidth - btnRect.right;

   let top = (btnRect.top - (popupHeight / 2));;
   let left = btnRect.right + 5;
   // calendar top position
   if (btnOffsetTop < (popupHeight / 2)) {
      top = (btnRect.bottom < navbarHeight) ? btnRect.bottom : (btnRect.top - (popupHeight / 2)) - (btnOffsetTop - (popupHeight / 2));
   }

   if (btnOffsetBottom < (popupHeight / 2)) {
      top = (btnOffsetBottom < 0) ? (btnRect.bottom - popupHeight) : (btnRect.bottom - popupHeight + btnOffsetBottom);
   }
   // calendar left position
   if (btnOffsetRight < (popupWidth + 5)) {
      left = (btnRect.left > popupWidth) ? (btnRect.left - popupWidth) : btnRect.left;
   }

   return { top, left };
}
