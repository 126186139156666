import React from "react";
import SignaturePad from "react-signature-canvas";

const CustomSignaturePad = ({ strokeBegin, setSign }) => {
  return (
    <div className="sigContainer">
      <label className="sign-label mb-1">Signature</label>
      <div className="signature-wrapper">
        <SignaturePad
          velocityFilterWeight={0.8}
          minWidth={0.5}
          maxWidth={2.5}
          dotSize={2}
          throttle={0}
          onBegin={strokeBegin}
          canvasProps={{ className: "sigPad" }}
          ref={(data) => setSign(data)}
        />
      </div>
    </div>
  );
};

export default CustomSignaturePad;
