import React, { useEffect, useState, useContext } from 'react'
import PhoneNumber from '../../../components/PhoneNumber';
import { useRegister } from './useRegister';
import { TfiEye } from "react-icons/tfi";
import { FaRegEyeSlash } from "react-icons/fa";
import { RegisterContext } from '../../../context/RegisterContext';

const EmpRegisterForm = ({ data, updateFields, errorMsg }) => {
   const { check, isLoading, error, setError } = useRegister();

   const { name, setName, phoneNumber, setPhoneNumber, dialingCode, setDialingCode } = useContext(RegisterContext);

   // Local state to manage the visibility of the error message
   const [showError, setShowError] = useState(!!errorMsg);

   //passwordw input type
   const [isShown, setIsSHown] = useState({
      password: false,
      cPassword: false
   });

   const togglePassword = (field) => {
      if (field === 'password') {
         setIsSHown({ ...isShown, password: !isShown.password });
      }
      if (field === 'cPassword') {
         setIsSHown({ ...isShown, cPassword: !isShown.cPassword });
      }
   };

   useEffect(() => {
      if (errorMsg) {
         setName(name);
         setPhoneNumber(phoneNumber);
         setDialingCode(dialingCode);
      }
      else {
         setName("");
         setPhoneNumber("");
         setDialingCode("");
         setError('');
      }
   }, []);

   //handle next click
   const handleSubmit = (e) => {
      const { password, confirmPassword } = data;
      e.preventDefault();
      setShowError(false);
      if (password === confirmPassword) {
         check(dialingCode, phoneNumber, password, name)
      } else {
         setError('Confirm password is not matching')
      }
   }




   return (
      <>
         <div className='form-register-wrapper'>
            <h2 className='mb-4 text-end'>Sign Up</h2>

            <form className='form-register' onSubmit={handleSubmit}>
               <div className="mb-3">
                  <div className="floating-control requiredMark">
                     <input type="text" name="name" className="floating-input"
                        placeholder="none" autoComplete='off' required
                        onChange={(e) => { setName(e.target.value); setError(''); }} value={name} />
                     <label htmlFor="name" className="floating-label">Name</label>
                  </div>
               </div>
               <div className="mb-3 requiredMark">
                  <PhoneNumber value={{ phoneNumber, dialingCode }}
                     onChange={{ setPhoneNumber, setDialingCode }} inputDataType="number"
                     labelText="Phone Number" />
               </div>
               <div className="mb-3">
                  <div className="d-flex">
                     <div className="floating-control requiredMark">
                        <input type={isShown.password ? "text" : "password"} name="password" id="password" className="floating-input"
                           placeholder="none" autoComplete='off' required
                           onChange={(e) => { updateFields({ password: e.target.value }); setError(''); }} value={data.password} />
                        <label htmlFor="password" className="floating-label">Password</label>
                     </div>
                     <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('password')}>
                        {isShown.password ? <FaRegEyeSlash /> : <TfiEye />}
                     </span>
                  </div>
               </div>
               <div className="mb-3">
                  <div className="d-flex">
                     <div className="floating-control requiredMark">
                        <input type={isShown.cPassword ? "text" : "password"} name="confirmPassword" id="confirmPassword" className="floating-input"
                           placeholder="none" autoComplete='off' required
                           onChange={(e) => { updateFields({ confirmPassword: e.target.value }); setError(''); }} value={data.confirmPassword} />
                        <label htmlFor="password" className="floating-label">Confirm Password</label>
                     </div>
                     <span className="input-group-text fs-4 cursor-pointer" onClick={() => togglePassword('cPassword')}>
                        {isShown.cPassword ? <FaRegEyeSlash /> : <TfiEye />}
                     </span>
                  </div>
               </div>
               <button disabled={isLoading} type="submit" className="btn btn-blue btn-registerEmp mt-1 mx-auto d-block"
               >Next</button>
               {error && <p className='text-danger text-center'>{error}</p>}
               {errorMsg && <p className='text-danger text-center'>{errorMsg}</p>}
            </form>

         </div>
      </>
   )
}

export default EmpRegisterForm