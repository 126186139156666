import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import Datetime from "react-datetime";
import SignaturePad from "react-signature-canvas";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { TfiEye } from "react-icons/tfi";
import { AiOutlineClose } from "react-icons/ai";
import { IoArrowBackOutline } from "react-icons/io5";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";

import CustomModal from "../../../../components/customModal/CustomModal.jsx";
import getBaseUrl from '../../../../hooks/http';
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useSocketContext } from '../../../../hooks/useSocketContext.js';
import { CountContext } from '../../../../context/CountContext.js';
import { useNavigate } from 'react-router-dom';
import DialogAlert from '../../../../components/customModal/customAlertModal';
import CndOfferList from '../../../../components/candidate/offer/CndOfferList.jsx';
import CndOfferHistoryList from '../../../../components/candidate/offer/CndOfferHistoryList.jsx';

import "./offer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Offers = () => {
  const { user } = useAuthContext();
  const socket = useSocketContext();
  const { updateOfferCount } = useContext(CountContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [acceptOfferModal, setAcceptOfferModal] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [minTime, setMinTime] = useState(null);
  const [offers, setOffers] = useState([]);
  const [verificationCount, setVerificationCount] = useState(1);
  const [pdf, setPdf] = useState({
    path: null,
    name: "",
    originalFile: null,
  });
  //---------------------------------------
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const [position, setPosition] = useState(null);
  const documentRef = useRef(null);

  //------------------------------------------------
  const [signModal, setSignModal] = useState(false);
  const [sign, setSign] = useState();
  const [url, setUrl] = useState();

  const [viewPdf, setViewPdf] = useState({
    show: false,
    file: null,
  });

  const [EmpIdActive, setEmpId] = useState("");

  const [error, setError] = useState({
    signaturePad: false,
    document: false,
  });

  const [formData, setFormData] = useState({
    show: false,
    error: "",
    identificationType: "",
    identificationNo: "",
    identificationName: "",
    identificationCoutry: "",
  });

  //Offer Cancel and Reschesule part added by Namrata
  const [dataType, setDataType] = useState("currentOffer");
  const [classActiveName, setClassName] = useState("active");
  const [offersHistory, setOffersHistory] = useState([]);
const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    //Datapicker changes
    const currentDateTime = new Date();
    currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
    setMinTime(currentDateTime);

    const empid = localStorage.getItem("empId");
    const arrayOfIdActionEmp = empid.split("_");
    setEmpId(arrayOfIdActionEmp[0]);

    if (dataType === "currentOffer") getOffers();
    else getOffersHistory();

    //socket io stuff
    socket.on("cndOfferStatusUpdate", handleCndOfferStatusUpdate);

    return () => {
      socket.off("cndOfferStatusUpdate", handleCndOfferStatusUpdate);
    };
  }, [dataType, user]);

  //get all offers data ------------------------------------
  const getOffers = async () => {
    try {
      setIsLoading(true);
      if (user !== null) {
        let config = {
          headers: { Authorization: `Bearer ${user.token}` },
        };

        const res = await axios.get(`${getBaseUrl}/candidates/offers/`, config);
        console.log(res);
        updateOfferCount(res.data.offers.length);
        if (res.data.personalDetailsData !== null) {
          setVerificationCount(
            res.data.personalDetailsData.verificationCounter
          );
        } else {
          setVerificationCount(null);
        }

        if (res.status === 200) {
          const data = res.data.offers;
          const offers = data.map((dt) => {
            const employerName = dt.otherData[0].name ?? "Unknown";
            const employerPhoto = dt.otherData[0].photo;
            const interviewDate = dt.interview_date_time;
            const status = dt.status;
            const offerId = dt._id;
            const offerPdf = dt?.offerPdf
              ? `${getBaseUrl}/candidates/getMediaFileFromDb/${dt?.offerPdf}`
              : "";
            const employerId = dt.employer_id;
            const candidateId = dt.candidate_id;
            const Is_OfferCreated = dt.Is_OfferCreated;

            return {
              name: employerName,
              photo: employerPhoto,
              interviewConductedDate: new Date(interviewDate),
              reschedule: {
                date: new Date(interviewDate),
                isShow: false,
              },
              status: status,
              offerId: offerId,
              offerPdf: offerPdf,
              employerId: employerId,
              candidateId: candidateId,
              Is_OfferCreated: Is_OfferCreated,
            };
          });

          setOffers(offers);
          setOffersHistory([]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // get Offers History data
  const getOffersHistory = async () => {
    try {
      setIsLoading(true);
      if (user !== null) {
        let config = {
          headers: { Authorization: `Bearer ${user.token}` },
        };

        const res = await axios.get(
          `${getBaseUrl}/candidates/offers-history/`,
          config
        );

        if (res.status === 200) {
          const interviewHistoryData = await res.data;
          const data = interviewHistoryData.map((ihd) => {
            const InterDate = new Date(ihd.OfferHistory.Interview_Date_Time);
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newDateTime = InterDate.toLocaleString("en-US", {
              timeZone: `${timezone}`,
            });
            const Interview_Date_Time = newDateTime
              ? new Date(newDateTime)
              : "";

            return {
              EmpTempName: ihd.name ?? "Unknown",
              date: new Date(Interview_Date_Time),
              interviewStatus: ihd.OfferHistory.Status,
              reasonForCancel: ihd.OfferHistory.Reason_For_Cancel,
            };
          });

          setOffers([]);
          setOffersHistory(data);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const [fromOfferError, setFromOfferError] = useState(""); // Error messages

  //handle formt input
  const handleFormInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFromOfferError({ ...fromOfferError, [e.target.name]: "" });
    //acceptOfferModal
  };

  

  const handleContinueClick = async () => {
    // const { identificationType, identificationNo, identificationName, identificationCountry } = formData;
    // if (!identificationType || !identificationNo || !identificationName || !identificationCountry) {
    //   setFormData({ ...formData, error: 'All fields are required.' });
    // } else {
    //   setFormData({ ...formData, show: false });
    //   await embeded('', identificationType, identificationNo, identificationName, identificationCountry);
    //   setAcceptOfferModal(true);
    // }
    const newErrors = {};

    // Validate email
    const {
      identificationType,
      identificationNo,
      identificationName,
      identificationCountry,
    } = formData;
    // Validate name
    if (!identificationType) {
      newErrors.identificationType = "identificationType is required";
    }
    if (!identificationNo) {
      newErrors.identificationNo = "identificationNo is required";
    }
    if (!identificationName) {
      newErrors.identificationName = "identificationName is required";
    }
    if (!identificationCountry) {
      newErrors.identificationCountry = "identificationCountry is required";
    }
    setFromOfferError(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setTimeout(() => {
        setFormData({ ...formData, show: false });
      }, 1000);
      //call generate pdf func
      await embeded(
        "",
        identificationType,
        identificationNo,
        identificationName,
        identificationCountry
      );
      setAcceptOfferModal(true);
    }
  };
  //pdf -----------------------------------------------
  const pdfPrevPage = () => {
    setPageNum(pageNum - 1);
  };
  const pdfNextPage = () => {
    setPageNum(pageNum + 1);
  };
  //---------------------------------------------------

  //sign modal ------------------------------------------
  const handleSignModalShow = () => {
    setSignModal(true);
    setError({ ...error, document: false });
  };
  const handleSignModalClose = () => setSignModal(false);

  const clearSign = () => {
    sign.clear();
  };

  const strokeBegin = () => {
    setError({ ...error, signaturePad: false });
  };
  //trim left singnature canvas side
  const trimLeftWhiteSpace = () => {
    const canvas = sign.getCanvas();

    // Find the bounding box of the signature
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data, width, height } = imageData;
    let minX = width;
    let minY = height;
    let maxX = 0;
    let maxY = 0;

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const pixelIndex = (y * width + x) * 4;
        if (data[pixelIndex + 3] > 0) {
          // Check if pixel is not transparent
          minX = Math.min(minX, x);
          minY = Math.min(minY, y);
          maxX = Math.max(maxX, x);
          maxY = Math.max(maxY, y);
        }
      }
    }

    // Create a new canvas for trimmed signature
    const trimmedWidth = maxX - minX + 1;
    const trimmedHeight = maxY - minY + 1;
    const trimmedCanvas = document.createElement("canvas");
    trimmedCanvas.width = trimmedWidth;
    trimmedCanvas.height = trimmedHeight;
    const trimmedCtx = trimmedCanvas.getContext("2d");

    // Draw the trimmed signature onto the new canvas
    trimmedCtx.drawImage(
      canvas,
      minX,
      minY,
      trimmedWidth,
      trimmedHeight,
      0,
      0,
      trimmedWidth,
      trimmedHeight
    );

    // Set the trimmed canvas as the reference for display
    return trimmedCanvas;
  };

  const saveSign = async () => {
    if (!sign.isEmpty()) {
      const abc = await trimLeftWhiteSpace();
      setUrl(abc.toDataURL("image/png"));
      setSignModal(false);
      //signature png image
      embeded(abc.toDataURL("image/png"));
    } else {
      setError({ ...error, signaturePad: true });
    }
  };

  //handle Accept Offer Click -----------------------------
  const handleAcceptOfferClick = (i) => {
    // setAcceptOfferModal(true);
    setFormData({ ...formData, show: true });
    const employerId = offers[i].employerId;
    const filePath = `${offers[i].offerPdf}`;
    const fileName = offers[i].offerPdf;
    const offerId = offers[i].offerId;
    setPdf({
      ...pdf,
      path: filePath,
      name: fileName,
      originalFile: filePath,
      offerId: offerId,
    });
  };

  //handle save Click -----------------------------
  const saveDocument = async (pdf) => {
    try {
      setIsSaving(true);
      if (!!url) {
        //check doc is signed or not
        let { path, offerId } = pdf;
        let data = new FormData();
        data.append("pdf", path);
        data.append("offerId", offerId);
        data.append("offerAction", "accept");

        let config = {
          headers: { Authorization: `Bearer ${user.token}` },
        };

        const res = await axios.post(
          `${getBaseUrl}/offers/acceptOffer/`,
          data,
          config
        );

        if (res.status === 200) {
          getOffers();
          setAcceptOfferModal(false);
        }
      } else {
        setError({ ...error, document: true });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsSaving(false);
    }
  };
  //handle embeded btn Click -----------------------------
  const embeded = async (
    signImg = "",
    identificationType = "",
    identificationNo = "",
    identificationName = "",
    identificationCountry = ""
  ) => {
    const arrayBuffer = await fetch(pdf.originalFile).then((res) =>
      res.arrayBuffer()
    );
    let pdfDoc;
    if (signImg !== "") {
      pdfDoc = await PDFDocument.load(pdf.path);
    } else {
      pdfDoc = await PDFDocument.load(arrayBuffer);
    }

    const pages = pdfDoc.getPages();
    const firstPage = pages[pageNum - 1];

    if (signImg !== "") {
      const pngImage = await pdfDoc.embedPng(signImg);
      const pngDims = pngImage.scale(0.2);
      firstPage.drawRectangle({
        x: 50,
        y: 240,
        width: 120, // The width and height should match the dimensions of the previously embedded image
        height: 60, // Adjust this to match the size of the signature image to cover the area
        color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
      });

      firstPage.drawImage(pngImage, {
        x: 50,
        y: 240,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    /* firstPage.drawRectangle({
       x: 365,
       y: 339,
       width:120,// The width and height should match the dimensions of the previously embedded image
       height: 11, // Adjust this to match the size of the signature image to cover the area
       color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
     });
     firstPage.drawRectangle({
       x: 365,
       y: 309,
       width:200,// The width and height should match the dimensions of the previously embedded image
       height: 11, // Adjust this to match the size of the signature image to cover the area
       color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
     });*/

    if (
      identificationType !== "" &&
      identificationNo !== "" &&
      identificationName !== "" &&
      identificationCountry !== ""
    ) {
      firstPage.drawRectangle({
        x: 170,
        y: 209,
        width: 200,
        height: 12,
        color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
      });
      firstPage.drawRectangle({
        x: 155,
        y: 187,
        width: 200,
        height: 12,
        color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
      });
      firstPage.drawRectangle({
        x: 155,
        y: 228,
        width: 200,
        height: 12,
        color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
      });
      firstPage.drawRectangle({
        x: 100,
        y: 168,
        width: 200,
        height: 12,
        color: rgb(1, 1, 1), // This sets the background color to white to hide the previous signature
      });
      firstPage.drawText(`${identificationName}`, { x: 155, y: 228, size: 12 });
      firstPage.drawText(`${identificationType}`, { x: 170, y: 208, size: 12 });
      firstPage.drawText(`${identificationNo}`, { x: 155, y: 187, size: 12 });
      firstPage.drawText(`${identificationCountry}`, {
        x: 100,
        y: 168,
        size: 12,
      });
    }

    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
    setPdf({ ...pdf, path: pdfBytes });
  };

  //handle drag stop event -------
  const handleStop = (e) => {
    setPosition(e);
  };

  //handle Offer Pdf View ------------------------------------
  const handleOfferPdfView = (i) => {
    const employerId = offers[i].employerId;
    const filePath = `${offers[i].offerPdf}`;
    setViewPdf({ ...viewPdf, show: true, file: filePath });
  };

  //download pdf --------------------------------------------
  const downloadPdf = async () => {
    try {
      if (user !== null) {
        const response = await axios.get(
          `${getBaseUrl}/agreement/download-pdf/${viewPdf.file
            .split("/")
            .pop()}`,
          {
            responseType: "blob", // Important: tells Axios to treat the response as binary data (file)
          }
        );

        // Create a blob from the file data and download it
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Extract the file name from the URL
        const originalFileName = viewPdf.file.split("/").pop().split("?")[0];
        // Get the file extension
        const extension = originalFileName.split(".").pop();
        let fileName = `Offers.${extension}`;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); // Set the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
      }
    } catch (error) {
      console.log(error);
    }
  };

  //print doc ------------------------------------------------
  const printPdf = async () => {
    const url = `${getBaseUrl}`.replace(/\/api/g, "");
    // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
    const pdfUrl = `${viewPdf.file}`;

    // Open the PDF in a new tab
    const newTab = window.open(pdfUrl, "_blank");

    // Wait for the PDF to load, then trigger the print dialog
    newTab.onload = () => {
      newTab.print();
    };
  };

  //deleteConfirmationModal ----------------------------------------------
  const [formErrors, setFormErrors] = useState({});
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    show: false,
    index: "",
  });

  // open Rescheduled DatePickier
  const openRescheduledDatePickier = (e, i) => {
    const { top, left } = position1(e);

    const list = [...offers];
    const scheduleDate = list[i]["interviewConductedDate"];
    const currentDateTime = new Date();
    if (
      new Date(scheduleDate).toDateString() === currentDateTime.toDateString()
    ) {
      // If a future date is selected, clear the excluded times
      //setExcludedTimes([]);
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);
    } else {
      // If the current date or a past date is selected, update the excluded times
      const newd = new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
        0, // Hours (midnight)
        0 // Minutes
      );
      setMinTime(newd);
    }
    for (let index = 0; index < list.length; index++) {
      if (index !== i) {
        list[index]["reschedule"]["isShow"] = false;
        list[i]["reschedule"]["error"] = false;
        list[i]["reschedule"]["error2"] = false;
      }
    }
    list[i]["reschedule"]["isShow"] = list[i].reschedule.isShow ? false : true;
    list[i]["reschedule"]["top"] = top;
    list[i]["reschedule"]["left"] = left;
    list[i]["reschedule"]["date"] = list[i]["interviewConductedDate"];
    setOffers(list);
  };
  // closeRescheduledDatePickier
  const closeRescheduledDatePickier = (i) => {
    const list = [...offers];
    list[i]["reschedule"]["isShow"] = false;
    list[i]["reschedule"]["error2"] = false;
    list[i]["reschedule"]["error"] = false;

    const lc_id = localStorage.getItem("candId");
    // const arrayOfIdAction = lc_id.split("_");
    /*localStorage.setItem('candId', arrayOfIdAction[0]);*/
    setClassName("false");
    localStorage.setItem("candId", null);
    setOffers(list);
  };
  //handle Rescheduled Date Change
  const handleRescheduledDateChange = (date, i) => {
    const currentDateTime = new Date();
    if (new Date(date).toDateString() === currentDateTime.toDateString()) {
      // If a future date is selected, clear the excluded times
      //setExcludedTimes([]);
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      setMinTime(currentDateTime);
    } else {
      // If the current date or a past date is selected, update the excluded times
      const newd = new Date(
        currentDateTime.getFullYear(),
        currentDateTime.getMonth(),
        currentDateTime.getDate(),
        0, // Hours (midnight)
        0 // Minutes
      );
      setMinTime(newd);
    }
    const list = [...offers];
    list[i]["reschedule"]["date"] = new Date(date);
    list[i]["reschedule"]["error"] = false;
    list[i]["reschedule"]["error2"] = false;

    setOffers(list);
  };
  // handleRescheduleClick
  const handleRescheduleClick = (i) => {
    const list = [...offers];

    var preDate = new Date(list[i].date);
    var newDate = new Date(list[i].reschedule.date);
    var currentDate = new Date();
    const timeDifference = newDate - currentDate;
    const timeDifference2 = Math.round(timeDifference / 1000 / 60);

    if (preDate.getTime() === newDate.getTime()) {
      list[i]["reschedule"]["error"] = true;
      setOffers(list);
    } else if (timeDifference2 <= 30) {
      list[i]["reschedule"]["error2"] = true;
      setOffers(list);
    } else {
      list[i]["reschedule"]["isShow"] = false;
      setOffers(list);
      //WA msg
      sendMeetingWhatsapp(i);
    }
  };

  const sendMeetingWhatsapp = async (index) => {
    try {
      const listData = offers[index];
      const res = await axios.post(
        `${getBaseUrl}/candidates/sendMeetingWhatsappMsg`,
        listData
      );
      if (res.status === 200) {
        //getInterviews('reshedule')
        //closeRescheduledDatePickier
        getOffers("reshedule");
        closeRescheduledDatePickier(index);
        handleStatusUpdate(listData.employerId, "Reschedule");
      }
    } catch (error) {
      console.log(error);
    }
  };

  //handle confirm Interview click
  const confirmMeeting = (index) => {
    const listData = offers[index];
    axios
      .post(`${getBaseUrl}/interviews/meetConfirmByCandidate`, listData)
      .then(async (res) => {
        console.log(res.data);
        if (res.status === 200) {
          getOffers("reshedule");
          handleStatusUpdate(listData.candID, "confirm");
        }
      })
      .catch((error) => {
        // handle error
        console.log(error);
        // Handle error response
        if (error.response && error.response.status === 400) {
          setOpen(true);
          setDialogMessage(error.response.data.message);
          // Display error message from backend
        } else {
        }
      });
  };

  //handle cancle meeting click
  const handleCancelMeeting = async (index) => {
    try {
      const listData = offers[index];
      await axios.post(
        `${getBaseUrl}/interviews/meetCancelByCandidate`,
        listData
      );
    } catch (error) {
      console.log(error);
    }
  };

  const position1 = (e, sl = false) => {
    const target = sl ? e : e.target;
    let bodyRect = document.body.getBoundingClientRect();
    let btnRect = target.getBoundingClientRect();
    //reschedule calendar popup width and height
    const popupWidth = 320;
    const popupHeight = 430;

    const tableWrapper = document.querySelector(".box");
    const tableWrapperReact = tableWrapper.getBoundingClientRect();

    let btnOffsetTop = btnRect.top - bodyRect.top;
    let btnOffsetLeft = btnRect.left - bodyRect.left;

    let scroll = document.documentElement.scrollTop || document.body.scrollTop;
    let top = btnOffsetTop - popupHeight - 10 - scroll;
    let left = btnOffsetLeft + target.offsetWidth / 2 - popupWidth / 2;

    if (tableWrapperReact.width < btnRect.left)
      left = btnRect.left - popupWidth; //left - 40;
    if (btnRect.top < popupHeight) top = btnRect.top / 2;
    left = btnRect.left - popupWidth;
    if (btnRect.left < popupWidth + 2) left = "calc(50% - 160px)";

    return { top, left };
  };

  //Cancel Offers
  const handleCancelOffers = (i) => {
    //set reasonforcancel value null
    offers[i]["reasonforcancel"] = "";
    //show modal
    setDeleteConfirmationModal({
      ...deleteConfirmationModal,
      show: true,
      index: i,
    });
  };

  const handleAlertClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const handleClose = (i) => {
    //set reasonforcancel value null
    offers[i]["reasonforcancel"] = "";
    //hide modal
    setDeleteConfirmationModal({ ...deleteConfirmationModal, show: false });
    //set error null
    setFormErrors("");
  };

  const handleReasonOfCancal = (cancelreason, i) => {
    const list = [...offers];
    setFormErrors("");
    list[i]["reasonforcancel"] = cancelreason.target.value;
    setOffers(list);
  };

  const cancelInterview = async (index) => {
    try {
      const listData = offers[index];

      if (!!listData.reasonforcancel) { //code by mohsin
        let config = {
          headers: { Authorization: `Bearer ${user.token}`, },
       };
        const res = await axios.post(`${getBaseUrl}/offers/cancelByCandidate`, listData, config);

        if (res.status === 200) {
          //getInterviews('cancle');
          //hide modal
          getOffers();
          setDeleteConfirmationModal({
            ...deleteConfirmationModal,
            show: false,
          });
          handleStatusUpdate(listData.employerId, "cancel");
          try {
            let config = {
              headers: { Authorization: `Bearer ${user.token}` },
            };
            const getOfferCount = await axios.get(
              `${getBaseUrl}/interviews/CandidategetCount`,
              config
            );
            updateOfferCount(getOfferCount.data.offerCount);
          } catch (e) {}
        }
      } else {
        let errors = {};
        errors.msg = "This is required field";
        setFormErrors(errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCndOfferStatusUpdate = (data) => {
    if (user.candidateId === data.cndId) {
      getOffers(data.status);
    }
  };

  //realtime status update
  const handleStatusUpdate = (empId, status) => {
    socket.emit("candidateOfferUpdate", { empId: empId, status: status });
  };

  return (
    <div className="cndOffer">
      {(verificationCount === null || verificationCount === undefined) && (
        <div className="Banner">
          <p>
            You need to complete Personal Details section 1<sup>st</sup> to
            Start.{" "}
            <span
              onClick={() => navigate("/candidate/profile/personal-details")}
            >
              Click Here
            </span>{" "}
            to goto Personals Deatils.
          </p>
        </div>
      )}
      {verificationCount === 0 && (
        <div className="Banner">
          <p>
            Personal details are pending for approval. Please contact Admin at{" "}
            <a
              href={`mailto:support@helperwish.com`}
              style={{ color: "white" }}
            >
              support@helperwish.com
            </a>{" "}
            for any questions
          </p>
        </div>
      )}

      <Container>
        <Row className="my-5">
          <Col sm={12}>
            <div className="text-center pb-5">
              <div className="form-check form-check-inline mb-3 mb-sm-0">
                <input
                  className=" d-none"
                  type="radio"
                  name="interview"
                  id="currentOffer"
                  value="currentOffer"
                  checked={dataType === "currentOffer" ? true : false}
                  onChange={(e) => setDataType(e.target.value)}
                />
                <label
                  className="form-check-label btn btn-outline-pink"
                  htmlFor="currentOffer"
                >
                  Offer
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="d-none"
                  type="radio"
                  name="interview"
                  id="offersHistory"
                  value="offersHistory"
                  checked={dataType === "offersHistory" ? true : false}
                  onChange={(e) => setDataType(e.target.value)}
                />
                <label
                  className="form-check-label btn btn-outline-pink"
                  htmlFor="offersHistory"
                >
                  Offer History
                </label>
              </div>
            </div>
          </Col>
          <Col>
            <div className="table-responsive interview-list box">
              {
                (dataType === 'currentOffer') &&
                 <CndOfferList data={offers} isLoading={isLoading}
                 handleOfferFormShow={handleAcceptOfferClick}
                 openRescheduledDatePickier={openRescheduledDatePickier}
                 handleOfferPdf={handleOfferPdfView}
                 confirmMeeting={confirmMeeting}
                 handleCancelOfferClick={handleCancelOffers}
                 handleCancelMeeting={handleCancelMeeting}
              />
                
              }

              {
                (dataType === 'offersHistory') &&
                 <CndOfferHistoryList data={offersHistory} isLoading={isLoading} />
              }
            </div>
          </Col>
        </Row>

        {/* display signature pad  */}
        <Modal
          size="lg"
          show={signModal}
          onHide={handleSignModalClose}
          backdrop="static"
          keyboard={false}
          id="signaturePadModal"
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={handleSignModalClose}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="scontainer mt-1 mt-sm-3">
              <div className="sigContainer">
                <label className="sign-label mb-1">Signature</label>
                <div className="signature-wrapper">
                  {signModal && (
                    <SignaturePad
                      velocityFilterWeight={0.8}
                      minWidth={0.5}
                      maxWidth={2.5}
                      dotSize={2}
                      throttle={0}
                      onBegin={strokeBegin}
                      canvasProps={{ className: "sigPad" }}
                      ref={(data) => setSign(data)}
                    />
                  )}
                </div>
              </div>
              {error.signaturePad && (
                <div className="text-danger text-center">
                  Signature can not be blank.
                </div>
              )}
            </div>
            <div className="d-flex flex-wrap justify-content-center">
            <Button variant="primary" className="mt-2 mx-2" onClick={saveSign}>
                Save Signature
              </Button>
              <Button
               variant="secondary"
                className="mt-2 mx-2"
                onClick={clearSign}
              >
                Clear Signature
              </Button>
             
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={formData.show}
          onHide={() => setFormData({ show: false })}
          backdrop="static"
          keyboard={false}
          id="offerFormModal"
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={() =>{setFormData({ show: false });setFromOfferError("")}}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h5 className="mb-4 text-center">Please provide below details</h5>
              {formData.error !== "" && (
                <p className="text-danger">{formData.error}</p>
              )}
              <div>
                <Row>
                  <Col sm={12}>
                    <div className="mb-3 d-flex flex-wrap align-items-center gap-10 mx-2">
                      <div className="m-0 flex-grow-1">
                        <div
                          className={`floating-control requiredMark mt-2 ${
                            fromOfferError.identificationName ? "invalid" : ""
                          }`}
                        >
                          <input
                            className="floating-input"
                            type="text"
                            name="identificationName"
                            value={formData.identificationName}
                            placeholder="none"
                            autoComplete="off"
                            required
                            onChange={handleFormInput}
                          />
                          <label htmlFor="name" className="floating-label">
                            Name as per Identification
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="mb-3 d-flex flex-wrap align-items-center gap-10 mx-2">
                      <div className="m-0 flex-grow-1">
                        <div
                          className={`floating-control requiredMark mt-2 ${
                            fromOfferError.identificationType ? "invalid" : ""
                          }`}
                        >
                          <input
                            className="floating-input"
                            type="text"
                            name="identificationType"
                            value={formData.identificationType}
                            placeholder="none"
                            autoComplete="off"
                            required
                            onChange={handleFormInput}
                          />
                          <label htmlFor="type" className="floating-label">
                            Identification Type
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="d-flex flex-wrap align-items-center gap-10 mx-2">
                      <div className="m-0 flex-grow-1 mt-2">
                        <div
                          className={`floating-control requiredMark m-0 ${
                            fromOfferError.identificationNo ? "invalid" : ""
                          }`}
                        >
                          <input
                            className="floating-input"
                            type="text"
                            name="identificationNo"
                            value={formData.identificationNo}
                            placeholder="none"
                            autoComplete="off"
                            required
                            onChange={handleFormInput}
                          />
                          <label htmlFor="No" className="floating-label">
                            Identification No
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={12} className="mt-2">
                    <div className="mb-3 d-flex flex-wrap align-items-center gap-10 mx-2">
                      <div className="m-0 flex-grow-1 mt-2">
                        <div
                          className={`floating-control requiredMark m-0 ${
                            fromOfferError.identificationCountry
                              ? "invalid"
                              : ""
                          }`}
                        >
                          <input
                            className="floating-input"
                            type="text"
                            name="identificationCountry"
                            value={formData.identificationCountry}
                            placeholder="none"
                            autoComplete="off"
                            required
                            onChange={handleFormInput}
                          />
                          <label htmlFor="duties" className="floating-label ">
                            Country where you are now
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col sm={12}>
                    <div className="text-center">
                      <Button
                        className="btn btn-primary mt-2"
                        onClick={handleContinueClick}
                      >
                        Continue
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* display and add sign in offer pdf */}
        <Modal
          size="lg"
          show={acceptOfferModal}
          onHide={() => setAcceptOfferModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={() => {
                  setAcceptOfferModal(false);
                  setFormData({ show: false });
                }}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <span
              className="btn-back d-flex align-items-center ps-4"
              onClick={() => {
                setAcceptOfferModal(false);
                setFormData({ ...formData, show: true });
                setUrl("");
              }}
            >
              <IoArrowBackOutline /> Back to edit data
            </span>
            <br/>
            <div className="d-flex justify-content-center">
              <Button
              variant="primary"
                className="mx-2"
               
                onClick={handleSignModalShow}
              >
                Add Sign
              </Button>
              <Button
                 variant="primary"
                className="mx-2"
                onClick={() => saveDocument(pdf)}
                disabled={isSaving}
              >
                Save Document
              </Button>
            </div>
            {error.document && (
              <div className="text-danger text-center p-2">
                Document can not be saved. Signature is required.
              </div>
            )}
            {pdf.path && (
              <div className="" ref={documentRef}>
                <Document
                  loading="Loading Offer"
                  file={pdf.path}
                  onLoadSuccess={(data) => {
                    setTotalPages(data.numPages);
                  }}
                >
                  <Page
                    loading="Loading Offer Pages"
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={false}
                    pageNumber={pageNum}
                    onLoadSuccess={(data) => {
                      setPageDetails(data);
                    }}
                  />
                </Document>
              </div>
            )}
          </Modal.Body>
        </Modal>

        {/* view pdf file  */}
        <Modal
          size="lg"
          show={viewPdf.show}
          onHide={() => setViewPdf({ ...viewPdf, show: false })}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={() => setViewPdf({ ...viewPdf, show: false })}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              <div className="icon mx-1 mx-sm-3" onClick={downloadPdf}>
                <img src="/images/download.png" alt="" className="download" />
              </div>
              <div className="icon mx-1 mx-sm-3" onClick={printPdf}>
                <img src="/images/printer.png" alt="" className="printer" />
              </div>
            </div>
            <div>
              {viewPdf.show && (
                <>
                  <Document
                    loading="Loading Offer"
                    file={viewPdf.file}
                    onLoadSuccess={(data) => {
                      setTotalPages(data.numPages);
                    }}
                  >
                    <Page
                      loading="Loading Offer Pages"
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      customTextRenderer={false}
                      pageNumber={pageNum}
                      className="w-100"
                      onLoadSuccess={(data) => {
                        setPageDetails(data);
                      }}
                    />
                  </Document>
                  {totalPages > 1 && (
                    <div className="page-controls d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={pdfPrevPage}
                        disabled={pageNum === 1 ? true : false}
                      >
                        ‹
                      </button>
                      <span className="mx-2">
                        {pageNum} of {totalPages}
                      </span>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={pdfNextPage}
                        disabled={pageNum === totalPages ? true : false}
                      >
                        ›
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {/* reschedule-popup */}
        {offers.map((interview, index) => (
          <CustomModal
            key={index}
            isOpen={interview.reschedule.isShow}
            onClose={() => closeRescheduledDatePickier(index)}
          >
            <div
              className={`reschedule-popup`}
              style={{
                top: interview.reschedule.top,
                left: interview.reschedule.left,
              }}
            >
              <div className="reschedule-popup-date bg-blue mb-2 text-center py-2">
                Current Interview : <br />
                {interview.reschedule.date
                  .toLocaleString("en-GB", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })
                  .replace(/00:/g, "12:")}
                <br />
                {`${interview.reschedule.date}`.substring(
                  `${interview.reschedule.date}`.indexOf("("),
                  `${interview.reschedule.date}`.lastIndexOf(")") + 1
                )}
              </div>
              <Datetime
                className=""
                value={interview.reschedule.date}
                onChange={(date) => handleRescheduledDateChange(date, index)}
                dateFormat="DD/MM/YYYY"
                timeFormat="hh:mm A" // Set time format to AM/PM
                isValidDate={(current) =>
                  current.isAfter(new Date()) ||
                  current.isSameOrAfter(
                    new Date().setDate(new Date().getDate() - 1)
                  )
                } // Set the minimum date
                input={false}
              />
              {interview.reschedule.error && (
                <div className="bg-white text-danger fs-6 mb-2 text-center">
                  Please select Date and Time
                </div>
              )}
              {interview.reschedule.error2 && (
                <div className="bg-white text-danger fs-6 mb-2 text-center">
                  Time selected should be <br /> later than Current Time
                </div>
              )}
              <div className="d-flex justify-content-center mt-2">
                <Button
                variant="primary"
                  className="me-1 btn-sm"
                  onClick={() => handleRescheduleClick(index)}>
                  Schedule
                </Button>
                <Button
                variant="secondary"
                  className="ms-1 btn-sm"
                  onClick={() => closeRescheduledDatePickier(index)}>
                  Cancel
                </Button>
              </div>
            </div>
          </CustomModal>
        ))}

        {/* delete confoirmation modal  */}
        <Modal
          show={deleteConfirmationModal.show}
          onHide={() => handleClose(deleteConfirmationModal.index)}
          id="deleteConfirmationModal"
          className="confirmationBox"
        >
          <Modal.Header className="p-0 justify-content-end border-0">
            <div className="position-relative">
              <AiOutlineClose
                onClick={() => handleClose(deleteConfirmationModal.index)}
                className="icon close-icon position-absolute"
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Reason for Cancellation?</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="cancelreason"
                  value={offers[deleteConfirmationModal.index]?.reasonforcancel}
                  onChange={(cancelreason) =>
                    handleReasonOfCancal(
                      cancelreason,
                      deleteConfirmationModal.index
                    )
                  }
                  required
                />
                {formErrors.msg && (
                  <div className="text-danger">{formErrors.msg}</div>
                )}
              </Form.Group>
            </Form>
            <h5>Are you sure you want to cancel offer?</h5>
            <div className='d-flex justify-content-center gap-10 mt-4'>
            <Button variant="danger" type="button" onClick={() => cancelInterview(deleteConfirmationModal.index)} >
                Yes
              </Button>
              <Button variant="secondary" onClick={() => handleClose(deleteConfirmationModal.index)}>
                No
              </Button>
             
            </div>
          </Modal.Body>
        </Modal>
        <DialogAlert
          open={open}
          handleClose={handleAlertClose}
          message={dialogMessage}
          title="Warning"
        />
      </Container>
    </div>
  );
};

export default Offers;
