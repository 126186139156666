import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import "./CndAgreementSing.css"

const CndAgreement = () => {
    const navigate = useNavigate();
    return (
      <div className='cndAgreement h-100'>
        <div className='instruction'>
          <p>You need to agree and sign the
            Contact with HelperWish before
            you can view the detail</p>
            <br/>
          <button className='proceedToC btn-rounded' onClick={() => navigate('/candidate/profile/CndAgreementSing')}>Proceed to Contract</button>
        </div>
      </div>
    )
  }

  export default CndAgreement